import React, {Component} from 'react'
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon'
import {Field, FieldsManager} from '../../../data/services/fields'
import {getProp} from '../../../common/util/util-helpers'
import TrashIcon from '@heroicons/react/24/outline/TrashIcon'
import {ArrowPathIcon, ClipboardDocumentListIcon, PlusIcon} from '@heroicons/react/24/outline'
import {
    LOAD_PRICING_FLAT,
    LOAD_PRICING_PER_MILE,
    LOAD_PRICING_PER_PIECE,
    LOAD_PRICING_PER_VOLUME,
    LOAD_PRICING_PER_WEIGHT,
    MATRIX_PRICING_ZIP,
    MATRIX_PRICING_ZONE
} from '../../../util/util-constants'
import Resources from '../../../data/services/resources'
import {createResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import ModalFooter from "../../../common/components/modal/modal-footer";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";

export default class PricingMatrixCreateUpdateDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            Items: this.getItemsFields(),

            canSubmit: false
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        const fields = FieldsManager.updateField(this.state.fields, name, value)

        if (name === 'PricingMatrixTypeID' || name === 'LoadPayMethodID') {
            this.setState({
                fields: fields,
                Items: [this.getDefaultItemField(FieldsManager.getFieldKeyValues(fields))],
                canSubmit: true
            })
        } else {
            this.setState({
                fields: fields,
                canSubmit: true
            })
        }
    }

    handleItemInputChange = (name, value, i) => {
        let Items = [...this.state.Items]
        Items[i][name].value = value
        this.setState({Items: Array.from(Items), canSubmit: true})
    }

    handleRemoveItemFromList = (index) => {
        let Items = this.state.Items
        Items.splice(index, 1)
        this.setState({Items: Array.from(Items), canSubmit: true})
    }

    handleAddReversedItem = (item) => {
        let Items = this.state.Items
        let reversedValues = Object.keys(item).reduce((memo, it) => {
            memo[it] = Object.assign({}, item[it])
            if (item[it]?.metadata?.reverseValueKey) {
                memo[it].value = item[item[it]?.metadata?.reverseValueKey].value
            }
            return memo
        }, {})
        Items.push(reversedValues)
        this.setState({Items: Array.from(Items), canSubmit: true})
    }

    handleAddItemToList = () => {
        this.setState({
            Items: this.state.Items.concat(this.getDefaultItemField(FieldsManager.getFieldKeyValues(this.state.fields)))
        })
    }

    handleSubmit = () => {
        this.setState({
            fields: FieldsManager.validateFields(this.state.fields),
            Items: this.state.Items.map(item => FieldsManager.validateFields(item))
        }, () => {
            if (
                FieldsManager.checkFieldsForErrors(this.state.fields) &&
                this.state.Items.every(item => FieldsManager.checkFieldsForErrors(item))
            ) {
                if (this.props.selectedItem) {
                    const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        Items: this.state.Items.map(item => FieldsManager.getFieldKeyValues(item)),
                        [this.props.primaryKey]: this.props.selectedItem[this.props.primaryKey]
                    })
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName,
                        query: this.props.query,
                        errorMessage: true, successMessage: 'Pricing Matrix Updated.',
                    }))
                    this.onClose()
                } else {
                    const params = Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        Items: this.state.Items.map(item => FieldsManager.getFieldKeyValues(item))
                    })
                    this.props.dispatch(createResource({
                        user: LocalStorage.get('user'),
                        params: params,
                        resource: this.props.resourceName,
                        piggyResource: this.props.piggyResourceName,
                        query: this.props.query,
                        errorMessage: true, successMessage: 'Pricing Matrix Created.',
                    }))
                    this.onClose()
                }
            }
        })
    }

    onClose = () => {
        this.props.onClose()
    }

    /** Helpers
     ================================================================= */
    getFields = () => {
        const fieldTemplates = {
            MatrixName: new Field('MatrixName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-3'}),
            LoadPayMethodID: new Field('LoadPayMethodID', '', ['empty'], false, 'select',
                {addContainerClass: 'col-span-3'}
            ),
            PricingMatrixTypeID: new Field('PricingMatrixTypeID', '', ['empty'], false, 'select',
                {addContainerClass: 'col-span-3'}
            ),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-3'})
        }

        return fillFieldsFromData(fieldTemplates, this.props.selectedItem)
    }

    getItemsFields = () => {
        if (this.props.selectedItem) {
            const items = getProp(this.props.selectedItem, 'Items', [])
            let selectedItem = Object.assign({}, this.props.selectedItem)
            delete selectedItem.Notes
            if (items.length > 0) {
                return items.map((item) => this.getDefaultItemField(Object.assign({}, item, selectedItem)))
            } else {
                return [this.getDefaultItemField(this.props.selectedItem)]
            }
        } else {
            return [this.getDefaultItemField()]
        }
    }

    getDefaultItemField = (item = null) => {
        let fieldTemplates = {}

        if (item && item.PricingMatrixTypeID == MATRIX_PRICING_ZIP) {
            fieldTemplates = Object.assign({}, fieldTemplates, {
                StartZip1: new Field('StartZip1', '', [], false, 'text', {
                    addContainerClass: 'p-2',
                    reverseValueKey: "EndZip1"
                }),
                EndZip1: new Field('EndZip1', '', [], false, 'text', {
                    addContainerClass: 'p-2',
                    reverseValueKey: "StartZip1"
                }),
                StartZip2: new Field('StartZip2', '', [], false, 'text', {
                    addContainerClass: 'p-2',
                    reverseValueKey: "EndZip2"
                }),
                EndZip2: new Field('EndZip2', '', [], false, 'text', {
                    addContainerClass: 'p-2',
                    reverseValueKey: "StartZip2"
                })
            })
        } else if (item && item.PricingMatrixTypeID == MATRIX_PRICING_ZONE) {
            fieldTemplates = Object.assign({}, fieldTemplates, {
                StartZoneID: new Field('StartZoneID', '', [], false, 'select-search', {
                    addContainerClass: 'p-2',
                    reverseValueKey: "EndZoneID"
                }),
                EndZoneID: new Field('EndZoneID', '', [], false, 'select-search', {
                    addContainerClass: 'p-2',
                    reverseValueKey: "StartZoneID"
                }),
            })
        }

        if (item?.LoadPayMethodID != LOAD_PRICING_FLAT) {
            fieldTemplates = Object.assign({}, fieldTemplates, {
                LowValue: new Field('LowValue', '', [], false, 'float', {addContainerClass: 'p-2'}),
                HighValue: new Field('HighValue', '', [], false, 'float', {addContainerClass: 'p-2'}),
            })
        }

        fieldTemplates = Object.assign({}, this.props.primaryKey == "CommodityPricingMatrixID" ? {
            CommodityID: new Field('CommodityID', '', ['empty'], false, 'select-search', {addContainerClass: 'p-2'}),
        } : {}, fieldTemplates, {
            Rate: new Field('Rate', '', [], false, 'float', {addContainerClass: 'p-2'}),
            Notes: new Field('Notes', '', [], false, 'textarea', {addContainerClass: 'col-span-full p-2'}),
        })

        return fillFieldsFromData(fieldTemplates, item)
    }

    render() {
        const {translate, selectedItem} = this.props
        const metadata = {
            PricingMatrixTypeID: {
                [MATRIX_PRICING_ZIP]: 'Zip to Zip',
                [MATRIX_PRICING_ZONE]: 'Zone to Zone'
            },
            LoadPayMethodID: {
                [LOAD_PRICING_FLAT]: 'Flat',
                [LOAD_PRICING_PER_MILE]: 'Per mile',
                [LOAD_PRICING_PER_WEIGHT]: 'Per weight',
                [LOAD_PRICING_PER_VOLUME]: 'Per volume',
                [LOAD_PRICING_PER_PIECE]: 'Per piece'
            },
            StartZoneID: {
                api: 'api/' + Resources.LocationZones,
                query: {},
                searchMap: (item) => ({
                    value: item.LocationZoneID,
                    label: item.LocationZone
                })
            },
            EndZoneID: {
                api: 'api/' + Resources.LocationZones,
                query: {},
                searchMap: (item) => ({
                    value: item.LocationZoneID,
                    label: item.LocationZone
                })
            },
            CommodityID: {
                api: 'api/' + Resources.Commodities,
                query: {
                    limit: 30,
                    offset: 0
                },
                searchMap: (item) => ({
                    value: item.CommodityID,
                    label: item.ProductName
                })
            },
        }

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)

        const itemsToHtml = this.state.Items.map((item, i) => {
            const columnOne = fieldsToHtml(Object.values(Object.assign({}, item)), translate, (name, value) => this.handleItemInputChange(name, value, i), metadata)

            const columnTwo = columnOne.splice(-1, 1)

            return (
                <div key={i} className={`my-5 pb-5 border-t border-tm-gray-200 ${i % 2 === 0 ? "bg-tm-gray-100" : ""}`}>
                    <div className={'flex justify-end mt-2'}>
                        <button
                            type="button"
                            onClick={() => {
                                this.handleAddReversedItem(item)
                            }}
                            className="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            <ArrowPathIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                            {translate('btn.add_reversed_value')}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                this.setState({[`ItemNotes${i}`]: !this.state[`ItemNotes${i}`]})
                            }}
                            className="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            <ClipboardDocumentListIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                            {translate('btn.toggle_notes')}
                        </button>
                        {this.state.Items.length > 1 && (
                            <button
                                type="button"
                                onClick={() => {
                                    this.handleRemoveItemFromList(i)
                                }}
                                className="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2"
                            >
                                <TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                                {translate('btn.delete')}
                            </button>
                        )}
                    </div>
                    <div className={"grid"} style={{
                        gridTemplateColumns: `repeat(${columnOne.length}, minmax(0, 1fr))`
                    }}>
                        {columnOne}
                    </div>
                    {!!this.state[`ItemNotes${i}`] && (
                        <div>
                            {columnTwo}
                        </div>
                    )}
                </div>
            )
        })

        return (
            <div>
                <header
                    className="p-4 flex items-center border-tm-gray-200 border-b text-tm-gray-900">
                    <h2
                        className="text-lg text-current truncate">{translate(`text.${selectedItem ? 'Update' : 'Create'}PricingMatrix`)}</h2>

                    <button
                        className="absolute right-5 top-5 btn btn-close"
                        aria-label="Close"
                        onClick={this.onClose}>
                        <XMarkIcon className="w-4 h-4"/>
                    </button>
                </header>

                <div className="p-5">
                    <div className="mb-5 grid grid-cols-3 gap-5">
                        {fieldsHtml}
                    </div>

                    {itemsToHtml}

                    <div className={'mt-5 text-center'}>
                        <button
                            type="button"
                            onClick={this.handleAddItemToList}
                            className="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md  bg-tm-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        >
                            <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true"/>
                            {translate('btn.AddNew')}
                        </button>
                    </div>
                </div>

                <ModalFooter
                    buttonLabel={translate('btn.save')}
                    buttonDisabled={!this.state.canSubmit}
                    onButtonClick={this.handleSubmit}

                    closeButtonLabel={translate('btn.cancel')}
                    onClose={this.onClose}
                />
            </div>
        )
    }
}
