import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {download} from '../../../data/actions/download'
import {Field, FieldsManager} from '../../../data/services/fields'
import Tippy from '@tippyjs/react'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    MAJOR_ACCOUNT_TYPE_ASSET,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_INCOME,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import {getSecondResource} from '../../../data/actions/secondResource'
import AccountReportDialog from '../dialogs/account-report-dialog'
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../common/components/resource-table";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import Page from "../../../common/components/layout/layout-components/page";
import Layout from "../../../common/components/layout";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import {fillFieldsFromData} from "../../../common/util/util-fields";
import Button from '../../../common/components/button'
import {DEFAULT_DATABASE_DATETIME_FORMAT, DEFAULT_QUERY_LIMIT} from '../../../common/util/util-consts'
import moment from "moment-timezone";

class Accounts extends Component {

    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: 'AccountName',
            accountTransactionsFields: {
                StartDate: new Field(
                    'StartDate',
                    moment().startOf('year').format(DEFAULT_DATABASE_DATETIME_FORMAT),
                    ['date'],
                    false,
                    'date',
                    {
                        addContainerClass: 'col-span-4'
                    }),
                EndDate: new Field(
                    'EndDate',
                    moment().format(DEFAULT_DATABASE_DATETIME_FORMAT),
                    ['date'],
                    false,
                    'date',
                    {
                        addContainerClass: 'col-span-4'
                    })
            },

            queryFilterFields: this.getQueryFilterFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),

            isResourceTableDialogVisible: false,
            confirmDialog: false,
            resourceTableID: undefined,
            breakpoint: {},
            isTableFullScreen: false
        }

        if (!this.state.tableOptions.behaviour.hasMenu) { // TEMP FUNCTION
            this.state.tableOptions.behaviour = Object.assign(this.state.tableOptions.behaviour, this.tablePageDefaults.behaviour);
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */

    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    archiveResource = (item) => {

        this.setState({text: this.props.translate('message.confirm_archive_generic') + ` ${item.AccountName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({
                            AccountID: item.AccountID
                        }, this.getQuery()),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.AccountName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {

        this.setState({text: this.props.translate('message.confirm_restore_generic') + ` ${item.AccountName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            AccountID: item.AccountID,
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `${item.AccountName} account restored`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'accounts_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelReports = (query) => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingAccountReport,
            query: Object.assign({
                format: 'EXCEL',
                name: 'chart_of_accounts_transactions_' + currentDate() + '.xlsx'
            }, query)
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10)
            }
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'AccountName'
            sort = 'ASC'
        }

        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: true
        })
    }

    handleToggleEditModal = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, editModalOpen: !this.state.editModalOpen})
    }

    handleToggleCreateModal = (selectedItem = null) => {
        this.setState({selectedItem: selectedItem, createModalOpen: !this.state.createModalOpen})
    }

    toggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleAccountDetails = (selectedItem) => {
        this.setState({
            selectedItem: selectedItem,
            accountDetailsDialog: !this.state.accountDetailsDialog
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate)
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleInputChange = (fields, name, value) => {
        fields = FieldsManager.updateField(fields, name, value)

        if (name === 'EnableCreateAsSubAccount') {
            fields['ParentAccountID'].disabled = !value
        }

        if (name === 'EnableCreateAsSubAccount') {
            if (!value) {
                fields['ParentAccountID'].value = {label: null, value: null}
            }
            fields['ParentAccountID'].validate = value ? ['empty'] : ['']
        }

        return fields
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'query', 'offset', 'paginationPage', 'showArchived'])
    }

    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,

            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
        }
    }

    getQueryFilterFields = () => ({
        query: new Field('query', '', [''], false, 'search', {}, {}),
        MainAccountTypeID: new Field('MainAccountTypeID', '', [''], false, 'select', {}, {
            values: {
                [MAJOR_ACCOUNT_TYPE_EXPENSE]: 'Expense',
                [MAJOR_ACCOUNT_TYPE_INCOME]: 'Income',
                [MAJOR_ACCOUNT_TYPE_ASSET]: 'Asset',
                [MAJOR_ACCOUNT_TYPE_LIABILITY]: 'Liability',
            },
            isClearable: true
        }),
        AccountTypeID: new Field('AccountTypeID', '', [''], false, 'select'),
        NotePayable: new Field('NotePayable', '', [''], false, 'checkbox'),
        DriverEscrow: new Field('DriverEscrow', '', [''], false, 'checkbox'),
        BankAccount: new Field('BankAccount', '', [''], false, 'checkbox', {label: "IsDebitAccount"}),
        archived: new Field('archived', '', [''], false, 'checkbox'),
        limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {hideLabel: true}, {menuPlacement: 'top'})
    })

    getFields = (item = null) => {
        const fieldTemplates = {
            AccountNumber: new Field('AccountNumber', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            AccountName: new Field('AccountName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-full'}),
            AccountTypeID: new Field('AccountTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            EnableCreateAsSubAccount: new Field('EnableCreateAsSubAccount', item?.ParentAccount ? 1 : 0, [''], false, 'checkbox', {
                addContainerClass: 'col-span-full',
                hideTable: true
            }),
            ParentAccountID: new Field('ParentAccountID', '', [''], item?.ParentAccount ? false : true, 'select-search', {addContainerClass: 'col-span-full'}),
            BankAccount: new Field('BankAccount', '', [''], false, 'checkbox', {
                label: "DebitAccountID",
                addContainerClass: 'col-span-full'
            }),
            DriverEscrow: new Field('DriverEscrow', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            NotePayable: new Field('NotePayable', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            NoteReceivable: new Field('NoteReceivable', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            IsChargeToContact: new Field('IsChargeToContact', '', [''], false, 'checkbox', {addContainerClass: 'col-span-full'}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    getResource = () => {
        return Resources.Accounts
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = !!resource.isLoading;

        const metadata = {
            ParentAccountID: {
                api: 'api/accounting/accounts',
                query: {},
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        }

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.chartOfAccounts')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />


                    <div className={'sm:flex mr-4'}>
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />
                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />

                            <Button
                                hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                                className={`btn btn-primary`}
                                onClick={() => {
                                    this.handleToggleCreateModal()
                                }}
                            >
                                {translate('btn.create_new')}
                            </Button>
                        </div>
                    </div>


                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? 'fixed inset-1 -top-1 z-50'
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(this.getResource(), READ_PERM) ? this.handleToggleAccountDetails : null}
                            onEdit={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleToggleEditModal : null}
                            onView={checkPerm(this.getResource(), READ_PERM) ? this.handleToggleAccountDetails : null}
                            onDelete={checkPerm(this.getResource(), DELETE_PERM) ? this.archiveResource : null}
                            onRestore={checkPerm(this.getResource(), UPDATE_PERM) ? this.restoreFromArchive : null}
                            tableKey={'AccountID'}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === 'offset'
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create new account'}
                            btnLabel="Create account"
                            onBtnClick={() => {
                                this.handleToggleCreateModal({AccountNumber: this.state.queryFilterFields.query.value})
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>
                </Page>

                <ModalSaveResource
                    title={translate('text.edit_account')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.editModalOpen}
                    onClose={() => this.handleToggleEditModal()}
                    fields={this.getFields(this.state.selectedItem)}
                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            params.AccountID = this.state.selectedItem.AccountID
                            this.props.dispatch(updateResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Account updated.',
                            }))
                            this.handleToggleEditModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalSaveResource
                    title={translate('text.add_account')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    show={this.state.createModalOpen}
                    onClose={() => this.handleToggleCreateModal()}
                    fields={this.getFields(this.state.selectedItem)}
                    handleInputChange={this.handleInputChange}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResource(),
                                piggyResource: this.getResource(),
                                errorMessage: true, successMessage: 'Account created.',
                            }))
                            this.handleToggleCreateModal()
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmDialog}
                    text={this.state.text}
                    onClose={() => this.setState({confirmDialog: false})}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmModal}
                />

                <AccountReportDialog
                    location={this.props.location}
                    AccountID={this.state.selectedItem?.AccountID}
                    AccountName={this.state.selectedItem?.AccountName}
                    StartDate={this.state.accountTransactionsFields.StartDate?.value}
                    EndDate={this.state.accountTransactionsFields.EndDate?.value}
                    onClose={this.handleToggleAccountDetails}
                    show={this.state.accountDetailsDialog}
                    translate={this.props.translate}
                    getTransactions={(query) => {
                        this.props.dispatch(getSecondResource({
                            user: LocalStorage.get('user'),
                            resource: Resources.AccountingAccountReport,
                            query: Object.assign(query, {
                                id: query.AccountID
                            })
                        }))
                    }}
                    downloadExcelReports={(query) => this.downloadExcelReports(query)}
                    isLoading={getProp(this.props, 'secondResource.isLoading', false)}
                    transactions={getProp(this.props, 'secondResource.data', {})}
                    thirdResource={this.props.thirdResource}
                    dialogResource={this.props.dialogResource}
                    dispatch={this.props.dispatch}
                />

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(Accounts)
