import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showModal} from '../../../data/actions/ui'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getLookup,
    getProp,
    mergeDeep,
    resourceIsCreated,
    returnOffsetAndPagination,
    saveTableColumns,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import Tippy from '@tippyjs/react'
import MyCarrierIntegrationImportDialog from './my-carrier-import-integration-dialog'
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import {CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM, READ_PERM, UPDATE_PERM} from '../../../util/util-constants'
import {currentDate, toFrontDateTimeFromUTC} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import CarriersImportDialog from "./carriers-import-dialog";
import {resetDialogResource} from "../../../data/actions/dialogResource";
import {Bars4Icon, DocumentTextIcon, PresentationChartBarIcon} from "@heroicons/react/24/outline";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";
import CellButtonWithCopy from "../../../common/components/resource-table/table-components/cell-button-copy";
import Layout from "../../../common/components/layout";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import ModalDefault from "../../../common/components/modal/modal-default";
import ModalHeader from "../../../common/components/modal/modal-header";
import Modal from "../../../common/components/modal";
import ResourceTable from "../../../common/components/resource-table";
import CreateSuccessModal from "../../../common/components/modal/create-success-modal";
import TableButtonsArea from "../../../common/components/resource-table/table-components/table-buttons-area";
import {getUser} from "../../../common/util/util-auth";
import ButtonIntegration from "../../../common/components/button/button-integration";

class CarriersView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'LegalName',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            isColumnsDialogVisible: false,

            // Dialogs
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            isImportCarrierDialogVisible: false,
            breakpoint: {},
            isTableFullScreen: false
        }

        this.state = mergeDeep(this.state, LocalStorage.rehydrateState(this.pagePath));
        this.importedCarrierID = "";
        this.OrganizationID = "";
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps) {
        if (resourceIsCreated(this.props.resource, prevProps.resource, "CarrierID")) {
            this.importedCarrierID = this.props.resource.create.CarrierID;
            this.OrganizationID = this.props.resource.create.OrganizationID;

            this.toggleCreateSuccessDialog();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({format: 'EXCEL', name: 'carriers_' + currentDate() + '.xlsx'}, this.getQuery())
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'carriers_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_archive_generic')} ${item.LegalName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, {id: item.CarrierID}),
                        piggyQuery: this.getQuery(),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [item.LegalName]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate('message.confirm_restore_generic')} ${item.LegalName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item.CarrierID,
                            ArchivedDate: 1
                        },
                        query: this.getQuery(),
                        errorMessage: true, successMessage: `Carrier ${item.SCAC} restored`,
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    importCarrier = (data) => {
        const states = getLookup('State', 'StateID', "StateAbbreviation") ?? {};

        const params = {
            "CountryID": 1,
            "LegalName": data["CompanyName"],
            "DBAName": data["DBAName"],
            "AddressName": data["Street"],
            "AddressName2": "",
            "CityName": data["City"],
            "StateID": Object.keys(states).find(stateKey => states[stateKey] === data["State"]),
            "PostalCode": data["ZipCode"],
            "ContactGroupID": getUser('ContactGroups')?.[0]?.ContactGroupID,
            "UserDefinedField1": "",
            "UserDefinedField2": "",
            "AreaCode": "+1",
            "PhoneNumber": data["Phone"],
            "PhoneExtension": "",
            "PrimaryEmail": data['PrimaryEmail'],
            "SCAC": "",
            "OrganizationEntityTypeID": "",
            "MCNumber": data["DocketNumber"],
            "USDOTNumber": data["DotNumber"],
            "FederalID": "",
            "NaicsID": "",
            "SendPromotionalEmails": 1,
            "Notes": 'DoubleBrokering: ' + data['DoubleBrokering'] + ", PossibleFraud: " + data['PossibleFraud'],
            "ContractOnFile": 0,
            "ContractStartDate": "",
            "ContractEndDate": "",
            "ContactNumber": "",
            "Blacklisted": 0,
            "Preferred": 0,
            "NumOfTrucks": "",
            "NumOfTrailers": "",
            "LiabilityInsuranceNo": "",
            "LiabilityInsuranceExpiryDate": "",
            "LiabilityInsuranceAmount": "",
            "CargoInsuranceNo": "",
            "CargoInsuranceExpiryDate": "",
            "CargoInsuranceAmount": "",
            "TrailerInteropInsuranceNo": "",
            "TrailerInteropInsuranceExpiryDate": "",
            "WorkCompInsuranceNo": "",
            "WorkCompInsuranceExpiryDate": "",
            "IsCommonAuth": 0,
            "IsContractAuth": 0,
            "IsBrokerAuth": 0,
            "Reviews": [],
            "Contacts": []
        }

        this.props.dispatch(createResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.Carriers,
            errorMessage: true,
            documentResource: Resources.CarrierDocuments,
        }))
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }

    handleImportCarrierClick = () => {
        this.setState({
            isImportCarrierDialogVisible: true
        }, () => {
            this.props.dispatch(resetDialogResource());
        })
    }

    handleCloseImportDialogClick = () => {
        this.setState({
            isImportCarrierDialogVisible: false
        }, () => {
            resetDialogResource();
        })
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleShowDetails = (item) => {
        this.props.dispatch(showModal('ViewCarrierCard', item))
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'LegalName'
        }
        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        const queryFilterFields = FieldsManager.updateField(this.state.queryFilterFields, name, value)
        if (name === 'Preferred' && !!value) queryFilterFields.Blacklisted.value = false
        if (name === 'Blacklisted' && !!value) queryFilterFields.Preferred.value = false

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleCreateUpdateResource = (item = null) => {
        if (item) {
            this.handleLinkClick(`/carriers/info/${item.OrganizationID}/${item.CarrierID}`)
        } else {
            this.handleLinkClick(`/carrier/create?prefilled=` + this.state.prefilled)
        }
    }

    handleLinkClick = (link) => {
        this.props.history.push(link)
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), {}, this.props.translate);
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    toggleCreateSuccessDialog = () => {
        this.setState({
            isCreateSuccessDialogOpen: !this.state.isCreateSuccessDialogOpen
        })
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                Blacklisted: this.state.queryFilterFields.Blacklisted.value ? 1 : 0,
                Preferred: this.state.queryFilterFields.Preferred.value ? 1 : 0,
            })
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3'
            }, {}),
            PreferenceTypeID: new Field('PreferenceTypeID', '', [''], false, 'select', {}, {
                isClearable: true,
                all: true,
            }),
            PreferenceTypeSubItem: new Field('PreferenceTypeSubItem', '', [''], false, 'multi-select', {}, {
                isClearable: true
            }),
            Blacklisted: new Field('Blacklisted', '', [''], false, 'checkbox'),
            Preferred: new Field('Preferred', '', [''], false, 'checkbox'),
            StateID: new Field('StateID', '', [], false, 'select'),
            ContactGroupID: new Field('ContactGroupID', '', [], false, 'select-search'),
            OfficeID: new Field('OfficeID', '', [], false, 'select-search'),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getFields = () => {
        return {
            LegalName: new Field('LegalName', '', ['empty'], false, 'custom', {
                render: (item) => {
                    let disabled = !item.Blacklisted && !item.Preferred
                    return (
                        <Tippy
                            disabled={disabled}
                            content={
                                <span>
                                        {item.Blacklisted ? `Blacklisted by: ${item.BlacklistUpdatedByContact} - ${toFrontDateTimeFromUTC(item.BlacklistUpdateDate)}` : ''}
                                    {item.Preferred ? `Preferred by: ${item.PreferredUpdatedByContact} - ${toFrontDateTimeFromUTC(item.PreferredUpdateDate)}` : ''}
                                    </span>
                            }
                        >
                                <span
                                    className={`${disabled ? 'cursor-pointer font-semibold' : ''} ${item.Blacklisted ? 'color-red' : ''} ${item.Preferred ? 'color-green' : ''} cursor-pointer`}
                                >
                                    {`${item.LegalName}`}
                                </span>
                        </Tippy>
                    )
                }
            }),
            DBAName: new Field('DBAName', '', ['empty']),
            USDOTNumber: new Field('USDOTNumber', '', ['empty']),
            MCNumber: new Field('MCNumber', '', ['empty'], false, 'custom', {
                label: "MCMXNumber",
                render: (it) => <CellButtonWithCopy copyText={it.MCNumber} translate={this.props.translate}>
                    {it.MCNumber}
                </CellButtonWithCopy>
            }),
            FFNumber: new Field('FFNumber', '', ['empty'], false, 'custom', {
                render: (it) => <CellButtonWithCopy copyText={it.FFNumber} translate={this.props.translate}>
                    {it.FFNumber}
                </CellButtonWithCopy>
            }),
            SCAC: new Field('SCAC', '', ['empty']),
            FederalID: new Field('FederalID', '', ['empty']),
            AddressName: new Field('AddressName', '', ['empty']),
            CityName: new Field('CityName', '', ['empty']),
            State: new Field('State', '', ['empty']),
            Country: new Field('Country', '', []),
            SendPromotionalEmails: new Field('SendPromotionalEmails', '', [], false, 'checkbox'),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search'),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'date')
        }
    }

    saveFilters = () => {
        let filters = []
        Object.keys(this.getQuery()).map(it => filters.push(it))
        LocalStorage.persistState(this.pagePath, this.state, filters)
    }

    getResource = () => {
        return Resources.Carriers
    }

    getPrimaryKey = () => {
        return 'CarrierID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props
        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const isLoading = resource.isLoading;
        const hasIntegration = !!getProp(this.props.resource, "data.integrations.MyCarrier.hasIntegration", false) && !isLoading;

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.carriers')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        secondaryButtonHidden={!hasIntegration}
                        onSecondaryButtonClick={this.handleImportCarrierClick}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        <ButtonIntegration
                            id="integration-btn"
                            hasIntegration={hasIntegration}
                            history={this.props.history}
                            hasPerm={checkPerm(Resources.Carriers, CREATE_PERM)}
                            onClick={this.handleImportCarrierClick}
                            integrationName={"MyCarrier"}
                        >
                            {"Import carrier"}
                        </ButtonIntegration>
                    </PageHeader>

                    <div className="flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <TableButtonsArea>
                            {/*<Tippy content={translate('text.download_template')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcelTemplate}
                                >
                                    <RectangleGroupIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </TableButtonsArea>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? "fixed inset-1 -top-1 z-50"
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(this.getResource(), UPDATE_PERM) ? this.handleCreateUpdateResource : null}

                            onView={this.handleShowDetails}
                            onEdit={this.handleCreateUpdateResource}
                            onDelete={this.archiveResource}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(Resources.OrganizationsQuick, READ_PERM)}
                            hasEditPerm={checkPerm(Resources.Carrier, UPDATE_PERM)}
                            hasDeletePerm={checkPerm(this.getResource(), DELETE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            tableKey={this.getPrimaryKey()}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <NoRecordsTable
                            show={(data.length === 0) && !isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={translate('text.no_matching_records')}
                            text={translate('text.create_new_resource', [this.state.queryFilterFields.query.value])}
                            btnLabel={translate('btn.create_carrier')}
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleCreateUpdateResource()
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    {this.state.MyCarrierIntegrationDialog && (
                        <MyCarrierIntegrationImportDialog
                            translate={translate}
                            resourceName={this.state.resourceName}
                            integrationResource={this.props.integrationResource}
                            dispatch={this.props.dispatch}
                            show={this.state.MyCarrierIntegrationDialog}
                            hideResourceDialog={() => this.setState({MyCarrierIntegrationDialog: false})}
                        />
                    )}

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    <ModalDefault
                        show={this.state.isImportCarrierDialogVisible}
                        widthClass={"max-w-7xl rounded-b-none"}
                        limitHeight={true}
                        title={translate("modal_heading.import_carrier")}
                        closeButtonLabel={translate("btn.close")}
                        onClose={this.handleCloseImportDialogClick}
                        hideDialogFooter={true}
                    >
                        <CarriersImportDialog
                            translate={translate}
                            dialogResource={this.props.dialogResource}
                            onClose={this.handleCloseImportDialogClick}
                            onImportCarrierClick={this.importCarrier}
                        />
                    </ModalDefault>

                    <Modal
                        show={this.state.isCreateSuccessDialogOpen}
                        widthClass={'max-w-sm w-screen'}
                        onClose={this.toggleCreateSuccessDialog}
                    >
                        <ModalHeader
                            title={translate("text.carrier_imported_successfully")}
                            onClose={this.toggleCreateSuccessDialog}
                        />
                        <CreateSuccessModal
                            translate={translate}
                            buttons={[
                                {
                                    label: translate("btn.view_imported_carrier"),
                                    onClick: () => this.props.history.push(
                                        "/carriers/info/" + this.OrganizationID + "/" + this.importedCarrierID
                                    ),
                                    iconLeading: DocumentTextIcon,
                                },
                                {
                                    label: translate("btn.list"),
                                    onClick: () => {
                                        this.fetchData();
                                        this.toggleCreateSuccessDialog();
                                    },
                                    iconLeading: Bars4Icon,
                                    iconTrailing: ArrowTopRightOnSquareIcon
                                },
                                {
                                    label: translate("btn.dashboard"),
                                    onClick: () => this.props.history.push("/home"),
                                    iconLeading: PresentationChartBarIcon,
                                    iconTrailing: ArrowTopRightOnSquareIcon
                                },
                            ]}
                        />
                    </Modal>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(CarriersView)
