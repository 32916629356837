import React, {Component} from "react";
import {connect} from "react-redux";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
import MagnifyingGlassIcon from "@heroicons/react/24/outline/MagnifyingGlassIcon";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import ChevronDownIcon from "@heroicons/react/20/solid/ChevronDownIcon";
import {checkPerm, classNames, getProp, openInNewTab} from "../../../../util/util-helpers";
import {READ_PERM} from "../../../../util/util-consts";
import Resources from "../../../../../data/services/resources";
import mainMenu from "../../../../../assets/menus/menu-main.json"
import Card from "../../../card";
import ResourceTable from "../../../resource-table";
import ModalHeader from "../../../modal/modal-header";
import {LoaderSmall} from "../../../loader";
import ModalFooter from "../../../modal/modal-footer";
import Modal from "../../../modal";
import {showGlobalModal, showModal} from "../../../../../data/actions/ui";
import {Field} from "../../../../../data/services/fields";
import {getInfoResource} from "../../../../../data/actions/infoResource";
import LocalStorage from "../../../../util/localStorage";
import icons from "../../../../../data/themes/icons";

class GlobalSearchPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: this.props.search,
            results: [],
            categoryCount: false,
            activeTab: '',
            searchDialogVisible: true,
            recent: null,
            fields: {},
            Collapsed: [],
            allCollapsed: [],
            sections: {},
            allSections: {}
        };

        this.categories = [
            {
                name: 'contacts',
                perm: checkPerm(Resources.Contacts, READ_PERM),
                fields: this.getContactsFields()
            },
            {
                name: 'locations',
                perm: checkPerm(Resources.Locations, READ_PERM),
                fields: this.getLocationsFields()
            },
            {
                name: 'offices',
                perm: checkPerm(Resources.Offices, READ_PERM),
                fields: this.getOfficesFields()
            },
            {
                name: 'trailers',
                perm: checkPerm(Resources.Trailers, READ_PERM),
                fields: this.getTrailersFields()
            },
            {
                name: 'trucks',
                perm: checkPerm(Resources.Trucks, READ_PERM),
                fields: this.getTrucksFields()
            },
            {
                name: 'loads',
                perm: checkPerm(Resources.Loads, READ_PERM),
                fields: this.getLoadsFields()
            },
            {
                name: 'drivers',
                perm: checkPerm(Resources.Drivers, READ_PERM),
                fields: this.getDriversFields()
            },
            {
                name: 'customers',
                perm: checkPerm(Resources.Customers, READ_PERM),
                fields: this.getCustomersFields()
            },
            {
                name: 'carriers',
                perm: checkPerm(Resources.Carriers, READ_PERM),
                fields: this.getCarriersFields()
            },
        ]

        this.navigation = mainMenu;
        this.searchInputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.info?.isLoading && !this.props?.info?.isLoading) {
            let results = {};
            let catNo = 0;

            this.categories.forEach(cat => {
                if (this.props?.info?.data && this.props?.info?.data[cat.name] && this.props?.info?.data[cat.name].length) {
                    catNo++;
                    results[cat.name] = this.props?.info?.data[cat.name];
                }
            })

            let activeTab = this.state.activeTab;

            if (!results[this.state.activeTab]) {
                activeTab = ""; // reset active tab if current is empty after search
            }

            this.setState({
                results,
                categoryCount: catNo,
                activeTab
            })
        }

        if (prevProps.ui.HideModalAnimation && !this.props.ui.HideModalAnimation) {
            this.setState({
                searchDialogVisible: true
            })
        }
    }

    fetchData = () => {
        this.props.dispatch(getInfoResource({
            user: LocalStorage.get("user"),
            resource: Resources.GlobalSearch,
            query: this.getQuery()
        }))
    };

    getQuery = () => {
        return {
            query: this.state.input,
        }
    }

    hideModal = () => {
        this.props.onClose()
    }

    handleFilterChange = (name, val) => {
        this.setState({
            [name]: val,
            Collapsed: [],
            allCollapsed: [],
            sections: {},
            allSections: {}
        }, this.fetchData)
    }

    saveResult = (icon, name, data) => {
        // TODO implement recent
    }

    handleContactClick = (ContactName, ContactID) => {
        this.setState({searchDialogVisible: false});
        this.saveResult('Phone', ContactName, ContactID);
        this.props.dispatch(showModal('ViewContactCard', {ContactID}));
    }

    handleTruckClick = (truck) => {
        const name = truck.SCAC + " " + (truck.TruckNumber ? "No. " + truck.TruckNumber : "");
        this.setState({searchDialogVisible: false})
        this.saveResult('Trucks', name, truck.TruckID);
        this.props.dispatch(showGlobalModal('ViewTruckCard', truck.TruckID));
    }

    handleLocationClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Places', item.LocationName + " - " + item.AddressName, item);
        this.props.dispatch(showModal('ViewLocationCard', Object.assign(
            item,
            {
                resource: Resources.GlobalSearch,
                query: this.getQuery()
            }))
        );
    }

    handleTrailersClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Trailers', this.props.translate('text.trailer_no') + " " + item.TrailerNumber, item);
        this.props.dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID));
    }

    handleOfficeClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Offices', item.OfficeName + " - " + item.CityName, item);
        this.props.dispatch(showModal('ViewOfficeCard', item));
    }

    handleLoadsClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Loads', item.LoadID, item);
        openInNewTab(`/loads/info/${item.LoadID}`);
    }

    handleDriversClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Driver', item.DriverID, item);
        this.props.dispatch(showGlobalModal('ViewDriverCard', item.DriverID));
    }

    handleCustomersClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Customer', item.CustomerID, item);
        this.props.dispatch(showModal('ViewCustomerCard', item));
    }

    handleCarriersClick = (item) => {
        this.setState({searchDialogVisible: false})
        this.saveResult('Carriers', item.CarrierID, item);
        this.props.dispatch(showModal('ViewCarrierCard', item));
    }

    handleTabClick = (tab) => {
        this.setState({
            activeTab: tab
        })
    }

    handleRecentClick = (iconName, data) => {
        switch (iconName) {
            case "Phone":
                this.props.dispatch(showModal('ViewContactCard', {ContactID: data.data}));
                break;
            case "Places":
                this.props.dispatch(showModal('ViewLocationCard', Object.assign(
                    data.data,
                    {
                        resource: Resources.GlobalSearch,
                        query: this.getQuery()
                    }))
                );
                break;
            case "Trailers":
                this.props.dispatch(showGlobalModal('ViewTrailerCard', data.data));
                break;
            case "Trucks":
                this.props.dispatch(showGlobalModal('ViewTruckCard', data.data));
                break;
            case "Offices":
                this.props.dispatch(showModal('ViewOfficeCard', data.data));
                break;
            case "Load":
                openInNewTab(`/loads/info/${data.LoadID}`);
                break;
            case "Driver":
                this.props.dispatch(showGlobalModal('ViewDriversCard', data.data));
                break;
            case "Customer":
                this.props.dispatch(showModal('ViewCustomerCard', data.data));
                break;
            case "Carriers":
                this.props.dispatch(showModal('ViewCustomerCard', data.data));
                break;
            default:
                return null
        }

        this.setState({searchDialogVisible: false})
    }

    handleToggleCollapse = (item) => {
        if (!this.state.activeTab) {
            let allSections = this.state.allSections;
            if (allSections[item.name]) {
                allSections[item.name].isCollapsed = !allSections[item.name].isCollapsed
                this.setState({allSections})
            } else {
                allSections[item.name] = {isCollapsed: false}
                allSections[item.name].isCollapsed = true
                this.setState({allSections})
            }

            let arr = this.state.allCollapsed
            arr[item.name] = !arr[item.name]
            this.setState({allCollapsed: arr})
        }
    }

    /** HELPERS
     ===================================================================*/
    getContactsFields = () => {
        return {
            FirstName: new Field("FirstName", '', [], false, 'text'),
            LastName: new Field("LastName", '', [], false, 'text'),
            Email: new Field("Email", '', [], false, 'text')
        }
    }

    getLocationsFields = () => {
        return {
            LocationName: new Field("LocationName", '', [], false, 'text'),
            CityName: new Field("CityName", '', [], false, 'text'),
            AddressName: new Field("AddressName", '', [], false, 'text'),
            PostalCode: new Field("PostalCode", '', [], false, 'text')
        }
    }

    getOfficesFields = () => {
        return {
            OfficeName: new Field("OfficeName", '', [], false, 'text'),
            CityName: new Field("CityName", '', [], false, 'text'),
            AddressName: new Field("AddressName", '', [], false, 'text')
        }
    }

    getTrailersFields = () => {
        return {
            TrailerNumber: new Field("TrailerNumber", '', [], false, 'text'),
            VIN: new Field("VIN", '', [], false, 'text'),
        }
    }

    getTrucksFields = () => {
        return {
            TruckNumber: new Field("TruckNumber", '', [], false, 'text'),
            VIN: new Field("VIN", '', [], false, 'text'),
        }
    }

    getLoadsFields = () => {
        return {
            LoadNumber: new Field("LoadNumber", '', [], false, 'text'),
            Customer: new Field("Customer", '', [], false, 'text'),
        }
    }

    getDriversFields = () => {
        return {
            FirstName: new Field("FirstName", '', [], false, 'text'),
            LastName: new Field("LastName", '', [], false, 'text'),
            CodFirstName: new Field("CodFirstName", '', [], false, 'text'),
            CodLastName: new Field("CodLastName", '', [], false, 'text'),
            Active: new Field("Active", '', [], false, 'checkbox')
        }
    }

    getCustomersFields = () => {
        return {
            LegalName: new Field("LegalName", '', [], false, 'text'),
            FederalID: new Field("FederalID", '', [], false, 'text'),
        }
    }

    getCarriersFields = () => {
        return {
            LegalName: new Field("LegalName", '', [], false, 'text'),
            FederalID: new Field("FederalID", '', [], false, 'text'),
        }
    }

    handleRowClick = (item, type) => {
        switch (type) {
            case "contacts":
                this.handleContactClick(item.FirstName + " " + item.LastName, item.ContactID);
                break;
            case "locations":
                this.handleLocationClick(item);
                break;
            case "trailers":
                this.handleTrailersClick(item);
                break;
            case "trucks":
                this.handleTruckClick(item);
                break;
            case "loads":
                this.handleLoadsClick(item);
                break;
            case "drivers":
                this.handleDriversClick(item);
                break;
            case "customers":
                this.handleCustomersClick(item);
                break;
            case "carriers":
                this.handleCarriersClick(item);
                break;
        }
    }

    render() {
        const {translate} = this.props;
        const tabsVisible = this.state.categoryCount > 1 && this.state.input;

        let recentItems = [];
        if (this.state.recent && this.state.recent.length) {
            recentItems = this.state.recent.map(item => (
                <h4
                    key={item.name}
                    className="p-2 mb-2 bg-inverse flex items-center rounded-lg hover hover:bg-primary hover:text-white group cursor-pointer"
                    onClick={() => this.handleRecentClick(item.icon, item)}
                >
                    <div style={{width: "24px"}} className="mr-3">
                        <span
                            className="text-tm-gray-600 group-hover:text-white group-hover:opacity-80">{icons[item.icon]}</span>
                    </div>

                    {item.name}
                </h4>
            ))
        }

        const tabs = this.categories
            .filter(it => !!it.perm)
            .map(cat => {
                if (!getProp(this.state.results, cat.name, []).length) {
                    return false;
                }

                return (
                    <li key={cat.name} className="nav-item">
                    <span className={
                        classNames(
                            'uppercase border-b-2 cursor-pointer',
                            this.state.activeTab === cat.name ? "border-primary" : "border-transparent"
                        )
                    }
                          onClick={() => this.handleTabClick(cat.name)}>{cat.name}</span>
                    </li>
                );
            })

        const nav = this.navigation
            .filter(it => it.children && !!it.perm)
            .map(it => {
                let input = this.state.input ? this.state.input.trim().toLowerCase() : ''
                const childrens = it.children
                    .filter(child => child && input && getProp(child, "name", "").toLowerCase().includes(input) && !!child.perm)
                    .map(child => {
                        let childName = translate(`page_title.${child.link}`).toLowerCase()
                        childName = childName.replace(input, "<span class='font-bold border-b-2 border-primary'>" + input + "</span>")

                        return (
                            <button
                                key={it.name}
                                onClick={() => {
                                    window.open(`${window.location.origin}${child.link}`, '_self');
                                }}
                                onMouseDown={(e) => {
                                    if (e.button === 1) {
                                        window.open(`${window.location.origin}${child.link}`, '_blank');
                                    }
                                }}
                                type="button"
                                className={`uppercase normal-case hover:text-white hover:bg-primary hover:border-transparent border  text-primary border-primary w-full flex justify-between mb-2 cursor-pointer px-4 py-2  shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2`}>
                                <div className={'uppercase'} dangerouslySetInnerHTML={{__html: childName}}/>
                                <ArrowTopRightOnSquareIcon className={'h-4 w-4'}/>
                            </button>
                        )
                    })

                return childrens.length > 0 && (
                    <div className={'border-b border-gray-200 mb-4'}>
                        <div
                            className="bg-tm-gray-100 group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md mb-2">
                            <it.icon className="h-8 w-8 mr-2 text-gray-500" aria-hidden="true"/>
                            {it.name}
                        </div>
                        <div>{childrens}</div>
                    </div>
                )
            })

        const tables = this.categories
            .filter(item => this.state.activeTab === item.name || !this.state.activeTab)
            .map((item, index) => {

                const data = this.props?.info?.data?.[item?.name] ?? []

                return data.length > 0 && (
                    <div>
                        <Card
                            bodyClass={''}
                            addClass={'mb-4'}
                        >
                            <div id={`table-element-${item.name}`}>
                                <div
                                    className="z-40 sticky top-0 capitalize bg-tm-gray-100 group w-full items-center pl-2 py-2 h-10 text-sm font-medium flex justify-between">
                                    <p className="text-lg px-3 text-tm-gray-700">{item.name}</p>
                                    {!this.state.activeTab && (
                                        <button
                                            className="mx-1 bg-tm-gray-100 p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                            onClick={() => {
                                                this.handleToggleCollapse(item, index)
                                            }}
                                        >

                                            {!!this.state.allCollapsed[item.name] && (
                                                <ChevronRightIcon className={'h-4 w-4'}/>)}
                                            {!this.state.allCollapsed[item.name] && (
                                                <ChevronDownIcon className={'h-4 w-4'}/>)}


                                        </button>
                                    )}

                                </div>

                                <div className={classNames(
                                    this.state.activeTab ? (this.state.sections[this.state.activeTab]?.isCollapsed ? 'hidden' : undefined) : (this.state.allSections[item.name]?.isCollapsed ? 'hidden' : undefined)
                                )}>
                                    <ResourceTable
                                        data={data}
                                        fields={item.fields}
                                        translate={this.props.translate}
                                        onRowClick={(row) => this.handleRowClick(row, item.name)}
                                        isLoading={this.props?.info?.isLoading}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                )
            })

        const hasData = Object.values(getProp(this.props.info, "data", [])).some(itemData => !!itemData && itemData?.length > 0)

        return (
            <React.Fragment>
                <Modal
                    show={this.props.show}
                    widthClass={"max-w-5xl"}
                    onClose={this.hideModal}
                    translate={translate}
                    onBackdropClick={this.hideModal}
                    initialFocusRef={this.searchInputRef}
                >
                    <ModalHeader
                        title={
                            <span className="font-bold">{translate("text.quick_search")}</span>
                        }
                        onClose={this.hideModal}
                    />

                    <div className="relative px-2 py-2">
                        <div className="flex items-center">
                            <MagnifyingGlassIcon className="absolute left-4 w-5 h-5 text-tm-gray-600"/>

                            <input
                                ref={this.searchInputRef}
                                type="text"
                                placeholder={translate("text.search")}
                                className="form-control pl-10 rounded-t-card"
                                value={this.state.input}
                                autoFocus
                                onChange={e => {
                                    this.handleFilterChange("input", e.target.value);
                                }}
                            />
                        </div>
                    </div>

                    {this.state.input && this.state.input.length > 2 && !!nav.find(it => !it) && (
                        <div className="p-5">
                            {nav}
                        </div>
                    )}

                    {tabsVisible && (
                        <ul className="flex space-x-3 pt-4 px-6 border-b border-tm-gray-200 font-semibold">
                            <li className="nav-item">
                                <span
                                    className={
                                        classNames(
                                            'uppercase border-b-2 cursor-pointer',
                                            this.state.activeTab === '' ? "border-primary" : "border-transparent"
                                        )
                                    }
                                    onClick={() => this.handleTabClick('')}>All</span>
                            </li>

                            {tabs}
                        </ul>
                    )}

                    {this.props.isLoading && (
                        <div className="p-5 text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    <div className="pt-6">
                        <div className="max-h-[calc(100vh-17rem)] overflow-auto relative">
                            {!!this.props?.info?.isLoading && (
                                <div className="p-5 text-center">
                                    <LoaderSmall/>
                                </div>
                            )}
                            {!this.props?.info?.isLoading && tables}

                            {!this.state.input && !!recentItems.length && (
                                <React.Fragment>
                                    <div className="cursor-pointer mb-4">
                                        <h2 className={"text-xl"}>Recent: </h2>
                                    </div>

                                    {recentItems}
                                </React.Fragment>
                            )}

                            {!this.state.input && !recentItems.length && (
                                <p className="py-8 m-0 text-lg text-center">No recent searches</p>
                            )}

                            {/* NO RECORDS */}
                            {!this.props?.info?.isLoading && !!this.state.input && !hasData && (
                                <h4 className={"m-2"}>{translate("text.NoResultsFor")} <strong>&quot;{this.state.input}&quot;</strong>
                                </h4>
                            )}
                        </div>
                    </div>

                    <ModalFooter
                        closeButtonLabel={translate("btn.close")}
                        onClose={this.hideModal}
                    />
                </Modal>
            </React.Fragment>
        );
    }
}

export default connect(state => state)(GlobalSearchPopup);
