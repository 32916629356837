import React, {Component} from "react";
import {connect} from "react-redux";
import LoadInfoBasic from "../load-view/load-sections/load-info-basic";
import {
    cnv,
    ConstantTranslate,
    getDefaultContactGroup,
    getFullAddressName,
    getMeasurementUnitName,
    getWeightUnitName,
    openInNewTab,
    resourceIsCreated,
} from'../../../common/util/util-helpers'
import {Field, FieldsManager} from "../../../data/services/fields";
import LocalStorage from "../../../util/localStorage";
import {
    clearLocationFields,
    convertMeasurements,
    generateQuoteStops,
    getCombinedStopObject,
    getCommodityFields,
    getLoadSelects,
    getStopByFields,
    getStopFields,
    mergeStops,
    processStopInputChange
} from "../load-view/load-utils";
import {checkPerm, getLookup, getProp} from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import {
    CREATE_PERM,
    LOAD_PRICING_TYPES,
    LOAD_STATUS_READY,
    STOP_TYPE_DELIVERY,
    STOP_TYPE_PICKUP,
    STOP_TYPE_STOP_BY,
    UPDATE_PERM
} from "../../../util/util-constants";
import {createResource, getResource, updateResource} from "../../../data/actions/resource";
import XCircleIcon from "@heroicons/react/24/outline/XCircleIcon";
import CheckBadgeIcon from "@heroicons/react/20/solid/CheckBadgeIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import {cloneDeep} from "../../../common/util/util-vanilla";
import LoadInfoCommodities from "../load-view/load-sections/load-info-commodities";
import {numberWithCommas} from "../../../util/util-formaters";
import CommodityForm from "../templates/dialogs/commodity-form";
import LoadInfoLocations from "../load-view/load-sections/load-info-locations";
import {
    Bars4Icon,
    DocumentIcon,
    DocumentTextIcon,
    MagnifyingGlassIcon,
    MapIcon,
    PaperAirplaneIcon,
    PresentationChartBarIcon
} from "@heroicons/react/24/outline";
import {ArrowPathIcon, ArrowTopRightOnSquareIcon, PencilSquareIcon} from "@heroicons/react/20/solid";
import axios from "axios";
import PlusIcon from "@heroicons/react/24/outline/PlusIcon";
import MinusIcon from "@heroicons/react/24/outline/MinusIcon";
import {showGlobalModal, showModal} from "../../../data/actions/ui";
import Tippy from "@tippyjs/react";
import {createDialogResource, updateDialogResource} from "../../../data/actions/dialogResource";
import PlusCircleIcon from "@heroicons/react/24/outline/PlusCircleIcon";
import InformationCircleIcon from "@heroicons/react/24/outline/InformationCircleIcon";
import GroupsViewDialog from "../../my-company/groups-view/groups-view-dialog";
import {timePickerValueToServerTime} from "../../../common/util/util-dates";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";
import Subtitle from "../../../common/components/layout/layout-components/page/subtitle";
import GoBackButton from "../../../common/components/layout/layout-components/go-back-button";
import Layout from "../../../common/components/layout";
import FieldOptions from "../../../common/components/fields/field-options";
import {LoaderSmall} from "../../../common/components/loader";
import Card from "../../../common/components/card";
import Button from "../../../common/components/button";
import ModalTabbed from "../../../common/components/modal/modal-tabbed";
import DragAndDrop from "../../../common/components/modal/truckDialogs/createTruckLocationDialog/DragAndDrop";
import CreateLocationDialog from "../../../common/components/modal/createLocationDialog";
import ModalHeader from "../../../common/components/modal/modal-header";
import CreateSuccessModal from "../../../common/components/modal/create-success-modal";
import ResourceTableDialog from "../../../common/components/modal/resource-table-dialog";
import Modal from "../../../common/components/modal";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TextCopy from "../../../common/components/resource-table/table-components/text-copy";
import Env from "../../../util/env";
import {getJWT} from "../../../common/util/util-auth";
class QuoteView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: this.getFields(),
            stops: [],
            stopBys: [],
            stopsCombined: [],
            CommodityData: [],
            expandedSections: this.getSectionSettings(),

            isConfirmQuoteRejectDialogOpen: false,
            isCommodityDialogOpen: false,
            isCreateSuccessDialogOpen: false,
            isLocationContactsDialogOpen: false,
            isLayoutScrolled: false,

            isGroupInfoDialogOpen: false,
            groupInfoData: {},

            isDirty: false,

            currentCreatedStopOrder: -2
        }

        this.selects = Object.assign(getLoadSelects(), {
                PriceTypeID: ConstantTranslate(LOAD_PRICING_TYPES, this.props.translate)
            }
        );

        this.isEditMode = !!this.props.match.params.id
        this.ID = this.props?.match?.params?.id;

        this.MeasureUnitID = LocalStorage.get('user')?.Contact.MeasureUnitID ?? 1
        this.MeasureUnit = LocalStorage.get('user')?.Contact.MeasureUnit ?? 'in'
        this.defaultWeightUnit = LocalStorage.get('user')?.Contact?.WeightUnit ?? 'lb'

        this.isMetric = ['cm', 'm'].includes(this.MeasureUnit)
        this.standardLengthMeasure = this.isMetric ? 'm' : 'ft'
        this.standardWeightMeasure = this.isMetric ? 'kg' : 'lb'

        this.LoadInfoBasicSection = React.createRef();
        this.LoadInfoLocationsSection = React.createRef();
        this.LoadInfoCommoditiesSection = React.createRef();

        this.countries = getLookup('Country', 'CountryID', 'CountryName');
        this.states = getLookup('State', 'StateID', 'StateAbbreviation');

        this.layoutRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        if (this.isEditMode) {
            this.fetchData();
        } else {
            this.createFromLoadPlaning();
        }
    }

    componentDidUpdate(prevProps) {

        if ((!this.props.resource.isLoading && prevProps.resource.isLoading)) {
            this.fillStateWithData()
            if (this.isCopyMode()) {
                this.setState({isDirty: true})
            }
        }

        if (!prevProps.resource?.create
            && !!this.props.resource?.create?.id
            /*&& !this.props?.match?.params?.id*/
            && this.props.resource.resource === Resources.QuotesInfo) {
            this.ID = this.props.resource.create.id;
            this.toggleCreateSuccessDialog();
        }

        if (resourceIsCreated(this.props.resource, prevProps.resource) && this.props.resource.resource === Resources.QuotesLoadConvert) {
            this.props.history.push("/quotes/info/" + this.ID);
        }

        if (
            resourceIsCreated(this.props.dialogResource, prevProps.dialogResource)
            && this.props.dialogResource.resource === "locations"
        ) {
            // Create new location
            const newDropDownOption = {
                value: this.props.dialogResource.create.id,
                label: this.state.createdLocationData.LocationName,
                metadata: this.state.createdLocationData
            }

            this.setState({
                isSelectLocationDialogOpen: false
            }, () => this.handleStopInputChange("StopID", newDropDownOption))
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.QuotesInfo,
            query: this.getQuery()
        }))
    }

    submit = () => {
        const validFields = FieldsManager.validateFields(this.state.fields);

        if (this.props.lineOfBusiness === 'carrier') {
            FieldsManager.updateField(fields, 'CarrierID', -1)
        }

        let stops = cloneDeep(this.state.stops).map((fields) => FieldsManager.validateFields(fields));
        let stopBys = cloneDeep(this.state.stopBys).map((fields) => FieldsManager.validateFields(fields));


        if (stops.length) {
            // Update StopOrder for stops and stop bys
            this.state.stopsCombined.forEach((it, i) => {
                if (it.StopType === STOP_TYPE_STOP_BY) {
                    stopBys[it.index].StopOrder.value = i + 1;
                } else {
                    stops[it.index].StopOrder.value = i + 1;
                }
            });
        }

        let locations = stops.map((location) => {
            location.StopTime.value = location.StopTime.value === "" ||
            timePickerValueToServerTime(location.StopTime.value) === 'Invalid date' ? null : timePickerValueToServerTime(location.StopTime.value)
            location.StopEndTime.value = location.StopEndTime.value === "" ||
            timePickerValueToServerTime(location.StopEndTime.value) === 'Invalid date' ? null : timePickerValueToServerTime(location.StopEndTime.value)

            if (!location.ReferenceNumber.value) {
                location.ReferenceNumber.value = [];
            }
            location.ReferenceNumber.value = location.ReferenceNumber.value.map(it => it.value).join(",");

            if (!location.AptReferenceNumber.value) {
                location.AptReferenceNumber.value = [];
            }
            location.AptReferenceNumber.value = location.AptReferenceNumber.value.map(it => it.value).join(",");

            delete location.IsSelected;

            return location;
        })

        let commodities = this.state.CommodityData.map(it => {
            delete it.LoadCommodityPickup
            delete it.LoadCommodityDestination
            delete it.ArchivedDate
            delete it.CreateUpdateDate
            delete it.LoadCommodityDestinationLatitude
            delete it.LoadCommodityDestinationLongitude
            delete it.LoadCommodityPickupLatitude
            delete it.LoadCommodityPickupLongitude
            delete it.LoadCommodityID
            delete it.LoadID
            delete it.UpdatedByContactID
            delete it.WeightConversion
            delete it.LengthConverted
            delete it.WidthConverted
            delete it.HeightConverted
            delete it.WeightConverted
            delete it.LengthConversion
            delete it.WidthConversion
            delete it.HeightConversion
            delete it.Template

            return it
        })

        const params = Object.assign(
            {LoadQuoteID: this.ID},
            FieldsManager.getFieldKeyValues(validFields),
            {
                Multistop: locations.map((fields) => Object.assign(FieldsManager.getFieldKeyValues(fields), {
                    StopDate: this.removeTimeFromDate(fields.StopDate.value),
                    StopEndDate: this.removeTimeFromDate(fields.StopEndDate.value),
                    StopID: fields.StopID?.value?.value,

                    Stop: fields.StopID?.value?.value === -1 && fields?.NewLocationData?.value
                        ? FieldsManager.getFieldKeyValues(fields.NewLocationData.value)
                        : null
                })),
                StopBy: stopBys.map((fields) => {
                    fields.ActualArrivalDateTime.value =
                        fields.ActualArrivalDate.value.split(" ")[0] + " " +
                        timePickerValueToServerTime(fields.ActualArrivalDateTime.value);

                    fields.ActualDepartureDateTime.value =
                        fields.ActualDepartureDate.value.split(" ")[0] + " " +
                        timePickerValueToServerTime(fields.ActualDepartureDateTime.value);

                    delete fields.ActualArrivalDate;
                    delete fields.ActualDepartureDate;

                    return Object.assign(FieldsManager.getFieldKeyValues(fields));
                }),
                IsBroker: (this.state.lineOfBusiness === 'brokerage' ? 1 : 0),
                Commodities: commodities,
                LoadQuoteID: this.ID
            }
        )

        if (FieldsManager.checkFieldsForErrors(validFields) && this.checkLocationsForError(stops, stopBys)) {
            if (this.isEditMode && !this.isCopyMode()) {
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: params,
                    resource: Resources.QuotesLoad,
                    piggyResource: Resources.QuotesLoad,
                    query: this.getQuery(),
                    errorMessage: true,
                    successMessage: this.props.translate("text.quote_updated"),
                }));
            } else {
                this.props.dispatch(createResource({
                    user: LocalStorage.get("user"),
                    params: params,
                    resource: Resources.QuotesLoad,
                    errorMessage: true
                }));
            }
        } else {
            this.setState({
                fields: validFields,
                stops: stops,
                stopBys: stopBys
            });
        }
    }

    rejectQuote = () => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {LoadQuoteID: this.ID},
            resource: Resources.QuotesLoadReject,
            piggyResource: Resources.QuotesLoad,
            query: this.getQuery(),
            errorMessage: true, successMessage: this.props.translate("message.quote_rejected_success"),
        }));

        this.setState({
            isConfirmDeleteDialogOpen: false,
            selectedItem: {}
        });
    }

    convertToLoad = (LoadID = this.ID) => {
        this.props.dispatch(createResource({
            user: LocalStorage.get("user"),
            params: {LoadQuoteID: LoadID},
            resource: Resources.QuotesLoadConvert,
            errorMessage: true,
            successMessage: this.props.translate("message.quote_converted_success"),
            query: this.getQuery()
        }));

        this.setState({
            isConfirmDeleteDialogOpen: false,
            selectedItem: {}
        });
    }

    toggleExpandSection = (sectionName) => {
        let expandedSections = Object.values(this.state.expandedSections);

        expandedSections.map(section => {
            if (sectionName === section.name) {
                section.expanded = !section.expanded;
            }
            return section;
        });

        expandedSections = expandedSections.reduce((memo, section) => {
            memo[section.name] = section;
            return memo;
        }, {});

        this.setState({
            expandedSections
        }, () => this.persistSectionSettings())
    }

    handleScrollToSectionClick = (sectionRef) => {
        sectionRef.current.scrollIntoView({block: "start", behavior: "smooth"})
    }

    persistSectionSettings = () => {
        if (this.props.match.params.id) {
            LocalStorage.set("quotes_section_settings", this.state.expandedSections);
        }
    }

    fillStateWithData = () => {
        const data = cloneDeep(getProp(this.props.resource, "data", []));
        const multiStops = cloneDeep(getProp(this.props.resource, 'data.Multistop', []));
        const stopOffs = cloneDeep(getProp(this.props.resource, 'data.stopby', []));

        let stops = generateQuoteStops(
            multiStops,
            this.props.translate);

        stops = this.addStopFieldsActions(stops);

        let stopBys = this.generateStopBys();
        let mergedStops = [];

        if (stops.length) {
            mergedStops = mergeStops(
                multiStops,
                stopOffs
            );
        }

        this.setState({
            fields: this.getFields(data),
            stops: stops,
            stopBys: stopBys,
            stopsCombined: mergedStops,
            CommodityData: cloneDeep(getProp(this.props.resource, 'data.Commodities', [])),
            isDirty: false
        })
    }

    createFromLoadPlaning = () => {
        if (this.props.resource.temp) {
            let stops = generateQuoteStops(this.props.resource.temp.stops, this.props.translate);
            stops = this.addStopFieldsActions(stops);
            const stopBys = this.generateStopBys(this.props.resource.temp.stopBys);
            const stopsCombined = mergeStops(cloneDeep(this.props.resource.temp).stops, cloneDeep(this.props.resource.temp).stopBys);
            const CommodityData = this.props.resource.temp.commodityData;

            this.setState({
                stops,
                stopBys,
                stopsCombined,
                CommodityData
            })
        }
    }

    /** UI Events
     ================================================================= */
    handleCommodityFormSubmitClick = (params, fields, selects) => {
        if (params) {

            params = Object.assign({}, params, {
                LoadCommodityPickup:
                    selects.LoadCommodityPickupID && selects.LoadCommodityPickupID[params.LoadCommodityPickupID],
                LoadCommodityDestination:
                    selects.LoadCommodityPickupID &&
                    selects?.LoadCommodityDestinationID?.[params.LoadCommodityDestinationID],

                PickupAddressName:
                    selects.LoadCommodityPickup && selects.LoadCommodityPickup[params.LoadCommodityPickupID],
                DestinationAddressName:
                    selects.LoadCommodityPickup && selects?.LoadCommodityDestination?.[params.LoadCommodityDestinationID],

                ProductName: params.ProductName,
            });

            if (params.CommodityID === -1 && !!params.updateCommodity) {
                params.saveCommodity = 1
            }

            let CommodityData = this.state.CommodityData

            if (!!this.state.selectedItemIndex || this.state.selectedItemIndex === 0) {
                CommodityData[this.state.selectedItemIndex] = params
            } else {
                CommodityData.push(params)
            }

            this.setState({
                CommodityData,
                isDirty: true
            }, () => {
                this.toggleCommodityFormModal()
            })
        }
    }

    getCommodityVolume = (commodity) => {
        const selectedMeasureUnit = getMeasurementUnitName(commodity.MeasureUnitID);
        const width = cnv(commodity.Width, selectedMeasureUnit, this.standardLengthMeasure)
        const height = cnv(commodity.Height, selectedMeasureUnit, this.standardLengthMeasure)
        const length = cnv(commodity.Length, selectedMeasureUnit, this.standardLengthMeasure)

        return width * height * length;
    }


    handleSelectStopClick = () => {
        this.setState({
            isSelectLocationDialogOpen: !this.state.isSelectLocationDialogOpen
        }, () => {
            if (
                !this.state.isSelectLocationDialogOpen
                && this.state.stopsCombined[this.state.stopsCombined.length - 1]?.addressName === ""
            ) {
                this.handleDeleteLocationClick(this.state.stopsCombined.length - 1)
            }
        })
    }

    handleCreateLoadStop = (name, value) => {
        let stops = cloneDeep(this.state.stops);
        let stopsCombined = this.state.stopsCombined;

        let stop = stopsCombined.find((stop) => {
            return !!stop.IsSelected;
        });

        let selectedIdx = stop.index;

        stop = stops[selectedIdx];

        stop.StopID.value = {"value": -1, "label": value};

        stops[selectedIdx] = stop;

        this.setState({
            stops
        }, () => this.toggleLocationDialog({LocationID: -1, LocationName: value}));
    }

    createLocationsState = (params) => {
        let stopsClone = cloneDeep(this.state.stops);
        let stopsCombinedClone = cloneDeep(this.state.stopsCombined)

        stopsClone.map((it, i) => {
            if (i === it.index) {
                stopsClone[i].StopType.value = this.guessNextStopType(stopsClone, i)

                stopsClone[i].LocationData.value = params;
                stopsClone[i].StopID.value.label = params.LocationName;
                stopsClone[i].StopID.value.metadata = params;
                stopsClone[i].StopID.value.metadata = params;
                stopsClone[i].NewLocationData = {value: params}
            }
            return it;
        });

        stopsCombinedClone.map((it) => {
            if (it.IsSelected) {
                it.IsSelected = true;
                it.IsStopCompleted = false;
                it.StopName = params.LocationName;
                it.StopType = stopsClone[it.index].StopType.value;
                it.addressName = getFullAddressName(params);
            }
            return it;
        });

        this.setState({
            stops: stopsClone,
            stopsCombined: stopsCombinedClone,
            createdLocationData: params
        })
    }

    handleInputChange = (name, value) => {

        const isConvertedToLoad = getProp(this.props.resource, "data.IsConvertedToLoad", false);

        if (isConvertedToLoad && this.isCopyMode()) {
            return
        }

        let fields = this.state.fields

        fields = FieldsManager.updateField(fields, name, value);

        fields[name].errorMessage = "";

        this.setState({
            fields: fields,
            isDirty: true
        })
    }

    handleStopInputChange = (name, value) => {

        const isConvertedToLoad = getProp(this.props.resource, "data.IsConvertedToLoad", false);

        if (isConvertedToLoad && this.isCopyMode()) {
            return
        }

        let stopsClone = cloneDeep(this.state.stops);
        let stopsCombinedClone = this.state.stopsCombined;

        let doesStopsNeedsUpdate = this.state.doesStopsNeedsUpdate;

        const {
            stops,
            stopsCombined
        } = processStopInputChange(name, value, stopsClone, stopsCombinedClone, !this.props.match.params.id)

        if (name === "StopID" && !!this.isEditMode) {
            // Show only if it has more than two stops on initial load
            if (getProp(this.props.resource, 'data.load/multistops', []).length > 1) {
                doesStopsNeedsUpdate = true;
            }
        }

        this.setState({
            stops: stops,
            stopsCombined: stopsCombined,
            doesStopsNeedsUpdate: doesStopsNeedsUpdate,
            isDirty: true
        });
    }

    handleStopByInputChange = (name, value) => {
        let stopBys = cloneDeep(this.state.stopBys);
        let stopsCombined = this.state.stopsCombined;
        let stopsCombinedIndex = 0;

        let stop = stopsCombined.find((it, i) => {
            stopsCombinedIndex = i;
            return !!it.IsSelected
        });

        const selectedStopIndex = stop.index;

        stop = stopBys[selectedStopIndex];
        stop[name].errorMessage = "";

        stopBys[selectedStopIndex] = FieldsManager.updateField(stop, name, value);

        this.setState({
            stopBys: stopBys,
            stopsCombined: stopsCombined,
            isDirty: true
        });
    }

    handleCancelClick = () => {
        this.fillStateWithData();
    }

    toggleLocationDialog = (item) => {
        if (item?.StopID) {
            item.LocationID = item.StopID;
        }

        this.setState({
            selectedLocation: item,
            isLocationDialogOpen: !this.state.isLocationDialogOpen
        })
    }

    handleConvertToLoadClick = () => {
        this.setState({
            confirmSubmit: this.convertToLoad,
            confirmTitle: this.props.translate("text.convert_to_load"),
            confirmText: this.props.translate("text.convert_quote_to_load_confirm", [this.ID]),
            isConfirmDeleteDialogOpen: true
        })
    }

    handleRejectQuoteClick = () => {
        this.setState({
            confirmSubmit: this.rejectQuote,
            confirmTitle: this.props.translate("text.reject_quote"),
            confirmText: this.props.translate("text.reject_quote_number_confirm", [this.ID]),
            isConfirmDeleteDialogOpen: true
        })
    }

    handleCloseConfirmDialogClick = () => {
        this.setState({
            isConfirmDeleteDialogOpen: false,
        })
    }

    clearField = (fieldName) => {
        let fieldsUpdate = this.state.fields;

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, fieldName, "");
        fieldsUpdate[fieldName].type = "hidden";

        this.setState({
            fields: fieldsUpdate
        })
    }

    handleAddNoteClick = (noteFieldName) => {
        let fieldsUpdate = this.state.fields;

        fieldsUpdate[noteFieldName].type = "textarea";

        this.setState({
            fields: fieldsUpdate
        });
    }

    toggleCommodityFormModal = (selectedItem = null, selectedItemIndex = null) => {
        if (!!this.state.isCommodityDialogOpen || !!selectedItem?.target) {
            selectedItem = null
            selectedItemIndex = null
        }

        this.setState({
            isCommodityDialogOpen: !this.state.isCommodityDialogOpen,
            selectedItem: selectedItem,
            selectedItemIndex: selectedItemIndex
        })
    }

    updateLocationsState = (params) => {
        let stops = cloneDeep(this.state.stops)

        let stopsFieldsUpdate = stops.map((it, i) => {
            const LocationID = it.LocationData.value.StopID ?? it.LocationData.value.LocationID;
            if (LocationID === params.LocationID) {
                it.StopType.value = this.guessNextStopType(stops, i)

                it.LocationData.value = params
                it.StopID.value.label = params.LocationName
                it.StopID.value.metadata = params
            }
            return it
        })

        this.setState({
            stops: stopsFieldsUpdate
        })
    }

    guessNextStopType = (locationFields, index) => {
        return (index == (locationFields.length - 1)) ? STOP_TYPE_DELIVERY : STOP_TYPE_PICKUP
    }

    handleDeleteCommodityClick = (it, i) => {
        this.setState({
            isConfirmDeleteDialogOpen: true,
            confirmSubmit: () => this.deleteCommodity(i),
            confirmText: this.props.translate('text.delete_commodity', [it.ProductName]),
            title: this.props.translate("text.remove_commodity_confirm"),
            text: this.props.translate('text.delete_commodity', [it.ProductName])
        })
    }

    deleteCommodity = (i) => {
        const CommodityData = cloneDeep(this.state.CommodityData)

        CommodityData.splice(i, 1)

        this.setState({
            CommodityData,
            isConfirmDeleteDialogOpen: false,
            confirmSubmit: undefined,
            isDirty: true
        })
    }

    generateStopBys = (data) => {
        let stopBysData;
        if (data) {
            stopBysData = data;
        } else {
            stopBysData = getProp(this.props.resource, 'data.load/stopby', []);
        }
        let stopBys = []

        if (stopBysData.length) {
            stopBys = stopBysData.reduce((memo, it) => {
                memo.push(getStopByFields(it, this.props.translate));
                return memo;
            }, [])
        }

        return stopBys;
    }

    handleAddLocationClick = () => {
        let stops = cloneDeep(this.state.stops);
        let currentCreatedStopOrder = this.state.currentCreatedStopOrder - 1;
        let stopsCombined = cloneDeep(this.state.stopsCombined);

        stopsCombined.map(it => {
            it.IsSelected = "";
            return it
        });

        stops.push(getStopFields({
            StopType: STOP_TYPE_DELIVERY,
            LoadStopID: currentCreatedStopOrder
        }, this.props.translate));

        stops = this.addStopFieldsActions(stops);

        stopsCombined.push(getCombinedStopObject({
            StopType: STOP_TYPE_DELIVERY,
            IsSelected: true
        }, undefined, this.state.stops, this.state.stopBys));

        this.setState({
            stops,
            stopsCombined,
            currentCreatedStopOrder: currentCreatedStopOrder,
            isSelectLocationDialogOpen: true
        });
    }

    handleAddStopByClick = () => {
        let stopBys = cloneDeep(this.state.stopBys);
        let stopsCombined = cloneDeep(this.state.stopsCombined);

        stopsCombined.map(it => {
            it.IsSelected = "";
            return it
        })

        stopBys.push(getStopByFields({}, this.props.translate));
        stopsCombined.push(getCombinedStopObject({
            StopType: STOP_TYPE_STOP_BY,
            IsSelected: true
        }, undefined, this.state.stops, this.state.stopBys));

        this.setState({
            stopBys,
            stopsCombined,
            StopByItem: undefined
        }, () => this.toggleSelectLocationDialog(undefined, true));
    }

    toggleSelectLocationDialog = (StopByItem) => {
        let StopByItemUpdate = StopByItem ?? this.state.StopByItem;

        if (!!StopByItem) {
            StopByItemUpdate = FieldsManager.getFieldKeyValues(StopByItem);
            StopByItemUpdate.Location = StopByItem.LocationID?.value?.label
            StopByItemUpdate.Country = StopByItem.CountryID?.value?.label
        }

        this.setState({
            isSelectLocationModalOpen: !this.state.isSelectLocationModalOpen,
            StopByItem: StopByItemUpdate
        })
    }

    handleDeleteLocationClick = (index) => {
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let stops = cloneDeep(this.state.stops);

        const removedStop = stopsCombined.splice(index, 1)[0];
        stops.splice(removedStop.index, 1);

        if (index === stopsCombined.length) {
            stopsCombined[index - 1].IsSelected = true;
        } else {
            stopsCombined[index].IsSelected = true;
        }

        let newIndex = 0;
        stopsCombined.map((it, i) => {
            if (it.StopType !== STOP_TYPE_STOP_BY) {
                it.index = newIndex;
                newIndex = newIndex + 1;
            }

            return it;
        });

        this.setState({
            stops: stops,
            stopsCombined: stopsCombined,
            isDirty: true
        })
    }

    handleDeleteStopByClick = (index) => {
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let stopBys = cloneDeep(this.state.stopBys);

        const removeStopBy = stopsCombined.splice(index, 1)[0];
        stopBys.splice(removeStopBy.index, 1);

        if (index === stopsCombined.length) {
            stopsCombined[index - 1].IsSelected = true;
        } else {
            stopsCombined[index].IsSelected = true;
        }

        this.setState({
            stopBys: stopBys,
            stopsCombined: stopsCombined,
            isDirty: true
        })
    }

    closeSelectLocationDialog = () => {
        let deleteStopByIndex = -1;
        let deleteStopsCombinedIndex = undefined;
        let stopsCombined = this.state.stopsCombined;
        let stopBys = this.state.stopBys;

        let selectedCombinedStop = stopsCombined.find((it, i) => {
            deleteStopsCombinedIndex = i
            return it.IsSelected;
        })

        let selectedStopBy = stopBys[selectedCombinedStop.index];
        // Check if location is selected by selecting 1.LocationID, 2.manually or by 3.clicking on a map
        if (!!selectedStopBy && !selectedStopBy.LocationID.value && !selectedStopBy.AddressName.value && !selectedStopBy.Latitude.value) {
            deleteStopByIndex = selectedCombinedStop.index
        }

        if (deleteStopByIndex > -1) {
            stopBys.splice(deleteStopByIndex, 1);
            stopsCombined.splice(deleteStopsCombinedIndex, 1);

            stopsCombined[stopsCombined.length - 1].IsSelected = true;

            this.setState({
                stopBys: stopBys,
                stopsCombined: stopsCombined,
                isSelectLocationModalOpen: false,
            })
        } else {
            this.setState({
                isSelectLocationModalOpen: false
            })
        }
    }

    handleAddStopsClick = () => {
        let stops = [];
        const stopsCombined = mergeStops(
            [],
            []
        );

        stops.push(
            getStopFields({
                StopType: STOP_TYPE_PICKUP,
                LoadStopID: -1
            }, this.props.translate, ["ReferenceNumber", "AptReferenceNumber", "RequiresAppointment"]),
            getStopFields({
                StopType: STOP_TYPE_DELIVERY,
                LoadStopID: -2
            }, this.props.translate, ["ReferenceNumber", "AptReferenceNumber", "RequiresAppointment"])
        );

        stops = this.addStopFieldsActions(stops);

        this.setState({
            stops,
            stopsCombined,
            currentCreatedStopOrder: -2
        });
    }

    handleLocationEnterPress = () => {
        this.focusFirstStopInput()
    }

    handleStopClick = (selectedStopIndex) => {
        let stopsCombined = this.state.stopsCombined;

        stopsCombined.map((it, i) => {
            it.IsSelected = selectedStopIndex === i ? true : "";

            return it;
        })

        this.setState({
            stopsCombined: stopsCombined
        }, () => {
            this.focusFirstStopInput();
        })
    }

    handleStopDragStart = (i) => {
        this.setState({
            draggedStopIndex: i
        })
    }

    handleStopDragEnd = () => {
        this.setState({
            draggedStopIndex: undefined
        })
    }

    handleStopDragEnter = (index) => {
        let stopsCombined = cloneDeep(this.state.stopsCombined);

        let doesStopsNeedsUpdate = this.state.doesStopsNeedsUpdate;

        let draggedColumn = stopsCombined.splice(this.state.draggedStopIndex, 1);
        stopsCombined.splice(index, 0, draggedColumn[0]);

        if (getProp(this.props.resource, 'data.load/multistops', []).length > 1) {
            doesStopsNeedsUpdate = true;
        }

        this.setState({
            stopsCombined: stopsCombined,
            draggedStopIndex: index,
            doesStopsNeedsUpdate: doesStopsNeedsUpdate,
            isDirty: true
        });
    }

    handleSelectLocationClick = (fields, tabs) => {
        const selectedTab = tabs.find(it => it.isCurrent);
        let stopBys = this.state.stopBys
        let stopsCombined = cloneDeep(this.state.stopsCombined);
        let stopsCombinedSelectedIndex = 0;

        let selectedStopByIndex = stopsCombined.find((it, i) => {
            stopsCombinedSelectedIndex = i;
            return it.IsSelected;
        }).index;

        let fieldUpdate = stopBys[selectedStopByIndex];

        fieldUpdate = clearLocationFields(fieldUpdate);

        if (selectedTab.name === "select_location") {
            fieldUpdate.LocationID.value = fields.LocationID.value;
            stopsCombined[stopsCombinedSelectedIndex].metadata = fieldUpdate.LocationID.value.metadata;
        }

        if (selectedTab.name === "enter_manually") {
            const GooglePlacesString = [
                fields.AddressName.value,
                fields.CityName.value,
                this.states[fields.StateID.value],
                fields.PostalCode.value,
                this.countries[fields.CountryID.value]
            ].filter(it => !!it).join(", ");

            fieldUpdate.AddressName.value = fields.AddressName.value;
            fieldUpdate.CityName.value = fields.CityName.value;
            fieldUpdate.StateID.value = fields.StateID.value;
            fieldUpdate.PostalCode.value = fields.PostalCode.value;
            fieldUpdate.CountryID.value = fields.CountryID.value;

            stopsCombined[stopsCombinedSelectedIndex].metadata = FieldsManager.getFieldKeyValues(fieldUpdate);
        }

        if (selectedTab.name === "select_on_map") {
            const lat = fields.Latitude.value;
            const lng = fields.Longitude.value;

            this.setState({
                isAxiosRequesting: true
            }, () => {
                axios.get(Env.getApiUrl('api/user/geocode', {Latitude: lat, Longitude: lng}), {
                    headers: {
                        'Authorization': 'Bearer ' + getJWT().access_token
                    }
                }).then((response) => {
                    let address = response.data.data;

                    stopsCombined[stopsCombinedSelectedIndex].StopName = address['FormatedAddress'];
                    stopsCombined[stopsCombinedSelectedIndex].addressName = "";
                    stopsCombined[stopsCombinedSelectedIndex].metadata.AddressName = address['FormatedAddress']

                    fieldUpdate.Latitude.value = lat;
                    fieldUpdate.Longitude.value = lng;
                    stopBys[selectedStopByIndex] = fieldUpdate;

                    stopsCombined[stopsCombinedSelectedIndex].hasLocation = true;

                    this.setState({
                        stopBys,
                        stopsCombined,
                        isSelectLocationModalOpen: false,
                        isAxiosRequesting: false

                    }, () => this.setState({
                        isDirty: true
                    }));

                });
            })


        } else {
            stopBys[selectedStopByIndex] = fieldUpdate;
            stopsCombined[stopsCombinedSelectedIndex].hasLocation = true;

            this.setState({
                stopBys,
                stopsCombined,
                isSelectLocationModalOpen: false,
                doesStopsNeedsUpdate: true,
                isDirty: true
            });
        }
    }

    toggleCreateSuccessDialog = () => {
        this.setState({
            isCreateSuccessDialogOpen: !this.state.isCreateSuccessDialogOpen
        })
    }

    toggleContactsDialog = (item) => {
        this.setState({
            isLocationContactsDialogOpen: !this.state.isLocationContactsDialogOpen,
            selectedItem: item
        })
    }

    /** Helpers
     ================================================================= */
    getQuery = () => {
        if (!!this.ID) {
            return {LoadQuoteID: this.ID}
        }

        if (!!this.props?.resource?.create?.id) {
            return {LoadQuoteID: this.props.resource.create.id}
        }

        return {};
    }

    addStopFieldsActions = (stops) => {
        return stops.map(stop => {
            stop.StopID.props = Object.assign(stop.StopID.props ?? {}, {onCreateOption: (name, value) => this.handleCreateLoadStop(name, value)});
            stop.StopID.type = 'creatable-select-search';
            stop.StopID.metadata = Object.assign(stop.StopID.metadata ?? {}, {
                addFieldContainerClass: "pr-11 relative",
                htmlAfterField: (it) => {
                    return <button
                        onClick={() => this.handleSelectStopClick()}
                        disabled={it?.disabled}
                        className="btn btn-input absolute top-0 right-0"
                    >
                        {!!it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    </button>
                }
            });
            return stop;
        });
    }

    handleGroupInfoClick = (groupData) => {
        this.setState({
            isGroupInfoDialogOpen: true,
            groupInfoData: groupData
        })
    }

    getContactDialogFields = () => ({
        FirstName: new Field('FirstName', '', [''], false, 'select-search'),
        LastName: new Field('LastName', '', [''], false, 'select-search'),
        Email: new Field("Email", '', [], false, "text", {
            render: (it) => it.Email ?
                <TextCopy
                    text={it.Email}
                    copyText={this.props.translate("text.copy_to_clipboard")}
                    copiedText={this.props.translate("text.copied")}
                /> :
                null
        }),
        Phone: new Field("Phone", '', [], false, "text", {
            render: (it) => (it.Phone) ?
                <TextCopy
                    text={it.Phone}
                    copyText={this.props.translate("text.copy_to_clipboard")}
                    copiedText={this.props.translate("text.copied")}
                /> :
                null
        }),
        DepartmentID: new Field('DepartmentID', '', [''], false, 'select-search'),
        Notes: new Field("Notes", '', [], false, "textarea", {limitWidth: true})
    });

    focusFirstStopInput = () => {
        const firstInput = this.LoadInfoLocationsSection.current.querySelector('input');

        if (!!firstInput) {
            firstInput.focus();
        }
    }

    removeTimeFromDate = (date) => {
        return date.split(' ')[0]
    }

    checkLocationsForError = (stops, stopsOffs) => {
        const currentStops = stops ?? this.state.stops;
        const currentStopsOffs = stopsOffs ?? this.state.stopBys;
        let locationsCheck = currentStops.map((fields) => {
            return FieldsManager.checkFieldsForErrors(fields)
        })
        let stopBysCheck = currentStopsOffs.map((fields) =>
            FieldsManager.checkFieldsForErrors(
                fields,
                ['ActualArrivalDate', 'ActualArrivalDateTime', 'ActualDepartureDate', 'ActualDepartureDateTime']
            )
        )

        return !locationsCheck.includes(false) && !stopBysCheck.includes(false);
    }

    toggleWorkingHoursModal = () => {
        const selectedLocation = this.state.stopsCombined.find(it => !!it.IsSelected);
        const isStopBy = selectedLocation.StopType === STOP_TYPE_STOP_BY;
        const stopData = isStopBy ? this.state.stopBys[selectedLocation.index] : this.state.stops[selectedLocation.index];

        this.props.dispatch(showGlobalModal('workingHoursModal', Object.assign({}, {
            locationName: stopData?.StopID?.value?.label,
            locationData: stopData?.LocationData.value
        })));
    }

    getQuoteID = () => {
        return getProp(this.props.resource, 'data.LoadQuoteID', null)
    }

    getQuoteContactGroup = () => {
        const info = getProp(this.props.resource, 'data', {})

        return {
            label: getProp(info, 'ContactGroup', ''),
            value: getProp(info, 'ContactGroupID', '')
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            LoadTypeID: new Field('LoadTypeID', "", ['empty'], false, 'select', {addContainerClass: "col-span-6 3xl:col-span-6"}),
            LoadSubTypeID: new Field('LoadSubTypeID', "", [''], false, 'select', {addContainerClass: "col-span-6 3xl:col-span-6"}),
            OfficeID: new Field('OfficeID', "", ['empty_select_search'], false, 'select-search',
                {addContainerClass: "col-span-6 3xl:col-span-6"}),
            ContactGroupID: new Field(
                'ContactGroupID',
                !this.getQuoteID() ? getDefaultContactGroup() : this.getQuoteContactGroup(),
                ['empty'],
                false,
                'select-search',
                {
                    addContainerClass: "col-span-6 3xl:col-span-6",
                    label: "LoadGroupID",
                    fieldOptions: (it) => {
                        return (
                            <FieldOptions
                                options={[
                                    {
                                        icon: InformationCircleIcon,
                                        onClick: () => this.handleGroupInfoClick(it?.value?.metadata),
                                        isVisible: !!it.value
                                    }
                                ]}
                            />
                        )
                    }
                }, {
                    isClearable: true,
                    api: 'api/' + Resources.ContactGroupsQuick,
                    query: {},
                    searchMap: (it) => ({
                        label: it.ContactGroupName,
                        value: it.ContactGroupID,
                        metadata: it
                    })
                }),
            CustomerID: new Field('CustomerID', "", ['empty_select_search'], false, 'select-search', {
                addContainerClass: "col-start-1 col-span-8",
            }),
            CustomerReferenceNumber: new Field('CustomerReferenceNumber', "", [''], false, "text", {addContainerClass: "col-span-4"}),

            ExternalNotesCustomer: new Field('ExternalNotesCustomer', "", [''], false, !!item?.ExternalNotesCustomer ? "textarea" : "hidden", {
                addContainerClass: "col-start-1 col-span-full 3xl:col-span-8",
                note: this.props.translate("text.external_customer_notes_message"),
                fieldOptions: () => (
                    <div className="space-x-1 h-5 relative bottom-1">
                        <button
                            onClick={() => this.clearField("ExternalNotesCustomer")}
                            className="inline-block field-btn animate-button-fade-in"
                        >
                            <XMarkIcon className="w-5 h-5 text-primary"/>
                        </button>
                    </div>
                )
            }),

            InternalNotes: new Field('InternalNotes', "", [''], false, !!item?.InternalNotes ? "textarea" : "hidden", {
                addContainerClass: "col-start-1 col-span-full 3xl:col-span-8",
                note: this.props.translate("text.internal_notes_message"),
                fieldOptions: () => (
                    <div className="space-x-1 h-5 relative bottom-1">
                        <button
                            onClick={() => this.clearField("InternalNotes")}
                            className="inline-block field-btn animate-button-fade-in"
                        >
                            <XMarkIcon className="w-5 h-5 text-primary"/>
                        </button>
                    </div>
                )
            }),

            PriceTypeID: new Field('PriceTypeID', 1, [''], false, 'select', {addContainerClass: "col-span-6"}),
            PriceBase: new Field('PriceBase', "", ['float'], false, 'float', {addContainerClass: "col-span-6"}),
            IsConvertedToLoad: new Field('IsConvertedToLoad', 0, [''], false, 'hidden'),
            IsRejected: new Field('IsRejected', 0, [''], false, 'hidden'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    };

    getSectionSettings = () => {
        const defaultData = {
            LoadInfoBasic: {
                name: "LoadInfoBasic",
                expanded: true,
                visible: true
            },
            LoadInfoLocations: {
                name: "LoadInfoLocations",
                expanded: true,
                visible: true
            },
            LoadInfoCommodities: {
                name: "LoadInfoCommodities",
                expanded: true,
                visible: true
            },
        }
        const QuoteSectionSetting = this.props.match.params.id ? LocalStorage.get("quotes_section_settings") : null;

        if (QuoteSectionSetting) {
            return Object.values(defaultData).reduce((memo, it) => {
                memo[it.name] = Object.assign(it, QuoteSectionSetting[it.name]);
                return memo;
            }, {});
        }
        return defaultData;
    }

    handleLayoutScroll = () => {
        if (this.layoutRef?.current.scrollTop > 100 && !this.state.isLayoutScrolled) {
            this.setState({
                isLayoutScrolled: true
            })
        }

        if (this.layoutRef?.current.scrollTop <= 100 && this.state.isLayoutScrolled) {
            this.setState({
                isLayoutScrolled: false
            })
        }
    }

    handleScrollLayoutOnTop = () => {
        if (this.layoutRef?.current) {
            this.layoutRef.current.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    isCopyMode = () => {
        return this.props.match.path.includes('copy')
    }

    render() {
        const {translate, resource} = this.props;
        const {isLayoutScrolled} = this.state;
        const isLoading = getProp(resource, "isLoading", false);
        const isRejected = getProp(resource, "data.IsRejected", false);
        const isConvertedToLoad = getProp(resource, "data.IsConvertedToLoad", false);
        const LoadID = getProp(resource, "data.LoadID", null);
        const quoteID = getProp(resource, "data.LoadQuoteID", "");

        const basicInfoFields = fieldsToHtml(Object.values(includeFields(this.state.fields, ["LoadTypeID", "LoadSubTypeID", "CustomerID", "CustomerReferenceNumber", "OfficeID", "ContactGroupID", "InternalNotes", "ExternalNotesCustomer"])), translate, this.handleInputChange, this.selects);
        const pricingFields = fieldsToHtml(Object.values(includeFields(this.state.fields, ["PriceBase", "PriceTypeID"])), translate, this.handleInputChange, this.selects);

        let stopFields = []
        let selectedLocation = this.state.stopsCombined.find(it => !!it.IsSelected);
        if (selectedLocation) {
            const isStopBy = selectedLocation.StopType === STOP_TYPE_STOP_BY;

            selectedLocation = isStopBy ? this.state.stopBys[selectedLocation.index] : this.state.stops[selectedLocation.index];

            stopFields = isStopBy ? fieldsToHtml(Object.values(includeFields(selectedLocation, [
                    "StopByTypeID",
                    "ActualArrivalDate",
                    "ActualArrivalDateTime",
                    "ActualDepartureDate",
                    "ActualDepartureDateTime",
                    "Notes"
                ])), translate, this.handleStopByInputChange, this.selects) :
                fieldsToHtml(
                    Object.values(
                        includeFields(
                            selectedLocation,
                            ["StopID", "StopType", "ReferenceNumber", "RequiresAppointment", "AptReferenceNumber", "StopDate", "StopTime", "StopEndDate", "StopEndTime", "Notes", "LoadStopID"]
                        )
                    ),
                    translate,
                    this.handleStopInputChange,
                    this.selects
                );
        }

        let commodityUnitType;

        const totalCommodityWeight = numberWithCommas(this.state.CommodityData.reduce((memo, it) => {
            // Todo: Check how many types there are
            if (!commodityUnitType) {
                commodityUnitType = it.UnitType
            }

            if (!it.Weight) {
                return memo
            }

            memo = memo + Number(cnv(it.Weight, getWeightUnitName(it.WeightUnit), this.defaultWeightUnit))
            return memo
        }, 0))

        commodityUnitType = commodityUnitType ?? translate("text.unit_s");

        const totalCommodityPieces = this.state.CommodityData.reduce((memo, it) => {
            memo = memo + Number(it.CountPieces)
            return memo
        }, 0)

        let totalCommodityVolume = 0;
        const totalCommodityPallets = this.state.CommodityData.reduce((memo, it) => {
            totalCommodityVolume = totalCommodityVolume + (this.getCommodityVolume(it) * it.CountPallets);
            memo = memo + Number(it.CountPallets)
            return memo
        }, 0)

        return (
            <Layout
                {...this.props}
                hasFooter
                onScroll={this.handleLayoutScroll}
                layoutRef={this.layoutRef}
                isDirty={this.state.isDirty}
                customPageTitle={quoteID ? `#${quoteID}` : 'Quote'}
            >
                <div className="col-span-full min-h-[calc(100vh-16rem)] relative">
                    <div className="flex items-center border-b border-tm-gray-300 pb-2">
                        <div className="flex items-center grow">
                            <GoBackButton
                                translate={this.props.translate}
                                history={this.props.history}
                                path={'/quotes'}
                            />

                            <h1 className={'flex items-center mr-5 text-3xl ml-2 text-tm-gray-900'}>
                                {this.isCopyMode() ? translate('text.copy_quote') : translate('text.Quote')}

                                {this.isEditMode && (
                                    <span className="text-tm-gray-700">{" : " + this.ID}</span>
                                )}
                            </h1>

                            <div className="flex justify-end space-x-3 ml-auto">
                                {!!this.state.fields.IsConvertedToLoad.value && (
                                    <div
                                        className="flex items-center gap-2 bg-green-600/10 rounded-md pl-2 pr-2 py-1 text-base">
                                        <CheckBadgeIcon className="w-5 h-5 text-green-700"/>
                                        {translate("text.ConvertedToLoad")}
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                openInNewTab(`/loads/info/${LoadID}`);
                                            }}
                                            className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                        >
                                            {LoadID}
                                        </button>
                                    </div>
                                )}

                                {!!this.state.fields.IsRejected.value && (
                                    <div
                                        className="flex items-center gap-2 bg-red-600/10 rounded-md pl-2 pr-4 py-1 text-base">
                                        <XCircleIcon className="w-5 h-5 text-red-700"/>
                                        {translate("text.rejected")}
                                    </div>
                                )}
                            </div>
                        </div>


                        {checkPerm(Resources.QuotesLoadConvert, CREATE_PERM) && this.isEditMode && !isConvertedToLoad && !isRejected && !isLoading && (
                            <React.Fragment>

                                <Tippy
                                    disabled={!this.state.isDirty}
                                    content={translate("text.save_quote_convert_info")}
                                >
                                    <div>
                                        <button
                                            disabled={this.state.isDirty}
                                            onClick={this.handleConvertToLoadClick}
                                            className="btn btn-primary ml-auto"
                                        >
                                            {translate("btn.convert_to_load")}
                                        </button>
                                    </div>
                                </Tippy>
                            </React.Fragment>
                        )}
                    </div>

                    {isLoading && (
                        <div className="absolute flex flex-center items-center inset-0">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!isLoading && (
                        <div className="flex flex-col max-w-full 3xl:max-w-7xl 3xl:mx-auto relative mt-4">
                            <div
                                className="hidden w-36 h-full absolute xl:block -left-4 3xl:left-auto 3xl:right-full -translate-x-4 3xl:-translate-x-4 ml-8">
                                <div className="sticky h-10 top-16">
                                    {isLayoutScrolled && (
                                        <div className="flex space-x-2">
                                            <GoBackButton
                                                translate={this.props.translate}
                                                history={this.props.history}
                                                path={'/quotes'}
                                            />

                                            <button
                                                onClick={this.handleScrollLayoutOnTop}
                                                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                                            >
                                                {this.props.match.params.id ?? translate("text.scroll_to_top")}
                                            </button>
                                        </div>
                                    )}

                                    {[
                                        {
                                            name: "LoadInfoBasic",
                                            visible: this.state.expandedSections.LoadInfoBasic.visible,
                                            ref: this.LoadInfoBasicSection
                                        },
                                        {
                                            name: "LoadInfoLocations",
                                            visible: this.state.expandedSections.LoadInfoLocations.visible,
                                            ref: this.LoadInfoLocationsSection
                                        },
                                        {
                                            name: "LoadInfoCommodities",
                                            visible: this.state.expandedSections.LoadInfoCommodities.visible,
                                            ref: this.LoadInfoCommoditiesSection
                                        }

                                    ].filter(it => it.visible).map(it => (
                                        <div
                                            key={it.name}
                                            className="group flex items-center lg:text-sm lg:leading-6 text-primary"
                                        >
                                            <button
                                                className="btn-icon p-1 mr-2 rounded-full hover:bg-tm-gray-200"
                                                onClick={() => this.toggleExpandSection(it.name)}
                                            >
                                                {!this.state.expandedSections[it.name].expanded && (
                                                    <PlusIcon className="w-5 h-5 text-green-600"/>
                                                )}

                                                {this.state.expandedSections[it.name].expanded && (
                                                    <MinusIcon className="w-5 h-5"/>
                                                )}
                                            </button>

                                            <button
                                                className="font-medium focus-visible:underline w-full text-left hover:text-tm-gray-900 py-2"
                                                onClick={() => this.handleScrollToSectionClick(it.ref)}
                                            >
                                                {translate("text." + it.name)}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Card
                                addClass="w-full xl:max-w-[calc(100%-144px)] ml-auto 3xl:max-w-full max-w-full"
                                bodyClass="py-3 space-y-3"
                            >
                                <LoadInfoBasic
                                    innerRef={this.LoadInfoBasicSection}
                                    fields={basicInfoFields}
                                    CustomerLoadSetting={this.state.fields.CustomerID.value?.metadata?.CustomerLoadSetting}
                                    loadStatusID={this.state.loadStatusID}
                                    onAddNoteClick={this.handleAddNoteClick}
                                    getCustomerPrimaryContact={() => null}
                                    areInternalNotesVisible={this.state.fields.InternalNotes.type !== 'hidden'}
                                    areCustomerNotesVisible={this.state.fields.ExternalNotesCustomer.type !== 'hidden'}
                                    isSectionExpanded={this.state.expandedSections.LoadInfoBasic.expanded}
                                    toggleExpandSection={this.toggleExpandSection}
                                    translate={translate}
                                />

                                <div className="grid grid-cols-3 px-6 border-t border-tm-gray-300">
                                    <div className="col-span-1 py-6 pr-3">
                                        <div className="flex items-center">
                                            <Subtitle subtitle={translate("text.pricing")}/>
                                        </div>
                                    </div>

                                    <div className="pl-5 col-span-2 py-6">
                                        <div className="grid grid-cols-12 gap-4">
                                            {pricingFields}
                                        </div>
                                    </div>
                                </div>

                                <LoadInfoLocations

                                    isQuote={true}

                                    innerRef={this.LoadInfoLocationsSection}
                                    fieldSelects={this.selects}
                                    areLocationAssetsVisible={this.state.lineOfBusiness !== 'brokerage'}
                                    hasTrailer={!!this.state.fields.TrailerID?.value?.value}
                                    // applySelectedLocationUnit={this.applySelectedLocationUnit}
                                    onStopByInputChange={this.handleStopByInputChange}
                                    onStopInputChange={this.handleStopInputChange}

                                    isEditMode={true}
                                    isSectionExpanded={this.state.expandedSections?.LoadInfoLocations?.expanded}
                                    fields={stopFields}

                                    stops={this.state.stops}
                                    stopBys={this.state.stopBys}
                                    stopsCombined={this.state.stopsCombined}

                                    onLocationClick={this.handleStopClick}
                                    onLocationEnterPress={this.handleLocationEnterPress}

                                    onStopDragStart={this.handleStopDragStart}
                                    onStopDragEnd={this.handleStopDragEnd}
                                    onStopDragEnter={this.handleStopDragEnter}
                                    draggedStopIndex={this.state.draggedStopIndex}

                                    onAddLocationClick={this.handleAddLocationClick}
                                    onAddStopByClick={this.handleAddStopByClick}
                                    onAddStopsClick={this.handleAddStopsClick}

                                    onDeleteLocationClick={this.handleDeleteLocationClick}
                                    onSelectLocationClick={this.toggleSelectLocationDialog}
                                    onDeleteStopByClick={this.handleDeleteStopByClick}

                                    toggleLocationDialog={this.toggleLocationDialog}
                                    toggleContactsDialog={this.toggleContactsDialog}

                                    loadStatusID={LOAD_STATUS_READY}
                                    initialLoadStatusID={LOAD_STATUS_READY}
                                    isStateDirty={this.state.isDirty}

                                    toggleWorkingHoursModal={this.toggleWorkingHoursModal}
                                    toggleExpandSection={this.toggleExpandSection}
                                    translate={translate}
                                />

                                <LoadInfoCommodities
                                    addClass="px-6 border-t border-tm-gray-300"
                                    innerRef={this.LoadInfoCommoditiesSection}
                                    data={convertMeasurements(cloneDeep(this.state.CommodityData))}
                                    getCommodityFields={getCommodityFields}
                                    toggleCommodityFormModal={this.toggleCommodityFormModal}
                                    handleDeleteCommodityClick={this.handleDeleteCommodityClick}

                                    totalCommodityPieces={totalCommodityPieces}
                                    totalCommodityPallets={totalCommodityPallets}
                                    commodityUnitType={commodityUnitType}
                                    totalCommodityVolume={totalCommodityVolume}
                                    totalCommodityWeight={totalCommodityWeight}
                                    defaultWeightUnit={this.defaultWeightUnit}

                                    isSectionExpanded={this.state.expandedSections?.LoadInfoCommodities?.expanded}
                                    toggleExpandSection={this.toggleExpandSection}
                                    translate={translate}
                                    isStateDirty={this.state.isDirty}
                                    hidePrintBOL={true}
                                />
                            </Card>
                        </div>
                    )}
                </div>

                <React.Fragment>
                    <div
                        className="h-16 flex justify-end items-center fixed bottom-0 right-0 px-8 bg-inverse border-tm-gray-200 border-t page-footer-width z-40"
                    >
                        {this.isEditMode && (
                            <Button
                                hasPerm={checkPerm(Resources.QuotesLoadReject, CREATE_PERM)}
                                className={'btn btn-danger mr-auto'}
                                disabled={isRejected || isLoading || isConvertedToLoad}
                                onClick={this.handleRejectQuoteClick}>{translate('btn.reject_quote')}
                            </Button>
                        )}

                        <div className="flex gap-x-3 items-center">
                            {!!this.props.resource.isLoading && (
                                <LoaderSmall addClass="mr-4"/>
                            )}
                            <Button
                                hasPerm={checkPerm(Resources.QuotesLoad, UPDATE_PERM)}
                                className={'btn btn-outline'}
                                disabled={!(this.state.isDirty) || isRejected || isConvertedToLoad && !this.isCopyMode()}
                                onClick={this.submit}>{translate('btn.save_quote')}
                            </Button>


                            <button
                                className={'btn btn-text'}
                                disabled={!this.state.isDirty || isRejected || isConvertedToLoad}
                                onClick={this.handleCancelClick}>{translate('btn.cancel')}
                            </button>
                        </div>
                    </div>
                </React.Fragment>

                <Modal
                    show={!!this.state.isCommodityDialogOpen}
                    widthClass="max-w-full md:max-w-2xl"
                    onClose={() => this.toggleCommodityFormModal()}
                >
                    <CommodityForm
                        title={(this.state.selectedItem ? 'Edit' : 'Add') + ' commodity'}
                        onClose={() => this.toggleCommodityFormModal()}
                        locationFields={this.state.stops}
                        selectedItem={this.state.selectedItem}
                        onSubmit={this.handleCommodityFormSubmitClick}
                        translate={translate}
                    />
                </Modal>

                <ModalTabbed
                    title={translate(`modal_heading.select_stop_by_location`)}
                    show={this.state.isSelectLocationModalOpen}
                    onClose={this.closeSelectLocationDialog}
                    onSubmit={this.handleSelectLocationClick}
                    validateOnlyCurrentTab={true}
                    sidebarWidth={null}
                    tabFields={getStopByFields(this.state.StopByItem, translate)}
                    selects={this.selects}
                    tabsData={{
                        "select_location": {
                            name: 'select_location',
                            icon: MagnifyingGlassIcon,
                            fields: ['LocationID'],
                        },
                        "enter_manually": {
                            name: 'enter_manually',
                            icon: PencilSquareIcon,
                            containerClass: "p-6 w-full max-w-lg mx-auto grid grid-cols-12 gap-4",
                            fields: ["CountryID", "GooglePlaces", "AddressName", "CityName", "StateID", "PostalCode"]
                        },
                        "select_on_map": {
                            name: 'select_on_map',
                            icon: MapIcon,
                            fields: ["Latitude", "Longitude"],
                            component: (fields, handleInputChange, updateFields, setDirty) => {
                                return (
                                    <React.Fragment>
                                        <DragAndDrop
                                            className="w-full h-full relative"
                                            locations={[]}
                                            isLoading={this.state.isAxiosRequesting}
                                            loaderMessage={translate("text.fetching_data_from_google")}
                                            Latitude={fields.Latitude.value}
                                            Longitude={fields.Longitude.value}
                                            addMarker={(data) => {
                                                setDirty(true);
                                                fields.Latitude.value = data.Latitude;
                                                fields.Longitude.value = data.Longitude;
                                                updateFields(fields);
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            }
                        }
                    }}
                    translate={translate}
                />

                <Modal //treba da postoje dva ovakva dialoga, razlika je u show propu, ne brisati
                    show={this.state.isLocationDialogOpen && !this.state.isSelectLocationDialogOpen}
                    widthClass={'max-w-7xl w-screen'}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => this.toggleLocationDialog(false)}
                    translate={translate}
                    hideFooter
                >
                    <CreateLocationDialog
                        onClose={() => this.toggleLocationDialog(false)}
                        updateItem={this.state.selectedLocation}
                        dispatch={this.props.dispatch}
                        dialogResource={this.props.dialogResource}
                        secondResource={this.props.secondResource}
                        contactResource={this.props.contactResource}
                        translate={translate}
                        onSubmit={(params) => {
                            if (!!this.state.selectedLocation?.StopID || !!this.state.selectedLocation?.LocationID) {
                                params.LocationID = this.state.selectedLocation.StopID ?? this.state.selectedLocation.LocationID;
                            }

                            if (!!params.LocationID && params.LocationID !== -1) {
                                this.updateLocationsState(params);
                                this.props.dispatch(updateDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: Resources.Locations,
                                    errorMessage: true, successMessage: `Location ${params.LocationName} updated`,
                                }))
                            } else {
                                this.createLocationsState(params);
                                this.props.dispatch(createDialogResource({
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    query: {sort: "ASC", sortBy: "LocationName", offset: 0, limit: 10},
                                    resource: Resources.Locations,
                                    piggyResource: Resources.Locations,
                                    errorMessage: true, successMessage: 'Location Created Successfully!'
                                }))
                            }
                        }}
                    />
                </Modal>

                <Modal
                    show={this.state.isCreateSuccessDialogOpen}
                    widthClass={'max-w-sm w-screen'}
                    onClose={this.toggleCreateSuccessDialog}
                >
                    <ModalHeader
                        title={translate("text.quote_created_successfully")}
                        onClose={this.toggleCreateSuccessDialog}
                    />
                    <CreateSuccessModal
                        translate={translate}
                        buttons={[
                            {
                                label: translate("btn.quote"),
                                onClick: () => this.props.history.push("/quotes/info/" + this.ID),
                                iconLeading: DocumentTextIcon,
                            },
                            {
                                label: translate("btn.create_new"),
                                onClick: this.toggleCreateSuccessDialog,
                                iconLeading: DocumentIcon,
                            },
                            {
                                label: translate("btn.list"),
                                onClick: () => this.props.history.push("/quotes"),
                                iconLeading: Bars4Icon,
                                iconTrailing: ArrowTopRightOnSquareIcon
                            },
                            {
                                label: translate("btn.create_load_from_quote"),
                                onClick: () => {
                                    this.convertToLoad(this.ID);
                                },


                                iconLeading: PaperAirplaneIcon,
                                iconTrailing: ArrowTopRightOnSquareIcon
                            },
                            {
                                label: translate("btn.dashboard"),
                                onClick: () => this.props.history.push("/home"),
                                iconLeading: PresentationChartBarIcon,
                                iconTrailing: ArrowTopRightOnSquareIcon
                            },
                        ]}
                    />
                </Modal>

                {/* Select stop dialog */}
                <ResourceTableDialog
                    show={this.state.isSelectLocationDialogOpen}
                    hideDialogFooter={true}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.LocationsQuick}
                    title={translate("text.select_stop")}

                    noRecordsText={(currentQuery) => translate("text.no_location_query", [currentQuery])}
                    noRecordsBtnLabel={(currentQuery) => translate("btn.create_location_query", [currentQuery])}
                    noRecordsBtnClick={currentQuery => this.handleCreateLoadStop("StopID", currentQuery)}
                    dispatch={this.props.dispatch}
                    fields={{
                        LocationName: new Field('LocationName', '', ['']),
                        LocationNumber: new Field('LocationNumber', '', ['']),
                        LocationZoneID: new Field('LocationZoneID', '', [''], false, 'select-search'),
                        AddressName: new Field('AddressName', '', ['']),
                        CityName: new Field('CityName', '', ['']),
                        State: new Field('State', '', ['']),
                        PhoneNumber: new Field('PhoneNumber', '', [''], false, 'phone'),
                        CreateUpdateDate: new Field('CreateUpdateDate', '', [''], false, 'date'),
                    }}

                    widthClass={"max-w-7xl"}
                    options={{
                        style: {
                            stripedRows: true,
                            horizontalLines: false,
                            floatingActions: true,
                        },
                    }}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    tableHeaderRight={
                        <div className="col-start-11 col-span-2 flex justify-end items-center">
                            {checkPerm(Resources.Locations, CREATE_PERM) && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.handleCreateLoadStop("StopID", "")}
                                >
                                    {translate("btn.create_location")}
                                </button>
                            )}
                        </div>
                    }
                    sortBy={"LocationName"}
                    defaultAction={(item) => this.props.dispatch(showModal('ViewLocationCard', item))}
                    onCustomActions={[
                        {
                            action: (item) => {
                                this.handleStopInputChange("StopID", {
                                    label: item.LocationName,
                                    value: item.LocationID,
                                    metadata: item
                                })
                                this.handleSelectStopClick();
                            },
                            icon: PlusCircleIcon,
                            visible: () => true
                        }
                    ]}
                    onClose={this.handleSelectStopClick}
                    translate={translate}
                    htmlAfter={<Modal //treba da postoje dva ovakva dialoga, razlika je u show propu, ne brisati
                        show={this.state.isLocationDialogOpen && this.state.isSelectLocationDialogOpen}
                        widthClass={'max-w-7xl w-screen'}
                        closeButtonLabel={translate('btn.close')}
                        onClose={() => this.toggleLocationDialog(false)}
                        translate={translate}
                        hideFooter
                    >
                        <CreateLocationDialog
                            onClose={() => this.toggleLocationDialog(false)}
                            updateItem={this.state.selectedLocation}
                            dispatch={this.props.dispatch}
                            dialogResource={this.props.dialogResource}
                            secondResource={this.props.secondResource}
                            contactResource={this.props.contactResource}
                            translate={translate}
                            isContactsTabHidden={true}
                            onSubmit={(params) => {
                                if (!!this.state.selectedLocation?.StopID || !!this.state.selectedLocation?.LocationID) {
                                    params.LocationID = this.state.selectedLocation.StopID ?? this.state.selectedLocation.LocationID;
                                }

                                if (!!params.LocationID && params.LocationID !== -1) {
                                    this.updateLocationsState(params);
                                    this.props.dispatch(updateDialogResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        resource: Resources.Locations,
                                        errorMessage: true, successMessage: `Location ${params.LocationName} updated`,
                                    }))
                                } else {
                                    this.createLocationsState(params);
                                    this.props.dispatch(createDialogResource({
                                        user: LocalStorage.get('user'),
                                        params: params,
                                        query: {sort: "ASC", sortBy: "LocationName", offset: 0, limit: 10},
                                        resource: Resources.Locations,
                                        piggyResource: Resources.Locations,
                                        errorMessage: true, successMessage: 'Location Created Successfully!'
                                    }))
                                }
                            }}
                        />
                    </Modal>}
                />

                <ResourceTableDialog
                    show={this.state.isLocationContactsDialogOpen}
                    dialogResource={this.props.dialogResource}
                    resource={Resources.LocationsContacts}
                    title={translate("text.contacts_for_location", [this.state.selectedItem?.StopID?.value?.label])}

                    dispatch={this.props.dispatch}
                    fields={this.getContactDialogFields()}
                    widthClass={"max-w-7xl"}
                    fieldsFilter={{
                        query: new Field('query', '', [''], false, 'search', {
                            addContainerClass: 'col-span-3',
                            labelType: "float"
                        }),
                    }}
                    defaultQuery={{
                        id: this.state.selectedItem?.StopID?.value?.value
                    }}
                    defaultAction={(item) => this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))}
                    onClose={this.toggleContactsDialog}
                    translate={translate}
                />

                <ModalConfirm
                    show={this.state.isConfirmDeleteDialogOpen}
                    title={this.state.confirmTitle}
                    text={this.state.confirmText}
                    onClose={this.handleCloseConfirmDialogClick}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={this.state.confirmSubmit}
                />

                <GroupsViewDialog
                    groupID={this.state.fields.ContactGroupID?.value?.value}
                    isGroupInfoDialogOpen={this.state.isGroupInfoDialogOpen}
                    groupInfoData={this.state.groupInfoData}
                    onClose={() => this.setState({
                        isGroupInfoDialogOpen: false
                    })}
                    translate={translate}
                />
            </Layout>
        )
    }
}

export default connect(state => state)(QuoteView)
