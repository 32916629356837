import React, {Component} from 'react'
import {connect} from 'react-redux'
import Resources from '../../../data/services/resources'
import {Field} from '../../../data/services/fields'
import {
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    NUMBER_OF_AXLES,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, classNames, getLookup, getProp, openInNewTab} from '../../../common/util/util-helpers'
import TrucksTableBadge from '../trucks-view/trucks-table-badge'
import {EyeIcon} from "@heroicons/react/24/outline";
import LocalStorage from "../../../util/localStorage";
import {getDialogResource} from "../../../data/actions/dialogResource";
import PresentationChartLineIcon from "@heroicons/react/24/outline/PresentationChartLineIcon";
import Tippy from "@tippyjs/react";
import {numberWithCommas} from "../../../util/util-formaters";
import funnelIcon from "@heroicons/react/24/outline/esm/FunnelIcon";
import MapPinIconDark from "@heroicons/react/20/solid/MapPinIcon";
import {showGlobalModal} from "../../../data/actions/ui";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import NavResponsive from "../../../common/components/nav-responsive";
import MobileTabs from "../../../common/components/tabs-navigation/mobile-tabs";
import WidgetStats from "../../../common/components/widgets/widget-stats";
import ResourceListTab from "../../../common/components/tabs/resource-list-tab";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecords from "../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../common/components/resource-table";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import {toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import FleetShareTableMap
    from "../../../common/components/command-center-table/fleet-share-table/fleet-share-table-map";

class ReportsView extends Component {
    constructor(props) {
        super(props)

        const tabs = [
            {
                name: 'TruckReports',
                resource: Resources.TruckReports,
                current: true,
                visible: checkPerm(Resources.TruckReports, READ_PERM)
            },
            {
                name: 'TrailerReports',
                resource: Resources.TrailerReports,
                current: false,
                visible: checkPerm(Resources.TrailerReports, READ_PERM)
            },
            {
                name: 'MileageReports',
                resource: Resources.MileageReports,
                current: false,
                visible: checkPerm(Resources.MileageReports, READ_PERM)
            }
        ]

        this.state = {
            ...DEFAULT_CRUD_STATE,
            tabs: tabs,
            selectedTab: Resources.TruckReports,
            breakpoint: {},
            selectedItem: null,
            detailsDialogOpen: false,
            mileageFilters: [],
            areStatsVisible: LocalStorage.has(this.pageSettingsName) ? JSON.parse(LocalStorage.get(this.pageSettingsName) ?? {})?.areStatsVisible : true,
        }
    }


    fetchData = (item) => {
        this.props.dispatch(getDialogResource({
            user: LocalStorage.get("user"),
            resource: Resources.MileageReports,
            query: Object.assign({}, this.getQuery(), {
                DriverID: item?.DriverID,
                TruckID: item?.TruckID,
                StartDate: this.state?.mileageFilters?.StartDate?.value ?? '',
                EndDate: this.state?.mileageFilters?.EndDate?.value ?? ''
            })
        }))
    };

    /** UI Events
     ================================================================= */
    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            PerState: 1
        }
    }

    handleStatsToggleClick = () => {
        this.setState({areStatsVisible: !this.state.areStatsVisible}, () => {
            let pageSettings = {}

            if (LocalStorage.has(this.pageSettingsName)) {
                pageSettings = JSON.parse(LocalStorage.get(this.pageSettingsName))
            }

            Object.assign(pageSettings, {areStatsVisible: this.state.areStatsVisible})
            LocalStorage.set(this.pageSettingsName, JSON.stringify(pageSettings))
        })
    }

    handleTabChange = (selectedTab) => {
        if (selectedTab !== this.state.selectedTab) this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === selectedTab
                return it
            }),
            selectedTab: selectedTab
        })
    }

    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        })
    }

    handleToggleDetailsDialog = (item = null) => {
        this.fetchData(item);
        this.setState({detailsDialogOpen: !this.state.detailsDialogOpen, selectedItem: item});
    }

    handleQuickDriverView = (item) => {
        this.props.dispatch(showGlobalModal('ViewDriverCard', item?.DriverID))
    }

    handleQuickTruckView = (item) => {
        this.props.dispatch(showGlobalModal('ViewTruckCard', item?.TruckID))
    }

    getTruckFields = () => {
        return {
            fields: {
                TruckID: new Field('TruckID', '', [], false, 'text', {
                    render: (item) => {
                        if (!item.TruckID) return null;

                        return (
                            <button
                                onClick={(e) => {
                                    if (checkPerm(Resources.TrucksInfo, UPDATE_PERM)) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openInNewTab(`/trucks/info/${item.TruckID}`);
                                    }
                                }}
                                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                            >
                                {item.TruckNumber}
                            </button>
                        )
                    }
                }),
                StatusID: new Field('StatusID', '', [''], false, 'custom', {
                    render: (item) => (
                        <TrucksTableBadge
                            item={item}
                            translate={this.props.translate}
                        />
                    )
                }),
                LastGPSLocation: new Field('LastGPSLocation', '', [''], false, 'custom', {
                    render: (item) => {
                        if (item.LocationDate) {
                            return (<CellButton
                                onClick={() => {
                                    this.setState({isMapModalOpen: true, lastLocation: item})
                                }}
                            >
                                <div className="text-left">
                                    {item?.CityName || item?.State || item?.PostalCode ?
                                        <div>{item?.CityName ? item.CityName + ", " : ""} {item?.State} {item?.PostalCode}</div> : ""}
                                    {(item.LocationDate ? <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                                </div>
                            </CellButton>)
                        } else {
                            return (<div className="flex items-center">No data</div>)
                        }
                    }, omitSort: true
                }),
                Manufacturer: new Field('Manufacturer', '', ['']),
                Model: new Field('Model', '', ['']),
                Year: new Field('Year', '', [''], false, 'text'),
                VIN: new Field('VIN', '', ['']),
                TruckLicenceNumber: new Field('TruckLicenceNumber', '', ['']),
                TruckOwnershipTypeID: new Field('TruckOwnershipTypeID', '', [''], false, 'select'),
                IsNonIFTA: new Field('IsNonIFTA', '', [''], false, 'checkbox', {addContainerClass: 'col-span-3'}),
                TruckWidth: new Field('TruckWidth', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
                TruckHeight: new Field('TruckHeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
                TruckUnloadedWeight: new Field('TruckUnloadedWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
                TruckGrossWeight: new Field('TruckGrossWeight', '', [''], false, 'float_or_empty', {addContainerClass: 'col-span-3'}),
                TruckNumberOfAxles: new Field('TruckNumberOfAxles', '', [''], false, 'select', {addContainerClass: 'col-span-3'}, {
                    values: NUMBER_OF_AXLES
                }),
                Office: new Field('Office', '', [''], false, 'text'),
                ContactGroup: new Field('ContactGroup', '', [], false, 'text', {label: "FleetGroupID"}),
            },
            searchFields: {
                query: new Field('query', '', [''], false, 'search', {
                    containerClass: 'col-md-4'
                }, {}),
                // LocationID: new Field('LocationID', '', [], false, 'select-search', {}, {isClearable: true}),
                limit: new Field('limit', 10, [''], false, 'select', {
                    containerClass: 'col-md-4 limit-field-container float-right', hideLabel: true
                })
            }
        }
    }

    getTrailerFields = () => {
        return {
            fields: {
                TrailerID: new Field('TrailerID', '', [], false, 'custom', {
                    render: (item) => {
                        if (!item.TrailerID) return null;

                        return (
                            <button
                                onClick={(e) => {
                                    if (checkPerm(Resources.Trailer, UPDATE_PERM)) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openInNewTab(`/trailers/info/${item.TrailerID}`);
                                    }
                                }}
                                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                            >
                                {item.TrailerNumber}
                            </button>
                        )
                    }
                }),
                StatusID: new Field('StatusID', '', [''], false, 'custom', {
                    render: (item) => (
                        <TrucksTableBadge
                            item={item}
                            translate={this.props.translate}
                        />
                    )
                }),
                LastGPSLocation: new Field('LastGPSLocation', '', [''], false, 'custom', {
                    render: (item) => {
                        if (item.LocationDate) {
                            return (<CellButton
                                onClick={() => {
                                    this.setState({isMapModalOpen: true, lastLocation: item})
                                }}
                            >
                                <div className="text-left">
                                    {item?.CityName || item?.State || item?.PostalCode ?
                                        <div>{item?.CityName ? item.CityName + ", " : ""} {item?.State} {item?.PostalCode}</div> : ""}
                                    {(item.LocationDate ? <div>({toFrontDateTimeFromUTC(item.LocationDate)})</div> : undefined)}
                                </div>
                            </CellButton>)
                        } else {
                            return (<div className="flex items-center">No data</div>)
                        }
                    }, omitSort: true
                }),
                Manufacturer: new Field('Manufacturer', '', ['']),
                Model: new Field('Model', '', ['']),
                Year: new Field('Year', '', [''], false, 'text'),
                VIN: new Field('VIN', '', ['']),
                TrailerLicenceNumber: new Field('TrailerLicenceNumber', '', ['']),
                OfficeID: new Field('OfficeID', '', [''], false, 'select-search'),
                TrailerOwnershipTypeID: new Field('TrailerOwnershipTypeID', '', [''], false, 'select'),
                CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date')
            },
            searchFields: {
                query: new Field('query', '', [''], false, 'search', {
                    containerClass: 'col-md-4'
                }, {}),
                limit: new Field('limit', 10, [''], false, 'select', {
                    containerClass: 'col-md-4 limit-field-container float-right', hideLabel: true
                })
            }
        }
    }

    getMileageFields = () => {
        const cellButtonClass = 'btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0';
        return {
            fields: {
                DriverID: new Field('DriverID', '', [''], false, 'select-search', {
                    render: (item) =>
                        <CellButton
                            onClick={() => checkPerm(Resources.Drivers, READ_PERM) ? this.handleQuickDriverView(item) : null}
                            className={cellButtonClass}
                        >
                            <div>{item.Driver}</div>
                        </CellButton>
                }),
                TruckID: new Field('TruckID', '', [''], false, 'select-search', {
                    render: (item) =>
                        <CellButton
                            onClick={() => checkPerm(Resources.Trucks, READ_PERM) ? this.handleQuickTruckView(item) : null}
                            className={cellButtonClass}
                        >
                            <div>{item.Truck}</div>
                        </CellButton>
                }),
                Mileage: new Field('Mileage', '', [''], false, 'float', {}),
                Fuel: new Field('Fuel', '', [''], false, 'float', {}),
            },
            searchFields: {
                limit: new Field('limit', 300, [''], false, 'select', {
                    containerClass: 'col-md-4 limit-field-container float-right', hideLabel: true
                }, {   menuPlacement: "top"}),
                StartDate: new Field('StartDate', '', [''], false, 'date', {}, {isClearable: true}),
                EndDate: new Field('EndDate', '', [''], false, 'date', {}, {isClearable: true}),
                DriverID: new Field('DriverID', '', [''], false, 'select-search', {}, {isClearable: true}),
                TruckID: new Field('TruckID', '', [''], false, 'select-search', {}, {isClearable: true}),
                StateID: new Field('StateID', '', [''], false, 'select', {}, {isClearable: true})
            }
        }
    }

    getDetailsFields = () => {
        return {
            StateID: new Field('StateID', '', ['empty'], false, 'select'),
            Mileage: new Field('Mileage', '', [''], false, 'float', {}),
            Fuel: new Field('Fuel', '', [''], false, 'float', {})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const dialogData = getProp(this.props, "dialogResource.data.list", []);
        const isDialogDataLoading = getProp(this.props, "dialogResource.isLoading", false);
        const TotalMileage = getProp(this.props.resource.data, 'TotalMileage', 0)
        const TotalFuel = getProp(this.props.resource.data, 'TotalFuel', 0)

        const stats = [
            {
                id: 1,
                name: translate('text.TotalMileage'),
                icon: MapPinIconDark,
                value: numberWithCommas(TotalMileage, 0),
            },
            {
                id: 2,
                name: translate('text.TotalFuel'),
                icon: funnelIcon,
                value: (TotalFuel),
                valueSuffix: ' gal',
                decimalCount: 2
            }
        ]
        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.reports')}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    >
                        {!!(this.state.selectedTab === Resources.MileageReports) && (
                            <Tippy
                                content={translate(this.state.areStatsVisible ? 'text.hide_stats' : 'text.show_stats')}
                                delay={[400, 0]}
                                trigger="mouseenter"
                            >
                                <button
                                    className={
                                        classNames(
                                            this.state.areStatsVisible ? 'text-primary border-primary' : undefined,
                                            'btn btn-header'
                                        )
                                    }
                                    onClick={this.handleStatsToggleClick}
                                >
                                    <PresentationChartLineIcon
                                        className="w-5 h-5"
                                    />
                                </button>
                            </Tippy>
                        )}
                    </PageHeader>

                    <div className="mb-5 hidden sm:block">
                        <NavResponsive
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="mb-5 sm:hidden">
                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    {(this.state.areStatsVisible && (this.state.selectedTab === Resources.MileageReports) && (
                        <div className="relative z-40">
                            <div className="grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-3">
                                <WidgetStats
                                    stats={stats}
                                />
                            </div>
                        </div>
                    ))}

                    {this.state.selectedTab === Resources.TruckReports && (
                        <ResourceListTab
                            key={Resources.TruckReports}
                            dispatch={this.props.dispatch}
                            translate={translate}
                            resource={this.props.resource}
                            breakpoint={this.state.breakpoint}

                            sortBy={''}
                            primaryKey={''}
                            resourceName={Resources.TruckReports}
                            fields={this.getTruckFields().fields}
                            searchFields={this.getTruckFields().searchFields}
                            selects={{
                                LocationID: {
                                    api: 'api/' + Resources.LocationsQuick,
                                    query: {},
                                    searchMap: (it) => ({
                                        label: it.LocationName,
                                        value: it.LocationID,
                                        metadata: it
                                    })
                                }
                            }}
                            disableEdit
                            disableCreate
                            disableDelete
                        />
                    )}

                    {this.state.selectedTab === Resources.TrailerReports && (
                        <ResourceListTab
                            key={Resources.TrailerReports}
                            dispatch={this.props.dispatch}
                            translate={translate}
                            resource={this.props.resource}
                            breakpoint={this.state.breakpoint}

                            sortBy={''}
                            primaryKey={''}
                            resourceName={Resources.TrailerReports}
                            fields={this.getTrailerFields().fields}
                            searchFields={this.getTrailerFields().searchFields}

                            disableEdit
                            disableCreate
                            disableDelete
                        />
                    )}

                    {this.state.selectedTab === Resources.MileageReports && (
                        <ResourceListTab
                            key={Resources.MileageReports}
                            dispatch={this.props.dispatch}
                            translate={translate}
                            resource={this.props.resource}
                            breakpoint={this.state.breakpoint}
                            isLoading={isDialogDataLoading}
                            dialogTitle={translate('modal_heading.mileage_report')}
                            sortBy={'DriverID'}
                            primaryKey={'DriverID'}
                            resourceName={Resources.MileageReports}
                            fields={this.getMileageFields().fields}
                            searchFields={this.getMileageFields().searchFields}
                            filters={(it) => this.setState({mileageFilters: it})}
                            metadata={{
                                TruckID: {
                                    api: 'api/' + Resources.TrucksQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        value: item.TruckID,
                                        label: item.Truck
                                    })
                                },
                                DriverID: {
                                    api: 'api/' + Resources.DriversQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        value: item.DriverID,
                                        label: item.FirstName + " " + item.LastName
                                    })
                                },
                                StateID: getLookup('State')
                            }}

                            hasPagination={false}
                            disableEdit
                            disableCreate
                            disableDelete

                            onRowClick={(item) => this.handleToggleDetailsDialog(item)}

                            actions={[
                                {
                                    title: () => this.props.translate('btn.view'),
                                    visible: () => true,
                                    action: (item) => this.handleToggleDetailsDialog(item),
                                    icon: () => EyeIcon
                                }
                            ]}
                        />
                    )}

                    <ModalDefault
                        show={this.state.detailsDialogOpen}
                        widthClass={"max-w-6xl"}
                        title={translate('modal_heading.mileage_report') + " " + ((this.state.selectedItem?.DriverID ? this.state.selectedItem?.Driver : "") + (this.state.selectedItem?.TruckID ? " - " + this.state.selectedItem?.Truck : ""))}

                        closeButtonLabel={translate('btn.Close')}
                        onClose={() => this.handleToggleDetailsDialog()}
                    >
                        <ResourceTable
                            data={dialogData}
                            commonTable={true}
                            fields={this.getDetailsFields()}

                            isLoading={isDialogDataLoading}
                            translate={translate}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                        />

                        <NoRecords
                            addClass={'mt-5'}
                            show={(dialogData.length === 0) && !isDialogDataLoading}
                            title={translate('text.no_records')}
                        />

                    </ModalDefault>

                    <ModalDefault
                        show={this.state.isMapModalOpen}
                        widthClass={"max-w-7xl"}
                        title={translate("text.last_known_truck_location")}

                        closeButtonLabel={translate("btn.close")}
                        onClose={() => this.setState({isMapModalOpen: false})}
                    >
                        <FleetShareTableMap
                            translate={translate}
                            selectedLocation={this.state.lastLocation}
                        />
                    </ModalDefault>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(ReportsView)
