import React, {Component} from "react";
import {scrollIntoView} from "../../../util/util-vanilla";
import {classNames} from "../../../util/util-helpers";

export default class FieldText extends Component {
    handleMutateValue = (value) => { // Ako dodje do jos pravila koji moraju da mutiraju tekstualnu vrednost, ovde cemo zapisivati pravila
        let mutatedValue = value
        if (this.props.numbersAndLettersOnly) {
            let regex = /[^A-Za-z0-9]+/
            mutatedValue = mutatedValue?.replace(regex, '')
        }
        if (this.props.upperCaseOnly) {
            mutatedValue = mutatedValue?.toUpperCase()
        }
        if (this.props.lowerCaseOnly) {
            mutatedValue = mutatedValue?.toLowerCase()
        }
        if (this.props.numbersOnly) {
            mutatedValue = mutatedValue?.replace(/\D/, '')
        }

        return mutatedValue ?? this.props.value
    }

    render() {
        const input = (
            <input
                id={this.props.id}
                ref={this.props.innerRef}
                type={this.props.type}
                required={!!this.props.validate && !!this.props.validate.length && this.props.validate[0] !== "" ? "empty" : false}
                readOnly={this.props.readOnly}
                autoFocus={this.props.autoFocus}
                onChange={(event) => {
                    this.props.onChange(this.props.name, this.handleMutateValue(event.target.value))
                }}
                onBlur={this.props.onBlur}
                onFocus={(event) => {
                    if (this.props.onFocus) {
                        this.props.onFocus(event);
                    }

                    scrollIntoView(event);
                }}
                tabIndex={this.props.tabIndex}
                onKeyDown={this.props.onKeyDown}
                name={this.props.name}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={this.props.disabled}
                className={this.props.addClass + (this.props.errorMessage ? " is-invalid" : "")}
                min={this.props.min}
                maxLength={this.props.max}
                step={this.props.step}
                autoComplete={this.props.autocomplete}
            />);

        return (
            <React.Fragment>
                <div className={"relative"}>
                    {!!this.props.icon && (
                        <div
                            className="absolute top-1 right-1 flex pointer-events-none">
                            {this.props.icon}
                        </div>
                    )}

                    {!!this.props.clear && (
                        <div className="relative">
                            {this.props.value && (
                                <svg className="w-5 h-5 absolute right-2 top-1/2 -translate-y-1/2" onClick={this.props.clear}>
                                    <path
                                        fill="currentColor"
                                        d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"/>
                                </svg>
                            )}

                            {input}
                        </div>
                    )}

                    {!this.props.clear && (
                        input
                    )}
                </div>

                {this.props.errorMessage && (
                    <div className={classNames("text-red-600 font-semibold", this.props?.metadata?.errorNoWrap ? "whitespace-nowrap" : undefined)}>
                        {!!this.props.translate && (
                            this.props.errorMessage.reduce((memo, it) => {
                                if (it.key) {
                                    return this.props.translate(it.key, it.values) + (!!memo ? "," + memo : "")
                                }
                                return this.props.translate(it) + (!!memo ? "," + memo : "")
                            }, "")
                        )}
                        {!this.props.translate && (
                            <span>Field required</span>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }
}
