import React, {useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {checkPerm, classNames, getLookup, getProp} from '../../../common/util/util-helpers'
import {cloneDeep} from '../../../common/util/util-vanilla'
import UpcomingLoads from "./load-sections/upcoming-loads";
import LoadInfoReadLocations from "./load-sections/load-info-read-locations";
import ContactSelectButton from './load-sections/load-components/contact-select-button'
import {showModal} from '../../../data/actions/ui'
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, UPDATE_PERM} from "../../../util/util-constants";
import Resources from "../../../data/services/resources";
import {updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../util/localStorage";
import DataCard from "../../../common/components/display-data/data-card";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import {includeFields} from "../../../common/util/util-fields";
import PencilIcon from "@heroicons/react/24/outline/PencilIcon";
import InfoBar from "../../../common/components/info-paragraph/info-bar";
import ModalConfirm from "../../../common/components/modal/modal-confirm";

const LoadInfoRead = (props) => {
    const {
        translate,
        fields,
        stops,
        stopsCombined,
        stopBys,
        selects,
        EmptyMiles,
        TotalMiles,
        TotalMilesNoStopBy,
        resource,
        onCompleteStopClick,
        isLocationAssetsInfoVisible,
        isLoadInternational,
        dispatch,
        CustomerContactsList,
        deliveredLoadInstructions,
        isInvoiced,
        getID,
        dispatchHistory
    } = props

    const editFields = ['LoadTypeID', 'OfficeID', 'ContactGroupID', 'CustomerID', 'CustomerReferenceNumber', 'ContactGroupID', 'ExternalNotesCustomer', 'InternalNotes', 'ExternalNotesCustomer'];

    const customerContactsList = Object.values(CustomerContactsList ?? {});

    let fieldsClone = cloneDeep(fields);

    const [isInvoicedCustomerChanged, setIsInvoicedCustomerChanged] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isConfirmCustomerChangeModalOpen, setIsConfirmCustomerChangeModalOpen] = useState(false);

    Object.assign(
        fieldsClone,
        {LineOfBusiness: new Field('LineOfBusiness', translate("text." + props.lineOfBusiness), [''], false, 'text')}
    );

    let fieldValues = Object.values(cloneDeep(fieldsClone)).reduce((memo, field) => {
        let value

        if (field.type === 'select') {
            value = getLookup(field.name.replace('ID', ''))?.[field.value]
        } else if (field.type === 'select-search' || field.type === 'creatable-select-search') {
            value = field.value.label
        } else if (field.type === 'checkbox') {
            value = field.value ? translate('text.yes') : translate('text.no')
        } else {
            value = field.value
        }

        memo[field.name] = value;
        return memo;
    }, {})

    const basicLoadInfoFields = ['LoadTypeID', 'LoadSubTypeID', 'OfficeID', 'ContactGroupID', 'CustomerID', 'CustomerReferenceNumber'];
    const basicLoadInfo = includeFields(fieldsClone, basicLoadInfoFields);

    const assetFields = props.lineOfBusiness === 'carrier' ?
        [
            "LineOfBusiness", "DriverID", "CoDriverID", "TruckID", "TrailerID", "TrailerType", "SecondTrailerID",
            "SecondTrailerType", "ThirdTrailerID", "ThirdTrailerType"
        ] :
        [
            "LineOfBusiness", "CoBrokerID", "CarrierID", "CarrierReferenceNumber", "DriverNameTxt", "DriverCellTxt",
            "CoDriverNameTxt", "CoDriverCellTxt", "TruckTxt", "TrailerTxt", "TrailerTypeTxt", "DispatchNameTxt",
            "DispatchAreaCode", "DispatchPhoneNumber", "DispatchPhoneExtension", "DispatchContactTxt", "DispatchContactID"
        ]

    const assets = includeFields(fieldsClone, assetFields);

    const notesFields = ['InternalNotes', 'ExternalNotesCustomer', 'ExternalNotes', 'ExternalDriverNotes', 'ExternalCarrierNotes'];
    const notes = includeFields(fieldsClone, notesFields);

    const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false)

    const editDetailsFieldsList = [
        'InternalNotes', 'ExternalNotesCustomer',
        'OfficeID', 'ContactGroupID', 'CustomerID'
    ]
    let editDetailsFields = Object.assign({}, includeFields(fieldsClone, editDetailsFieldsList))


    const getEditDetailsFields = () => {
        if (isInvoiced) {
            editDetailsFields.LoadTypeID.metadata.htmlBefore = () => <div className="col-span-full">
                <InfoBar type="danger">
                    Changing customer value after the load is invoiced would alter the contents of the invoice, and the
                    invoice creator would be notified of this action.
                </InfoBar>
            </div>
        }

        editDetailsFields.CustomerReferenceNumber.type = 'text';
        editDetailsFields.LoadTypeID.type = 'select';
        editDetailsFields.ContactGroupID.validate = ['empty']
        editDetailsFields.ExternalNotesCustomer.type = 'textarea';
        editDetailsFields.InternalNotes.type = 'textarea';
        editDetailsFields.InternalNotes.metadata.fieldOptions = null
        editDetailsFields.ExternalNotesCustomer.metadata.fieldOptions = null

        editDetailsFields = Object.values(editDetailsFields).reduce((memo, field) => {
            if (editFields.includes(field.name))
                field.metadata.addContainerClass = "col-span-full";
            memo[field.name] = field;
            return memo;
        }, {})

        return editDetailsFields;
    }

    function handleDetailsInputChange(detailsFields, name, value) {
        const detailsFieldsUpdate = FieldsManager.updateField(detailsFields, name, value);
        
        if (isInvoiced && "CustomerID" === name) {
            setIsInvoicedCustomerChanged(fields.CustomerID?.value?.value !== detailsFields.CustomerID?.value?.value);
        }

        return detailsFieldsUpdate
    }

    function submitEditedDetails(items) {
        let params = {};

        params.LoadID = getID;

        editFields.forEach(fieldName => {
            params[fieldName] = items[fieldName];
        })

        dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: params,
            resource: Resources.LoadPost,
            piggyResource: Resources.LoadInfo,
            piggyQuery: {
                id: getID
            },
            successMessage: translate('message.load_details_updated'),
        }))
    }

    return (
        <div className="space-y-4">
            <div className={
                classNames(
                    "grid gap-8 max-w-7xl mx-auto",
                    getProp(resource, "data.load/upcomingLoads", []).length ? "grid-cols-3" : "grid-cols-2"
                )
            }>
                <div>
                    <div
                        className="text-lg leading-6 font-medium text-tm-gray-900 my-2 flex justify-between items-center h-9">
                        {translate('card_header.basic_load_info')}

                        {checkPerm(Resources.LoadPost, UPDATE_PERM) && (
                            <button className={"btn-outline btn"} onClick={() => {
                                setIsEditDetailsModalOpen(true)
                            }}>
                                <PencilIcon className="w-5 h-5 mr-2"/> Edit Details
                            </button>
                        )}
                    </div>

                    <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                        {Object.values(basicLoadInfo).filter(it => it.type !== 'hidden').map((field, i) => {
                            field.type = "text";

                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={fieldValues}
                                    className={
                                        classNames(
                                            "py-1.5 flex items-center px-6",
                                            i < basicLoadInfoFields.length - 1
                                                ? "border-b border-tm-gray-300"
                                                : undefined
                                        )
                                    }
                                    selects={selects}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>
                </div>

                <div>
                    <div
                        className="text-lg leading-6 font-medium text-tm-gray-900 my-2 flex justify-between items-center h-9">
                        {translate('text.assets')}

                        {dispatchHistory}
                    </div>

                    <div className="bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                        {Object.values(assets).filter(it => it.type !== 'hidden').map((field, i) => {
                            field.type = "text";

                            return (
                                <DataCard
                                    key={field.name}
                                    displayField={field}
                                    fieldsData={fieldValues}
                                    className={classNames("px-3 py-1.5 flex items-center px-6", i < assetFields.length - 1 ? "border-b border-tm-gray-300" : undefined)}
                                    selects={selects}
                                    translate={translate}
                                />
                            )
                        })}
                    </div>
                </div>

                {!!customerContactsList.length && (
                    <div className='col-span-full'>
                        <div className='font-bold leading-5 mb-2'>
                            Customer list
                        </div>

                        <div className='flex flex-wrap gap-2'>
                            {
                                customerContactsList.map(contact => {
                                    return <ContactSelectButton
                                        readOnly={true}
                                        key={contact.ContactID}
                                        id={contact.ContactID}
                                        name={contact.LastName + " " + contact.FirstName}
                                        hasImagePath={!!contact.ImagePath}
                                        onChoseContactClick={() => dispatch(
                                            showModal('ViewContactCard', {"ContactID": contact.ContactID}))
                                        }
                                        onAvatarClick={() => dispatch(
                                            showModal('ViewContactCard', {ContactID: contact.ContactID}))
                                        }
                                        isLoading={false}
                                        translate={translate}
                                    />
                                })
                            }
                        </div>
                    </div>
                )}

                {!!getProp(resource, "data.load/upcomingLoads", []).length && (
                    <div className="w-full max-w-sm">
                        <p className="text-lg leading-6 font-medium text-tm-gray-900 my-2 flex justify-between items-center h-9">
                            {translate("text.upcoming_loads_for_driver", [fieldValues.DriverID])}
                        </p>

                        <div className="space-y-4">
                            <UpcomingLoads
                                upcomingLoads={
                                    getProp(resource, "data.load/upcomingLoads", [])
                                }
                            />
                        </div>
                    </div>
                )}
            </div>

            <div className="grid gap-y-4 mx-auto">
                {!!deliveredLoadInstructions?.Instruction && !!deliveredLoadInstructions?.Active && (
                    <div>
                        <div className="font-bold leading-5 mb-2">{translate("text.delivered_load_instructions")}</div>

                        <div className="px-3 py-8 bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                            <div className="max-w-2xl mx-auto whitespace-pre-line">
                                <span
                                    dangerouslySetInnerHTML={{__html: deliveredLoadInstructions?.Instruction.split("\n").join("<br/>")}}
                                >
                                </span>
                            </div>
                        </div>
                    </div>
                )}

                {Object.values(notes).filter(it => it.type !== 'hidden' && !!it.value).map((field) => {
                    return (
                        <div key={field.name}>
                            <div className="font-bold leading-5 mb-2">{translate('field.' + field.name)}</div>

                            <div className="px-3 py-8 bg-inverse rounded-xl border-tm-gray-300 border shadow-card">
                                <div className="max-w-2xl mx-auto whitespace-pre-line">
                                    {field.value}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <LoadInfoReadLocations
                TotalMiles={TotalMiles}
                TotalMilesNoStopBy={TotalMilesNoStopBy}
                EmptyMiles={EmptyMiles}
                isLoadInternational={isLoadInternational}
                stopsCombined={stopsCombined}
                onCompleteStopClick={onCompleteStopClick}
                isStopInfoVisible={true}
                isLocationAssetsInfoVisible={isLocationAssetsInfoVisible}
                stops={stops}
                stopBys={stopBys}
                translate={translate}
            />

            <ModalSaveResource
                show={isEditDetailsModalOpen}
                title={translate('text.load_edit_details')}
                widthClass="max-w-md"
                canSubmit={true}
                onClose={() => setIsEditDetailsModalOpen(false)}
                fields={getEditDetailsFields()}
                handleInputChange={handleDetailsInputChange}
                onSubmit={(items) => {
                    if (isInvoicedCustomerChanged) {
                        setSelectedItem(items);
                        setIsConfirmCustomerChangeModalOpen(true);
                    } else {
                        submitEditedDetails(items);
                    }
                }}
                metadata={{
                    CustomerID: {
                        api: 'api/' + Resources.CustomersQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            label: item.LegalName || item.Organization,
                            value: item.CustomerID,
                            metadata: item
                        })
                    },
                    OfficeID: {
                        api: 'api/' + Resources.OfficesQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (it) => ({
                            label: it.OfficeName,
                            value: it.OfficeID
                        })
                    },
                    ContactGroupID: {
                        api: 'api/' + Resources.ContactGroupsQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (it) => ({
                            label: it.ContactGroupName,
                            value: it.ContactGroupID
                        })
                    }
                }}
                translate={translate}
            />

            <ModalConfirm
                title={'Confirm customer change'}
                show={isConfirmCustomerChangeModalOpen}
                onClose={() => {
                    setIsConfirmCustomerChangeModalOpen(false);
                    selectedItem(null);
                }}
                buttonLabel={translate('btn.yes')}
                closeButtonLabel={'No'}
                translate={translate}
                onConfirm={() => {
                    setIsConfirmCustomerChangeModalOpen(false);
                    submitEditedDetails(selectedItem);
                    setSelectedItem(null);
                }}
            >
                <div className="text-base mb-2">
                    Changing customer value after the load is invoiced will alter the contents of the invoice, and the invoice creator will be notified of this action.
                </div>

                <div className="text-base">
                    Are you sure you want to perform this action?
                </div>
            </ModalConfirm>
        </div>
    )
}

export default LoadInfoRead
