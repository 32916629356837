import {
    TRAILER_STATUS_TYPE_TIPPY_TEXT,
    TRUCK_STATUS_AVAILABLE,
    TRUCK_STATUS_BOOKED,
    TRUCK_STATUS_ON_LOAD,
    TRUCK_STATUS_OUT_OF_SERVICE,
    TRUCK_STATUS_RETIRED,
    TRUCK_STATUS_SOLD,
    TRUCK_STATUS_TYPE_TIPPY_TEXT,
    TRUCK_STATUS_TYPES,
    UPDATE_PERM
} from "../../../../util/util-constants";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Tippy from "@tippyjs/react";
import React from "react";
import {checkPerm} from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import Badge from "../../../../common/components/badge";

export default function TrucksTableBadge({item, itemType, translate}) {
    if (!item || !item?.StatusID) {
        return null;
    }

    const canFollowLink = !!item.StatusID && !!item.OnLoadID;

    return (
        <Tippy
            disabled={!item.StatusID}
            content={<span>{
                itemType === 'trailer' ? translate(TRAILER_STATUS_TYPE_TIPPY_TEXT[item.StatusID]) : translate(TRUCK_STATUS_TYPE_TIPPY_TEXT[item.StatusID])
            }</span>}
        >
            <div>
                <Badge
                    onClick={(e) => {
                        e.stopPropagation();
                        if (checkPerm(Resources.LoadInfo, UPDATE_PERM) && canFollowLink && (item.StatusID === TRUCK_STATUS_BOOKED || item.StatusID === TRUCK_STATUS_ON_LOAD)) {
                            window.open(`${window.location.origin}/loads/info/${item.OnLoadID}`, '_blank');
                        }
                    }}
                    type={getBadgeClass(item.StatusID)}>
                    <div className="flex">
                        {TRUCK_STATUS_TYPES[item.StatusID]}
                        {canFollowLink && (item.StatusID === TRUCK_STATUS_BOOKED || item.StatusID === TRUCK_STATUS_ON_LOAD) && (
                            <ArrowTopRightOnSquareIcon
                                className={'w-5 h-5 ml-4'}
                            />
                        )}
                    </div>
                </Badge>
            </div>
        </Tippy>
    )
}

const getBadgeClass = (type) => {
    switch (type) {
        case TRUCK_STATUS_AVAILABLE:
            return "success"
        case TRUCK_STATUS_BOOKED:
        case TRUCK_STATUS_ON_LOAD:
            return "primary"
        case TRUCK_STATUS_SOLD:
        case TRUCK_STATUS_RETIRED:
            return "danger"
        case TRUCK_STATUS_OUT_OF_SERVICE:
            return "warning"
        default:
            return "danger"
    }
}
