import React, {Component} from 'react'
import {fieldsToHtml, fillFieldsFromData, includeFields,} from '../../../common/util/util-fields';
import Resources from '../../../data/services/resources'
import Env from '../../../util/env'
import axios from 'axios'
import {processResponse} from '../../../data/services/api-util'
import Tippy from '@tippyjs/react'
import {ArrowTopRightOnSquareIcon} from '@heroicons/react/20/solid'
import {showModal} from '../../../data/actions/ui'
import {
    DEFAULT_HISTORY_TABLE_FILTER_FIELDS,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    READ_PERM,
    UPDATE_PERM
} from '../../../common/util/util-consts'
import {checkPerm, getLookup, getProp, getVacationTemplateLabel} from '../../../common/util/util-helpers'
import {toFrontDateNoTime} from '../../../common/util/util-dates'
import {getHistoryResource} from "../../../data/actions/historyResource";
import LocalStorage from "../../../util/localStorage";
import {Field} from "../../../data/services/fields";
import {HourglassIcon} from "../../../data/themes/icons";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import FieldGooglePlaces from "../../../common/components/fields/field-google-places";
import PhoneFields from "../../../common/components/misc/phone-fields";
import ContactPhoto from "../../../common/components/forms/contact-form/contact-photo";
import HistoryModal, {DEFAULT_HISTORY_TABLE_FIELDS} from "../../../common/components/modal/modal-history";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {LoaderSmall} from "../../../common/components/loader";
import EmployeeStatusBadge from "../../../common/components/badge/employee-status-badge";
import FieldSelectSearch from "../../../common/components/fields/field-select-search";
import FieldDropdownSelect from "../../../common/components/fields/field-dropdown-select";
import Card from "../../../common/components/card";
import InfoParagraph from "../../../common/components/info-paragraph";
import ContactInfoFields from "../../../common/components/contact/contact-fields/contact-info-fields";
import FieldContainer from "../../../common/components/fields/field-container";
import FieldText from "../../../common/components/fields/field-text";
import EmergencyContactFields from "../../../common/components/contact/contact-fields/emergency-contact-fields";
import CompanyRoleFields from "../../../common/components/contact/contact-fields/company-role-fields";
import Modal from "../../../common/components/modal";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";
import {getJWT} from "../../../common/util/util-auth";

export default class EmployeeInfoTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            historyModalOpen: false
        }
    }

    cancel = () => {
        if (this.props.canSubmit) {
            this.props.cancel()
        }
    }

    getFieldTypes = () => {
        return Object.values(this.props.fields).reduce((memo, it) => {
            memo[it.name] = it.type
            return memo
        }, {})
    }

    returnLastEmployeeEmployment = () => {
        const EmployeeEmployment = getProp(this.props.resource, 'data.EmployeeEmployment', [])
        let styles = {
            1: 'btn-success',
            2: 'btn-outline',
            3: 'btn-outline-warning'
        }
        const lastEmployment = EmployeeEmployment[EmployeeEmployment.length - 1] ?? {}
        let txt = EmployeeEmployment.length > 0 ? `Last employment info: ${toFrontDateNoTime(lastEmployment.HireDate)} - ${toFrontDateNoTime(lastEmployment.ReleaseDate)} ${lastEmployment.ContractNum ? `(${lastEmployment.ContractNum})` : ''}` : `Employee has no employment info`
        let style = EmployeeEmployment.length > 0 ? styles[2] : styles[3]
        return (
            <button
                onClick={() => this.props.setActiveTab(Resources.EmployeesEmployments)}
                className={`btn h-8 ${style}`}>
                {txt}
            </button>
        )
    }

    getFieldsToHtmlForIncludedFields = (arr = []) => {
        const selects = {
            MailingStateID: getLookup('State'),
            OfficeID: {
                api: 'api/' + Resources.OfficesQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    label: item.OfficeName,
                    value: item.OfficeID
                })
            }
        }

        return fieldsToHtml((Object.values(Object.assign({}, includeFields(this.props.fields, arr)))), this.props.translate, this.props.handleInputChange, selects)
    }

    getLinkedContactFields = () => {
        const linkedInfo = this.props.linkedContactInfo;
        const fieldTemplates = {
            FirstName: new Field('FirstName', '', [''], true, 'text', {addContainerClass: "md:col-span-4 md:col-start-1 my-2"}),
            LastName: new Field('LastName', '', [''], true, 'text', {addContainerClass: "md:col-span-4 my-2"}),
            Email: new Field('Email', '', [''], true, 'text', {
                addContainerClass: "md:col-span-4 my-2",
                label: "BusinessEmail"
            }),
            PersonalTitleID: new Field('PersonalTitleID', '', [''], true, 'select', {addContainerClass: "md:col-span-4"}, {placeholder: ' '}),
            Title: new Field('Title', '', [''], true, "text", {addContainerClass: "md:col-span-4"}),
            DateOfBirth: new Field('DateOfBirth', '', [''], true, "date", {addContainerClass: "md:col-span-4 my-2"})
        }

        let fields = fillFieldsFromData(fieldTemplates, linkedInfo)

        return fields
    }

    render() {
        const {translate, resource, historyResource} = this.props

        const states = getLookup('State', 'StateID', 'State')
        const countries = getLookup('Country', 'CountryID', 'CountryName')

        const HireNote = getProp(this.props.resource, 'data.HireNote', '')

        const employmentData = getProp(this.props.resource, 'data', '')
        const statusID = employmentData.StatusID

        const fieldsHtmlMailingAddressInfo = this.getFieldsToHtmlForIncludedFields(this.props.fields.MailingSameAsFirstAddress.value ? ['MailingSameAsFirstAddress'] : ['MailingSameAsFirstAddress', 'MailingListAddressAutocomplete', 'MailingAddressName', 'MailingAddressName2', 'MailingCityName', 'MailingStateID', 'MailingPostalCode'])

        const fieldsHtmlLinkedContact = fieldsToHtml(Object.values(Object.assign({}, this.getLinkedContactFields())), translate)

        return (
            <div className={"pb-16"}>
                {resource.isLoading && (
                    <Card addBodyClass="">
                        <div className="relative h-72">
                            <LoaderSmall addClass={'absolute inset-center'}/>
                        </div>
                    </Card>
                )}

                {checkPerm(Resources.EmployeesHistory, READ_PERM) && this.props.match.params.id && !resource.isLoading &&
                    <div className={'flex justify-between ml-4'}>
                        <TabTitle
                            text={translate('text.Employee')}
                        />

                        <div className={'flex justify-end items-center mb-4'}>
                            <div className={'mx-1'}>
                                <div className="mx-1">
                                    <EmployeeStatusBadge
                                        item={{StatusID: statusID}}
                                        translate={translate}
                                    />
                                </div>
                                {HireNote && <InfoParagraph className="mt-2" message={HireNote}/>}
                            </div>

                            <div className={'mx-1'}>
                                {this.returnLastEmployeeEmployment()}
                            </div>

                            <Tippy content={<span>{translate('text.view_history')}</span>}>
                                <button
                                    className="btn btn-header"
                                    onClick={() => this.setState({historyModalOpen: true})}
                                >
                                    {<HourglassIcon className="w-5 h-5"/>}
                                </button>
                            </Tippy>
                        </div>
                    </div>
                }

                {!resource.isLoading && (
                    <React.Fragment>
                        <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-4 pb-4">
                            <div>
                                <div className="row">
                                    <div className="col">
                                        {this.props.resource.errorMessage === 'EMAIL_PARAMETER_ALREADY_EXISTS' &&
                                            <div className={'col-md-12 '}>
                                                <div className="alert alert-danger rounded display-space-between">
                                                    {translate('EMAIL_PARAMETER_ALREADY_EXISTS')}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <Card addClass={"h-full"}>
                                    {!this.props.mainID && (
                                        <div className="row mb-3">
                                            <div className="col">
                                                <button onClick={this.props.handleToggleCreateFromExistingContact}
                                                        className="btn btn-outline cursor-pointer ">
                                                    {!this.props.createFromExistingContact ? translate('text.link_existing_contact') : translate('text.create_contact')}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {this.props.createFromExistingContact && (
                                        <div className="row">
                                            <div className="col">
                                                <label className="flex justify-between items-center">
                                                    <span
                                                        className={'font-bold'}>{translate('field.ContactID')} *</span>
                                                    {this.props.fields.ContactID.value?.value && (
                                                        <span className="flex justify-end items-center">
                                                        {checkPerm(Resources.Contacts, UPDATE_PERM) && (
                                                            <Tippy content={<span>{translate('btn.edit')}</span>}>
                                                                <span
                                                                    className={'cursor-pointer'}
                                                                    onClick={() => window.open(`${window.location.origin}/contacts?ContactID=${this.props.fields.ContactID.value?.value}`, '_blank')}>
                                                                    <ArrowTopRightOnSquareIcon
                                                                        className={'w-5 h-5 text-primary'}/>
                                                                </span>
                                                            </Tippy>
                                                        )}
                                                    </span>
                                                    )}
                                                </label>
                                                <div>
                                                    <FieldDropdownSelect
                                                        onChange={this.props.handleInputChange}

                                                        {...this.props.fields.ContactID}
                                                        addClass="form-control"
                                                        defaultOptions={true}
                                                        placeholder={' '}
                                                        loadOptions={
                                                            (inputValue, callback) => {
                                                                axios.get(
                                                                    Env.getApiUrl('api/' + Resources.ContactsQuick, {
                                                                        query: inputValue,
                                                                        NotEmployees: 1,
                                                                        NotAgents: 1,
                                                                        NotDrivers: 1
                                                                    }),
                                                                    {
                                                                        headers: {
                                                                            'Authorization': 'Bearer ' + getJWT().access_token
                                                                        }
                                                                    }
                                                                )
                                                                    .then((response) => {
                                                                        const result = processResponse(response)
                                                                        if (result && result.status === 0) {
                                                                            const list = result.data.list.map((it) => {
                                                                                return {
                                                                                    label: it.FirstName + ' ' + it.LastName,
                                                                                    value: it.ContactID,
                                                                                    metadata: it
                                                                                }
                                                                            })
                                                                            callback(list)
                                                                        }
                                                                    })
                                                            }
                                                        }
                                                    />
                                                </div>

                                                <InfoParagraph className={"mt-5"}
                                                               message={translate("text.use_existing_contact")}/>
                                                <div>{this.props.fields.ContactID.value != -1 && fieldsHtmlLinkedContact}</div>

                                            </div>
                                        </div>
                                    )}

                                    {!this.props.createFromExistingContact && (
                                        <ContactInfoFields
                                            fields={this.props.fields}
                                            handleInputChange={this.props.handleInputChange}
                                            translate={translate}
                                            ValidationType={{
                                                1: 'Email',
                                                2: 'SMS',
                                            }}
                                            hideSystemUser={this.props.match.params.id}
                                            selectedItem={this.props.match.params.id} // for disabling email
                                        />
                                    )}

                                </Card>
                            </div>

                            <div>
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.AddressInfo')}
                                    />
                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-4">
                                            {/*<label className="form-group has-float-label mb-4">*/}
                                            <FieldContainer
                                                item={this.props.fields.CountryID}
                                                translate={translate}
                                            >
                                                <FieldSelectSearch
                                                    className="form-control"
                                                    values={countries}
                                                    onChange={this.props.handleInputChange}
                                                    {...this.props.fields.CountryID}
                                                    placeholder={''} addClass={'form-control'}/>
                                                {/*<span>{translate('field.country_id')}</span>*/}
                                            </FieldContainer>
                                            {/*</label>*/}
                                        </div>

                                        <div className="col-span-8">
                                            <label
                                                htmlFor="Notes"
                                                className="flex items-center text-sm font-semibold text-tm-gray-900 h-5 whitespace-nowrap"
                                            >
                                                <span>{translate('field.location_autocomplete')}</span>
                                            </label>
                                            <React.Fragment>
                                                <FieldGooglePlaces
                                                    key={this.props.fields.CountryID?.value}
                                                    setLocations={this.props.setLocations}
                                                    stateFields={this.props.fields}
                                                    placeholderText={translate('text.enter_a_city')}
                                                />
                                            </React.Fragment>
                                        </div>
                                    </div>


                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-full">
                                            <FieldContainer
                                                item={this.props.fields.AddressName}
                                                translate={translate}
                                            >
                                                <FieldText
                                                    addClass="form-control"
                                                    onChange={this.props.handleInputChange}
                                                    {...this.props.fields.AddressName}
                                                    placeholder={''}/>
                                            </FieldContainer>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-full">
                                            <FieldContainer
                                                item={this.props.fields.AddressName2}
                                                translate={translate}
                                            >
                                                <FieldText
                                                    addClass="form-control"
                                                    onChange={this.props.handleInputChange}
                                                    {...this.props.fields.AddressName2}
                                                    placeholder={''}/>
                                            </FieldContainer>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-4">
                                            <FieldContainer
                                                item={this.props.fields.CityName}
                                                translate={translate}
                                            >
                                                <FieldText
                                                    addClass="form-control"
                                                    onChange={this.props.handleInputChange}
                                                    {...this.props.fields.CityName}
                                                    placeholder={''}/>
                                            </FieldContainer>
                                        </div>
                                        {this.props.fields.CountryID.value == 1 && (
                                            <div className="col-span-4">
                                                <FieldContainer
                                                    item={this.props.fields.StateID}
                                                    translate={translate}
                                                >
                                                    <FieldSelectSearch
                                                        className="form-control"
                                                        values={states}
                                                        onChange={this.props.handleInputChange}
                                                        {...this.props.fields.StateID}
                                                        placeholder={''} addClass={'form-control'}/>
                                                </FieldContainer>
                                            </div>
                                        )}
                                        <div className="col-span-4">
                                            <FieldContainer
                                                item={this.props.fields.PostalCode}
                                                translate={translate}
                                            >
                                                <FieldText
                                                    addClass="form-control"
                                                    onChange={this.props.handleInputChange}
                                                    {...this.props.fields.PostalCode}
                                                    placeholder={''}/>
                                            </FieldContainer>
                                        </div>
                                    </div>
                                </Card>
                            </div>

                            <div>
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.MailingAddressInfo')}
                                    />
                                    <div className="grid grid-cols-3 gap-4">
                                        {fieldsHtmlMailingAddressInfo}
                                    </div>
                                </Card>
                            </div>

                            {!this.props.createFromExistingContact && (

                                <div>
                                    <Card addClass={'h-full'}>
                                        <CardSubTitle
                                            text={translate('text.Phones')}
                                        />

                                        <PhoneFields
                                            phonesFields={this.props.phonesFields}
                                            handleItemInputChange={this.props.handleItemInputChange}
                                            removePhoneField={this.props.removePhoneField}
                                            {...this.props}
                                        />

                                        <div className="row mb-5">
                                            <div className="col">
                                                <button className="btn btn-outline"
                                                        onClick={() => this.props.addPhoneField()}>{translate('btn.add_new_phone')}</button>
                                            </div>
                                        </div>

                                        <ContactPhoto
                                            addClass="col-span-full"
                                            imageID={getProp(this.props.resource, 'data.ContactID', null)}
                                            hasImage={getProp(this.props.resource, 'data.ImagePath', null)}
                                            lastUpdated={getProp(this.props.resource, 'data.CreateUpdateDate', null)}
                                            onGetImage={(file) => this.props.setImage(file)}
                                            translate={translate}
                                            removeDisabled={true}
                                        />
                                    </Card>
                                </div>
                            )}

                            <div>
                                <Card addClass={'h-full'}>
                                    <div className="grid grid-cols-1 gap-4">
                                        <EmergencyContactFields
                                            translate={this.props.translate}
                                            handleInputChange={this.props.handleInputChange}
                                            fields={this.props.fields}
                                        />
                                    </div>
                                </Card>
                            </div>


                            <div>
                                <Card addClass={'h-full'}>
                                    <CardSubTitle
                                        text={translate('text.EmployeeInfo')}
                                    />
                                    {/*<div className="col-md-4">*/}
                                    <div className="col-span-4">
                                        <FieldContainer
                                            item={this.props.fields.EmployeeIDNumber}
                                            translate={translate}
                                        >
                                            <FieldText
                                                addClass="form-control"
                                                onChange={this.props.handleInputChange}
                                                {...this.props.fields.EmployeeIDNumber}
                                                placeholder={''}
                                            />
                                        </FieldContainer>
                                    </div>

                                    <div className="col-span-4">
                                        <FieldContainer
                                            item={this.props.fields.SocialSecurityNumber}
                                            translate={translate}
                                        >
                                            <FieldText
                                                addClass="form-control"
                                                id={'SocialSecurityNumber'}
                                                onChange={this.props.handleInputChange}
                                                {...this.props.fields.SocialSecurityNumber}
                                            />
                                        </FieldContainer>

                                    </div>

                                    <div className="col-span-4">
                                        <FieldContainer
                                            item={this.props.fields.OfficeID}
                                            translate={translate}
                                        >
                                            <FieldDropdownSelect
                                                onChange={this.props.handleInputChange}
                                                {...this.props.fields.OfficeID}
                                                addClass={'form-control'}
                                                className={'form-control'}
                                                defaultOptions={true}
                                                placeholder={' '}
                                                loadOptions={
                                                    (inputValue, callback) => {
                                                        axios.get(
                                                            Env.getApiUrl('api/' + Resources.OfficesQuick, {query: inputValue}),
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                                }
                                                            }
                                                        )
                                                            .then((response) => {
                                                                const result = processResponse(response)
                                                                if (result && result.status === 0) {
                                                                    let officesCache = {}
                                                                    const list = result.data.list.map((it) => {
                                                                        officesCache[it.OfficeID] = it.AreaCode

                                                                        if (it.OfficeID && it.OfficeID === this.props.fields.OfficeID.value) {
                                                                            this.setState({
                                                                                OfficeName: it.OfficeName,
                                                                                defaultCountryCode: it.AreaCode
                                                                            })
                                                                        }
                                                                        return {
                                                                            label: it.OfficeName,
                                                                            value: it.OfficeID
                                                                        }
                                                                    })

                                                                    this.setState({officesCache})
                                                                    callback(list)
                                                                }
                                                            })
                                                    }
                                                }
                                            />
                                        </FieldContainer>
                                    </div>

                                    <div className="col-span-4">
                                        <FieldContainer
                                            item={this.props.fields.UserDefinedField1}
                                            translate={translate}
                                        >
                                            <FieldText
                                                addClass="form-control"
                                                onChange={this.props.handleInputChange}
                                                {...this.props.fields.UserDefinedField1}
                                                placeholder={''}
                                            />
                                        </FieldContainer>
                                    </div>

                                    <div className="col-span-4">
                                        <FieldContainer
                                            item={this.props.fields.UserDefinedField2}
                                            translate={translate}
                                        >
                                            <FieldText
                                                addClass="form-control"
                                                onChange={this.props.handleInputChange}
                                                {...this.props.fields.UserDefinedField2}
                                                placeholder={''}
                                            />
                                        </FieldContainer>
                                    </div>

                                    <div className="col-span-4">
                                        <FieldContainer
                                            item={this.props.fields.VacationTemplateID}
                                            translate={translate}
                                        >
                                            <FieldDropdownSelect
                                                onChange={this.props.handleInputChange}
                                                {...this.props.fields.VacationTemplateID}
                                                addClass={'form-control'}
                                                className={'form-control'}
                                                defaultOptions={true}
                                                placeholder={' '}
                                                loadOptions={
                                                    (inputValue, callback) => {
                                                        axios.get(
                                                            Env.getApiUrl('api/' + Resources.CompanyVacation, {query: inputValue}),
                                                            {
                                                                headers: {
                                                                    'Authorization': 'Bearer ' + getJWT().access_token
                                                                }
                                                            }
                                                        )
                                                            .then((response) => {
                                                                const result = processResponse(response)
                                                                if (result && result.status === 0) {
                                                                    const list = result.data.list.map((it) => {
                                                                        return {
                                                                            label: getVacationTemplateLabel(it),
                                                                            value: it.VacationTemplateID
                                                                        }
                                                                    })

                                                                    callback(list)
                                                                }
                                                            })
                                                    }
                                                }
                                            />
                                        </FieldContainer>
                                    </div>

                                    <div className="grid grid-cols-1 gap-4">
                                        <CompanyRoleFields
                                            translate={this.props.translate}
                                            handleInputChange={this.props.handleInputChange}
                                            fields={this.props.fields}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </div>


                        {!this.props.isCreate && (
                            <PageFooter
                                translate={translate}
                                canSubmit={this.props.canSubmit}
                                actionCancel={this.cancel}
                                actionSubmit={checkPerm(Resources.EmployeesInfo, UPDATE_PERM) && this.props.onSubmit}
                            />
                        )}
                    </React.Fragment>
                )}

                <Modal
                    show={this.state.historyModalOpen}
                    widthClass={"max-w-5xl"}
                    onClose={() => this.setState({historyModalOpen: false})}
                    translate={translate}
                >
                    {this.state.historyModalOpen && (
                        <HistoryModal
                            onClose={() => this.setState({historyModalOpen: false})}
                            translate={translate}
                            title={translate("text." + Resources.EmployeesHistory)}
                            tableFields={DEFAULT_HISTORY_TABLE_FIELDS(translate, this.getFieldTypes())}
                            filterFields={DEFAULT_HISTORY_TABLE_FILTER_FIELDS()}
                            onFetchData={(query) => {
                                this.props.dispatch(getHistoryResource({
                                    user: LocalStorage.get('user'),
                                    query: Object.assign({}, query, {
                                        id: this.props.match.params.id
                                    }),
                                    resource: Resources.EmployeesHistory
                                }))
                            }}
                            data={getProp(historyResource, "data.list", [])}
                            count={getProp(historyResource, "data.count", 0)}
                            isLoading={getProp(historyResource, "isLoading", false)}
                            showUserAvatar

                            onRowClick={(item) => {
                                this.props.dispatch(showModal('ViewContactCard', {ContactID: item.ContactID}))
                            }}
                            fieldTypes={this.getFieldTypes()}
                        />
                    )}
                </Modal>
            </div>
        )
    }
}
