import {classNames, getBoundsFromStops, getDefaultTableOptions, getProp} from '../../../../common/util/util-helpers'
import React, {useMemo, useRef, useState} from "react";
import LoadInfoReadLocations from "./load-info-read-locations";
import {MapPinIcon, MapIcon} from "@heroicons/react/20/solid";
import FlagIcon from "@heroicons/react/20/solid/FlagIcon";
import ArrowsRightLeftIcon from "@heroicons/react/20/solid/ArrowsRightLeftIcon";
import {generateStops, mergeStops} from "../load-utils";
import LocalStorage from "../../../../util/localStorage";
import {toFrontDateTimeFromUTC} from '../../../../common/util/util-dates'
import {Field} from "../../../../data/services/fields";
import TrimbleMap from "../../../../common/components/map/TrimbleMap";
import ResourceTable from "../../../../common/components/resource-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import NoRecords from "../../../../common/components/no-records-found/no-records";
import Buttons from "../../../../common/components/buttons";
import TableCardFooter from '../../../../common/components/resource-table/table-components/table-card-footer'
import {checkFeatureFlag} from "../../../../common/components/feature-flags";
import Accur8Tracking from "./accur8-tracking";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {ClockIcon} from "@heroicons/react/24/outline";

export default function LoadInfoTracks({
                                           data,
                                           tracksKey = undefined,
                                           mapHeaderButtons,
                                           queryFields,
                                           onQueryChange,
                                           actions,
                                           translate,
                                           trackingData,
                                           isLoading,
                                           loadID,
                                           piggyResource,
                                           driverCell,
                                           driverName,
                                           isPublic
                                       }) {
    const mapRef = useRef();
    const [currentTab, setCurrentTab] = useState("load");

    const tableOptions = useMemo(() => {
        return getDefaultTableOptions(getTrackFields(), {
            style: {
                floatingActions: true,
                stripedRows: true
            },
            columns: {
                Number: {
                    minWidth: 70
                },
                Source: {
                    minWidth: 100
                },
                IsSynced: {
                    minWidth: 80
                }
            }
        }, "load-info-tracks", translate)
    }, []);

    const dataListKey = [tracksKey, "list"].filter(it => !!it);
    const dataCountKey = [tracksKey, "count"].filter(it => !!it);

    let tracks = getProp(data, dataListKey, []);

    tracks = tracks.map((it, index) => {
        it.Number = Number(index) + 1
        return it;
    });

    let tracksCount = getProp(data, dataCountKey, 0);
    const hasTracks = !!tracks.length;
    const multistopsData = getProp(data, 'load/multistops', []);
    const stopOffs = getProp(data, 'load/stopby', []);
    let stops = generateStops(multistopsData, translate);
    const hasStops = !!stops.length;
    let sortedStops = multistopsData.concat(getProp(data, "load/stopby", [])).sort((a, b) => a.StopOrder - b.StopOrder);

    let mergedStops = [];

    if (hasStops) {
        mergedStops = mergeStops(
            multistopsData,
            stopOffs
        )
    }

    const [areGridElementsReOrdered, setAreGridElementsReOrdered] = useState(
        !!LocalStorage.get("track_map_settings")?.order
    );

    const lastPosition = () => {
        mapRef.current.fitBounds(getBoundsFromStops([tracks[0]]));
    }

    const handleFocusTracks = () => {
        mapRef.current.fitBounds(getBoundsFromStops(tracks));
    }

    const toggleGridElementsOrder = () => {
        setAreGridElementsReOrdered(!areGridElementsReOrdered);
        LocalStorage.set("track_map_settings", {order: !areGridElementsReOrdered});
    }

    const handleFocusStartEndLoad = () => {
        mapRef.current.fitBounds(
            getBoundsFromStops(
                stops.reduce((memo, it) => {
                    memo.push(it.LocationData.value)
                    return memo;
                }, [])
            )
        );
    }

    const handleStopClick = (stop) => {
        mapRef.current.fitBounds(getBoundsFromStops([stop.metadata]));
    }

    const handleTrackClick = (track) => {
        mapRef.current.fitBounds(getBoundsFromStops([track]));

        setCurrentTab('load');
    }

    const getPaginatedData = (array, offset, limit) => {
        if (limit < 0) return array.slice(offset);
        return array.slice(offset, offset + Number(limit));
    }

    function getTrackFields() {
        return {
            Number: new Field('Number', '', [''], false, 'text', {label: 'number_hash'}),
            Location: new Field('Location', '', [''], false, 'custom', {
                label: "locationTimeAddress",
                render: (it) => <div>
                    <div className="font-bold">
                        {isPublic ? toFrontDateTimeFromUTC(it.LocationDate) : toFrontDateTimeFromUTC(it.LocationDate)}
                    </div>
                    <div>
                        {[it.AddressName, it.CityName, it.State, it.PostalCode, it.Country]
                            .filter(it => !!it)
                            .join(", ")}
                    </div>
                </div>
            }),
            IsSynced: new Field('IsSynced', '', [''], false, isPublic ? 'hidden' : 'checkbox', {
                hideTable: isPublic
            }),
            IsShared: new Field('IsShared', '', [''], false, isPublic ? 'hidden' : 'checkbox', {
                hideTable: isPublic
            }),
        }
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return (
        <div className="xl:flex gap-4">
            <div className={
                classNames(
                    areGridElementsReOrdered ? "order-last" : undefined,
                    "xl:max-w-[60-rem] w-full relative"
                )}
            >
                {checkFeatureFlag('IsAccurTrackingOn') && !isPublic && (
                    <div className={`flex gap-4 pt-8`}>
                        <Accur8Tracking
                            trackingData={trackingData}
                            isLoading={isLoading}
                            translate={translate}
                            loadID={loadID}
                            piggyResource={piggyResource}
                            driverCell={driverCell}
                            driverName={driverName}
                        />
                    </div>
                )}

                <button
                    className="btn-icon absolute right-2 top-8 hidden xl:block"
                    onClick={toggleGridElementsOrder}
                >
                    <ArrowsRightLeftIcon className="w-5 h-5"/>
                </button>

                <p className="text-lg leading-6 font-medium text-tm-gray-900 mt-12 mb-6">{translate("text.load_tracks")}</p>

                <div
                    className="bg-inverse rounded-card border-x border-t border-tm-gray-300 md:shadow-card relative"
                >
                    {isPublic && (
                        <InfoBar Icon={ClockIcon}>
                            {translate("text.displayed_timezone_below", [timezone])}
                        </InfoBar>
                    )}

                    <ResourceTable
                        addClass={"rounded-t-card"}
                        maxHeightClass={'max-h-[76rem]'}
                        data={getPaginatedData(tracks, queryFields?.offset?.value, queryFields?.limit?.value)}
                        options={tableOptions}
                        fields={getTrackFields()}
                        onRowClick={handleTrackClick}
                        tableKey={'Number'}
                        translate={translate}
                        actions={actions}
                    />

                    {tracks.length ?
                        <TableCardFooter
                            show={true}
                        >
                            <Pagination
                                count={tracksCount}
                                softHideRowsPerPage={true}
                                isLoading={false}
                                handleQueryChange={(name, value) => onQueryChange(name, value)}
                                pageOffset={queryFields?.offset?.value ?? 0}
                                queryFields={queryFields}
                                translate={translate}
                            />
                        </TableCardFooter>
                        :
                        <NoRecords
                            show
                            addClass={"py-8"}
                            title={translate("text.no_matching_records")}
                        />
                    }
                </div>
            </div>

            <div className="w-full">
                <LoadInfoReadLocations
                    loadedMilesText={translate("text.miles")}
                    TotalMiles={data.TotalMilesNoStopBy}
                    onStopClick={handleStopClick}
                    disableTotalMilesClick={true}
                    stopsCombined={mergedStops}
                    stops={stops}
                    stopBys={stopOffs}
                    hideAdditionalInfo={true}
                    translate={translate}
                />

                <div className="mt-8 mb-5">
                    <header className={
                        classNames(
                            "flex",
                            mapHeaderButtons ? "justify-between" : "justify-end"
                        )}
                    >
                        <Buttons
                            buttons={mapHeaderButtons}
                        />


                        <span className="isolate mx-auto sm:mx-0 inline-flex rounded-xl shadow-sm">
                            <button
                                type="button"
                                disabled={!hasTracks}
                                className={
                                    classNames(
                                        "relative flex-col sm:flex-row inline-flex items-center rounded-l-xl border border-tm-gray-300 bg-inverse px-4 py-2 text-sm text-tm-gray-700 font-medium hover:bg-tm-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-link",
                                    )
                                }
                                onClick={lastPosition}
                            >
                                <MapPinIcon className="w-5 h-5 text-tm-gray-600 mr-1"/>
                                {translate("btn.last_position")}
                            </button>

                            <button
                                type="button"
                                disabled={!hasTracks}
                                title={!hasTracks && translate("text.no_tracks")}
                                className={
                                    classNames(
                                        "group relative -ml-px flex-col sm:flex-row inline-flex items-center border bg-inverse px-4 py-2 text-sm font-medium hover:bg-tm-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-link disabled:bg-tm-gray-100 disabled:text-tm-gray-500",
                                        currentTab === 'load' ? "text-primary z-10  border-primary" : "text-tm-gray-700 border-tm-gray-300"
                                    )
                                }
                                onClick={handleFocusTracks}
                            >
                                <MapIcon
                                    className={
                                        classNames(
                                            "w-5 h-5 group-disabled:text-tm-gray-400 mr-1",
                                            currentTab === 'load' ? "text-primary" : "text-tm-gray-600"
                                        )
                                    }
                                />
                                {translate("btn.show_load")}
                            </button>

                            <button
                                disabled={!hasStops}
                                type="button"
                                className={
                                    classNames(
                                        "relative flex-col sm:flex-row -ml-px inline-flex items-center rounded-r-xl border border-tm-gray-300 bg-inverse px-4 py-2 text-sm font-medium hover:bg-tm-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-link",
                                        currentTab === 'locations' ? "text-primary z-10  border-primary" : "text-tm-gray-700 border-tm-gray-300"
                                    )
                                }
                                onClick={handleFocusStartEndLoad}
                            >
                                <FlagIcon className={
                                    classNames(
                                        "w-5 h-5 mr-1",
                                        currentTab === 'locations' ? "text-primary" : "text-tm-gray-600"
                                    )
                                }/>

                                {translate("btn.show_start_end")}
                            </button>
                        </span>
                    </header>
                </div>

                <div
                    className="relative h-[calc(100vh-30rem)] rounded-xl overflow-hidden border border-tm-gray-300 mb-5">
                    <TrimbleMap
                        className={"absolute inset-0"}
                        stops={sortedStops}
                        mapRef={mapRef}
                        tracks={tracks}
                    />
                </div>
            </div>
        </div>

    )
}