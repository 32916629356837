import FieldContainer from "../components/fields/field-container";
import {classNames, getLookup, getProp, openInNewTab} from "./util-helpers";
import FieldDropdownSelect from "../components/fields/field-dropdown-select";
import axios from "axios";
import Env from "../../util/env";
import {processResponse} from "../../data/services/api-util";
import FieldTextarea from "../components/fields/field-textarea";
import {cloneDeep} from "./util-vanilla";
import FieldCheckbox from "../components/fields/field-checkbox";
import ColorPicker from "../components/color-picker";
import FieldSelectSearch from "../components/fields/field-select-search";
import MultiSelect from "../components/fields/field-multi-select";
import CreatableSelect from "../components/fields/field-creatable";
import RichTextEditor from "../components/fields/rich-text-editor";
import FieldDate from "../components/fields/field-date";
import FieldTimeCustom from "../components/fields/field-time-custom";
import {ArrowTopRightOnSquareIcon, CheckCircleIcon} from "@heroicons/react/24/outline";
import FieldGooglePlaces from "../components/fields/field-google-places";
import FieldSwitch from "../components/fields/field-switch";
import FieldText from "../components/fields/field-text";
import {integerWithCommas, numberWithCommas} from "../../util/util-formaters";
import slateHTMLToValue from "../components/fields/rich-text-editor/slate-deserialize";
import {isMilitaryTimeSet, toLocalTimeFromUTC, getTimeFromServerDate} from "./util-dates";
import FieldCreatableAsync from "../components/fields/field-creatable-async";
import Tippy from "@tippyjs/react";
import Square2StackIcon from "@heroicons/react/24/outline/Square2StackIcon";
import FieldSearch from "../components/fields/field-text/search";
import FieldTextMask from "../components/fields/field-text-mask";
import FieldMoney from "../components/fields/field-money";
import InfoTooltipNew from "../components/info-tooltip-new";
import FieldRadio from "../components/fields/field-radio";
import {getJWT} from "./util-auth";
import React from "react";

export function fieldsToHtml(fieldsCpy, translate, handleInputChange, selects = {}) {
    return fieldsCpy
        .filter(
            it => !it?.metadata?.hideDialog && (!selects[it.name]?.visibleFilter || !selects[it.name].visibleFilter(fieldsCpy))
        )
        .reduce((memo, item) => {
            const req = (item.validate.includes("empty") || item.validate.find(it => it.includes("length_")) || item.validate.includes("empty_select_search") || item.validate.includes("integer") || item.validate.includes("float"));
            let field;
            const metadata = item.metadata;

            if (!item.props) {
                item.props = {}
            }

            // @TODO: standardize to form inputs with stacked, floating and left label
            let containerClass = item?.metadata?.containerClass ?? "col-md-12";

            let itemLabelText;

            if (item?.metadata?.labelRaw) {
                itemLabelText = item.metadata.labelRaw + (req ? " *" : "");
            } else {
                itemLabelText = (item?.metadata?.label) ? (translate("field." + item?.metadata?.label) + " " + (req ? "*" : "")) : (translate("field." + item.name) + " " + (req ? "*" : ""))
            }

            if (item.type === 'custom') {
                field = selects[item.name];
            } else if (item.type === 'select-search' || item.type === "multi-select-search") {
                const api = selects?.[item.name]?.api ?? getProp(item, "props.api", "");
                const query = selects?.[item.name]?.query
                    ? selects?.[item.name]?.query
                    : getProp(item, "props.query", {});
                field = (
                    <FieldContainer
                        item={item}
                        translate={translate}
                        key={item?.props?.key ?? item.name}
                    >
                        <FieldDropdownSelect
                            onChange={handleInputChange}
                            {...item}
                            addClass="form-control"
                            defaultOptions={true}
                            translate={translate}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(
                                            api,
                                            Object.assign(query, {query: inputValue})),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + getJWT().access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);

                                            if (result && result.status === 0) {
                                                let list;
                                                if (selects?.[item.name]?.customizeList) {
                                                    list = selects[item.name].customizeList(result.data.list);
                                                } else {
                                                    list = result.data.list.map((it) => {
                                                        if (typeof item?.props?.searchMap === "function") {
                                                            return item.props.searchMap(it);
                                                        }

                                                        return selects[item.name].searchMap(it);
                                                    });
                                                }


                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log("Error", error)
                                        });
                                }
                            }
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === 'creatable-select-search') {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldCreatableAsync
                            onChange={handleInputChange}
                            className="form-control"
                            onCreateOption={!item.props.onCreateOption ? undefined : ((name, value) => item.props.onCreateOption(name, value))}
                            isMulti={item.props?.isMulti}
                            defaultOptions={true}
                            formatCreateLabel={item.props.formatCreateLabel}
                            loadOptions={
                                (inputValue, callback) => {
                                    axios.get(
                                        Env.getApiUrl(selects[item.name].api, Object.assign(selects[item.name].query ? selects[item.name].query : {}, {query: inputValue})),
                                        {
                                            headers: {
                                                'Authorization': 'Bearer ' + getJWT().access_token
                                            }
                                        }
                                    )
                                        .then((response) => {
                                            const result = processResponse(response);
                                            if (result && result.status === 0) {
                                                const list = result.data.list.map((it) => {
                                                    return selects[item.name].searchMap(it);
                                                });
                                                callback(list);
                                            }
                                        })
                                        .catch((error) => {
                                            console.log("Error", error)
                                        });
                                }
                            }
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === 'hidden' || item.type === 'meta') {
                field = null;
            } else if (item.type === "textarea") {
                let heightClass = metadata?.heightClass ?? "min-h-[6rem]";
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldTextarea
                            addClass={classNames(heightClass, "form-control whitespace-pre-wrap")}
                            onChange={handleInputChange}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === "checkbox") {
                let itemClone = cloneDeep(item);

                if (itemClone?.metadata?.hideLabel !== false) {
                    itemClone.metadata = itemClone?.metadata ? itemClone.metadata : {};
                    itemClone.metadata.hideLabel = true;
                    itemClone.metadata.note = undefined;
                }

                field = (
                    <FieldContainer
                        key={item.name}
                        item={itemClone}
                        translate={translate}
                    >
                        <span
                            className="h-9 flex items-center justify-start px-2 -ml-2 rounded-lg"
                        >
                            <FieldCheckbox
                                id={item?.props?.id ?? item.name}
                                className={classNames("checkbox", item.value ? "border-primary" : "border-tm-gray-400")}
                                onChange={handleInputChange}
                                {...item}
                                {...item.props}
                            />

                            {item?.metadata?.hideLabel !== false && (
                                <label
                                    htmlFor={item?.props?.id ?? item.name}
                                    className={
                                        classNames(
                                            "pl-2 flex flex-col text-sm font-semibold select-none",
                                            item.disabled ? "text-tm-gray-400" : "hover:cursor-pointer text-tm-gray-900"
                                        )
                                    }
                                >
                                    {itemLabelText}

                                    {!!item?.metadata?.note && (
                                        <p className="m-0 font-normal text-xs text-tm-gray-700 leading-none">{item.metadata.note}</p>
                                    )}
                                </label>
                            )}
                        </span>
                    </FieldContainer>
                );
            } else if (item.type === "button-group") {
                const buttonData = selects[item.name] ?? item?.metadata?.data;
                const buttonKeys = Object.keys(buttonData);
                const addClass = item?.props?.addClass;
                const addButtonClass = item?.metadata?.addButtonClass;
                const hasTooltip = !!item?.metadata?.tooltip;
                const buttonsGroup = (
                    <div
                        className="flex items-center"
                        key={item.name}
                    >
                        <div
                            className={
                                classNames(
                                    addClass,
                                    !item.disabled ? "bg-tm-gray-100 hover:bg-tm-gray-200" : "bg-tm-gray-300",
                                    "group p-0.5 flex min-h-[2.25rem] rounded-lg border border-tm-gray-200",
                                    item?.metadata?.addClass
                                )
                            }
                        >
                            {
                                buttonKeys.map((buttonValue, i) => {
                                    buttonValue = Number(buttonValue);
                                    const isActive = item.value == buttonValue;

                                    return (
                                        <button
                                            type="button"
                                            key={buttonData[buttonValue] + "-" + i}
                                            disabled={item.disabled}
                                            className={classNames(
                                                addButtonClass,
                                                isActive
                                                    ? "shadow-sm ring-1 ring-black ring-opacity-5 focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100 focus-visible:ring-opacity-50"
                                                    : "text-tm-gray-700 focus-visible:ring-2 focus-visible:ring-link focus-visible:ring-offset-2 focus:outline-none focus-visible:ring-offset-gray-100",
                                                isActive && !item.disabled ? "bg-inverse text-primary" : "",
                                                isActive && item.disabled ? "bg-tm-gray-100 text-tm-gray-700" : "",
                                                item?.metadata?.buttonText ? item.metadata.buttonText : "text-sm font-medium ",
                                                item.disabled ? "cursor-default" : "",
                                                "rounded-input p-1.5 lg:px-3 flex justify-center items-center grow"
                                            )}
                                            onClick={() => item.disabled ? null : handleInputChange(item.name, buttonValue)}
                                        >
                                            {buttonData[buttonValue]}
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                )

                field = (
                    <>
                        {metadata?.labelType !== "left" && (
                            <div className={getFieldContainerClass(item?.metadata?.addContainerClass)}>
                                <span className={getFieldLabelClass(metadata)}>
                                    {!item?.metadata?.hideLabel && (
                                        itemLabelText
                                    )}

                                    {item?.metadata?.info && (
                                        <Tippy content={item?.metadata?.info}>
                                            <i className="simple-icon-info ml-2 align-top"/>
                                        </Tippy>
                                    )}
                                </span>

                                {buttonsGroup}
                            </div>
                        )}

                        {metadata?.labelType === "left" && (
                            <div
                                className="relative flex items-center py-4 col-span-full">
                                <div className="min-w-0 flex-1 text-sm">
                                    <span className={
                                        classNames(
                                            hasTooltip ? "flex gap-1" : "",
                                            "font-medium text-tm-gray-900 select-none"
                                        )}
                                    >
                                        {translate("field." + item.name)}

                                        {hasTooltip && (
                                            <InfoTooltipNew>
                                                {item.metadata.tooltip}
                                            </InfoTooltipNew>
                                        )}

                                        {item?.metadata?.afterLabelHtml}
                                    </span>

                                    {!!item?.metadata?.note && (
                                        <p className="text-tm-gray-700 pr-4">{item?.metadata?.note}</p>
                                    )}
                                </div>

                                {buttonsGroup}
                            </div>
                        )}
                    </>
                );
            } else if (item.type === "radio") {
                if (metadata.options) {
                    let options = metadata.options.map(it => (
                        <label
                            key={it.name}
                            className="flex items-center p-2 min-h-[2.25rem] hover:bg-tm-gray-100 rounded-md cursor-pointer"
                            htmlFor={it.name}
                        >
                            <FieldRadio
                                id={it.name}
                                className="radio mr-2"
                                onChange={() => handleInputChange(item.name, it.value)}
                                name={it.name}
                                value={item.value === it.value}
                            />

                            {it.name}
                        </label>
                    ));

                    field = (
                        <div
                            key={item.name}
                            className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}
                        >
                            <label
                                className={classNames(getFieldLabelClass(metadata), "mb-1")}
                            >{itemLabelText}</label>

                            <div className="flex flex-col space-y-1">
                                {options}
                            </div>
                        </div>
                    )
                }
            } else if (item.type === "toggle") {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <div className="h-10 flex items-center">
                            <button disabled="" className="btn-group bg-transparent border-0 btn p-0"
                                    onClick={() => item.disabled ? null : handleInputChange(item.name, !item.value)}>
                                <span
                                    className={"btn btn-xs " + (item.disabled ? "disabled " : "") + (!item.value ? "btn-primary" : "btn-outline")}>{item?.metadata?.buttonOff ?? "No"}</span>
                                <span
                                    className={"btn btn-xs " + (item.disabled ? "disabled " : "") + (item.value ? "btn-primary" : "btn-outline")}>{item?.metadata?.buttonOn ?? "Yes"}</span>
                            </button>
                        </div>
                    </FieldContainer>
                );
            } else if (item.type === "color-picker") {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <ColorPicker
                            className={"form-control"}
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === "select") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""));
                }

                field = (
                    <FieldContainer
                        item={item}
                        translate={translate}
                        key={item.name}
                    >
                        <FieldSelectSearch
                            addClass="form-control"
                            values={values}
                            onChange={handleInputChange}
                            translate={translate}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === "multi-select") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""));
                }

                field = (
                    <div
                        key={item.name}
                        className={getFieldContainerClass(item?.metadata?.addContainerClass, metadata?.labelType)}
                    >
                        <span className={getFieldLabelClass(metadata)}>{itemLabelText}</span>

                        <MultiSelect
                            values={values}
                            addClass="select-css-search form-control p-0"
                            onChange={handleInputChange}
                            {...item}
                            {...item.props}
                        />
                    </div>
                );
            } else if (item.type === "creatable") {
                let values;
                if ((typeof selects[item.name] === "function")) {
                    values = selects[item.name](item, fieldsCpy, handleInputChange);
                } else if (selects[item.name]) {
                    values = selects[item.name];
                } else {
                    values = getLookup(item.name.replace("ID", ""));
                }

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <CreatableSelect
                            values={values}
                            addClass="form-control p-0"
                            onChange={handleInputChange}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === 'creatable-csv') {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <CreatableSelect
                            addClass="form-control p-0"
                            onChange={handleInputChange}
                            isMulti={true}
                            components={{DropdownIndicator: null}}
                            menuIsOpen={false}
                            {...item}
                            {...item.metadata}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === 'rich-text') {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <RichTextEditor
                            //key={item?.metadata?.innerKey}
                            name={item.name}
                            keywords={item?.metadata?.keywords}
                            onChange={handleInputChange}
                            value={item.value}
                            className={item?.props?.className}
                            {...item.props}
                        />
                    </FieldContainer>
                )
            } else if (item.type === "date" || item.type === "datetime" || item.type === "datetimezone") {
                const minDate = !!item?.metadata?.minDate && item?.metadata?.minDate(fieldsCpy);
                const maxDate = !!item?.metadata?.maxDate && item?.metadata?.maxDate(fieldsCpy);

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldDate
                            addClass={"form-control"}
                            onChange={handleInputChange}
                            showTimeSelect={false}
                            minDate={minDate}
                            maxDate={maxDate}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === "time-custom" || item.type === "timezone-custom") {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldTimeCustom
                            onChange={handleInputChange}
                            isMilitaryTimeFormat={isMilitaryTimeSet()}
                            addClass={classNames("form-control text-center", item?.props?.addClass)}
                            startWithPM={item?.props?.startWithPM ?? false}
                            {...item}
                        />
                    </FieldContainer>
                );
            } else if (item.type === "emails") {
                field = (
                    <div
                        key={item.name}
                        className={containerClass}
                    >
                        <div className="has-float-label">
                        <span className="input-group mb-4">
                            <FieldText
                                className={"form-control p-0"}
                                addClass={"form-control"}
                                onChange={handleInputChange}
                                translate={translate}
                                {...item}
                                {...item.props}
                            />

                            <div className="input-group-append">
                                <button className="btn btn-outline relative z-index-3" type="button">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                            d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                                    </svg>
                                </button>
                            </div>
                        </span>

                            <span className="">{itemLabelText}</span>
                        </div>
                    </div>
                );
            } else if (item.type === "link") {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <div>
                            {!!item.value && (
                                <div className="flex shadow-sm">
                                    {item?.metadata?.htmlBeforeInput}
                                    <input disabled={true}
                                           className="form-control overflow-ellipsis rounded-r-none shadow-none"
                                           value={item.value}/>

                                    <button
                                        className={classNames(item?.metadata.copied ? "text-green-600" : "", "border border-tm-gray-300 border-l-0 px-2 flex items-center hover:bg-tm-gray-50 whitespace-nowrap")}
                                        onClick={() => handleInputChange(item.name, item.value)}
                                    >
                                        {item?.metadata?.copied ? <CheckCircleIcon className="h-4 w-4 mr-1"/> :
                                            <Square2StackIcon className="h-4 w-4 mr-1"/>}
                                        Copy
                                    </button>

                                    <button
                                        onClick={() => openInNewTab(item.value)}
                                        className="border border-tm-gray-300 border-l-0 px-2 flex items-center hover:bg-tm-gray-50 hover:text-tm-gray-900 whitespace-nowrap rounded-r-btn"
                                    >
                                        <ArrowTopRightOnSquareIcon className="h-4 w-4 mr-1"/>
                                        Visit
                                    </button>

                                </div>

                            )}
                        </div>
                    </FieldContainer>
                );
            } else if (item.type === "search") {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldSearch
                            id={item.name}

                            className={"form-control p-0"}
                            addClass={"form-control peer placeholder-transparent"}
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                            type="text"
                        />
                    </FieldContainer>
                );
            } else if (item.type === "mask") {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldTextMask
                            id={item.name}
                            mask={item.props.mask}
                            showMask
                            className="form-control"
                            onChange={handleInputChange}

                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            } else if (item.type === "google-locations") {
                const countryKey = item?.metadata?.countryKey ?? 'CountryID';

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldGooglePlaces
                            key={fieldsCpy.find(it => it.name === countryKey)?.value}
                            countryKey={countryKey}
                            setLocations={(fields) => item?.metadata?.setLocations
                                ? item.metadata.setLocations(fields)
                                : handleInputChange(item.name, fields)}
                            stateFields={fieldsCpy.reduce((memo, it) => {
                                memo[it.name] = it;
                                return memo;
                            }, {})}
                            placeholderText={translate('text.enter_a_city')}
                        />
                    </FieldContainer>
                )
            } else if (item.type === "switch") {
                field = (
                    <div key={item.name} className={item?.metadata?.addContainerClass}>
                        {!!item?.metadata?.htmlBefore && item.metadata.htmlBefore(item)}

                        <FieldSwitch
                            {...item}
                            {...item.props}
                            label={item?.metadata?.label ?? item?.name}
                            key={item.name}
                            onChange={handleInputChange}
                        />

                        {!!item?.metadata?.htmlAfter && item.metadata.htmlAfter(item)}
                    </div>
                )
            } else if (item.type === "integer") {
                item.value = item.value.toString().replace(/\D/g, '');

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldText
                            id={item.name}
                            addClass={classNames("form-control peer", item?.metadata?.addClass)}
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                )
            } else if (item.type === "money") {
                if (!item.metadata) {
                    item.metadata = {}
                }

                item.metadata.htmlBeforeField = () => (
                    <div className="pointer-events-none absolute left-0 flex items-center h-9 pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                );

                item.metadata.htmlAfterField = () => (
                    <div className="pointer-events-none absolute right-0 flex items-center h-9 pr-3">
                        <span className="text-gray-500 sm:text-sm" id="price-currency">USD</span>
                    </div>
                )

                item.props.className = "form-control pl-7 pr-12"

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldMoney
                            item={item}
                            isNegativeAllowed={item?.props?.isNegativeAllowed}
                            placeholder={item.placeholder ?? "$0.00"}
                            onChange={handleInputChange}
                            translate={translate}
                        />
                    </FieldContainer>
                )
            } else if (item.type === "percentage") {
                if (!item.metadata) {
                    item.metadata = {}
                }

                if (!item.props) {
                    item.props = {}
                }

                item.metadata.htmlAfterField = () => (
                    <div className="pointer-events-none absolute right-0 flex items-center h-9 pr-3">
                        <span className="text-gray-500 sm:text-sm">%</span>
                    </div>
                )
                item.metadata.htmlBeforeField = null


                item.props.className = "form-control pl-7 pr-12"

                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldText
                            id={item.name}
                            addClass={"form-control peer"}
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                )
            } else {
                field = (
                    <FieldContainer
                        key={item.name}
                        item={item}
                        translate={translate}
                    >
                        <FieldText
                            id={item.name}
                            addClass={"form-control peer"}
                            onChange={handleInputChange}
                            translate={translate}
                            placeholder={""}
                            {...item}
                            {...item.props}
                        />
                    </FieldContainer>
                );
            }

            memo.push(field);

            return memo;
        }, []);
}

export function includeFields(fields = {}, includedFields = []) {
    const fieldsUpdate = cloneDeep(fields)

    for (const [key] of Object.entries(fieldsUpdate)) {
        if (!includedFields.includes(key)) fieldsUpdate[key].type = "hidden";
    }

    return fieldsUpdate;
}

export function excludeFields(fields = {}, excludedFields = []) {

    const fieldsUpdate = cloneDeep(fields)

    for (const [key] of Object.entries(fieldsUpdate)) {
        if (!!excludedFields.includes(key)) fieldsUpdate[key].type = "hidden";
    }

    return fieldsUpdate;
}

export function fillFieldsFromData(fieldTemplates, data) {
    const formats = ['float', 'float_or_empty', 'integer', 'integer_or_empty'];

    if (!data) {
        return fieldTemplates;
    }

    return Object.values(fieldTemplates).reduce((memo, item) => {
        if (item.validate.filter(value => formats.includes(value)).length > 0) {
            const val = data[item.name];
            if (!!val && (val.length > 0) && val.includes(",")) {
                item.value = val;
            } else {
                if (item.validate[0] == 'integer' || item.validate[0] == 'integer_or_empty') {
                    item.value = integerWithCommas(val ? val : fieldTemplates[item.name].value);
                } else {
                    item.value = numberWithCommas(val ? val : fieldTemplates[item.name].value);
                }
            }
        } else if (item.type === 'select-search' || item.type === 'creatable-select-search') {
            if (!data[item.name]) {
                item.value = null // empty select should be null
            } else {
                item.value = {
                    value: data[item.name],
                    label: !!data[item.name.replace("ID", "")] ? data[item.name.replace("ID", "")] : ""
                }
            }
        } else if (item.type === 'checkbox') {
            item.value = !!data[item.name]
        } else if (item.type === 'rich-text') {
            item.value = data[item.name] ? slateHTMLToValue(data[item.name]) : null;
        } else if (item.type === 'datetimezone') {
            item.value = toLocalTimeFromUTC(data[item.name]);
        } else if (item.type === 'time-custom') {
            item.value = getTimeFromServerDate(data[item.name.replace('Time', '')]);
        } else if (item.type === 'timezone-custom') {
            item.value = getTimeFromServerDate(toLocalTimeFromUTC(data[item.name.replace('Time', '')]));
        } else if (item.type === 'creatable-csv') {
            item.value = data[item.name] ? data[item.name].split(",").map(it => {
                return {label: it, value: it};
            }) : [""];
            if (item.value.length === 1 && item.value[0] === "") {
                item.value = null;
            }
        } else {
            item.value = data[item.name] ?? fieldTemplates[item.name].value;
        }

        memo[item.name] = item;
        return memo;
    }, {});
}

export function getFieldContainerClass(addClass = "", labelType = "") {
    let fieldContainerClass = "";

    switch (labelType) {
        case 'left':
            fieldContainerClass = "relative text-tm-gray-900 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5";
            break;
        default:
            fieldContainerClass = "relative text-tm-gray-900"
    }

    fieldContainerClass = classNames(fieldContainerClass, addClass);

    return fieldContainerClass;
}

export function getFieldLabelClass(metadata) {
    let formLabelClass;
    if (metadata?.formLabelClass) {
        formLabelClass = metadata.formLabelClass;
    } else {
        switch (metadata?.labelType) {
            case 'left':
                formLabelClass = "block text-sm font-semibold text-tm-gray-700 sm:mt-px sm:pt-2";
                break;
            case 'float':
                formLabelClass = "label leading-4 text-tm-gray-900 font-semibold absolute -top-2 left-2.5 label-bg px-1 text-xs z-10"
                break;
            case 'stack':
            default:
                formLabelClass = "label-stack";
        }
    }

    return formLabelClass;
}