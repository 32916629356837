import React from "react";
import moment from "moment";
import {getUser} from "../../../util/util-auth";

export default function WeatherCompactCard({weatherData, locationData}) {
    const {current, hourly} = weatherData;

    const isCelsius = getUser("Contact.MeasureTemperature") === "Celsius";
    const isDistanceMetric = getUser("Contact.DistanceUnit") === "km";

    // Humidity
    const humidity = current?.humidity;

    // Visibility
    const isVisibilityMax = current?.visibility === 10000;
    const visibilityMetric = current?.visibility > 1000 ? (current?.visibility / 1000) + "km" : current?.visibility + "m";
    let visibilityImperial = (current?.visibility * 0.000621) + "mi";
    const visibility = isVisibilityMax
        ? "Max"
        : isDistanceMetric ? visibilityMetric : visibilityImperial;

    // Wind
    const windSpeed = isDistanceMetric
        ? (current?.wind_speed)?.toFixed(2) + "km/h"
        : (current?.wind_speed * 0.621371)?.toFixed(2) + "mph";


    // Icon
    const imgPath = current?.weather?.[0]?.icon;

    const imagePath = `/images/weather-icons/${imgPath}.svg`

    // TEMPERATURE
    let tempKelvinHigh = null;
    let tempKelvinLow = null;

    hourly?.forEach(it => {
        if (tempKelvinHigh === null || it.temp > tempKelvinHigh) {
            tempKelvinHigh = it.temp;
        }

        if (tempKelvinLow === null || it.temp < tempKelvinLow) {
            tempKelvinLow = it.temp;
        }
    });

    const tempCelsius = (current?.temp - 273.15).toFixed(0);
    const tempHighCelsius = (tempKelvinHigh - 273.15).toFixed(0);
    const tempLowCelsius = (tempKelvinLow - 273.15).toFixed(0);
    const tempFahrenheit = ((tempCelsius * 9 / 5) + 32).toFixed(0);
    const tempHighFahrenheit = ((tempHighCelsius * 9 / 5) + 32).toFixed(0);
    const tempLowFahrenheit = ((tempLowCelsius * 9 / 5) + 32).toFixed(0);


    return <div className="flex flex-col w-full">

                <div className="font-bold text-xl text-tm-gray-700 capitalize">{locationData?.CityName ?? "Weather"}</div>

                <div className="text-sm text-tm-gray-500">
                    {locationData?.CityName ? moment().format('dddd, MMMM D, YYYY') : "Please select a location"}
                </div>
        {!!locationData?.CityName && (
            <>
                <div
                    className="mt-6 text-6xl self-center inline-flex items-center justify-center rounded-lg text-indigo-400 h-24 w-48">
                    {/*<svg className="w-32 h-32" fill="none" stroke="currentColor" viewBox="0 0 24 24"*/}
                    {/*     xmlns="http://www.w3.org/2000/svg">*/}
                    {/*    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"*/}
                    {/*          d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"></path>*/}
                    {/*</svg>*/}

                    <img
                        className={'w-56 h-56 object-cover'}
                        src={imagePath}
                        alt="weather"
                    />
                </div>

                <div className="flex flex-row items-center justify-center mt-6">
                    <div className="font-medium text-6xl">{isCelsius ? tempCelsius : tempFahrenheit}°</div>
                    <div className="flex flex-col items-center ml-6">
                        {current?.weather?.[0]?.main && (
                            <div>{current.weather[0].main}</div>
                        )}
                        <div className="mt-1">
                            <span className="text-sm"><i className="far fa-long-arrow-up"></i></span>
                            <span className="text-sm font-light text-gray-500">{isCelsius ? tempHighCelsius : tempHighFahrenheit}{isCelsius ? "°C" : "°F"}</span>
                        </div>
                        <div>
                            <span className="text-sm"><i className="far fa-long-arrow-down"></i></span>
                            <span className="text-sm font-light text-gray-500">{isCelsius ? tempLowCelsius : tempLowFahrenheit}{isCelsius ? "°C" : "°F"}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between mt-6">
                    <div className="flex flex-col items-center">
                        <div className="font-medium text-sm">Wind</div>
                        <div className="text-sm text-gray-500">{windSpeed}</div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="font-medium text-sm">Humidity</div>
                        <div className="text-sm text-gray-500">{humidity}%</div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="font-medium text-sm">Visibility</div>
                        <div className="text-sm text-gray-500">{visibility}</div>
                    </div>
                </div>
            </>
        )}
    </div>
}