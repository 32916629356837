import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import {createResource, deleteResource, getResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DEFAULT_QUERY_LIMIT,
    DELETE_PERM
} from '../../../util/util-constants'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    mergeDeep,
    saveTableColumns,
    verifyFilterFields
} from '../../../common/util/util-helpers'
import Resources from '../../../data/services/resources'
import {CheckCircleIcon, TrashIcon} from '@heroicons/react/24/outline'
import Tippy from '@tippyjs/react'
import {currentDate, toFrontDateTime} from '../../../common/util/util-dates'
import {cloneDeep, genericMoneyFormatter} from '../../../common/util/util-vanilla'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {download} from '../../../data/actions/download'
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import NoRecords from "../../../common/components/no-records-found/no-records";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import ResourceTable from "../../../common/components/resource-table";
import ModalSaveResource from "../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCardFooter from '../../../common/components/resource-table/table-components/table-card-footer'
import Button from '../../../common/components/button'

export default class TransfersTab extends Component {
    constructor(props) {
        super(props)

        this.pagePath = this.props.location.pathname.substring(1) + '_income_transfers'

        this.tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: this.props.sortBy ? this.props.sortBy : 'Description',

            fields: this.getFields(),
            queryFilterFields: this.getQueryFilterFields(),

            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            isTableFullScreen: false,

            selectedItem: null,
            createModalOpen: false,
            confirmModalOpen: false
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ))
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            query: this.getQuery(),
            resource: this.getResource()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign(
                {format: 'EXCEL', name: 'Transfers_' + currentDate() + '.xlsx'},
                this.getQuery()
            )
        }))
    }

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'DESC'
        }, () => {
            this.fetchData()
            this.saveFilters()
        })
    }

    handleUpdateOffset = (offset, num) => {
        this.setState({
            offset: offset,
            paginationPage: num
        }, () => {
            this.fetchData()
            this.saveFilters()

        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen,
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleFilterInputChange = (name, value) => {
        this.setState({
            queryFilterFields: FieldsManager.updateField(this.state.queryFilterFields, name, value),
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterClear = () => {
        this.setState({
            queryFilterFields: this.getQueryFilterFields(),
        }, () => {
            this.fetchData()
            this.saveFilters()

        })
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            columnsModalOpen: !this.state.columnsModalOpen
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            columnsModalOpen: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate)
    }

    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'limit', 'offset', 'paginationPage', 'input'])
    }

    getResource = () => {
        return Resources.IncomeTransfers
    }

    getQuery = () => {
        const {AccountToID, AccountFromID, Date} = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)

        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                AccountFromID: AccountFromID,
                AccountToID: AccountToID,
                Date: Date
            })
        }
    }

    getFields = () => {
        return {
            Description: new Field('Description', '', [''], false, 'text', {addContainerClass: 'col-span-full'}),
            Amount: new Field('Amount', '', ['empty'], false, 'money', {
                addContainerClass: 'col-span-full',
                render: (item) => {
                    if (item.ReconciliationDate) {
                        return (
                            <Tippy content={'Reconciled: ' + toFrontDateTime(item.ReconciliationDate)}>
                                <div className={'flex justify-end'}>
                                    <CheckCircleIcon className="w-5 h-5 text-green-600 mr-4"/>
                                    <div>{genericMoneyFormatter(item.Amount)}</div>
                                </div>
                            </Tippy>
                        )
                    }
                    return <div className={'text-right'}>{genericMoneyFormatter(item.Amount)}</div>
                }
            }),
            Date: new Field('Date', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
            AccountFromID: new Field('AccountFromID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName,
                })
            }),
            AccountToID: new Field('AccountToID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}, {
                api: 'api/' + Resources.AccountsQuick,
                query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName,
                })
            })
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            AccountFromID: new Field('AccountFromID', '', [''], false, 'select-search', {},
                {
                    isClearable: true,
                    api: 'api/' + Resources.AccountsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.AccountID,
                        label: item.AccountNumber + ' ' + item.AccountName,
                    }),
                }),
            AccountToID: new Field('AccountToID', '', [''], false, 'select-search', {},
                {
                    isClearable: true,
                    api: 'api/' + Resources.AccountsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.AccountID,
                        label: item.AccountNumber + ' ' + item.AccountName,
                    }),
                }),
            Date: new Field('Date', '', [''], false, 'date'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                containerClass: 'col-md-6 limit-field-container float-right', hideLabel: true
            }, {menuPlacement: 'top'})
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {
            translate,
        } = this.props

        const data = getProp(this.props, 'resource.data.list', [])
        const count = getProp(this.props, 'resource.data.count', 0)

        const isLoading = getProp(this.props, 'resource.isLoading', false)

        return (
            <React.Fragment>
                <div className="flex mb-2">
                    <ActiveFilters
                        filterFields={this.state.queryFilterFields}
                        onLabelClick={this.handleFilterInputChange}
                        onClearFiltersClick={this.handleClearFiltersClick}
                        translate={translate}
                    />


                    <div className="flex ml-auto">
                        <div className={'flex mr-4'}>

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>

                        <Button
                            hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                            className={`btn btn-primary`}
                            onClick={() => {
                                this.handleToggleCreateModal()
                            }}
                        >
                            {translate('btn.create_new')}
                        </Button>
                    </div>
                </div>

                <TableCard
                    addClass={
                        classNames(
                            this.state.isTableFullScreen
                                ? 'fixed inset-1 -top-1 z-50'
                                : undefined
                        )}
                >

                    <TableFilters
                        hideLimit
                        filterFields={this.state.queryFilterFields}
                        handleInputChange={this.handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={this.fetchData}
                        onFullScreenToggle={this.handleFullScreenToggle}
                        isLoading={isLoading}
                        isTableFullScreen={this.state.isTableFullScreen}
                    />

                    <ResourceTable
                        tableKey={'AccountTransferID'}
                        data={data}
                        commonTable={true}
                        fields={this.getFields()}

                        translate={translate}
                        isLoading={isLoading}

                        options={this.state.tableOptions}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        saveTableOptions={this.setOptions}

                        actions={[
                            {
                                action: this.handleToggleConfirmModal,
                                icon: TrashIcon,
                                visible: (it) => !it.ReconciliationDate,
                                hasPerm: checkPerm(this.getResource(), DELETE_PERM),
                                title: translate('text.delete_item'),
                            }
                        ]}
                    />

                    {/*Table footer*/}
                    <NoRecords
                        show={(data.length === 0) && !isLoading}
                        title={translate('text.no_records')}
                        addClass={'pt-16 pb-10'}
                    />
                    <TableCardFooter
                        show={!(!data.length && !isLoading)}
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === 'offset'
                                    ? this.handleUpdateOffset(value, currentPage)
                                    : this.handleFilterInputChange(name, value)
                            }
                            pageOffset={this.state.offset}
                            queryFields={this.state.queryFilterFields}
                            translate={translate}
                        />
                    </TableCardFooter>
                </TableCard>

                <ModalSaveResource
                    show={this.state.createModalOpen}
                    title={translate('Create transfer')}
                    widthClass="max-w-md"
                    gridColsClass="grid-cols-3"
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    translate={this.props.translate}
                    closeButtonLabel={translate('btn.cancel')}
                    onSubmit={(params) => {
                        if (params) {
                            this.props.dispatch(createResource(
                                {
                                    user: LocalStorage.get('user'),
                                    params: params,
                                    resource: this.getResource(),
                                    piggyResource: this.getResource(),
                                    errorMessage: true, successMessage: this.props.translate('message.item_created')
                                }))
                            this.handleToggleCreateModal()
                        }
                    }
                    }
                />

                <ModalConfirm
                    title={'Confirm'}
                    show={!!this.state.confirmModalOpen}
                    text={translate('message.are_you_sure_delete_item')}
                    onClose={this.handleToggleConfirmModal}
                    buttonLabel={translate('btn.confirm')}
                    closeButtonLabel={'Cancel'}
                    translate={translate}
                    onConfirm={() => {
                        let id = this.state.selectedItem.AccountTransferID
                        this.props.dispatch(deleteResource({
                            user: LocalStorage.get('user'),
                            query: {
                                AccountTransferID: id
                            },
                            piggyQuery: this.getQuery(),
                            resource: this.getResource(),
                            piggyResource: this.getResource(),
                            errorMessage: true, successMessage: translate('message.transfer_deleted')
                        }))
                        this.handleToggleConfirmModal()
                    }}
                />

                <TableOptionsDialog
                    show={this.state.columnsModalOpen}
                    pagePath={this.pagePath}
                    columns={this.state.tableOptions.columns}
                    setTableColumnOptions={this.handleSetTableColumnOptions}
                    getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                    onClose={this.handleToggleColumnSettingsDialog}
                    translate={translate}
                />

            </React.Fragment>
        )
    }
}
