import Badge from "../index";
import React from "react";
import Tooltip from "../../tooltip";
import {
    EMPLOYEE_STATUS_ACTIVE, EMPLOYEE_STATUS_INACTIVE, EMPLOYEE_STATUS_PAUSED, EMPLOYEE_STATUS_PENDING,
    EMPLOYEE_STATUS_TYPE_TIPPY_TEXT,
    EMPLOYEE_STATUS_TYPES
} from "../../../../util/util-constants";

export default function EmployeeStatusBadge({item, translate}) {
    function getEmployeeBadgeClass(type) {
        switch (type) {
            case EMPLOYEE_STATUS_ACTIVE:
                return "success"
            case EMPLOYEE_STATUS_PAUSED:
            case EMPLOYEE_STATUS_INACTIVE:
            case EMPLOYEE_STATUS_PENDING:
                return "warning"
            default:
                return "danger"
        }
    }

    return (
        <Tooltip
            content={<span>{translate(EMPLOYEE_STATUS_TYPE_TIPPY_TEXT[item.StatusID])}</span>}
        >
            <span>
                <Badge
                    addClass={"h-6 items-center"}
                    onClick={(e) => {}}
                    type={getEmployeeBadgeClass(item.StatusID)}
                >
                    <div className="flex">
                        {EMPLOYEE_STATUS_TYPES[item.StatusID]}
                    </div>
                </Badge>
            </span>
        </Tooltip>
    )
}