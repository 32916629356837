import React from "react";
import {classNames} from "../../../util/util-helpers";
import {formatMoney} from "../../../util/util-formaters";


export default function FieldMoney({item = {}, isNegativeAllowed, onBlur, onFocus, onChange, translate}) {
    if (!item.props) {
        item.props = {}
    }

    const removeCommas = (e) => {
        e.target.value = e.target.value.replace(/,/g, '');

        e.target.select();

        if (onFocus) {
            onFocus();
        }
        // setTimeout(() => {
        //     target;
        //
        //
        // }, 10);
    }

    const addCommas = (e) => {
        let value = e.target.value;
        if (value?.toString()?.split('.')?.length > 2) {
            value = value?.toString()?.split('.');
            value = value[0] + "." + value[1];
        }

        value = formatMoney(value);
        onChange(item.name, value);
    }

    const handleInputChange = (e) => {
        let hasNegativeValue = false;
        let value = e.target.value;

        if (isNegativeAllowed && value[0] === "-") {
            hasNegativeValue = true;
        }

        let original = value.toString().split(".");

        if (original[1] && original[1].length > 2) {
            value = original[0] + (original[1] ? "." + original[1].charAt(0) + original[1].charAt(1) : "");
        }

        value = value.replace(/[^0-9.]/g, '');
        value = value.replace(/,/g, "");

        if (hasNegativeValue) {
            value = "-" + value;

            if (value === 0) {
                value = '-';
            }
        }

        onChange(item.name, value)
    }

    return (
        <React.Fragment>
            <input
                id={item.props.id ?? item.name}
                onChange={handleInputChange}
                onBlur={addCommas}
                onFocus={removeCommas}
                name={item.name}
                value={item.value}
                placeholder={item?.props?.placeholder}
                disabled={item.disabled}
                autoFocus={item.props.autofocus}
                className={
                    classNames(
                        item?.props?.className ?? "form-control",
                        item.errorMessage ? item?.metadata?.errorClass ?? "is-invalid" : undefined
                    )
                }
            />

            {item.errorMessage && (
                <div className={"text-red-600 font-semibold"}>
                    {!!translate && (
                        item.errorMessage.reduce((memo, it) => {
                            if (it.key) {
                                return translate(it.key, it.values) + (memo ? "," + memo : "")
                            }
                            return translate(it) + (memo ? "," + memo : "")
                        }, "")
                    )}
                    {!translate && (
                        <span>Amount is not valid*</span>
                    )}
                </div>
            )}
        </React.Fragment>
    )
}

