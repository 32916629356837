import {combineReducers} from "redux";
import userReducer from "./user";
import resourceReducer from "./resource";
import secondResourceReducer from "./secondResource";
import thirdResourceReducer from "./thirdResource";
import taskResourceReducer from "./taskResource";
import uiReducer from "./ui";
import downloadReducer from "./download";
import infoReducer from "./infoReducer";
import stackedResourceReducer from "./stackedResource";
import historyReducer from "./historyReducer";
import helpReducer from "./helpResource";
import contactsReducer from "./contactResource";
import sidebarReducer from "./sidebarResource";
import integrationReducer from "./integrationResource";
import publicReducer from "./publicResource";
import dialogReducer from "./dialogResource";
import commentReducer from "./commentResource";
import notificationsReducer from "../../common/package/notification/reducers/notifications";
import loadPresenceReducer from '../../package/realtime/reducers/loadPresenceReducer'
import loadUpdateReducer from '../../package/realtime/reducers/loadUpdateReducer'
import ocrFillReducer from './ocrFillReducer'
import dialogInfoReducer from './dialogInfoResource'
import grabTaskReducer from "./grabTaskReducer";
import tablesDataReducer from "./tablesDataResource";
import resourceDiffReducer from "./resourceDiff";
import documentResourceReducer from "./documentResource";
import previewDocumentReducer from "./previewDocumentReducer";

const appReducer = combineReducers({
    user: userReducer,
    resource: resourceReducer,
    documentResource: documentResourceReducer,
    ui: uiReducer,
    secondResource: secondResourceReducer,
    thirdResource: thirdResourceReducer,
    taskResource: taskResourceReducer,
    download: downloadReducer,
    commentResource: commentReducer,
    info: infoReducer,
    dialogInfoResource: dialogInfoReducer,
    historyResource: historyReducer,
    helpResource: helpReducer,
    contactResource: contactsReducer,
    sidebarResource: sidebarReducer,
    stackedResource: stackedResourceReducer,
    publicResource: publicReducer,
    integrationResource: integrationReducer,
    dialogResource: dialogReducer,
    notifications: notificationsReducer,
    loadPresence: loadPresenceReducer,
    loadUpdate: loadUpdateReducer,
    ocrFillResource: ocrFillReducer,
    secondTask: grabTaskReducer,
    tablesDataResource: tablesDataReducer,
    resourceDiff: resourceDiffReducer,
    previewDocumentResource: previewDocumentReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return {
            ui: {},
            user: {},
            resource: {},
            secondResource: {},
            thirdResource: {},
            taskResource: {},
            secondTask: {},
            commentResource: {},
            download: {},
            documentResource: {},
            stackedResource: {},
            helpResource: {},
            historyResource: {},
            publicResource: {},
            contactResource: {},
            sidebarResource: {},
            integrationResource: {},
            chatResource: {},
            dialogResource: {},
            notifications: {},
            loadPresence: {},
            ocrFillResource: {},
            tablesDataResource: {},
            previewDocumentResource: {},
            resourceDiff: {}
        }
    }
    return appReducer(state, action);
};

export default rootReducer;
