import Resources from "../../../../data/services/resources";
import {DEFAULT_METADATA_SELECT_SEARCH_QUERY, READ_PERM} from "../../../util/util-consts";
import {Field, FieldsManager} from "../../../../data/services/fields";
import moment from "moment/moment";
import ModalDefault from "../modal-default";
import InvoiceExpenseDetailsDialog from "../invoice-expense-details-dialog";
import {getThirdResource} from "../../../../data/actions/thirdResource";
import LocalStorage from "../../../../util/localStorage";
import ResourceTableDialog from "../resource-table-dialog";
import React, {useEffect, useState} from "react";
import {checkPerm, classNames, getLookup, getProp} from "../../../util/util-helpers";
import CopyToClipboardButton from "../../buttons/copy-to-clipboard-button";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../util/util-fields";
import {Loader} from "../../loader";
import {ChevronRightIcon} from "@heroicons/react/20/solid";
import {formatMoney} from "../../../util/util-formaters";

export default function InventoryReportDialog({
                                                  isReportDialogOpen,
                                                  dialogResource,
                                                  resource,
                                                  dispatch,
                                                  thirdResource,
                                                  translate,
                                                  setIsReportDialogOpen,
                                                  selectedReportItem
                                              }) {

    const [isExpanded, setIsExpanded] = useState(true);
    const data = getProp(dialogResource, "data.ProductService", []);

    useEffect(() => {
        !dialogResource.isLoading && setFields(getFields(data))
    }, [dialogResource.isLoading])

    const getFields = (item) => {
        let fieldTemplates = {
            ProductService: new Field('ProductService', "", ['empty'], true, 'text', {
                addContainerClass: "col-span-6",
                label: "name"
            }),
            SKU: new Field('SKU', '', [''], true, 'text', {addContainerClass: "col-span-6", label: 'sku'}),
            ProductServiceCategoryID: new Field('ProductServiceCategoryID', '', [''], true, 'select-search', {
                addContainerClass: "col-span-4",
                // htmlAfterField: (it) => {
                //     return <Button
                //         disabled={it.disabled}
                //         onClick={() => this.setState({
                //             isCategoryListModalOpen: true
                //         })}
                //         className="btn btn-input"
                //     >
                //         {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                //     </Button>
                // }
            }, {placeholder: ' '}),
            ProductServicePartLocationID: new Field('ProductServicePartLocationID', '', [''], true, 'select-search', {
                addContainerClass: "col-span-4",
                // htmlAfterField: (it) => {
                //     return <Button
                //         disabled={it.disabled}
                //         onClick={() => this.setState({
                //             isLocationsListModalOpen: true
                //         })}
                //         className="btn btn-input"
                //     >
                //         {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                //     </Button>
                // }
            }, {placeholder: ' '}),
            UomID: new Field('UomID', '', [''], true, 'select', {addContainerClass: "col-span-4"}, {
                placeholder: ' ',
                values: getLookup('Uom')
            }),
            ManufacturerID: new Field('ManufacturerID', '', [], true, 'select-search',
                {
                    addContainerClass: 'col-span-6',
                    // htmlAfterField: (it) => {
                    //     return <Button
                    //         disabled={it.disabled}
                    //         onClick={() => this.setState({
                    //             isManufacturerListModalOpen: true
                    //         })}
                    //         className="btn btn-input"
                    //     >
                    //         {it?.value?.value ? <ArrowPathIcon/> : <PlusIcon/>}
                    //     </Button>
                    // }
                }, {placeholder: ' '}),
            QRCode: new Field('QRCode', '', [''], true, 'text', {
                addContainerClass: 'col-span-6',
            }),
            OEMNumber: new Field('OEMNumber', '', [''], true, 'text', {
                addContainerClass: 'col-span-6',
            }),
            CrossRefNumber: new Field('CrossRefNumber', '', [''], true, 'creatable-csv', {
                fieldOptions: (it) => {
                    return (
                        it.value
                            ? <div className="gap-x-3 h-5 relative bottom-0.5 flex">
                                <CopyToClipboardButton
                                    clipboardText={(it.value ? it.value : []).reduce((memo, it) => {
                                        memo.push(it.value);
                                        return memo;
                                    }, []).join(", ")}
                                    addClass="relative -top-2"
                                    translate={translate}
                                />
                            </div>
                            : null
                    )
                },
                addContainerClass: 'col-span-6',
                onEnterDown: true
            }, {placeholder: ' ', isDisabled: true}),
            IncomePrice: new Field('IncomePrice', '', [''], true, 'money', {addContainerClass: 'col-span-3'}),
            ExpensePrice: new Field('ExpensePrice', '', [''], true, 'money', {addContainerClass: 'col-span-3'}),
            ReorderPoint: new Field('ReorderPoint', '', [''], true, 'integer', {
                addContainerClass: "col-span-3",
                placeholder: "0",
                label: translate("MinRecommendedStock")
            }),
            MaxRecommendedStock: new Field('MaxRecommendedStock', '', [''], true, 'integer', {
                addContainerClass: "col-span-3",
                placeholder: "0"
            }),
        }

        if (item.ProductServiceTypeID !== 1) {
            fieldTemplates = includeFields(fieldTemplates, ['ProductService', 'SKU'])
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    const [fields, setFields] = useState({});
    const [isInvoiceExpenseDialogOpen, setIsInvoiceExpenseDialogOpen] = useState(false);
    const [selectedInvoiceExpenseItem, setSelectedInvoiceExpenseItem] = useState({});

    const handleInvoiceExpenseClick = (item = null) => {
        setSelectedInvoiceExpenseItem(item);
        setIsInvoiceExpenseDialogOpen(true);
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
    };

    const fieldsHTML = fieldsToHtml(Object.values(Object.assign({}, fields)), translate, handleInputChange, {})

    return (
        <ResourceTableDialog
            widthClass={'max-w-6xl'}
            show={isReportDialogOpen}
            dialogResource={dialogResource}
            resource={resource}
            title={'Inventory report'}
            dispatch={dispatch}
            isFooterVisible={true}
            onView={(item) => {
                handleInvoiceExpenseClick(item)
            }}
            hasViewPerm={checkPerm(Resources.ExpenseExpense, READ_PERM) && checkPerm(Resources.InvoicesInvoice, READ_PERM)}
            defaultAction={(item) => {
                if (item.Type === 1 && !checkPerm(Resources.ExpenseExpense, READ_PERM)) {
                    return false;
                }

                if (item.Type !== 1 && !checkPerm(Resources.InvoicesInvoice, READ_PERM)) {
                    return false;
                }

                handleInvoiceExpenseClick(item)
            }}
            limit={50}
            fields={{
                Date: new Field('Date', '', [], false, 'date', {
                    omitSort: true
                }),
                Description: new Field('Description', '', [''], false, 'textarea', {omitSort: true}),
                Type: new Field('Type', '', [''], false, 'custom', {
                    render: (it) => {
                        return it.Type === 1 ? 'Bought' : 'Sold'
                    },
                    omitSort: true
                }),
                FromTo: new Field('FromTo', '', [''], false, 'custom', {
                    render: (it) => it.FromTo
                }),
                Amount: new Field('Amount', '', [''], false, 'money', {omitSort: true}),
                Qty: new Field('Qty', '', [''], false, 'text', {omitSort: true}),
                TaxID: new Field('TaxID', '', [''], false, 'select-search', {omitSort: true}),
                TotalAmount: new Field('TotalAmount', '', [''], false, 'money', {omitSort: true})
            }}

            fieldsFilter={{
                StartDate: new Field('StartDate', moment().startOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                EndDate: new Field('EndDate', moment().endOf('year').format('YYYY-MM-DD HH:mm:ss'), [''], false, 'date', {addContainerClass: 'col-span-4'}, {}),
                OrganizationID: new Field('OrganizationID', '', [''], false, 'select-search', {addContainerClass: 'col-span-4'}, {
                    isClearable: true
                }),
            }}

            htmlBefore={(
                <div className={"p-2 text-lg"}>
                    <div className="p-5">

                        <button onClick={() => setIsExpanded(!isExpanded)}
                                className={"border rounded border-primary"}
                        >
                            <ChevronRightIcon
                                className={
                                    classNames(
                                        "w-5 h-5",
                                        isExpanded ? "rotate-90" : undefined
                                    )
                                }
                            />
                        </button>

                        {!dialogResource.isLoading && !!isExpanded && (
                            <div className="grid grid-cols-12 gap-4">
                                {fieldsHTML}
                            </div>
                        )}

                        {dialogResource.isLoading && (
                            <div className={"flex justify-center"}>
                                <Loader disableContainer={true}/>
                            </div>
                        )}

                    </div>
                    Current profit for the filtered
                    records: {formatMoney(dialogResource?.data?.TotalTotalAmount ?? 0)}
                </div>
            )}

            tableKey="Name"
            defaultQuery={{id: selectedReportItem?.ProductServiceID ?? null}}
            onClose={() => {
                setIsReportDialogOpen(false);
                setIsExpanded(true);
            }
            }
            translate={translate}
            selects={{
                OrganizationID: {
                    api: 'api/' + Resources.OrganizationsQuick,
                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                    searchMap: (item) => ({
                        value: item.OrganizationID,
                        label: item.LegalName,
                        Organization: item
                    })
                }
            }}
            htmlAfter={() => {
                return <ModalDefault
                    show={isInvoiceExpenseDialogOpen}
                    widthClass={'max-w-5xl w-screen'}
                    title={"Details"}

                    closeButtonLabel={translate('btn.Close')}
                    onClose={() => setIsInvoiceExpenseDialogOpen(false)}
                >
                    <InvoiceExpenseDetailsDialog
                        resource={thirdResource}
                        isLoading={thirdResource?.isLoading}
                        translate={translate}
                        dispatch={dispatch}
                        showLoadReferenceLinks={true}

                        disableComments={true}
                        canDeletePayment={false}
                        disableAddReference={true}
                        disableTransactionImport={true}

                        // Data events
                        onFetchData={() => {
                            if (selectedInvoiceExpenseItem?.ID) {
                                dispatch(getThirdResource({
                                    user: LocalStorage.get('user'),
                                    query: {
                                        [selectedInvoiceExpenseItem.Type === 1 ? "ExpenseID" : "InvoiceID"]: selectedInvoiceExpenseItem.ID
                                    },
                                    resource: selectedInvoiceExpenseItem.Type === 1 ? Resources.ExpenseExpense : Resources.InvoicesInvoice
                                }))
                            }
                        }}
                    />
                </ModalDefault>
            }}
        />
    )
}