import React, {Component} from 'react'
import {getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'

import {
    ACCOUNT_PAYABLE,
    ACCOUNT_RECEIVABLE,
    ACCOUNT_TYPE_CASH,
    ACCOUNT_TYPE_CURRENT_ASSETS,
    ACCOUNT_TYPE_FIXED_ASSETS,
    LINE_OF_BUSINESS_PRIMARY_DRIVER,
    MAJOR_ACCOUNT_TYPE_ASSET,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_INCOME,
    MAJOR_ACCOUNT_TYPE_LIABILITY,
    PAYMENT_METHOD_CACHE,
    UPDATE_PERM
} from '../../../util/util-constants'
import {checkPerm, classNames, getLookup, getProp} from '../../../common/util/util-helpers'
import {PaymentAccountTypeExpense, PaymentAccountTypeIncome} from "../../../common/util/util-accounting";
import {fieldsToHtml, fillFieldsFromData} from "../../../common/util/util-fields";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import Card from "../../../common/components/card";
import {LoaderSmall} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";

class AccountingSettingsTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            PaymentAccountID: ACCOUNT_TYPE_CASH,
            ReceiveAccountID: ACCOUNT_TYPE_CASH,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount = () => {
        this.fetchData()
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.resource.isLoading && (!this.props.resource.isLoading && !!this.props.resource.data)) {
            const item = getProp(this.props, 'resource.data', {})
            this.setState({
                fields: this.getFields(item),
                PaymentAccountID: PaymentAccountTypeExpense(ACCOUNT_TYPE_CASH, this.props.resource.data?.PreferredPaymentMethodID),
                ReceiveAccountID: PaymentAccountTypeIncome(ACCOUNT_TYPE_CASH, this.props.resource.data?.PreferredReceiveMethodID)
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: Resources.AccountingSettings
        }))
    }

    onSubmit = () => {
        if (!this.state.canSubmit) {
            return
        }

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                this.setState({canSubmit: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({
                            AccountingSettingsID: getProp(this.props, 'resource.data.AccountingSettingsID', 0)
                        }, FieldsManager.getFieldKeyValues(this.state.fields)),
                        resource: Resources.AccountingSettings,
                        piggyResource: Resources.AccountingSettings,
                        errorMessage: true, successMessage: 'Settings saved successfully!'
                    }))
                })
            }
        })
    }

    /** UI Events
     ================================================================= */

    handleInputChange = (name, value) => {
        const newFields = FieldsManager.updateField(this.state.fields, name, value)

        if (name === 'PreferredPaymentMethodID') {
            const QueryAccountTypeID = PaymentAccountTypeExpense(ACCOUNT_TYPE_CASH, value)

            newFields.PreferredPaymentAccountID.value = ''
            this.setState({
                PaymentAccountID: QueryAccountTypeID
            })
        }

        if (name === 'PreferredReceiveMethodID') {
            const QueryAccountTypeID = PaymentAccountTypeIncome(ACCOUNT_TYPE_CASH, value)

            newFields.PreferredReceiveAccountID.value = ''
            this.setState({
                ReceiveAccountID: QueryAccountTypeID
            })
        }

        this.setState({
            canSubmit: Object.values(newFields).reduce((memo, it) => {
                return memo || (getProp(this.props, 'resource.data.Contact.' + it.name) !== it.value)
            }, false), fields: newFields
        })
    }

    onCancel = () => {
        if (!this.state.canSubmit) {
            return
        }

        this.setState({
            canSubmit: false,
            fields: this.getFields()
        })
    }

    /** Helpers
     ================================================================= */
    getFields = (item = {}) => {
        const fieldTemplates = {
            LockedDate: new Field('LockedDate', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
            FiscalYearStart: new Field('FiscalYearStart', '', ['empty'], false, 'date', {addContainerClass: 'col-span-full'}),
            BookDateDefault: new Field('BookDateDefault', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),

            IncomeAccountID: new Field('IncomeAccountID', '', ['empty'], false, 'select-search', getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_DRIVER) ? {addContainerClass: 'col-span-6'} : {addContainerClass: 'col-span-3'}),
            CarrierDefaultAccountID: getProp(LocalStorage.get('user'), 'Contact.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_DRIVER) ? new Field('CarrierDefaultAccountID', '', [], false, 'select-search', {
                addContainerClass: 'col-span-3',
                label: "CarrierExpenseAccount"
            }) : new Field('CarrierDefaultAccountID', '', [], false, 'hidden', {addContainerClass: 'col-span-3'}),
            ExpenseAccountID: new Field('ExpenseAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            ReceivablesAccountID: new Field('ReceivablesAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            PayableAccountID: new Field('PayableAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            BankAccountID: new Field('BankAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            FixedAccountID: new Field('FixedAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            InventoryAccountID: new Field('InventoryAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),

            ReimbursementAccountID: new Field('ReimbursementAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),
            AdvancesAccountID: new Field('AdvancesAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-3'}),

            PaymentNetTermTypeID: new Field('PaymentNetTermTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            PreferredPaymentMethodID: new Field('PreferredPaymentMethodID', PAYMENT_METHOD_CACHE, ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            PreferredPaymentAccountID: new Field('PreferredPaymentAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),

            NetTermTypeID: new Field('NetTermTypeID', '', ['empty'], false, 'select', {addContainerClass: 'col-span-3'}),

            PreferredReceiveMethodID: new Field('PreferredReceiveMethodID', PAYMENT_METHOD_CACHE, ['empty'], false, 'select', {addContainerClass: 'col-span-full'}),
            PreferredReceiveAccountID: new Field('PreferredReceiveAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),

            PreferredUndepositedAccountID: new Field('PreferredUndepositedAccountID', '', ['empty'], false, 'select-search', {addContainerClass: 'col-span-full'}),
            FactorExpenseAccountID: new Field('FactorExpenseAccountID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}),
            IsPercentageFactor: new Field('IsPercentageFactor', 0, [], false, 'button-group', {
                data: {
                    0: 'Flat',
                    1: 'Percentage'
                }
            }),
            FactorFee: new Field('FactorFee', '', ['float'], false, 'float', {addContainerClass: 'col-span-6'}),
            InvoiceTerms: new Field('InvoiceTerms', '', [], false, 'rich-text', {
                addContainerClass: 'col-span-full',
                labelClass: 'form-group has-float-label font-weight-bold'
            })
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props
        const isLoading = this.props.resource.isLoading

        const accountsAPIFields = (type, subtype) => ({
            api: 'api/accounting/accounts',
            query: {
                MainAccountTypeID: type,
                AccountTypeID: subtype
            },
            searchMap: (item) => ({
                value: item.AccountID,
                label: item.AccountNumber + ' ' + item.AccountName
            })
        })

        const bookDateDefault = {
            '1': 'Invoice/Bill Date',
            '2': 'Pickup Date',
            '3': 'Delivery Date'
        }

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
            IncomeAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_INCOME),
            ExpenseAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_EXPENSE),
            ReceivablesAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_ASSET, ACCOUNT_RECEIVABLE),
            PayableAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_LIABILITY, ACCOUNT_PAYABLE),
            BankAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_ASSET, ACCOUNT_TYPE_CASH),
            FixedAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_ASSET, ACCOUNT_TYPE_FIXED_ASSETS),
            InventoryAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_ASSET),

            AdvancesAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_ASSET),
            ReimbursementAccountID: accountsAPIFields(MAJOR_ACCOUNT_TYPE_LIABILITY),

            BookDateDefault: bookDateDefault,

            PaymentNetTermTypeID: getLookup('NetTermType'),
            PreferredPaymentMethodID: getLookup('PaymentType'),
            PreferredUndepositedAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    AccountTypeID: ACCOUNT_TYPE_CURRENT_ASSETS
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            PreferredPaymentAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    AccountTypeID: this.state.PaymentAccountID,
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },

            PreferredReceiveMethodID: getLookup('PaymentType'),
            PreferredReceiveAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    AccountTypeID: this.state.ReceiveAccountID,
                },
                searchMap: (item) => ({
                    label: item.AccountNumber + ' ' + item.AccountName,
                    value: item.AccountID
                })
            },
            FactorExpenseAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            },
            CarrierDefaultAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE},
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
        })

        const info = fieldsHtml.slice(0, 3)
        const defaultAccounts = fieldsHtml.slice(3, 12)
        const payable = fieldsHtml.slice(12, 15)
        const receivable = fieldsHtml.slice(15, 23)

        return (
            <Page>
                <div className="relative">
                    <div className={classNames('mt-6 grid gap-4 grid-cols-6', isLoading ? 'invisible' : '')}>
                        <Card addClass="col-span-6 lg:col-span-2">
                            <h5 className="mb-4 text-tm-gray-700 text-base">{translate('text.general')}</h5>

                            <div className="grid gap-4 grid-cols-3">
                                {info}
                            </div>
                        </Card>

                        <Card addClass="col-span-6 lg:col-span-4">
                            <h5 className="mb-4 text-tm-gray-700 text-base">{translate('text.accounts')}</h5>

                            <div className="grid gap-4 grid-cols-6">
                                {defaultAccounts}
                            </div>
                        </Card>

                        <Card addClass="col-span-6 lg:col-span-2">
                            <h5 className="mb-4 text-tm-gray-700 text-base">{translate('text.payable')}</h5>

                            <div key={this.state.PaymentAccountID} className="grid gap-4 grid-cols-6">
                                {payable}
                            </div>
                        </Card>

                        <Card addClass="col-span-6 lg:col-span-4">
                            <h5 className="mb-4 text-tm-gray-700 text-base">{translate('text.Receivable')}</h5>

                            <div key={this.state.ReceiveAccountID} className="grid gap-4 grid-cols-6">
                                {receivable}
                            </div>
                        </Card>
                    </div>

                    {!!isLoading && (
                        <div className={'inset-0'}>
                            <div className="inset-center">
                                <LoaderSmall/>
                            </div>
                        </div>
                    )}

                    {!isLoading && (
                        <PageFooter
                            translate={translate}
                            canSubmit={this.state.canSubmit && checkPerm(Resources.AccountingSettings, UPDATE_PERM)}
                            actionCancel={this.onCancel}
                            actionSubmit={this.onSubmit}
                        />
                    )}
                </div>
            </Page>
        )
    }
}

export default AccountingSettingsTab
