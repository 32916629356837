import React, {useState} from "react";
import {Field} from "../../../../data/services/fields";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import {
    CheckBadgeIcon, ClipboardDocumentCheckIcon,
    HashtagIcon,
    MapPinIcon,
    EnvelopeIcon,
    PaperClipIcon,
    PhoneIcon,
    ShieldCheckIcon,
    ExclamationTriangleIcon,
    StarIcon,
    TruckIcon,
    UserIcon,
    XCircleIcon
} from "@heroicons/react/24/outline";
import {checkPerm, classNames} from "../../../../common/util/util-helpers";
import CheckCircleIcon from "@heroicons/react/24/outline/CheckCircleIcon";
import {StarIcon as StarSolidIcon} from "@heroicons/react/20/solid";
import logo from '../../companies-assets/tia-watchdog-logo-small.png'
import Resources from "../../../../data/services/resources";
import DocumentIcon from "@heroicons/react/24/outline/DocumentIcon";
import LocalStorage from "../../../../util/localStorage";
import {createIntegrationResource} from "../../../../data/actions/integrationResource";
import {useDispatch} from "react-redux";
import CarrierReportSection from "./carrier-report-section";
import {download} from "../../../../data/actions/download";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import {CREATE_PERM} from "../../../../common/util/util-consts";

export default function CarrierReportDialog({
                                                data,
                                                dataSource,
                                                onClose,
                                                onImportCarrierClick,
                                                hasAgreement,
                                                setHasCarrierData,
                                                onSendInvite,
                                                translate,
                                                integrationResource
                                            }) {

    const dispatch = useDispatch();

    const [isDocumentPreviewModalVisible, setIsDocumentPreviewModalVisible] = useState(false);
    const [previewDocument, setPreviewDocument] = useState({});

    if (!!data) {
        data = dataAdapter(data, dataSource);
    }

    const insurance = [
        {name: "InsurerName", label: "Underwriter"},
        {name: "insurerAMBestRating", label: "A.M. Best"},
        {name: "Type", label: "Type"},
        {name: "PolicyNumber", label: "PolicyNumber"},
        {name: "ExpirationDate", label: "ExpirationDate"},
        {name: "CoverageLimit", label: "CoverageLimit"},
        {name: "Deductible", label: "Deductible"}
    ];

    const insuranceCertificates = data?.CertData?.Certificates?.[0]?.Coverages ?? [];

    const handleDownloadDocumentClick = (doc) => {
        // Unfinished for preview dialog
        // setIsDocumentPreviewModalVisible(true)
        // setPreviewDocument(doc);

        dispatch(download({
            user: LocalStorage.get("user"),
            query: {
                BlobName: doc.BlobName,
                name: doc.FileName ?? (doc.BlobName + ".pdf")
            },
            resource: Resources.MyCarrierIntegrationInvite,
        }))
    }

    const handleInviteAgreement = () => {
        const USDOTNumber = data.USDOTNumber
        const Email = data.PrimaryEmail


        dispatch(createIntegrationResource({
            user: LocalStorage.get("user"),
            resource: Resources.MyCarrierIntegrationInvite,
            params: {
                USDOTNumber: USDOTNumber,
                Email: Email,
            },
            piggyResource: Resources.MyCarrierIntegrationInvite,
            //query: {id: id}
        }))
    }

    return (
        <div className="">
            <div className="p-5 space-y-5 w-full bg-tm-gray-50">
                <React.Fragment>
                    <div className="grid grid-cols-12 gap-5">
                        {!!integrationResource?.error ? (
                            <div className={"col-span-4 flex items-center justify-center text-lg"}>
                                No data available for carrier
                            </div>
                        ) : (
                            <div className="col-span-4">
                                <InfoTable
                                    addClass="pt-2"
                                    // hasLabel={false}
                                    labelWidthClass="w-10"
                                    fields={getInfoFields(data)}
                                    data={data}
                                    // title={"Info"}
                                    // Icon={InformationCircleIcon}
                                    translate={translate}
                                />
                            </div>
                        )}


                        <div className="col-span-8 flex flex-col justify-center items-center">
                            <div className="flex items-center">
                                <div>
                                    {data?.RiskAssessment?.Overall === 'Acceptable' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-20 h-20 stroke-1 fill-green-100 text-green-600">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"/>
                                        </svg>
                                    )}

                                    {data?.RiskAssessment?.Overall === 'Moderate' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" strokeWidth={1.5} fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             className="w-20 h-20 stroke-1 fill-yellow-100 text-yellow-600">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"/>
                                        </svg>
                                    )}

                                    {data?.RiskAssessment?.Overall === 'Unacceptable' && (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor"
                                             className="w-20 h-20 stroke-1 fill-red-100 text-red-600">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                                        </svg>
                                    )}
                                </div>

                                <div>
                                    <div className="text-sm font-semibold leading-6 text-gray-600">
                                        Overall assessment using checklist
                                    </div>
                                    <div
                                        className="text-3xl font-semibold leading-6 text-gray-900">{(data?.RiskAssessmentDetails?.OverallRating === "UnacceptableReview") ? "Unacceptable - Review required" :
                                        ("UnacceptableFail" === data?.RiskAssessmentDetails?.OverallRating ? "FAILED!" : data?.RiskAssessmentDetails?.OverallRating)
                                    }</div>
                                </div>
                            </div>

                            <dl className="mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-card text-center sm:grid-cols-2 lg:grid-cols-6">
                                {['Authority', 'Insurance', 'Operation', 'Other', 'Safety'].map(it => {
                                    return (
                                        <div className="flex itmes-center flex-col bg-tm-gray-100 p-4">
                                            <dt className="text-sm font-semibold leading-6 text-gray-600">
                                                {it}
                                            </dt>

                                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 text-center">
                                                {data?.RiskAssessment?.[it] !== 'Unacceptable' && (
                                                    <ShieldCheckIcon className={
                                                        classNames(
                                                            "inline-block w-7 h-7",
                                                            data?.RiskAssessment?.[it] === 'Acceptable' ? "text-green-600" : undefined,
                                                            data?.RiskAssessment?.[it] === 'Moderate' ? "text-yellow-600" : undefined
                                                        )}
                                                    />
                                                )}

                                                {data?.RiskAssessment?.[it] === 'Unacceptable' && (
                                                    <XCircleIcon className={
                                                        classNames(
                                                            "inline-block w-7 h-7",
                                                            data?.RiskAssessment?.[it] === 'Unacceptable' ? "text-red-600" : undefined
                                                        )}
                                                    />
                                                )}
                                            </dd>
                                        </div>
                                    )
                                })}

                                <div className="flex flex-col bg-tm-gray-100 p-4">
                                    <dt className="text-sm font-semibold leading-6 text-gray-600">
                                        {data.WatchdogReports === 'No' ? "No reports" : "??"}
                                    </dt>
                                    <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 h-9 flex items-center">
                                        <img src={logo} alt="Tia"/>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    {!!data?.Authority && (
                        <CarrierReportSection
                            data={data.Authority}
                            title={"Authority"}
                            Icon={CheckBadgeIcon}
                        />
                    )}

                    {!!data?.Safety && (
                        <CarrierReportSection
                            data={data.Safety}
                            title={"Safety and Review"}
                            Icon={CheckBadgeIcon}
                        />
                    )}

                    {!!data?.Drivers && (
                        <CarrierReportSection
                            data={data.Drivers}
                            title={"Drivers"}
                            Icon={UserIcon}
                        />
                    )}

                    {!!data?.Equipment && (
                        <CarrierReportSection
                            data={data.Equipment}
                            title={"Equipment"}
                            Icon={TruckIcon}
                        />
                    )}

                    <div className={classNames("bg-inverse px-5 border border-tm-gray-300 rounded-card")}>
                        <table className="min-w-full divide-y divide-tm-gray-300 text-tm-gray-900">
                            <thead className="divide-y divide-tm-gray-300">
                            <tr>
                                <th
                                    colSpan={2}
                                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-bold sm:pl-0"
                                >
                                    <div className="flex">
                                        <CheckBadgeIcon className="w-5 h-5 mr-2 text-tm-gray-600"/>

                                        Insurance
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                {insurance.map(it => {
                                    return (
                                        <th className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm text-gray-600 sm:pl-0">
                                            {it.label}
                                        </th>
                                    )
                                })}
                            </tr>
                            </thead>

                            <tbody className="divide-y divide-tm-gray-200 bg-inverse">
                            {insuranceCertificates.map(row => {
                                return (
                                    <tr>
                                        {
                                            insurance.map((it, index) => {
                                                return (
                                                    <td className="py-2 pl-4 pr-3 text-sm text-tm-gray-500 sm:pl-0">
                                                        {row[it.name]}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>

                    <div className="grid grid-cols-2 gap-5">
                        {!!data.PossibleFraud || !!data.DoubleBrokering || !!data.FraudCallNumber && (
                            <InfoTable
                                fields={getSafetyFields(data)}
                                data={data}
                                title={"Safety"}
                                Icon={ExclamationTriangleIcon}
                                translate={translate}
                            />
                        )}

                        {data.RatingValue || data.RatingValue || data.AvgRatingBasisText || data.CustomerRatingText && (
                            <InfoTable
                                fields={getCarrierRatingFields(data)}
                                data={data?.CarrierRating ?? {}}
                                title={"Carrier ratings"}
                                Icon={StarIcon}
                                translate={translate}
                            />
                        )}

                        {!!data?.AvailableFiles?.length && (
                            <div className="px-5 pb-5 border border-tm-gray-300 rounded-card bg-inverse">
                                <th colSpan="2"
                                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-bold sm:pl-0">
                                    <div className="flex">
                                        <DocumentIcon className="w-5 h-5 mr-2 text-tm-gray-600"/>
                                        Available documents
                                    </div>
                                </th>

                                <div className="mt-2 text-sm sm:col-span-2 sm:mt-0">

                                    <ul role="list"
                                        className="divide-y divide-tm-gray-200 rounded-md border border-tm-gray-300"
                                    >
                                        {data.AvailableFiles.map(doc => {
                                                return (
                                                    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
                                                        <div className="flex w-0 flex-1 items-center">
                                                            <PaperClipIcon className="w-5 h-5 text-tm-gray-500"/>
                                                            <div className="ml-4 flex min-w-0 flex-1 gap-2">
                                                                <span
                                                                    className="truncate font-medium"
                                                                >
                                                                    {doc.FileName}
                                                                </span>

                                                                {doc.Type && (
                                                                    <span
                                                                        className="flex-shrink-0 text-gray-400"
                                                                    >
                                                                        ({doc.Type})
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="ml-4 flex-shrink-0">
                                                            <button
                                                                className="btn btn-text"
                                                                onClick={() => handleDownloadDocumentClick(doc)}
                                                            >
                                                                Download
                                                            </button>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                </React.Fragment>
            </div>

            {/*<ModalDefault*/}
            {/*    show={isDocumentPreviewModalVisible}*/}
            {/*    widthClass={"max-w-7xl"}*/}
            {/*    title={translate("text.chose_a_theme")}*/}

            {/*    closeButtonLabel={translate("btn.close")}*/}
            {/*    onClose={() => setIsDocumentPreviewModalVisible(false)}*/}
            {/*>*/}
            {/*    <FileViewer*/}
            {/*        fileType={"pdf"}*/}
            {/*        filePath={Env.getApiUrl("api/" + Resources.MyCarriersIntegrationsPreview, {*/}
            {/*            BlobName: previewDocument?.BlobName,*/}
            {/*            name: previewDocument?.FileName ?? (previewDocument?.BlobName + ".pdf"),*/}
            {/*            token: getJWT().access_token*/}
            {/*        })}*/}
            {/*        onError={(e) => {*/}
            {/*            console.log(e)*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</ModalDefault>*/}

            {!!onImportCarrierClick && (
                <div className="fixed top-full w-full">
                    <ModalFooter
                        closeButtonLabel={translate('btn.close')}
                        onClose={onClose}

                        buttonDisabled={!checkPerm(Resources.Carriers, CREATE_PERM)}
                        buttonLabel={translate('btn.import_carrier')}
                        onButtonClick={() => {
                            setHasCarrierData(false);
                            onImportCarrierClick(data);
                            onClose();
                        }}
                    />
                </div>
            )}

            {!hasAgreement && !onImportCarrierClick && (
                <div className="fixed top-full w-full">
                    <ModalFooter
                        closeButtonLabel={translate('btn.close')}
                        onClose={onClose}

                        buttonLabel={translate('btn.send_invite')}
                        onButtonClick={() => {
                            onSendInvite(data);
                            onClose();
                        }}
                    />
                </div>
            )}
        </div>
    )
}

const getInfoFields = (data) => {
    let fields = {
        "Numbers": new Field('Numbers', '', [''], false, "text", {labelRaw: () => <HashtagIcon className="w-5 h-5"/>}),
        CompanyName: new Field('CompanyName', '', [''], false, "text", {
            labelRaw: () => <BuildingOfficeIcon className="w-5 h-5"/>
        }),
        // DBAName: new Field('DBAName', '', [''], false, "text", {icon: () => BuildingOfficeIcon}),
        AddressName: new Field('AddressName', '', [''], false, "text", {
            labelRaw: () => <MapPinIcon className="w-5 h-5"/>
        }),
        Phone: new Field('Phone', '', [''], false, "text", {labelRaw: () => <PhoneIcon className="w-5 h-5"/>}),
        // "DotNumber": new Field('DotNumber', '', [''], false, "text", {icon: () => HashtagIcon}),
        //IsIntrastateCarrier: new Field('IsIntrastateCarrier', '', [''], false, "checkbox"),
        PrimaryEmail: new Field('PrimaryEmail', '', [''], false, "text", {
            note: data.Owner,
            labelRaw: () => <EnvelopeIcon className="w-5 h-5"/>
        }),
    }

    if (!!data.CarrierPacketStatus) {
        fields["CarrierPacketStatus"] = new Field('CarrierPacketStatus', '', [''], false, "text", {
            note: "Status",
            labelRaw: () => <ClipboardDocumentCheckIcon className="w-5 h-5"/>
        })
    }

    return fields;
}

const getSafetyFields = (data) => {
    return {
        "PossibleFraud": new Field('PossibleFraud', '', [''], false, "text", {
            addValueClass: data?.PossibleFraud === "No Reports" ? "text-green-600" : undefined
        }),
        "DoubleBrokering": new Field('DoubleBrokering', '', [''], false, "text", {
            addValueClass: data?.DoubleBrokering === "No Alerts" ? "text-green-600" : undefined
        }),
        "FraudCallNumber": new Field('FraudCallNumber', '', [''], false, "text"),
        "HasSaferWatchKey": new Field('HasSaferWatchKey', '', [''], false, "checkbox"),
    }
}

const getCarrierRatingFields = () => {
    return {
        "RatingValue": new Field('RatingValue', '', [''], false, "star", {}),
        "AvgRatingText": new Field('AvgRatingText', '', [''], false, "text", {}),
        "AvgRatingBasisText": new Field('AvgRatingBasisText', '', [''], false, "text", {}),
        "CustomerRatingText": new Field('CustomerRatingText', '', [''], false, "text", {}),
        "HasCompletedPacket": new Field('HasCompletedPacket', '', [''], false, "checkbox"),
    }
}

const InfoTable = ({fields, data, Icon, title, translate, hasLabel = true, labelWidthClass = "w-52", addClass}) => {
    const getData = (cell) => {
        switch (fields[cell].type) {
            case "checkbox":
                return !!data?.[cell]
                    ? <CheckCircleIcon className="w-5 h-5 text-green-600"/>
                    : <XCircleIcon className="w-5 h-5 text-tm-gray-600"/>
            case "star":
                return <div className="flex gap-x-1">
                    {new Array(data[cell])
                        .fill(null).map(() => <StarSolidIcon className="w-5 h-5 text-yellow-600"/>)}
                </div>
            default:
                return data?.[cell];
        }
    }
    return (
        <div className={classNames(addClass, "px-5 bg-inverse border border-tm-gray-300 rounded-card")}>
            <table className="min-w-full divide-y divide-tm-gray-300 text-tm-gray-900">
                {(!!Icon || !!title) && (
                    <thead>
                    <tr>
                        <th
                            colSpan={2}
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-bold sm:pl-0"
                        >
                            <div className="flex">
                                {!!Icon && (
                                    <Icon className="w-5 h-5 mr-2 text-tm-gray-600"/>
                                )}

                                {title}
                            </div>
                        </th>
                    </tr>
                    </thead>
                )}
                <tbody className="divide-y divide-tm-gray-200 bg-inverse">
                {Object.keys(fields).map(cell => {
                    return <tr>
                        {hasLabel && (
                            <td key={cell}
                                className={
                                    classNames(
                                        "py-2 pl-4 pr-3 text-sm text-tm-gray-500 sm:pl-0",
                                        labelWidthClass
                                    )
                                }
                            >
                                {!fields[cell]?.metadata?.labelRaw && (
                                    !!fields[cell]?.metadata?.label
                                        ? translate("field." + fields[cell]?.metadata?.label)
                                        : translate("field." + cell)
                                )
                                }

                                {
                                    !!fields[cell]?.metadata?.labelRaw && fields[cell]?.metadata?.labelRaw(fields[cell])
                                }
                            </td>
                        )}

                        <td
                            className={
                                classNames(
                                    "px-2 py-2 text-sm font-medium max-w-[10rem]",
                                    !!fields[cell].metadata?.addValueClass ? fields[cell].metadata.addValueClass : ""
                                )
                            }
                        >
                            <div>
                                {getData(cell)}
                            </div>

                            {!!fields[cell]?.metadata?.note && (
                                <div className="text-tm-gray-600 text-xs">
                                    {fields[cell]?.metadata?.note}
                                </div>
                            )}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    )
}

const dataAdapter = (data, source) => {
    let adaptedData = Object.assign({}, data);
    switch (source) {
        case "MyCarrierImport":
            adaptedData.Numbers = "DOT" + data.DotNumber + " / " + data.DocketNumber;
            adaptedData.PrimaryEmail = data?.Emails?.[0]?.Email
            adaptedData.Owner = data?.Emails?.[0]?.Description
            adaptedData.AddressName = [data.Street, data.City, data.State, data.ZipCode, data.Country].filter(it => !!it).join(", ");

            if (!!data?.CertData?.Certificates?.[0]) {
                const certData = data.CertData?.Certificates?.[0];
                data.CertData.Certificates[0].AddressName = [certData.ProducerAddress, certData.ProducerCity, certData.ProducerState, certData.ProducerZip].filter(it => !!it).join(", ");
                adaptedData.CertData = data.CertData;
            }

            break;
        case Resources.MyCarrierIntegration:
            adaptedData.Numbers = "DOT" + data.DOTNumber + " / " + data.MCNumber;
            adaptedData.CompanyName = data?.LegalName;
            adaptedData.AddressName = [data.MailingAddress1, data.MailingCity, data.MailingState, data.MailingZipcode, data.MailingCountry].filter(it => !!it).join(", ");
            adaptedData.Phone = data?.AssureAdvantage?.[0]?.CarrierDetails?.Identity?.businessPhone ?? data?.FreePhone;
            adaptedData.PrimaryEmail = data?.OwnerContactEmail;
            adaptedData.RiskAssessment = data.AssureAdvantage?.[0]?.CarrierDetails?.RiskAssessment;
            adaptedData.RiskAssessmentDetails = data.AssureAdvantage?.[0]?.CarrierDetails?.RiskAssessmentDetails;
            adaptedData.WatchdogReports = data.AssureAdvantage?.[0]?.CarrierDetails?.Other?.watchdogReports
            adaptedData.CarrierPacketStatus = data.CarrierCustomerPacketStatuses?.[0]?.CarrierPacketStatus;

            if (!!data.AssureAdvantage?.[0]?.CarrierDetails?.CertData?.Certificate?.[0]?.Coverage) {
                adaptedData.CertData = {
                    Certificates: [{
                        Coverages: []
                    }]
                };

                adaptedData.CertData.Certificates[0].Coverages = data.AssureAdvantage?.[0]?.CarrierDetails?.CertData?.Certificate?.[0]?.Coverage;
                adaptedData.CertData?.Certificates[0]?.Coverages?.map(it => {
                    it.CoverageLimit = it.coverageLimit;
                    it.Deductable = it.deductable;
                    it.ExpirationDate = it.expirationDate;
                    it.InsurerName = it.insurerName;
                    it.PolicyNumber = it.policyNumber;
                    it.ReferBreakDeduct = it.referBreakDeduct;
                    it.ReferBreakdown = it.referBreakdown;
                    it.Type = it.type

                    return it;
                })
            }

            adaptedData.Authority = getAuthorityData(data);

            adaptedData.Safety = getSafetyData(data);

            adaptedData.Drivers = getDrivers(data);

            adaptedData.Equipment = getEquipment(data);

            break;
        default:
            return data;
    }

    return adaptedData;
}

const getEquipment = (data) => {
    const dataDetails = data?.CarrierOperationalDetail ?? {};

    return [
        {
            tableWrapperClass: "col-span-2",
            columns: ["", "Fleet"],
            data: [
                [
                    "Fleet Size",
                    dataDetails.FleetSize
                ], [
                    "Total Power Units",
                    dataDetails.TotalPowerUnits
                ], [
                    "Total Truck",
                    dataDetails.NumberOfVehicles
                ]
            ]
        }, {
            tableWrapperClass: "col-span-4",
            columns: ["Equipment", "Owned", "Term Leased", "Trip Leased"],
            data: [
                [
                    "Tractors",
                    dataDetails.OwnedTractors,
                    dataDetails.TermLeasedTractors,
                    dataDetails.TripLeasedTractors
                ], [
                    "Trucks",
                    dataDetails.OwnedTrucks,
                    dataDetails.TermLeasedTrucks,
                    dataDetails.TripLeasedTrucks
                ], [
                    "Trailers",
                    dataDetails.OwnedTrailers,
                    dataDetails.TermLeasedTrailers,
                    dataDetails.TripLeasedTrailers
                ]
            ]
        }
    ]
}


const getDrivers = (data) => {
    const dataDetails = data?.CarrierOperationalDetail ?? {};

    return [
        {
            tableWrapperClass: "col-span-3",
            columns: ["Total drivers", "Count"],
            data: [
                [
                    "Interstate & Intrastate drivers",
                    dataDetails.InterstateAndIntrastateDrivers
                ], [
                    "CLD Employed Driver",
                    dataDetails.CDLEmployedDrivers
                ], [
                    "Monthly average Leased drivers",
                    dataDetails.MonthlyAverageLeasedDrivers
                ]
            ]
        }, {
            tableWrapperClass: "col-span-3",
            columns: ["Interstate drivers", "Count"],
            data: [
                [
                    "Drivers",
                    dataDetails.InterstateDriversTotal
                ], [
                    "CLD Employed Driver",
                    dataDetails.InterstateDriversLT100Miles
                ], [
                    "Drivers 100+ miles",
                    dataDetails.InterstateDriversGT100Miles
                ]
            ]
        }, {
            tableWrapperClass: "col-span-3",
            columns: ["Intrastate drivers", "Count"],
            data: [
                [
                    "Drivers",
                    dataDetails.IntrastateDriversTotal
                ], [
                    "Drivers < 100 miles",
                    dataDetails.IntrastateDriversLT100Miles
                ], [
                    "Drivers 100+ miles",
                    dataDetails.IntrastateDriversGT100Miles
                ]
            ]
        }
    ];
}

const getSafetyData = (data) => {
    const dataSafety = data.AssureAdvantage?.[0]?.CarrierDetails?.Safety ?? {};
    const dataReview = data.AssureAdvantage?.[0]?.CarrierDetails?.Review ?? {};

    return [
        {
            tableWrapperClass: "col-span-3",
            columns: ["", "Safety"],
            data: [
                [
                    "Safety rating",
                    dataSafety.rating
                ], [
                    "Rating date",
                    dataSafety.ratingDate === '0000-00-00' ? '' : dataSafety.ratingDate
                ]
            ]
        },
        {
            tableWrapperClass: "col-span-3",
            columns: ["", " MCS-150 - Most Recent Report"],
            data: [
                [
                    "Date",
                    dataReview.mcs150Date
                ], [
                    "MCS-150 years",
                    dataReview.mcs150MileYear === '0000-00-00' ? '' : dataReview.mcs150MileYear
                ], [
                    "MCS-150 miles",
                    (dataReview.mcs150Miles ?? 0) + " mi."
                ]
            ]
        },
        {
            tableWrapperClass: "col-span-3",
            columns: ["", " Latest review"],
            data: [
                [
                    "Review type",
                    dataReview.reviewType
                ], [
                    "Review date",
                    dataReview.reviewDate === '0000-00-00' ? '' : dataReview.reviewDate
                ], [
                    "Documents",
                    dataReview.reviewDocNum
                ], [
                    "Reported miles",
                    dataReview.reviewMiles
                ]
            ]
        }

    ]
}

const getAuthorityData = (data) => {
    const dataAuthority = data.AssureAdvantage?.[0]?.CarrierDetails?.Authority ?? {};

    return [
        {
            tableWrapperClass: "col-span-4",
            columns: ["Authority", "Status", "App Pending", "Revocation Pending"],
            data: [
                [
                    "Common",
                    dataAuthority.commonAuthority,
                    dataAuthority.commonAuthorityPending,
                    dataAuthority.commonAuthorityRevocation
                ], [
                    "Contract",
                    dataAuthority.contractAuthority,
                    dataAuthority.contractAuthorityPending,
                    dataAuthority.contractAuthorityRevocation,
                ], [
                    "Broker",
                    dataAuthority.brokerAuthority,
                    dataAuthority.brokerAuthorityPending,
                    dataAuthority.brokerAuthorityRevocation,
                ]
            ]
        },
        {
            tableWrapperClass: "col-span-2",
            columns: ["", "Cargo Authorization"],
            data: [
                ["Freight", dataAuthority.commonAuthority],
                ["Passenger", dataAuthority.contractAuthority],
                ["Household", dataAuthority.brokerAuthority],
            ]
        },
        {
            tableWrapperClass: "col-span-2",
            columns: ["", "Authority date"],
            data: [
                [" Granted on", dataAuthority.authGrantDate],
            ]
        }
    ]
}

