import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {
    cnv, getDefaultMeasurementUnitName, getDefaultWeightUnitName,
    getMeasurementUnitName, getTempUnitString,
    getWeightUnitName,
} from '../../../../common/util/util-helpers'
import Resources from '../../../../data/services/resources'
import Tippy from '@tippyjs/react'
import ArrowUturnLeftIcon from '@heroicons/react/20/solid/ArrowUturnLeftIcon'
import LocalStorage from '../../../../util/localStorage'
import CubeIcon from '@heroicons/react/24/outline/CubeIcon'
import CubeTransparentIcon from '@heroicons/react/20/solid/CubeTransparentIcon'
import {FIELD_MASK_PHONE} from '../../../../util/util-constants'
import {classNames, getLookup, getProp} from '../../../../common/util/util-helpers'
import {scrollErrorIntoView, setSelectionRange} from '../../../../common/util/util-vanilla'
import InformationCircleIcon from "@heroicons/react/20/solid/InformationCircleIcon";
import ModalHeader from "../../../../common/components/modal/modal-header";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import Badge from "../../../../common/components/badge";
import InfoParagraph from "../../../../common/components/info-paragraph";
import ModalFooter from "../../../../common/components/modal/modal-footer";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../../common/util/util-fields";

export default class CommodityForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getCommodityFields(this.props.selectedItem),
            selectedItem: {},
            isAutocompleteActive: false,
            dirty: false,
        }

        this.defaultMeasureUnit = getDefaultMeasurementUnitName()
        this.defaultWeightUnit = getDefaultWeightUnitName()
        this.isMetric = ['cm', 'm'].includes(this.defaultMeasureUnit)
        this.standardLengthMeasure = this.isMetric ? 'm' : 'ft'
        this.standardWeightMeasure = this.isMetric ? 'kg' : 'lb'
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = this.state.fields;
        let isDirty = true;

        if ('CommodityTemplates' === name) {
            fields = this.getCommodityFields(value.metadata)
            value = {value: value.value, label: value.metadata.ProductName}
            isDirty = true;
        }

        if ('Units' === name) {
            if (value) {
                fields = FieldsManager.updateField(fields, 'MeasureUnitID', 1)
                fields = FieldsManager.updateField(fields, 'WeightUnit', 1)
            } else {
                fields = FieldsManager.updateField(fields, 'MeasureUnitID', 4)
                fields = FieldsManager.updateField(fields, 'WeightUnit', 0)
            }
        }

        if ('IsLTL' === name) {
            fields.ProductNMFC.type = value ? 'text' : 'hidden'
            fields.FreightClassID.type = value ? 'select' : 'hidden'
        }

        if ('Units' === name) {
            fields = FieldsManager.updateField(fields, 'WeightConversion', cnv(fields.Weight.value, getWeightUnitName(value)));

            if (!value) {
                fields.Temp.metadata.label = 'TemperatureCelsius';
                //fields.Temp.metadata.note = cnv(fields.Temp.value, 'c');
                fields.WeightConversion.metadata.label = 'in_lb';
                fields.Weight.metadata.label = 'weight_in_kg';
            } else {
                fields.Temp.metadata.label = 'TemperatureFahrenheit';
                fields.WeightConversion.metadata.label = 'in_kg';
                fields.Weight.metadata.label = 'weight_in_lb';
            }
        }

        if ('UnitTypeID' === name) {
            fields = getWidthAndHeightFromOptionLabel(fields, value)
        }

        if ('Length' === name) {
            fields = FieldsManager.updateField(fields, 'LengthConversion', cnv(value, getMeasurementUnitName(fields.MeasureUnitID.value)).toFixed(2))
        }

        if ('Width' === name) {
            fields = FieldsManager.updateField(fields, 'WidthConversion', cnv(value, getMeasurementUnitName(fields.MeasureUnitID.value)).toFixed(2))
        }

        if ('Height' === name) {
            fields = FieldsManager.updateField(fields, 'HeightConversion', cnv(value, getMeasurementUnitName(fields.MeasureUnitID.value)).toFixed(2))
        }

        if ('Weight' === name) {
            fields = FieldsManager.updateField(fields, 'WeightConversion', cnv(value, getMeasurementUnitName(fields.WeightUnit.value)).toFixed(2))
        }

        fields = FieldsManager.updateField(fields, name, value)

        if (['ProductName', 'MeasureUnitID', 'Length', 'Width', 'Height', 'Weight', 'CountPieces', 'UnitTypeID', 'Units', 'Temp'].includes(name)) {
            fields = this.convertMeasurements(fields)
            
            fields.Weight.metadata.note = this.getWeightNote(fields, fields.Units.value ? 'lb' : 'kg');
        }

        fields[name].errorMessage = '';

        this.setState({
            fields,
            dirty: isDirty,
        })
    }

    undoChanges = () => {
        if (!this.state.dirty) return

        this.setState({
            fields: this.getCommodityFields(this.props.selectedItem),
            dirty: false
        })
    }

    getMeasureNote = (fields, field, measure) => {
        const defaultMeasure = getDefaultMeasurementUnitName()
        const isDefaultMeasure = measure === defaultMeasure

        const result = cnv(fields[field].value, measure)

        if (!result || isDefaultMeasure) {
            return null
        }

        return result.toFixed(2) + ' ' + defaultMeasure
    }

    getWeightNote = (fields, measure) => {
        const defaultMeasure = getDefaultWeightUnitName()
        const isDefaultMeasure = measure === defaultMeasure

        const result = cnv(fields.Weight.value, measure)

        if (!result || isDefaultMeasure) {
            return null
        }

        return result.toFixed(2) + ' ' + defaultMeasure
    }

    convertMeasurements = (fields) => {
        const measure = getMeasurementUnitName(fields.MeasureUnitID.value)

        fields.Length.metadata.label = 'length_in_' + measure;
        fields.Length.metadata.note = this.getMeasureNote(fields, 'Length', measure);
        fields.Width.metadata.label = 'width_in_' + measure;
        fields.Width.metadata.note = this.getMeasureNote(fields, 'Width', measure);
        fields.Height.metadata.label = 'height_in_' + measure;
        fields.Height.metadata.note = this.getMeasureNote(fields, 'Height', measure);

        return fields
    }

    /** Helpers
     ================================================================= */
    getCommodityFields = (item = {}) => {
        const defaultMeasureUnitID = getProp(LocalStorage.get('user'), "Contact.MeasureUnitID", "");
        const defaultWeightUnitID = getProp(LocalStorage.get('user'), "Contact.WeightUnitID", "");
        const defaultTempUnitID = getProp(LocalStorage.get('user'), "Contact.MeasureTemperatureID", 2);

        const defaultMeasurementUnitString = getDefaultMeasurementUnitName();
        const selectedMeasurementUnitString = getMeasurementUnitName(item?.MeasureUnitID ?? defaultMeasureUnitID)
        const showMeasureUnitConversion = selectedMeasurementUnitString !== defaultMeasurementUnitString

        const defaultWeightUnitString = getDefaultWeightUnitName();
        const selectedWeightUnitString = getWeightUnitName(item?.WeightUnit ?? defaultWeightUnitID)
        const showWeightUnitConversion = item?.Weight && selectedWeightUnitString !== defaultWeightUnitString;

        const isImperial = ['in', 'ft'].includes(selectedMeasurementUnitString);

        const defaultTempString = getTempUnitString(defaultTempUnitID);
        const selectedTempUnitString = getTempUnitString(isImperial ? 2 : 1);
        const showTempUnitConversion = item?.Temp && defaultTempString !== selectedTempUnitString;

        let LengthInitialConversion = '';
        let WidthInitialConversion = '';
        let HeightInitialConversion = '';
        let WeightInitialConversion = '';
        let TempInitialConversion = '';

        if (item?.Length) {
            LengthInitialConversion = cnv(item.Length, selectedMeasurementUnitString).toFixed(2);
        }

        if (item?.Width) {
            WidthInitialConversion = cnv(item.Width, selectedMeasurementUnitString).toFixed(2);
        }

        if (item?.Height) {
            HeightInitialConversion = cnv(item.Height, selectedMeasurementUnitString).toFixed(2);
        }

        if (item?.Weight) {
            WeightInitialConversion = cnv(item.Weight, selectedWeightUnitString, defaultWeightUnitString).toFixed(2);
        }

        if (item?.Temp) {
            TempInitialConversion = cnv(item.Temp, selectedTempUnitString).toFixed(2);
        }

        const IsLTL = !!item?.IsLTL;

        const ProductName = (this.props.prefilled || item?.ProductName) ?? ''
        const TemperatureLabel = "Temperature" + (isImperial ? "Fahrenheit" : "Celsius")

        // Pre populate LoadCommodityPickupID && LoadCommodityDestinationID if there is only one pickup and/or stop.
        let loadCommodityPickupValue = undefined;
        let loadCommodityDestinationValue = undefined;

        const locationFields = getProp(this.props, "locationFields", {});


        // const pickups = Object.values(locationFields).filter(it => (it.StopType.value == '1' || it.StopType.value == '4') && it.StopID?.value?.value);
        // const destinations = Object.values(locationFields).filter(it => (it.StopType.value == '5' || it.StopType.value == '4') && it.StopID?.value?.value);

        const pickups = Object.values(locationFields)
            .filter(it => (it.StopType.value === 1 || it.StopType.value === 4) && it.LoadStopID.value);
        const destinations = Object.values(locationFields)
            .filter(it => (it.StopType.value === 5 || it.StopType.value === 4) && it.LoadStopID.value);


        if (!item?.LoadCommodityPickupID) {
            if (pickups.length === 1) {
                loadCommodityPickupValue = pickups[0].LoadStopID?.value;
            }
        }

        if (!item?.LoadCommodityDestinationID) {
            if (destinations.length === 1) {
                loadCommodityDestinationValue = destinations[0].LoadStopID?.value;
            }
        }

        const fieldTemplates = {
            CommodityTemplates: new Field('CommodityTemplates', '', [''], '', this.props?.hideFields?.includes('CommodityTemplates') ? 'hidden' : 'select-search', {
                addContainerClass: 'col-span-full',
                note: this.props.translate("text.commodity_templates_note")
            }),

            LoadCommodityPickupID: new Field('LoadCommodityPickupID', loadCommodityPickupValue ?? "", [''], '', 'select', {
                addContainerClass: 'col-span-6'
            }, {menuPlacement: "top"}),

            LoadCommodityDestinationID: new Field('LoadCommodityDestinationID', loadCommodityDestinationValue ?? "", [''], '', 'select', {
                addContainerClass: 'col-span-6'
            }, {menuPlacement: "top"}),

            CommodityID: new Field('CommodityID', '', [], '', 'hidden'),

            IsLTL: new Field('IsLTL', IsLTL ? 1 : 0, [], false, 'button-group', {
                data: {0: 'tl', 1: 'ltl'},
                addClass: "rounded-md",
                addButtonClass: 'uppercase font-semibold rounded-md',
                addContainerClass: 'col-span-3'
            }),

            FreightClassID: new Field('FreightClassID', '', [''], '', IsLTL ? 'select' : 'hidden', {
                addContainerClass: 'col-span-5'
            }),
            ProductNMFC: new Field('ProductNMFC', '', [], '', IsLTL ? 'text' : 'hidden', {addContainerClass: 'col-span-4'}),

            ProductName: new Field('ProductName', ProductName, ['empty'], '', 'text', {addContainerClass: 'col-span-full'}),

            ProductCode: new Field('ProductCode', '', [], '', 'text', {
                addContainerClass: 'col-span-6'
            }),

            CommodityReferenceNumber: new Field('CommodityReferenceNumber', '', [''], false, 'text', {
                addContainerClass: 'col-span-6'
            }),

            UnitTypeID: new Field('UnitTypeID', '', [], '', 'select', {
                addContainerClass: 'col-span-6 col-start-1',
                label: 'UnitType'
            }),

            CountPallets: new Field('CountPallets', '', ['float_or_empty'], false, 'float_or_empty', {addContainerClass: 'col-span-6'}),

            CountPieces: new Field('CountPieces', '', ['float_or_empty'], false, 'float_or_empty', {addContainerClass: 'col-span-6'}),

            Stackable: new Field('Stackable', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-6 flex items-end',
                note: this.props.translate("text.stackable_note")
            }),

            Exch: new Field('Exch', '', [''], false, this.props?.hideFields?.includes('Exch') ? 'hidden' : 'checkbox', {
                addContainerClass: 'col-span-6 col-start-7',
                note: this.props.translate("text.exch_note")
            }, {id: "pallets-exch"}),

            Units: new Field('Units', isImperial ? 1 : 0, [''], false, 'button-group', {
                data: {0: this.props.translate('btn.metric'), 1: this.props.translate('btn.imperial')},
                addContainerClass: 'col-span-3'
            }),

            MeasureUnitID: new Field('MeasureUnitID', defaultMeasureUnitID, [''], '', 'button-group', {
                addContainerClass: 'col-span-3'
            }),

            Length: new Field('Length', 0, ['float_or_empty'], false, 'float_or_empty', {
                label: this.props.translate('length_in_' + selectedMeasurementUnitString),
                addContainerClass: 'col-start-1 col-span-3',
                note: showMeasureUnitConversion 
                    ? LengthInitialConversion + '(' + defaultMeasurementUnitString + ')' : null
            }),
            LengthConversion: new Field('LengthConversion', LengthInitialConversion, [''], true, showMeasureUnitConversion ? 'float_or_empty' : 'hidden', {
                label: this.props.translate('length_in_' + defaultMeasurementUnitString),
            }),

            Width: new Field('Width', 0, ['float_or_empty'], false, 'float_or_empty', {
                label: this.props.translate('width_in_' + selectedMeasurementUnitString),
                addContainerClass: 'col-span-3',
                note: showMeasureUnitConversion 
                    ? WidthInitialConversion + '(' + defaultMeasurementUnitString + ')' 
                    : null
            }),
            WidthConversion: new Field('WidthConversion', WidthInitialConversion, [''], true, showMeasureUnitConversion ? 'float_or_empty' : 'hidden', {
                label: this.props.translate('width_in_' + defaultMeasurementUnitString)
            }),

            Height: new Field('Height', 0, ['float_or_empty'], false, 'float_or_empty', {
                label: this.props.translate('height_in_' + selectedMeasurementUnitString),
                addContainerClass: 'col-span-3',
                note: showMeasureUnitConversion 
                    ? HeightInitialConversion + '(' + defaultMeasurementUnitString + ')' 
                    : null
            }),
            HeightConversion: new Field('HeightConversion', HeightInitialConversion, [''], true, showMeasureUnitConversion ? 'float_or_empty' : 'hidden', {
                label: this.props.translate('height_in_' + defaultMeasurementUnitString)
            }),

            WeightUnit: new Field('WeightUnit', item?.WeightUnit ?? defaultWeightUnitID, [], false, 'button-group', {
                square: true,
                buttonPadding: 'px-4 py-2'
            }),
            Weight: new Field('Weight', 0, ['float_or_empty'], false, 'float_or_empty', {
                label: isImperial ? 'total_weight_in_lb' : 'total_weight_in_kg',
                addContainerClass: 'col-span-3',
                note: showWeightUnitConversion 
                    ? WeightInitialConversion + '(' + defaultWeightUnitString + ')' 
                    : null
            }),
            WeightConversion: new Field('WeightConversion', WeightInitialConversion, ['float_or_empty'], true, 'float_or_empty', {
                label: isImperial ? 'in_kg' : 'in_lb',
            }),

            Temp: new Field('Temp', '', [''], false, 'text', {
                addContainerClass: 'col-span-3',
                label: TemperatureLabel,
                    // note: showTempUnitConversion
                    // ? TempInitialConversion + '(' + defaultTempString + ')'
                    // : null
            }),

            HAWB: new Field('HAWB', '', [''], false, 'text', {
                addContainerClass: 'col-span-3'
            }),
            MAWB: new Field('MAWB', '', [''], false, 'text', {
                addContainerClass: 'col-span-3'
            }),

            CommodityDescription: new Field('CommodityDescription', '', [''], false, 'textarea', {
                addContainerClass: 'col-span-full',
                formLabelClass: 'hidden'
            }),

            PackingGroupID: new Field('PackingGroupID', '', [''], false, 'select', {
                addContainerClass: 'col-span-6'
            }),

            Hazmat: new Field('Hazmat', '', [''], false, 'checkbox', {
                addContainerClass: 'col-span-5 flex items-end',
                note: this.props.translate('text.hazmat_field_notes')
            }),

            HazmatClassID: new Field('HazmatClassID', '', [''], false, 'select', {
                addContainerClass: 'col-span-6'
            }),
            HazmatCode: new Field('HazmatCode', '', [''], false, 'text', {
                addContainerClass: 'col-span-3'
            }),
            CommonChemical: new Field('CommonChemical', '', [''], false, 'text', {
                addContainerClass: 'col-span-3'
            }),
            EmergencyContact: new Field('EmergencyContact', '', [''], false, 'mask', {
                addContainerClass: 'col-span-3'
            }, {
                mask: FIELD_MASK_PHONE,
                onFocus: this.setSelectionRange
            }),
            EmergencyContactName: new Field('EmergencyContactName', '', [''], false, 'text', {
                addContainerClass: 'col-span-3'
            }, {mask: FIELD_MASK_PHONE}),

            updateCommodity: new Field('updateCommodity', '', [''], false, 'checkbox'),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields)
        validatedFields = this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            if (!validatedFields.IsLTL.value) {
                delete validatedFields.FreightClassID
                delete validatedFields.ProductNMFC
                return FieldsManager.getFieldKeyValues(validatedFields)
            }

            delete validatedFields.CommodityTemplates;

            return FieldsManager.getFieldKeyValues(validatedFields)
        } else {
            this.setState({fields: validatedFields})

            scrollErrorIntoView(validatedFields)
        }
    }

    setSelectionRange = (e) => {
        return setSelectionRange('EmergencyContact', e.target.value)
    }

    getCommodityVolume = () => {
        const selectedMeasureUnit = getMeasurementUnitName(this.state.fields.MeasureUnitID.value)

        const width = cnv(this.state.fields.Width.value, selectedMeasureUnit, this.standardLengthMeasure)
        const height = cnv(this.state.fields.Height.value, selectedMeasureUnit, this.standardLengthMeasure)
        const length = cnv(this.state.fields.Length.value, selectedMeasureUnit, this.standardLengthMeasure)

        return width * height * length
    }

    getCommodityWeight = () => {
        const selectedWeightUnit = this.state.fields.WeightUnit.value ? 'lb' : 'kg'

        return cnv(this.state.fields.Weight.value, selectedWeightUnit, this.standardWeightMeasure)
    }

    getMeasureUnits = () => {
        let measureUnites = getLookup('MeasureUnit', 'MeasureUnitID', 'MeasureUnit')

        if (this.state.fields.Units.value) {
            delete measureUnites[4]
            delete measureUnites[5]
        } else {
            delete measureUnites[1]
            delete measureUnites[2]
        }

        return measureUnites
    }

    getAddressName = (it) => {
        const state = it.StateAbbreviation ? it.StateAbbreviation + ", " : "";
        const city = it.CityName ? it.CityName + ", " : "";
        const postalCode = it.PostalCode ? it.PostalCode : "";
        const address = it.LocationData?.value?.AddressName ? it.LocationData.value.AddressName + ", " : "";
        return state + city + address + postalCode;
    }

    getCommoditySelects = () => {
        const locationFields = getProp(this.props, "locationFields", {});

        const pickups = Object.values(locationFields).filter(it => (it.StopType.value == '1' || it.StopType.value == '4') && it.StopID?.value?.value);
        const destinations = Object.values(locationFields).filter(it => (it.StopType.value == '5' || it.StopType.value == '4') && it.StopID?.value?.value);

        return {
            LoadCommodityPickupID: !pickups.length ? null : Object.values(locationFields).filter(it => (it.StopType.value == '1' || it.StopType.value == '4') && it.StopID.value.value).reduce((memo, it) => {
                memo[it.LoadStopID.value] = it.StopID.value.label;
                return memo
            }, {}),
            LoadCommodityPickup: !pickups.length ? null : Object.values(locationFields).filter(it => (it.StopType.value == '1' || it.StopType.value == '4') && it.StopID.value.value).reduce((memo, it) => {
                memo[it.LoadStopID.value] = this.getAddressName(it.StopID.value.metadata);
                return memo
            }, {}),
            LoadCommodityDestinationID: !destinations.length ? null : Object.values(locationFields).filter(it => (it.StopType.value == '5' || it.StopType.value == '4') && it.StopID.value.value).reduce((memo, it) => {
                memo[it.LoadStopID.value] = it.StopID.value.label;
                return memo
            }, {}),
            LoadCommodityDestination: !destinations.length ? null : Object.values(locationFields).filter(it => (it.StopType.value == '5' || it.StopType.value == '4') && it.StopID.value.value).reduce((memo, it) => {
                memo[it.LoadStopID.value] = this.getAddressName(it.StopID.value.metadata);
                return memo
            }, {}),
            UnitTypeID: getLookup('UnitType', 'UnitTypeID', 'UnitType'),
            MeasureUnitID: this.getMeasureUnits(),
            FreightClassID: getLookup('FreightClass', 'FreightClassID', 'FreightClass'),
            WeightUnit: {0: 'kg', 1: 'lb'},
            CommodityTemplates: {
                api: 'api/' + Resources.Commodities,
                query: {},
                searchMap: (it) => ({
                    label: it.ProductName,
                    value: it.CommodityID,
                    metadata: it
                })
            }
        }
    }

    onSaveTemplateAndSubmit = () => {
        let params = this.getValidFields();
        params.updateCommodity = 1;

        this.props.onSubmit(params, this.state.fields, this.getCommoditySelects());
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, title, onSubmit, footerInfo, showInfo, canSaveTemplate} = this.props

        const isHazmat = this.state.fields.Hazmat.value

        const volume = this.getCommodityVolume()
        const weight = this.getCommodityWeight()

        const volumePieces = volume * (this.state.fields.CountPallets.value ?? this.state.fields.CountPieces.value);
        const densityPieces = weight / volumePieces;

        const totalVolume = Number(volumePieces).toFixed(2)
        const totalDensity = Number(densityPieces).toFixed(2)

        const selects = this.getCommoditySelects();

        const hideStops = !Object.keys(getProp(selects, "LoadCommodityPickupID", [])).length &&
            !Object.keys(getProp(selects, "LoadCommodityDestinationID", [])).length;

        const StopsHtml = hideStops ? null : fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['LoadCommodityPickupID', 'LoadCommodityDestinationID'])))), translate, this.handleInputChange, selects)
        const ProductNameHtml = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, [!this.props.hideTemplates ? 'CommodityTemplates' : undefined, 'ProductName', 'IsLTL', 'FreightClassID', 'ProductNMFC'])))), translate, this.handleInputChange, selects)
        const ShippingItemsHtml = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['ProductCode', 'CommodityReferenceNumber', 'UnitTypeID', 'CountPieces', 'HAWB', 'MAWB', 'CountPallets', 'Stackable', 'Exch'])))), translate, this.handleInputChange, selects)

        const ShippingItemsHtml2 = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['Units', 'MeasureUnitID'])))), translate, this.handleInputChange, selects)
        const ShippingItemsHtml3 = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['Length', 'Width', 'Height', 'Weight', 'Temp'])))), translate, this.handleInputChange, selects)
        const HazmatCheckbox = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['Hazmat'])))), translate, this.handleInputChange, selects)
        const HazmatHtml = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['PackingGroupID', 'HazmatClassID', 'HazmatCode', 'CommonChemical', 'EmergencyContact', 'EmergencyContactName'])))), translate, this.handleInputChange, selects)
        const DescHtml = fieldsToHtml((Object.values(Object.assign({}, includeFields(this.state.fields, ['CommodityDescription'])))), translate, this.handleInputChange, selects)

        return (
            <React.Fragment>
                <ModalHeader
                    title={title}
                    onClose={() => this.props.onClose()}
                >
                    <div className="flex flex-grow w-full justify-end px-4 items-center">
                        <Tippy content={<span>{translate('text.undo_changes')}</span>}>
                            <button
                                type="button"
                                onClick={this.undoChanges}
                                disabled={!this.state.dirty}
                                className={classNames('btn btn-icon')}
                            >
                                <ArrowUturnLeftIcon className="h-4 w-4"/>
                            </button>
                        </Tippy>
                    </div>
                </ModalHeader>

                <div className={"h-dialog-body"}>
                    <div className="px-6 pt-4 pb-6">
                        {showInfo && (
                            <InfoBar
                                addClass="mb-5"
                                message="All values can be changed when creating a load"/>
                        )}

                        <div className="md:flex items-center mb-4 md:justify-between">
                            <Subtitle
                                subtitle={translate('text.shipment_items')}
                            />
                        </div>

                        <div className="grid gap-4 grid-cols-12">
                            {ProductNameHtml}

                            {ShippingItemsHtml}

                            <div className="col-span-full">
                                <div className="flex items-center gap-x-3 mt-6">
                                    <Subtitle
                                        className="text-tm-gray-500"
                                        subtitle={translate('text.measurements')}
                                    />

                                    <Tippy
                                        content={
                                            <React.Fragment>
                                                <p>{translate('text.measurements_starpline_line_1')}</p>
                                            </React.Fragment>
                                        }
                                        delay={[400, 0]}
                                        trigger={"mouseenter"}
                                    >
                                        <InformationCircleIcon className="w-5 h-5 text-primary"/>
                                    </Tippy>
                                </div>
                            </div>

                            {ShippingItemsHtml2}

                            <div className="col-span-6">
                                        <span className="flex items-center text-sm font-semibold text-tm-gray-900">
                                            {totalVolume > 0 ? totalDensity > 0 ? 'Volume and density calculation' : 'Volume calculation' : null}
                                        </span>

                                <div className="flex items-center space-x-4 pt-2">
                                    {totalVolume > 0 && (
                                        <Badge>
                                            <div className="flex"><CubeIcon className="w-5 h-5 mr-1"/>
                                                <span>{totalVolume} {this.standardLengthMeasure}<sup>3</sup></span>
                                            </div>
                                        </Badge>
                                    )}

                                    {totalVolume > 0 && totalDensity > 0 && (
                                        <Badge>
                                            <div className="flex"><CubeTransparentIcon className="w-5 h-5 mr-1"/>
                                                <span>{totalDensity} {this.standardWeightMeasure}/{this.standardLengthMeasure}<sup>3</sup></span>
                                            </div>
                                        </Badge>
                                    )}
                                </div>
                            </div>

                            {ShippingItemsHtml3}

                        </div>
                    </div>

                    <div className="px-6 pt-2 pb-2 bg-tm-gray-100 border-t border-tm-gray-200">
                        <div className="flex justify-between">
                            <Subtitle
                                className="flex items-center"
                                subtitle={translate('text.hazmat_details')}
                            />

                            {HazmatCheckbox}
                        </div>

                        {!!isHazmat && (
                            <div className="grid gap-4 grid-cols-12 mt-4 pb-4">
                                {HazmatHtml}
                            </div>
                        )}
                    </div>

                    <div className={classNames('px-6 pt-4 pb-6 border-t border-tm-gray-200')}>
                        <Subtitle
                            className="mb-4"
                            subtitle={translate('text.description')}
                        />

                        <div className="grid gap-4 grid-cols-12">
                            {DescHtml}
                        </div>
                    </div>

                    {!hideStops && (
                        <div
                            className={classNames('px-6 pt-4 pb-12 border-t border-tm-gray-200 bg-tm-gray-100')}>
                            <h2 className="text-lg text-nowrap text-tm-gray-900 font-semibold">
                                {translate("modal_sub_heading.commodity_stops")}
                            </h2>

                            <p className="mb-4 text-tm-gray-700 text-base">{translate("text.current_load_only")}</p>

                            <div className="grid gap-4 grid-cols-12">
                                {StopsHtml}
                            </div>
                        </div>
                    )}
                </div>

                <ModalFooter
                    buttonLabel={translate('btn.save')}
                    buttonDisabled={!this.state.dirty}
                    onButtonClick={() => {
                        onSubmit(this.getValidFields(), this.state.fields, selects)
                    }}
                    closeButtonLabel={translate('btn.close')}
                    onClose={() => {
                        this.props.onClose()
                    }}
                >
                    {!!footerInfo && (
                        <div className="mr-auto">
                            <InfoParagraph>
                                {footerInfo}
                            </InfoParagraph>
                        </div>
                    )}

                    {/*canSaveTemplate && (
                        <button
                            disabled={!this.state.dirty}
                            className="btn btn-outline"
                            onClick={this.onSaveTemplateAndSubmit}
                        >
                            {translate('text.save_add_template')}
                        </button>
                    )*/}
                </ModalFooter>
            </React.Fragment>
        )
    }
}

const getWidthAndHeightFromOptionLabel = (fields, value) => {
    const unitTypeName = getLookup('UnitType', 'UnitTypeID', 'UnitType')[value];
    let measurements = unitTypeName.toLowerCase().replace(/[^0-9x]/g, '');

    if (measurements !== "") {
        measurements = measurements.split("x");
        if (measurements.length === 2 || measurements.length === 3) {
            fields.Length.value = fields.Length.value ? fields.Length.value : measurements[1];
            fields.Width.value = fields.Width.value ? fields.Width.value : measurements[0];
        }

        if (measurements.length === 3) {
            fields.Height.value = fields.Height.value ? fields.Height.value : measurements[2];
        }
    }

    return fields;
}
