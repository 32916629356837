import React, {useEffect, useState} from 'react'
import {deleteIntegrationResource, getIntegrationResource,} from '../../../../data/actions/integrationResource'
import LocalStorage from '../../../../util/localStorage'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {
    checkPerm,
    getDefaultContactGroup,
    getDefaultUserOfficeValues,
    getProp,
    longTableColumn
} from '../../../../common/util/util-helpers'
import {
    getDefaultTableOptions,
} from '../../../../common/util/util-helpers'
import Resources from "../../../../data/services/resources";
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    READ_PERM
} from '../../../../util/util-constants'
import {createResource} from "../../../../data/actions/resource";
import {getThirdResource, updateThirdResource} from "../../../../data/actions/thirdResource";
import {CreditCardIcon} from "@heroicons/react/24/outline";
import moment from "moment";
import {toBackDateTime} from "../../../../common/util/util-dates";
import ExclamationTriangleIcon from '@heroicons/react/24/outline/ExclamationTriangleIcon'
import PageHeader from "../../../../common/components/layout/layout-components/page/page-header";
import TableCard from "../../../../common/components/resource-table/table-components/table-card";
import ResourceTable from "../../../../common/components/resource-table";
import Pagination from "../../../../common/components/resource-table/table-components/pagination";
import NoRecordsTable from "../../../../common/components/no-records-found/no-records-table";
import ModalSaveResource from "../../../../common/components/modal/modal-save-resource";
import ModalConfirm from "../../../../common/components/modal/modal-confirm";
import ModalDefault from "../../../../common/components/modal/modal-default";
import InfoParagraph from "../../../../common/components/info-paragraph";
import TableFilters from "../../../../common/components/resource-table/table-components/table-filters";
import InvoiceExpenseDetailsDialog from "../../../../common/components/modal/invoice-expense-details-dialog";
import {LoaderSmall} from "../../../../common/components/loader";
import TableCardFooter from "../../../../common/components/resource-table/table-components/table-card-footer";
import {excludeFields, fieldsToHtml, fillFieldsFromData} from "../../../../common/util/util-fields";

export default function EFSIntegrationImportDialog({
                                                       translate,
                                                       resource,
                                                       thirdResource,
                                                       dispatch,
                                                       isLoading,
                                                       defaults
                                                   }) {

    /** Helpers
     ================================================================= */
    const getFields = () => {
        return {
            Source: new Field('Source', '', ['empty'], false, 'text', {
                omitSort: true
            }),
            StartDate: new Field('StartDate', '', ['empty'], false, 'date', {
                omitSort: true
            }),
            EndDate: new Field('EndDate', '', ['empty'], false, 'date', {
                omitSort: true
            }),
            IsExpense: new Field('IsExpense', '', [], false, 'checkbox', {
                label: "IncludesEFS"
            }),
            IsDirect: new Field('IsDirect', '', [], false, 'checkbox', {
                label: "IncludesPilot"
            }),
            IsCompleted: new Field('IsCompleted', '', [], false, 'checkbox'),
            NetTotal: new Field('NetTotal', '', [], false, 'money'),
            NotFoundCards: new Field('NotFoundCards', '', [''], false, 'text', {
                render: (item) => (
                    <div>{!!item.NotFoundCards && (<ExclamationTriangleIcon
                        className="h-6 w-6 inline text-red-600"/>)} {longTableColumn(item.NotFoundCards)}</div>
                )
            }),
            Notes: new Field('Notes', '', [''], false, 'textarea'),
            CreateUpdateDate: new Field('CreateUpdateDate', '', [], false, 'datetimezone'),
            UpdatedByContactID: new Field('UpdatedByContactID', '', [], false, 'select-search'),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search'),
            ContactGroupID: new Field('ContactGroupID', '', [''], false, 'select-search')
        }
    }

    const getFieldsImport = () => {
        return {
            OrganizationID: new Field('OrganizationID', defaults?.EFSImportOrganizationID ? {
                value: defaults?.EFSImportOrganizationID,
                label: defaults?.EFSImportOrganization
            } : '', [], false, 'select-search', {addContainerClass: "col-span-6"}),
            DirectOrganizationID: new Field('DirectOrganizationID', defaults?.EFSDirectImportOrganizationID ? {
                value: defaults?.EFSDirectImportOrganizationID,
                label: defaults?.EFSDirectImportOrganization
            } : '', [], false, 'select-search', {addContainerClass: "col-span-6"}),

            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: "col-span-4"}),
            BookDate: new Field('BookDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: "col-span-4"}),
            DueDate: new Field('DueDate', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: "col-span-4"}),
        }
    }

    const getImportPreviewQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            offset: new Field('offset', '0', [''], false, 'text'),
            limit: new Field('limit', 200, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: "top"})
        }
    }

    const getImportIntegrationFields = () => {
        return {
            Description: new Field('Description', '', [''], false, 'textarea'),
            Location: new Field('Location', '', [''], false, 'text'),
            AddressName: new Field('AddressName', '', [''], false, ''),
            CityName: new Field('CityName', '', [''], false, ''),
            StateID: new Field("StateID", '', [], false, 'select-search'),
            PostalCode: new Field('PostalCode', '', [''], false, ''),
            Driver: new Field('Driver', '', ['']),
            TruckID: new Field('TruckID', '', [], false, 'select-search'),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search'),
            Load: new Field('Load', '', []),
            Fuel: new Field('Fuel', '', [''], false, 'custom', {
                render: (item) => (
                    <>{item.Fuel}</>
                )
            }),
            TotalAmount: new Field('TotalAmount', '', ['empty'], false, 'money'),
            Date: new Field('Date', '', [''], false, 'datetimezone'),
            TransactionType: new Field('TransactionType', '', [''], false, 'custom', {
                render: (it) => {
                    if (it.TransactionType == 4) {
                        return 'Direct';
                    } else {
                        return 'EFS';
                    }
                }
            })
        }
    }

    const getFieldsEdit = (item = {}) => {
        const fieldTemplates = {
            LoadID: new Field('LoadID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}),
            DriverID: new Field('DriverID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-3"}),
            TruckID: new Field('TruckID', '', ['empty'], false, 'select-search', {addContainerClass: "col-span-3"}),
            TrailerID: new Field('TrailerID', '', [], false, 'select-search', {addContainerClass: "col-span-3"}, {isClearable: true}),
        };
        return fillFieldsFromData(fieldTemplates, item);
    };

    const getQuery = () => {
        return {
            limit: query.limit,
            offset: query.offset,
            sort: query.sort,
            sortBy: query.sortBy,
        }
    }

    const getImportPreviewQuery = () => {
        return {
            query: importPreviewQueryFields.query.value,
            limit: importPreviewQueryFields.limit.value,
            offset: importPreviewQueryFields.offset.value,
            sort: importPreviewQuery.sort,
            sortBy: importPreviewQuery.sortBy
        }
    }

    /** State
     ================================================================= */
    const importIntegrationData = (getProp(thirdResource, 'data.list', []))
    const importIntegrationPreviewData = (getProp(thirdResource, 'data.import', {}))
    const importDataMismatch = (getProp(thirdResource, 'data.import.DataMismatch', null))
    const importIntegrationCount = (getProp(thirdResource, 'data.count', 0))
    const data = (getProp(resource, 'data.list', []))
    const count = (getProp(resource, 'data.count', 0))
    const [query, setQuery] = useState(Object.assign({}, DEFAULT_CRUD_STATE, {
        sort: 'DESC',
        sortBy: 'StartDate',
    }))
    const [importPreviewQuery, setImportPreviewQuery] = useState(DEFAULT_CRUD_STATE)
    const [createModalOpen, setCreateModalOpen] = useState(false)
    const [redirectModalOpen, setRedirectModalOpen] = useState(false)
    const [discardModalOpen, setDiscardModelOpen] = useState(false)
    const [serverResponseID, setServerResponseID] = useState(null)
    const [importIntegrationModalOpen, setImportIntegrationModalOpen] = useState(false)
    const [showEFSViewBill, setShowEFSViewBill] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [ModalFetchKey, setModalFetchKey] = useState(null)
    const [ModalFetchResource, setModalFetchResource] = useState(null)
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [fieldsImport, setFieldsImport] = useState(getFieldsImport())
    const [importPreviewQueryFields, setImportPreviewQueryFields] = useState(getImportPreviewQueryFields())
    const [noTrucks, setNoTrucks] = useState(false)

    /** Lifecycles
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [query])

    useEffect(() => {
        if (serverResponseID && importIntegrationModalOpen) {
            fetchImportIntegrationData()
        }
    }, [importIntegrationModalOpen])

    useEffect(() => {
        if (serverResponseID) {
            fetchImportIntegrationData()
        }
    }, [importPreviewQueryFields, importPreviewQuery])

    /** Data Events
     ================================================================= */
    const fetchData = () => {
        dispatch(getIntegrationResource({
            user: LocalStorage.get('user'),
            resource: Resources.FuelIntegrationPreviews,
            query: getQuery()
        }))
    }

    const fetchImportIntegrationData = () => {
        dispatch(getThirdResource({
            user: LocalStorage.get('user'),
            resource: Resources.FuelIntegrationPreview,
            query: Object.assign({}, {
                FuelPurchaseImportPreviewID: serverResponseID
            }, {...getImportPreviewQuery()})
        }))
    }

    /** UI Events
     ================================================================= */
    const handleUpdateOffset = (offset, paginationPage) => {
        setQuery(prevState => ({
            ...prevState,
            offset: offset,
            paginationPage: paginationPage
        }))
    }

    const handleToggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen)
    }

    const handleToggleRedirectionModal = () => {
        setRedirectModalOpen(!redirectModalOpen)
    }

    const handleToggleImportIntegrationModal = (item = null) => {
        if (item) {
            setServerResponseID(item.FuelPurchaseImportPreviewID)
        }
        setImportIntegrationModalOpen(!importIntegrationModalOpen);
        setImportPreviewQueryFields(FieldsManager.updateField(Object.assign({}, importPreviewQueryFields), 'offset', '0')) // getImportPreviewQueryFields()
    }

    const handleToggleDiscardModal = (item = null) => {
        if (item) {
            setServerResponseID(item.FuelPurchaseImportPreviewID)
        }
        setDiscardModelOpen(!discardModalOpen)
    }

    const handleToggleViewBill = (item = null) => {
        let BillModalFetchKey = 'ExpenseID';
        let BillModalFetchResource = Resources.ExpenseExpense;

        setSelectedItem(item);
        setShowEFSViewBill(!showEFSViewBill);
        setModalFetchKey(BillModalFetchKey);
        setModalFetchResource(BillModalFetchResource);
    }

    const handleToggleEditModal = (item = null) => {
        setEditModalOpen(!editModalOpen)
        setSelectedItem(item)
    }
    const handleInputImportChange = (name, value) => {
        setFieldsImport(FieldsManager.updateField(fieldsImport, name, value))
    }

    const handlePreviewImportQueryChange = (name, value) => {
        setImportPreviewQueryFields(FieldsManager.updateField(importPreviewQueryFields, name, value));

        if (name !== 'offset') {
            setImportPreviewQueryFields(FieldsManager.updateField(importPreviewQueryFields, 'offset', '0'))
        }
    }

    const handleUpdateSort = (sortBy) => {
        setImportPreviewQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (importPreviewQuery.sortBy === sortBy) ? (importPreviewQuery.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))
    }

    const handleCreateImportPreviewInputChange = (fields, name, value) => {
        let newFields = FieldsManager.updateField(fields, name, value)

        if (name === 'StartDate') {
            newFields.EndDate.props = {
                minDate: fields.StartDate.value,
                maxDate: moment(fields.StartDate.value).add(7, 'd')
            }
        }

        if (name === 'EndDate') {
            newFields.StartDate.props = {
                maxDate: fields.EndDate.value,
                minDate: moment(fields.EndDate.value).subtract(7, 'd')
            }
        }
        return newFields
    }

    /** Render
     ================================================================= */
    const tableOptions = getDefaultTableOptions(getFields(), {}, 'efs-integration-settings-dialog', translate)
    const importIntegrationTableOptions = getDefaultTableOptions(getImportIntegrationFields(), {}, 'efs-integration-settings-dialog', translate)
    const selects = {
        OrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        },
        DirectOrganizationID: {
            api: 'api/' + Resources.OrganizationsQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.OrganizationID
            })
        },
    }

    const fieldsImportHTML = fieldsToHtml(Object.values(Object.assign({}, fieldsImport)), translate, handleInputImportChange, selects)

    const metadata = {
        LoadID: {
            api: 'api/' + Resources.LoadsQuick,
            query: {},
            searchMap: (item) => ({
                value: item.LoadID,
                label: item.LoadNumber
            })
        },
        DriverID: {
            api: 'api/' + Resources.DriversQuick,
            query: {},
            searchMap: (item) => ({
                value: item.DriverID,
                label: item.FirstName + " " + item.LastName
            })
        },
        TruckID: {
            api: 'api/' + Resources.TrucksQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.TruckID,
                label: item.Truck
            })
        },
        TrailerID: {
            api: 'api/' + Resources.TrailersQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.TrailerID,
                label: item.Trailer
            })
        }
    }

    const initialStartDate = moment().subtract(7, 'd').format(DEFAULT_DATABASE_DATETIME_FORMAT)
    const initialEndDate = moment().format(DEFAULT_DATABASE_DATETIME_FORMAT)
    const initialMinDate = toBackDateTime(moment().subtract(7, 'd'))
    const initialMaxDate = toBackDateTime(moment())

    return (
        <div className={'relative p-5'}>
            {isLoading && (
                <div className="text-center absolute position-absolute-center">
                    <LoaderSmall/>
                </div>
            )}

            <PageHeader
                buttonLabel={translate('btn.create_new')}
                onButtonClick={handleToggleCreateModal}
                buttonHidden={!checkPerm(Resources.FuelIntegrationPreviews, CREATE_PERM)}
            />

            <TableCard addClass={'mt-5'}>
                <ResourceTable
                    data={data}
                    fields={getFields()}

                    translate={translate}
                    isLoading={isLoading}

                    options={tableOptions}

                    onRowClick={checkPerm(Resources.FuelIntegrationPreview, CREATE_PERM) && handleToggleImportIntegrationModal}
                    onView={handleToggleImportIntegrationModal}
                    onDelete={handleToggleDiscardModal}

                    hasViewPerm={checkPerm(Resources.FuelIntegrationPreview, READ_PERM)}
                    hasDeletePerm={checkPerm(Resources.FuelIntegrationPreviews, DELETE_PERM)}

                    actions={[
                        {
                            title: () => translate('btn.viewBill'),
                            visible: (item) => item.IsCompleted === 1,
                            action: (item) => {
                                handleToggleViewBill(item)
                            },
                            icon: () => CreditCardIcon,
                            order: 20
                        }]}
                />

                {/*Table footer*/}
                {!(!data.length && !isLoading) && (
                    <div
                        className="bg-inverse px-4 py-3 flex items-center justify-between border-t border-tm-gray-300 sm:px-6 h-16 rounded-b-lg"
                    >
                        <Pagination
                            count={count}
                            hideRowsPerPage={true}
                            pageLimit={query.limit}
                            pageOffset={query.offset}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : null
                            }

                            translate={translate}
                        />
                    </div>
                )}

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    canCreate={checkPerm(Resources.FuelIntegrationPreviews, CREATE_PERM)}
                    title={'No matching records found'}
                    text={'Create a new import preview'}
                    btnLabel="Create import preview"
                    onBtnClick={handleToggleCreateModal}
                    filters={{}}
                />
            </TableCard>

            <ModalSaveResource
                title={'Create import preview'}
                widthClass="max-w-xl"
                gridColsClass="grid-cols-2"
                show={createModalOpen}
                onClose={handleToggleCreateModal}
                fields={{
                    StartDate: new Field('StartDate', initialStartDate, ['empty'], false, 'date', {}, {
                        maxDate: initialEndDate ?? '',
                        minDate: initialMinDate ?? ''
                    }),
                    EndDate: new Field('EndDate', initialEndDate, ['empty'], false, 'date', {}, {
                        minDate: initialStartDate ?? '',
                        maxDate: initialMaxDate ?? ''
                    }),
                    IsExpense: new Field('IsExpense', '', [], false, 'checkbox', {
                        label: "IncludesEFS"
                    }),
                    IsDirect: new Field('IsDirect', '', [], false, 'checkbox', {
                        label: "IncludesPilot"
                    }),
                    OfficeID: new Field('OfficeID', getDefaultUserOfficeValues(), ['empty'], false, 'select-search'),
                    ContactGroupID: new Field('ContactGroupID', getDefaultContactGroup(), ['empty'], false, 'select-search'),
                    Notes: new Field('Notes', '', [''], false, 'textarea')
                }}
                handleInputChange={handleCreateImportPreviewInputChange}
                onSubmit={(params) => {
                    if (params) {
                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            params: params,
                            resource: Resources.FuelIntegrationPreview,
                            onSuccessCallback: (result) => {
                                setServerResponseID(result.data.id)
                                handleToggleRedirectionModal()
                                handleToggleCreateModal()
                            },
                        }))
                    }
                }}
                isLoading={isLoading}
                translate={translate}
                canSubmit={true}
                htmlBefore={
                    <div className={"mx-2 mt-5"}>
                        <InfoParagraph>
                            {translate("text.preview_import_create")}
                        </InfoParagraph>
                    </div>
                }
                metadata={{
                    OfficeID: {
                        api: 'api/' + Resources.OfficesQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            label: item.OfficeName,
                            value: item.OfficeID
                        })
                    },
                    ContactGroupID: {
                        api: 'api/' + Resources.ContactGroupsQuick,
                        query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                        searchMap: (item) => ({
                            label: item.ContactGroupName,
                            value: item.ContactGroupID
                        })
                    }
                }}
            />

            <ModalConfirm
                title={'Confirm discard'}
                show={!!discardModalOpen}
                text={translate("text.discard_import")}
                onClose={handleToggleDiscardModal}
                buttonLabel={translate('btn.confirm')}
                closeButtonLabel={'Cancel'}
                translate={translate}
                onConfirm={() => {
                    dispatch(deleteIntegrationResource({
                        user: LocalStorage.get('user'),
                        query: {
                            FuelPurchaseImportPreviewID: serverResponseID
                        },
                        piggyQuery: getQuery(),
                        errorMessage: true,
                        successMessage: `Import discarded`,
                        resource: Resources.FuelIntegrationPreviews,
                        piggyResource: Resources.FuelIntegrationPreviews,
                    }));
                    handleToggleDiscardModal();
                }}
            />

            <ModalConfirm
                type="info"
                onClose={handleToggleRedirectionModal}
                onConfirm={() => {
                    setServerResponseID(null)
                    handleToggleRedirectionModal()
                    fetchData()
                }}
                buttonLabel={'Back to the List'}
                show={redirectModalOpen}
                title={translate("modal_heading.confirm")}
                translate={translate}
                customButtonsHTML={(
                    <div
                        className="btn btn-outline mr-auto"
                        onClick={() => {
                            handleToggleImportIntegrationModal()
                            handleToggleRedirectionModal()
                        }}
                    >
                        Open created import preview
                    </div>
                )}
            >
                <p>Import preview created successfully!</p>
            </ModalConfirm>

            <ModalDefault
                show={importIntegrationModalOpen}
                widthClass={'max-w-full'}
                title={translate('modal_heading.efs_integration_details')}

                buttonLabel={translate("text.confirm_import")}
                buttonDisabled={importIntegrationData.length === 0}

                onButtonClick={() => {
                    const validatedFields = FieldsManager.validateFields(fieldsImport)
                    setFieldsImport(validatedFields)
                    if (FieldsManager.checkFieldsForErrors(validatedFields) && !importIntegrationData.some((it) => !!it.TruckID === false)) {
                        dispatch(createResource({
                            user: LocalStorage.get('user'),
                            resource: Resources.FuelIntegrationImport,
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(fieldsImport), {FuelPurchaseImportPreviewID: serverResponseID}),
                            onSuccessCallback: () => {
                                handleToggleImportIntegrationModal()
                                setServerResponseID(null)
                                fetchData()
                                setFieldsImport(getFieldsImport())
                            },
                            piggyResource: Resources.Fuel
                        }))
                    } else if (FieldsManager.checkFieldsForErrors(validatedFields)) {
                        setNoTrucks(true);
                    }
                }}
                closeButtonLabel={translate('btn.Cancel')}
                onClose={() => {
                    handleToggleImportIntegrationModal()
                    fetchData()
                    setFieldsImport(getFieldsImport())
                }}
            >
                <div className={"h-[calc(100vh-12rem)] flex flex-col overflow-hidden"}>
                    <div className={"mx-5 my-5"}>
                        <InfoParagraph>
                            {translate("text.complete_import")}
                        </InfoParagraph>

                        {!!importIntegrationPreviewData.NotFoundCards && importIntegrationPreviewData.NotFoundCards.length > 0 && (
                            <InfoParagraph type={"danger"} className={"mt-2 word-break overflow-y-auto"}>
                                <div className={"max-h-12"}>
                                    {translate("text.complete_import_missing_cards_warning")} {importIntegrationPreviewData.NotFoundCards}
                                </div>
                            </InfoParagraph>
                        )}

                        {!!importDataMismatch && (
                            <InfoParagraph type={"danger"} className={"mt-2 word-break"}>
                                {importDataMismatch}
                            </InfoParagraph>
                        )}
                    </div>

                    <div className={"mx-5 relative z-30 pb-5"}>
                        <div className="grid gap-4 grid-cols-12">
                            {fieldsImportHTML}
                        </div>
                    </div>

                    <TableFilters
                        hideLimit
                        filterFields={excludeFields(importPreviewQueryFields, ['offset'])}
                        handleInputChange={handlePreviewImportQueryChange}
                        translate={translate}
                    />

                    <ResourceTable
                        data={importIntegrationData}
                        fields={getImportIntegrationFields()}

                        translate={translate}
                        isLoading={thirdResource.isLoading || resource.isLoading}

                        options={importIntegrationTableOptions}

                        onEdit={handleToggleEditModal}

                        limit={importPreviewQueryFields.limit.value}
                        sort={importPreviewQuery.sort}
                        sortBy={importPreviewQuery.sortBy}

                        onSortChange={handleUpdateSort}
                    />

                    <TableCardFooter
                        show={!(!data.length && !(thirdResource.isLoading || resource.isLoading))}
                    >
                        <Pagination
                            count={importIntegrationCount}
                            queryFields={importPreviewQueryFields}
                            isLoading={thirdResource.isLoading || resource.isLoading}
                            handleQueryChange={handlePreviewImportQueryChange}
                            translate={translate}
                        />
                    </TableCardFooter>

                    <NoRecordsTable
                        show={(importIntegrationData.length === 0) && !thirdResource.isLoading}
                        title={translate("text.no_matching_records")}
                    />
                </div>

            </ModalDefault>

            <ModalDefault
                show={showEFSViewBill}
                widthClass={'max-w-5xl w-screen'}
                title={ModalFetchKey ? translate('text.' + ModalFetchKey) : ''}

                closeButtonLabel={translate('btn.Close')}
                onClose={() => handleToggleViewBill()}
            >
                <InvoiceExpenseDetailsDialog
                    resource={thirdResource}
                    isLoading={thirdResource.isLoading}
                    translate={translate}
                    dispatch={dispatch}

                    showLoadReferenceLinks={true}
                    disableComments={true}
                    canDeletePayment={false}
                    disableAddReference={true}
                    disableTransactionImport={true}

                    type="expense"

                    onFetchData={() => {
                        if (selectedItem) {
                            dispatch(getThirdResource({
                                user: LocalStorage.get('user'),
                                query: {
                                    [ModalFetchKey]: selectedItem.ExpenseID ?? selectedItem.DirectExpenseID
                                },
                                resource: ModalFetchResource
                            }))
                        }
                    }}
                />
            </ModalDefault>

            <ModalConfirm
                type={"info"}
                show={noTrucks}
                widthClass="max-w-4xl"
                limitHeight={true}
                onClose={() => setNoTrucks(false)}
                closeButtonLabel={translate('btn.ok')}
            >
                <div className="grid justify-content-center text-md text-lg">
                    {translate('text.noTrucksSet')}
                </div>
            </ModalConfirm>

            <ModalSaveResource
                title={translate("text.Edit")}
                widthClass="max-w-md"
                gridColsClass="grid-cols-3"
                show={editModalOpen}
                onClose={handleToggleEditModal}
                fields={getFieldsEdit(selectedItem)}
                onSubmit={(params) => {
                    if (params) {
                        params.FuelPurchasePreviewID = selectedItem.FuelPurchasePreviewID;
                        dispatch(updateThirdResource({
                            user: LocalStorage.get("user"),
                            params: params,
                            query: Object.assign({}, {
                                FuelPurchaseImportPreviewID: serverResponseID
                            }, {...getImportPreviewQuery()}),
                            resource: Resources.FuelIntegrationPreview,
                            piggyResource: Resources.FuelIntegrationPreview,
                            errorMessage: true, successMessage: "Item updated.",
                        }));
                        handleToggleEditModal()
                    }
                }}
                metadata={metadata}
                translate={translate}
                closeButtonLabel={translate('btn.cancel')}
            />
        </div>
    )
}
