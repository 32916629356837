import {Field} from "../../../../../../data/services/fields";
import {toFrontDateTime} from "../../../../../../common/util/util-dates";
import React from "react";
import Bot from '../../../../../../assets/images/bot.png'
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import ChatbotBox from "../../../../../../common/components/layout/layout-components/AIChat/chatbot-box";
import DisplayDataGrid from "../../../../../../common/components/display-data/display-data-grid";

export default function OcrDocumentSummary({translate, data, onAcceptClick, onCancelClick}) {

    return (
        <div
            className="h-full relative max-w-[800px] w-[800px] border-r border-tm-gray-200 pt-6 pb-8 bg-tm-gray-50">

            <div
                className="absolute h-32 left-0 right-0 bottom-0 bg-popup z-10 pt-16 border-x border-t-2 rounded-card border-tm-gray-200">
                <div>
                    <div className="flex mt-3 absolute top-0 left-0 mr-3">
                        <div className="flex-shrink-0 bg-inverse p-3">
                            <img src={Bot} className="w-8 h-8" alt="bot"/>
                        </div>

                        <div className={"text-sm"}>
                            <ChatbotBox
                                fakeStreamSpeed={20}
                                streamContent={true}
                                content={"Hey, this is a summary of PDF. We have already entered this data for you. Can you check it and tell us if it looks good?"}
                            />
                        </div>
                    </div>
                </div>

                <div className="absolute flex justify-end gap-x-2 pr-4 h-8 left-0 right-0 bottom-1 z-20">
                    <button onClick={onAcceptClick}
                            className="w-8 h-8 bg-green-600/20 hover:bg-green-600/30 rounded-btn flex items-center justify-center">
                        <CheckIcon className="w-5 h-5 text-green-600"/>
                    </button>

                    <button
                        className="w-8 h-8 bg-red-600/20 hover:bg-red-600/30 rounded-btn flex items-center justify-center">
                        <XMarkIcon onClick={onCancelClick} className="w-5 h-5 text-red-600"/>
                    </button>
                </div>
            </div>

            <div className="overflow-y-scroll h-[calc(100%-5rem)] pb-10 px-2">
                <p className="text-lg leading-6 font-medium text-tm-gray-900 mb-3">Document summary</p>

                <div>
                    <DisplayDataGrid
                        addGridClass={"grid-cols-3 border-b border-tm-gray-300 px-4 pb-2 text-tm-gray-700 font-bold inverse"}
                        displayList={Object.values({
                            document_issuer: new Field('document_issuer', '', [''], false, "text", {addContainerClass: "col-span-full"}),
                            load_number: new Field('load_number', '', [''], false,),
                            equipment_type: new Field('equipment_type', '', [''], false),
                        })}
                        data={data}
                        translate={translate}
                    />

                    <DisplayDataGrid
                        addGridClass={"grid-cols-3 border-b border-tm-gray-300 px-4 py-2 text-tm-gray-700 font-bold"}
                        displayList={Object.values({
                            total_weight: new Field('total_weight', '', [''], false, "text"),
                            total_price: new Field('total_price', '', [''], false,),
                        })}
                        data={data}
                        translate={translate}
                    />

                    <div className="text-base font-semibold leading-7 text-tm-gray-900 mt-4">
                        Shipping Addresses
                    </div>

                    <div className="divide-y divide-tm-gray-100 rounded-md border border-tm-gray-200 bg-inverse">
                        {data?.shipping_addresses && data.shipping_addresses.map((shippingAddress, i) => {
                            const addressClone = Object.assign({}, shippingAddress)
                            addressClone.date = toFrontDateTime(data.shipping_dates[i]);
                            addressClone.endDate = data.shipping_dates[i]?.length > 16 ? toFrontDateTime(data.shipping_dates[i].slice(-16)) : ""
                            addressClone.StopType = 'Pickup'
                            return <div key={i} className="relative">
                                <div
                                    className="absolute top-1 right-1 w-8 h-8 text-lg rounded-card border border-tm-gray-200 flex items-center justify-center">
                                    {i + 1}
                                </div>

                                <DisplayDataGrid
                                    key={i}
                                    gridClass={"grid gap-2"}
                                    addGridClass={"grid-cols-6 border-b border-tm-gray-300 px-4 py-2 text-tm-gray-700 font-bold"}
                                    displayList={Object.values({
                                        name: new Field('name', '', [''], false, "text", {addContainerClass: "col-span-4"}),
                                        StopType: new Field('StopType', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        address_line: new Field('address_line', '', [''], false, "text", {addContainerClass: "col-span-4"}),
                                        ref_number: new Field('ref_number', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        city_name: new Field('city_name', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        state: new Field('state', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        postal_code: new Field('postal_code', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        date: new Field('date', '', [''], false, "text", {addContainerClass: "col-span-3"}),
                                        endDate: new Field('endDate', '', [''], false, "text", {addContainerClass: "col-span-3"}),
                                        notes: new Field('notes', '', [''], false, "", {addContainerClass: "col-span-full"}),
                                    })}
                                    data={addressClone}
                                    translate={translate}
                                />
                            </div>
                        })}
                    </div>

                    <div className="text-base font-semibold leading-7 text-tm-gray-900 mt-4">
                        Delivery Addresses
                    </div>

                    <div className="divide-y divide-tm-gray-100 rounded-md border border-tm-gray-200 bg-inverse">
                        {data?.delivery_addresses && data.delivery_addresses.map((deliveryAddress, i) => {
                            const addressClone = Object.assign({}, deliveryAddress)
                            addressClone.date = toFrontDateTime(data.delivery_dates[i]);
                            addressClone.endDate = data.delivery_dates[i]?.length > 16 ? toFrontDateTime(data.delivery_dates[i].slice(-16)) : ""
                            addressClone.StopType = 'Delivery'
                            return <div className="relative" key={i}>
                                <div
                                    className="absolute top-1 right-1 w-8 h-8 text-lg rounded-card border border-tm-gray-200 flex items-center justify-center">
                                    {i + 1}
                                </div>

                                <DisplayDataGrid
                                    key={i}
                                    gridClass={"grid gap-2"}
                                    addGridClass={"grid-cols-6 border-b border-tm-gray-300 px-4 py-2 text-tm-gray-700 font-bold"}
                                    displayList={Object.values({
                                        name: new Field('name', '', [''], false, "text", {addContainerClass: "col-span-4"}),
                                        StopType: new Field('StopType', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        address_line: new Field('address_line', '', [''], false, "text", {addContainerClass: "col-span-4"}),
                                        ref_number: new Field('ref_number', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        city_name: new Field('city_name', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        state: new Field('state', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        postal_code: new Field('postal_code', '', [''], false, "text", {addContainerClass: "col-span-2"}),
                                        date: new Field('date', '', [''], false, "text", {addContainerClass: "col-span-3"}),
                                        endDate: new Field('endDate', '', [''], false, "text", {addContainerClass: "col-span-3"}),
                                        notes: new Field('notes', '', [''], false, "", {addContainerClass: "col-span-full"}),
                                    })}
                                    data={addressClone}
                                    translate={translate}
                                />
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}