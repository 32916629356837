import React, {useEffect, useState} from 'react'
import Resources from '../../../../../data/services/resources';
import Env from '../../../../../util/env';
import axios from 'axios';
import {LoaderSmall} from "../../../../../common/components/loader";
import {getJWT, getUser} from "../../../../../common/util/util-auth";

export default function OCRWarnings({warnings, onCreateLocations}) {
    if (!warnings) {
        return null;
    }

    const warningsTable = warnings.reduce((memo, it) => {
        memo[it.field] = it;
        return memo;
    }, {});

    const isCreatingLocations = warnings.some(warning => warning?.actions?.createLocation);

    const [isPostingLocation, setIsPostingLocation] = useState(true);
    const [messages, setMessages] = useState({});

    useEffect(() => {
        let uniqueLocations = [];

        const requests = warnings.reduce((memo, warning) => {
            if (
                warning?.actions?.createLocation?.LocationName 
                    && !uniqueLocations.includes(warning?.actions?.createLocation?.LocationName)
            ) {
                memo[warning?.field] = axios.post(
                    Env.getApiUrl('api/' + Resources.Locations),
                    Object.assign({
                        ContactGroupID: getUser('ContactGroups')?.[0]?.ContactGroupID,
                        CountryID: 1,
                        CustomShippingHours: 2,
                        CustomReceivingHours: 2,
                        StopTypeID: 1
                    }, warning.actions.createLocation),
                    {
                        headers: {
                            'Authorization': 'Bearer ' + getJWT().access_token
                        }
                    }
                )

                uniqueLocations.push(warning?.actions?.createLocation?.LocationName);
            }

            return memo;
        }, {});

        axios.all(Object.values(requests)).then((responses) => {
            const createdLocations = {};

            setMessages(Object.keys(requests).reduce((memo, key, i) => {
                const locationName = warningsTable[key]?.actions?.createLocation?.LocationName;
                if (responses[i]?.status === 201 || responses[i]?.message === 'OK') {
                    memo[key] = {
                        message: `Location ${locationName} created successfully`,
                        status: responses[i]?.status,
                    }

                    createdLocations[locationName] = responses[i]?.data?.data?.id;
                } else {
                    memo[key] = {
                        message: `Something went wrong when creating location ${locationName}`,
                        status: responses[i]?.status
                    }
                }
                return memo;
            }, {}));

            setIsPostingLocation(false);
            if (Object.values(createdLocations).length) {
                onCreateLocations(createdLocations);
            }
        });

    }, []);

    return (
        <div>
            <h3 className="text-sm font-medium text-yellow-800">
                There were {warnings?.length} warnings(s) with your submission
            </h3>
            
            <div className="mt-2 text-sm text-yellow-700">
                <ul role="list" className="list-disc space-y-1 pl-5">
                    {warnings.map((warning, i) => {
                        return <li key={i}>

                            <div>
                                <span className="font-bold">
                                    {warning.field.split(".").pop()}
                                </span> 

                                {warning.message}

                            </div>

                            {messages[warning.field] && (
                                messages[warning.field].message
                            )}
                        </li>
                    })}
                </ul>

                {isCreatingLocations && isPostingLocation && (
                    <div className='flex gap-x-4 bg-primary-transparent text-primary rounded-btn'>
                        <LoaderSmall disableContainer={true} />
                        Creating location ...
                    </div>
                )}
            </div>
        </div>
    )
}

