import React, {Component} from 'react'
import {fieldsToHtml, fillFieldsFromData} from '../../../common/util/util-fields'
import {Field, FieldsManager} from '../../../data/services/fields'
import Resources from '../../../data/services/resources'
import LocalStorage from '../../../util/localStorage'
import {getResource, updateResource} from '../../../data/actions/resource'
import {LoaderSmall} from "../../../common/components/loader";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {UPDATE_PERM} from '../../../util/util-constants'
import {checkPerm, getProp} from '../../../common/util/util-helpers'
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import {toFrontDateTimeFromUTC} from "../../../common/util/util-dates";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import {showModal} from "../../../data/actions/ui";
import DisplayDataGrid from "../../../common/components/display-data/display-data-grid";
import Card from "../../../common/components/card";
import FieldOptions from "../../../common/components/fields/field-options";

export default class CustomerCreditTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields(),

            isDirty: false,
            footerIsSticky: true,
        }
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({ fields: this.getFields() })
        }
        if (prevState.isDirty !== this.state.isDirty) {
            this.props.setIsStateDirty(this.state.isDirty)
        }
    }

    /** Data events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResourceName(),
            query: {
                id: this.getID()
            }
        }))
    }

    onSubmit = () => {
        if (this.state.isDirty) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                            id: this.getID(),
                        }),
                        query: {
                            id: this.getID(),
                        },
                        errorMessage: true, successMessage: this.props.translate('text.CustomerSettingsUpdated'),
                        resource: this.getResourceName(),
                        piggyResource: this.getResourceName()
                    }))
                    this.setState({isDirty: false})
                }
            })
        }
    }

    /** UI events
     ================================================================= */
    handleFormCancel = () => {
        this.setState({
            fields: this.getFields(),
            isDirty: false
        })
    }

    handleInputChange = (name, value) => {
        this.setState({
            fields: FieldsManager.updateField(this.state.fields, name, value),
            isDirty: true
        })
    }

    /** Fields/data definitions
     ================================================================= */
    getFields = () => {
        let info = getProp(this.props.resource, 'data', {})

        let fieldTemplates = {
            Rating: new Field('Rating', '', [''], false, '', {addContainerClass: "col-span-full"}),
            Agency: new Field('Agency', '', [''], false, '', {addContainerClass: "col-span-full"}),
            CreditLimit: new Field('CreditLimit', 0, ['empty'], false, 'money', {addContainerClass: "col-span-full"}),
            AllowCreditLimitOverride: new Field('AllowCreditLimitOverride', '', [''], false, 'checkbox', {labelType: "float", addContainerClass: "col-span-full sm:col-span-6 lg:col-span-3"}),
            CreditHold: new Field('CreditHold', '', [''], false, 'checkbox', {labelType: "float", addContainerClass: "col-span-full sm:col-span-6 sm:col-start-1 lg:col-start-1 lg:col-span-2"}),
            CreditHoldNote: new Field('CreditHoldNote', '', [''], false, 'textarea', {addContainerClass: "col-span-full"}),
            CreditLimitUpdateDate: new Field('CreditLimitUpdateDate', '', [''], false, 'datetimezone', { hideDialog: true }, {addContainerClass: "col-span-full"}),
            CreditLimitUpdateBy: new Field('CreditLimitUpdateBy', '', [''], false, 'text', {
                hideDialog: true,
                options: () => (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                onClick: () => this.props.dispatch(showModal('ViewContactCard', {ContactID: getProp(this.props.resource, 'data.CreditLimitUpdateByID', 0)})),
                                isVisible: true
                            }
                        ]}
                    />
                )
            }),
        }

        return fillFieldsFromData(fieldTemplates, info)
    }

    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.CustomersCredit
    }

    getID = () => {
        return this.props.match.params.CustomerID
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const fieldsHTML = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {})

        return (
            <div className="max-w-4xl mx-auto">
                <Card className="bg-inverse rounded-card border-tm-gray-300 border shadow-card mb-4">
                    {!!resource.isLoading && (
                        <div className="w-full text-center">
                            <LoaderSmall/>
                        </div>
                    )}

                    {!resource.isLoading && (
                        <div className={'grid grid-cols-12 gap-4'}>
                            {fieldsHTML}

                            {!!getProp(this.props.resource, 'data.CreditLimitUpdateByID', 0) && (
                                <DisplayDataGrid
                                    addGridClass={"col-span-full grid-cols-2 mb-5 border border-tm-gray-300 rounded-lg p-5 text-tm-gray-700 font-bold"}
                                    displayList={Object.values(this.getFields()).filter(it => it.name === 'CreditLimitUpdateDate' || it.name === 'CreditLimitUpdateBy')}
                                    data={{
                                        CreditLimitUpdateBy: getProp(this.props.resource, "data.CreditLimitUpdateBy", ''),
                                        CreditLimitUpdateDate: toFrontDateTimeFromUTC(getProp(this.props.resource, "data.CreditLimitUpdateDate", ''))
                                    }}
                                    translate={translate}
                                />
                            )}
                        </div>
                    )}
                </Card>

                <PageFooter
                    translate={translate}
                    canSubmit={this.state.isDirty}
                    actionCancel={this.handleFormCancel}
                    actionSubmit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.onSubmit}
                />
            </div>
        )
    }
}
