import FieldText from "../field-text";
import React, {useEffect, useState} from "react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import {classNames} from "../../../util/util-helpers";
import {ExclamationCircleIcon} from "@heroicons/react/20/solid";

export default function FieldCellTextNew({name, value, className, addClass, errorMessage, autoFocus, type, align, placeholder, disabled, onChange}) {
    const [isFocused, setIsFocused] = useState(false);
    const [innerValue, setInnerValue] = useState(value);

    useEffect(() => {
        if (type === 'integer') {
            value = (!value ? "" : value.toString()).replace(/\D/g, "");
        }

        setInnerValue(value);
    }, [value]);

    return (
        <div className="relative">
            {!isFocused && !errorMessage?.length && !disabled && (
                <PencilSquareIcon className={
                    classNames(
                        "pointer-events-none z-10 absolute top-1 w-5 h-5 text-tm-gray-400",
                        align === 'right' ? "-right-5" : "right-3"
                    )
                } />
            )}

            {(!!errorMessage?.length && !isFocused) && (
                <ExclamationCircleIcon className={
                    classNames(
                        "text-red-600 pointer-events-none z-10 absolute top-1 w-5 h-5",
                        align === 'right' ? "-right-5" : "right-3"
                    )
                } />
            )}

            <FieldText
                name={name}
                addClass={classNames(
                    className ?? "text-tm-gray-700 w-full border border-transparent focus:border-primary placeholder:text-tm-gray-500 bg-transparent focus:shadow-sm py-1 px-1.5 focus:outline-none focus:bg-field focus:ring-0 focus:border-primary sm:text-sm disabled:bg-tm-gray-50 disabled:text-tm-gray-700 focus:text-tm-gray-700 rounded-input",
                    addClass,
                    !isFocused && errorMessage?.length ? "!bg-red-600/10" : undefined,
                    align === 'right' ? "text-right" : undefined
                )}
                value={innerValue}
                autoFocus={autoFocus}
                onFocus={() => setIsFocused(true)}
                placeholder={placeholder}
                onChange={(_, value) => setInnerValue(value)}
                disabled={disabled}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        onChange(name, innerValue);
                    }
                }}
                onBlur={(e) => {
                    setIsFocused(false);
                    onChange(name, e.target.value);
                    setInnerValue(e.target.value);
                }}
            />
        </div>
    );
}
