import { Field } from "../../../data/services/fields";
import { longTableColumn } from'../../../common/util/util-helpers'
import TrucksTableBadge from "../../fleet/trucks-view/trucks-table-badge";
import Tippy from "@tippyjs/react";
import { DRIVER_STATUS_TYPES, READ_PERM, TRUCK_STATUS_TYPES } from "../../../util/util-constants";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import React from "react";
import {showGlobalModal} from "../../../data/actions/ui";
import { checkPerm } from "../../../common/util/util-helpers";
import Resources from "../../../data/services/resources";
import DriverStatusBadge from "../../../common/components/badge/driver-status-badge";
import {fillFieldsFromData} from "../../../common/util/util-fields";

export default function GetDispatchManagerFields(translate, dispatch, item = null) {
    const containerClass = 'col-span-full sm:col-span-6 lg:col-span-3'

    const fieldTemplates = {
        DriverID: new Field('DriverID', '', ['empty'], false, 'select-search', {
            addContainerClass: containerClass,
            render: (item) => {
                if (!item) return null;

                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(showGlobalModal('ViewDriverCard', item.DriverID))
                        }}
                        disabled={!checkPerm(Resources.DriverInfo, READ_PERM)}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.Driver}
                    </button>
                )
            }
        }),
        DriverStatusID: new Field('DriverStatusID', '', [''], false, 'select', {
            addContainerClass: containerClass,
            render: (item) => (
                <div className={"flex space-x-2 items-center"}>
                    <DriverStatusBadge
                        item={Object.assign({}, item, {
                            StatusID: item.DriverStatusID,
                            OnLoadID: item.OnLoadDriverID
                        })}
                        translate={translate}
                    />
                    {item.TruckStatusID != item.DriverStatusID && (
                        <Tippy
                            content={<span>{translate("txt.unit_status_missmatch", [
                                translate(DRIVER_STATUS_TYPES[item.DriverStatusID]),
                                translate(TRUCK_STATUS_TYPES[item.TruckStatusID])
                            ])}</span>}
                        >
                            <ExclamationTriangleIcon className="w-5 h-5 mr-2 text-red-600"/>
                        </Tippy>
                    )}
                </div>
            ),
            hideDialog: true
        }, {
            isClearable: true,
            values: DRIVER_STATUS_TYPES
        }),
        CoDriverID: new Field('CoDriverID', '', [], false, 'select-search', {
            addContainerClass: containerClass,
            render: (item) => {
                if (!item.CoDriverID) return null;

                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(showGlobalModal('ViewDriverCard', item.CoDriverID))
                        }}
                        disabled={!checkPerm(Resources.DriverInfo, READ_PERM)}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.CoDriver}
                    </button>
                )
            }
        },{isClearable: true}),
        TruckID: new Field('TruckID', '', ['empty'], false, 'select-search', {
            addContainerClass: containerClass,
            render: (item) => {
                if (!item.TruckID) return null;

                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(showGlobalModal('ViewTruckCard', item.TruckID))
                        }}
                        disabled={!checkPerm(Resources.TrucksInfo, READ_PERM)}
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.Truck}
                    </button>
                )
            }
        }),
        TruckStatusID: new Field('TruckStatusID', '', [''], false, 'select', {
            addContainerClass: containerClass,
            render: (item) => (
                <div className={"flex space-x-2 items-center"}>
                    <TrucksTableBadge
                        item={Object.assign({}, item, {
                            StatusID: item.TruckStatusID,
                            OnLoadID: item.OnLoadTruckID
                        })}
                        translate={translate}
                    />
                </div>
            ),
            hideDialog: true
        }, {
            isClearable: true,
            values: TRUCK_STATUS_TYPES
        }),
        TruckLocation: new Field('TruckLocation', '', [''], false, 'custom', {
            addContainerClass: containerClass,
            render: (item) => (
                <div className={"flex space-x-2 items-center"}>
                    {item.TruckLocation}
                </div>
            ),
            hideDialog: true
        }),
        TrailerID: new Field('TrailerID', '', [], false, 'select-search', {
            addContainerClass: containerClass,
            render: (item) => {
                if (!item.TrailerID) return null;

                return (
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(showGlobalModal('ViewTrailerCard', item.TrailerID))
                        }}
                        disabled={!checkPerm(Resources.Trailers, READ_PERM)}                        
                        className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
                    >
                        {item.Trailer}
                    </button>
                )
            }
        },{isClearable: true}),
        TrailerStatusID: new Field('TrailerStatusID', '', [''], false, 'select', {
            addContainerClass: containerClass,
            render: (item) => {
                if (!item.TrailerID) return null;

                return (
                    <div className={"flex space-x-2 items-center"}>
                        <TrucksTableBadge
                            itemType="trailer"
                            item={Object.assign({}, item, {
                                StatusID: item.TrailerStatusID,
                                OnLoadID: item.OnLoadTrailerID
                            })}
                            translate={translate}
                        />
                    </div>
                )
            },
            hideDialog: true
        }, {
            isClearable: true,
            values: TRUCK_STATUS_TYPES
        }),
        TrailerLocation: new Field('TrailerLocation', '', [''], false, 'text', {hideDialog: true}),
        TrailerType: new Field('TrailerType', '', [], false),
        PrimaryDispatch: new Field('PrimaryDispatch', '', [''], false, 'text', {
            addContainerClass: containerClass,
            hideDialog: true
        }),
        ContactGroup: new Field('ContactGroup', '', [''], false, 'custom', {
            render: (item) => (
                <div>{longTableColumn(item.ContactGroup)}</div>
            )
        }),
        Notes: new Field('Notes', '', [''], false, 'textarea', {addContainerClass: "col-span-full", label: "UnitNotes"}),
    }

    return fillFieldsFromData(fieldTemplates, item)
}