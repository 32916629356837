import React, {Component} from 'react'
import {Field, FieldsManager} from '../../../../data/services/fields'
import LocalStorage from '../../../../util/localStorage'
import axios from 'axios'
import Env from '../../../../util/env'
import Resources from '../../../../data/services/resources'
import {processResponse} from '../../../../data/services/api-util'
import {createResource} from '../../../../data/actions/resource'
import {getLookupWithFilter, getProp} from '../../../../common/util/util-helpers'
import {toFrontDateNoTime} from '../../../../common/util/util-dates'
import {genericMoneyFormatter} from '../../../../common/util/util-vanilla'
import {
    ACCOUNT_TYPE_CASH,
    DEFAULT_DATABASE_DATETIME_FORMAT,
    EXPENSE_TYPE_BILL,
    EXPENSE_TYPE_EXPENSE,
    MAJOR_ACCOUNT_TYPE_EXPENSE,
    PAYMENT_METHOD_CHECK,
    PAYMENT_METHOD_FACTOR
} from '../../../../util/util-constants'
import moment from 'moment'
import FieldDropdownSelect from "../../../../common/components/fields/field-dropdown-select";
import FieldContainer from "../../../../common/components/fields/field-container";
import FieldText from "../../../../common/components/fields/field-text";
import {excludeFields, fieldsToHtml, includeFields} from "../../../../common/util/util-fields";
import ModalDefault from "../../../../common/components/modal/modal-default";
import {PaymentAccountTypeExpense, PaymentAccountTypeIncome} from "../../../../common/util/util-accounting";
import {getJWT} from "../../../../common/util/util-auth";

export default class PaymentDialog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: this.getFields()
        }
    }

    /** Lifecycle
     ================================================================= */
    // componentDidMount() {
    //     this.fetchData()
    // }

    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item) {
            this.setState({
                fields: this.getFields()
            })
        }
    }

    /** Data Events
     ================================================================= */
        // fetchData = () => {
        //     this.props.dispatch(getInfoResource({
        //         user: LocalStorage.get('user'),
        //         resource: Resources.Expense
        //     }))
        // }
    handleSubmitButtonClick = () => {
        let fieldsList = ['AccountID', 'PaymentTypeID', 'Amount', 'Date', 'Description', 'DepositOverpayToID', 'InvoiceID', 'FactorAccountID', 'FactorFee']

        if (this.props.payment === 'send') {
            fieldsList.splice(6, 1)
            fieldsList.push('ExpenseID')
        }

        this.setState({
            fields: FieldsManager.validateFields(this.state.fields, fieldsList)
        }, () => {
            if (FieldsManager.checkFieldsForErrors(this.state.fields, fieldsList)) {
                this.props.dispatch(createResource({
                    user: LocalStorage.get('user'),
                    params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {
                        AccountID: this.state.fields.AccountID.value.value
                    }),
                    resource: this.props.resourcePath,
                    query: this.props.query,
                    piggyResource: this.props.piggyResourcePath,
                    errorMessage: true,
                    successMessage: 'Entry created'
                }))

                this.closeDialog()
            }
        })
    }

    /** UI Events
     ================================================================= */
    handleAccountType = (value) => {
        let QueryAccountTypeID = ACCOUNT_TYPE_CASH
        if (!(this.props.payment === 'send')) {//income
            QueryAccountTypeID = PaymentAccountTypeIncome(QueryAccountTypeID, value)
        } else {//expense
            QueryAccountTypeID = PaymentAccountTypeExpense(QueryAccountTypeID, value)
        }
        return QueryAccountTypeID
    }

    handleInputChange = (name, value) => {
        let fields = this.state.fields
        fields[name].value = value
        if (name === 'Amount') {
            fields.DepositOverpayToID.validate = (this.props?.item?.Amount < value) ? ['empty'] : []
        }
        if (name === 'PaymentTypeID') {
            if (value !== PAYMENT_METHOD_CHECK) {
                fields.CheckNumber.type = 'hidden'
            }

            let QueryAccountTypeID = ACCOUNT_TYPE_CASH
            if (!(this.props.payment === 'send')) {//income
                QueryAccountTypeID = PaymentAccountTypeIncome(QueryAccountTypeID, value)
                if (value == PAYMENT_METHOD_CHECK) {
                    fields.AccountID.value = this.props.defaults.PreferredUndepositedAccountID ? {
                        label: this.props.defaults.PreferredUndepositedAccount,
                        value: this.props.defaults.PreferredUndepositedAccountID
                    } : ''
                    fields.CheckNumber.type = 'text'
                } else {
                    fields.AccountID.value = ''
                }
            } else {//expense
                QueryAccountTypeID = PaymentAccountTypeExpense(QueryAccountTypeID, value)
                fields.AccountID.value = ''
            }

            fields.FactorFee.type = ((Number(value) === 5) || (Number(value) === 6)) ? 'float' : 'hidden'
            fields.IsPercentageFactor.type = ((Number(value) === 5) || (Number(value) === 6)) ? 'button-group' : 'hidden'
            fields.FactorAccountID.type = ((Number(value) === 5) || (Number(value) === 6)) ? 'select-search' : 'hidden'
            fields.FactorFee.validate = ((Number(value) === 5) || (Number(value) === 6)) ? ['empty'] : ['']
            fields.FactorAccountID.validate = ((Number(value) === 5) || (Number(value) === 6)) ? ['empty'] : ['']
            fields.FactorFee.value = ((Number(value) === 5) || (Number(value) === 6)) ? this.props.defaults.FactorFee : ''
            fields.IsPercentageFactor.value = ((Number(value) === 5) || (Number(value) === 6)) ? this.props.defaults.IsPercentageFactor ?? 0 : ''
            fields.FactorAccountID.value = (((Number(value) === 5) || (Number(value) === 6)) && this.props.defaults.FactorExpenseAccountID) ? {
                value: this.props.defaults.FactorExpenseAccountID,
                label: this.props.defaults.FactorExpenseAccount
            } : ''

            this.setState({
                QueryAccountTypeID: QueryAccountTypeID
            })
        }
        this.setState({fields: fields})
    }

    closeDialog = () => {
        this.props.onClose()
    }

    /** Helpers
     ================================================================= */
    isItemBillOrExpense = () => {
        return this.props?.item?.ExpenseTypeID == EXPENSE_TYPE_EXPENSE || this.props?.item?.ExpenseTypeID == EXPENSE_TYPE_BILL
    }

    getFields = () => {
        let prefAccount = getProp(this.props, 'item.PreferredPaymentAccount', false) ?
            {
                label: this.props?.item?.PreferredPaymentAccount !== ' ' ? this.props?.item?.PreferredPaymentAccount : this.props.defaults.PreferredPaymentAccount,
                value: this.props?.item?.PreferredPaymentAccountID ?? this.props.defaults.PreferredPaymentAccountID
            }
            : ''
        let prefMethod = this.props.item?.PreferredPaymentMethodID ?? this.props?.defaults?.PreferredPaymentMethodID
        if (!(this.props.payment === 'send')) {//income
            prefAccount = this.props.defaults.PreferredReceiveAccountID ? {
                label: this.props.defaults.PreferredReceiveAccount,
                value: this.props.defaults.PreferredReceiveAccountID
            } : ''
            prefMethod = this.props?.defaults?.PreferredReceiveMethodID
        }
        return {
            Organization: new Field('Organization', (this.props?.item?.Organization ?? this.props?.item?.Contact), [''], this.isItemBillOrExpense(), 'text', {
                addContainerClass: 'col-span-6',
                label: this.props?.item?.Organization ? 'Organization' : 'Contact',
                /*fieldOptions: (it) => (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                toolTip: this.props.translate("text.view_customer_info"),
                                onClick: this.handleShowOrganizationDetailsClick,
                                isVisible: true !!Object.values(this.state.fields.Organization.value.metadata).length,
                            },

                        ]}
                    />
                )*/
            }),
            Date: new Field('Date', moment().format(DEFAULT_DATABASE_DATETIME_FORMAT), ['empty'], false, 'date', {addContainerClass: 'col-span-6'}),
            Factoring: new Field('Factoring', this.props?.item?.IsFactoringActive ? this.props?.item?.FactoringOrganization : "/", [''], true, this.isItemBillOrExpense() ? 'text' : 'hidden', {
                addContainerClass: 'col-span-6',
                /*fieldOptions: (it) => (
                    <FieldOptions
                        options={[
                            {
                                icon: InformationCircleIcon,
                                toolTip: this.props.translate("text.view_customer_info"),
                                onClick: this.handleShowOrganizationDetailsClick,
                                isVisible: true /!*!!Object.values(this.state.fields.Organization.value.metadata).length*!/
                            },

                        ]}
                    />
                )*/
            }),
            DueDate: new Field('DueDate', this.props?.item?.DueDate ?? '', [''], true, this.isItemBillOrExpense() ? 'date' : 'hidden', {
                addContainerClass: 'col-span-6'
            }),
            PaymentTypeID: new Field('PaymentTypeID', prefMethod, ['empty'], false, 'select', {addContainerClass: 'col-span-6'}),
            AccountID: new Field('AccountID', prefAccount
                , ['empty'], false, 'select-search', {
                    addContainerClass: 'col-span-6',
                    label: (this.props.payment === 'send' ? 'payment_account' : 'deposit_to')
                }),
            CheckNumber: new Field('CheckNumber', '', [''], false, 'hidden', {addContainerClass: 'col-span-6'}),
            InvoiceID: new Field('InvoiceID', getProp(this.props.item, 'InvoiceID', ''), ['empty']),
            ExpenseID: new Field('ExpenseID', getProp(this.props.item, 'ExpenseID', ''), ['empty']),
            Description: new Field('Description', '', [], false, 'textarea'),
            DepositOverpayToID: new Field('DepositOverpayToID', '', [''], false, 'select-search'),
            Amount: new Field('Amount', (this.props?.item?.Amount - this.props?.item?.AmountTransferred), ['float']),
            FactorFee: new Field('FactorFee', Number(prefMethod) === 5 ? this.props.defaults.FactorFee : '', [], false, Number(prefMethod) === 5 ? 'float' : 'hidden', {addContainerClass: 'col-span-6'}),
            FactorAccountID: new Field('FactorAccountID', (Number(prefMethod) === 5 && this.props.defaults.FactorExpenseAccountID) ? {
                value: this.props.defaults.FactorExpenseAccountID,
                label: this.props.defaults.FactorExpenseAccount
            } : '', [], false, (Number(prefMethod) === 5) ? 'select-search' : 'hidden', {addContainerClass: 'col-span-6'}),
            IsPercentageFactor: new Field('IsPercentageFactor', Number(prefMethod) === 5 ? this.props.defaults.IsPercentageFactor ?? 0 : '', [], false, (Number(prefMethod) === 5) ? 'button-group' : 'hidden', {
                data: {
                    0: 'Flat',
                    1: 'Percentage'
                }
            }),
            Cleared: new Field('Cleared', '', [''], false, 'checkbox')
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, show} = this.props

        const selects = {
            PaymentTypeID: (this.props.payment === 'send') ? getLookupWithFilter('PaymentType', 'PaymentTypeID', 'PaymentType', (it) => {
                return it.PaymentTypeID !== PAYMENT_METHOD_FACTOR
            }) : null, // Null will take automatically all values
            AccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    IncludeUndepositedAccount: this.props.payment === 'receive' ? 1 : 0,
                    AccountTypeID: this.state.QueryAccountTypeID ?? this.handleAccountType(this.props?.item?.PreferredPaymentMethodID ?? this.props?.defaults?.PreferredPaymentMethodID)
                },
                searchMap: (it) => ({
                    label: it.AccountNumber + ' ' + it.AccountName,
                    value: it.AccountID
                })
            },
            FactorAccountID: {
                api: 'api/' + Resources.AccountsQuick,
                query: {
                    MainAccountTypeID: MAJOR_ACCOUNT_TYPE_EXPENSE
                },
                searchMap: (item) => ({
                    value: item.AccountID,
                    label: item.AccountNumber + ' ' + item.AccountName
                })
            }
        }
        return (
            <React.Fragment>
                <ModalDefault
                    show={show}
                    widthClass={'max-w-5xl'}
                    title={translate((this.props.payment === 'receive' ? 'modal_heading.receive_payment' : 'modal_heading.send_payment'))}
                    onClose={this.closeDialog}
                    closeButtonLabel={translate('btn.cancel')}
                    onButtonClick={this.handleSubmitButtonClick}
                    buttonLabel={translate((this.props.payment === 'receive' ? 'btn.receive_payment' : 'btn.send_payment'))}
                    limitHeight={false}
                >
                    <div className="p-6">
                        <div className="col grid grid-cols-12 gap-4" key={this.state.QueryAccountTypeID}>
                            {fieldsToHtml(Object.values(Object.assign({}, excludeFields(this.state.fields, ['Amount', 'ExpenseID', 'InvoiceID', 'DepositOverpayToID', 'Description', 'IsPercentageFactor', 'Cleared']))), translate, this.handleInputChange, selects)}
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col-12">
                                    {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.fields, ['Description']))), translate, this.handleInputChange)}
                                    {fieldsToHtml(Object.values(Object.assign({}, includeFields(this.state.fields, ['IsPercentageFactor', 'Cleared']))), translate, this.handleInputChange)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-break mb-0">
                        <div
                            className="grid grid-cols-4 bg-tm-gray-100 text-tm-gray-900 font-semibold px-6 py-2 mt-4">
                            <p
                                className="pl-3">{(this.props.payment === 'send') ? translate('table.ExpenseNumber') : translate('table.InvoiceNumber')}</p>
                            {(this.props.payment === 'receive') && (
                                <p className="pl-3">{translate('table.DueDate')}</p>
                            )}
                            <p className="pl-3">{translate('table.Amount')}</p>
                            <p className="pl-3">{translate('table.Amount')} *</p>
                        </div>
                    </div>
                    <div className="">
                        <div className="grid grid-cols-4 bg-tm-gray-50 px-6 py-2">
                            <div
                                className="pl-3 flex justify-start items-center">{this.props?.item?.AutoCounter}</div>
                            {(this.props.payment === 'receive') && (
                                <div
                                    className="pl-1 flex justify-start items-center">{toFrontDateNoTime(this.props?.item?.DueDate)}</div>
                            )}
                            <div
                                className="pl-3 flex justify-start items-center">{genericMoneyFormatter(this.props?.item?.Amount)}</div>
                            <div className="pl-3">
                                <FieldText
                                    addClass="form-control"
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.Amount}
                                />
                            </div>
                        </div>
                    </div>

                    {((this.props?.item?.Amount - this.props?.item?.AmountTransferred) < (this.state.fields.Amount.value)) && (
                        <div className="px-6 py-4">
                            <FieldContainer
                                item={this.state.fields.DepositOverpayToID}
                                translate={translate}
                            >
                                <FieldDropdownSelect
                                    onChange={this.handleInputChange}
                                    {...this.state.fields.DepositOverpayToID}
                                    addClass="form-control"
                                    defaultOptions={true}
                                    placeholder={''}
                                    menuPlacement={"top"}
                                    loadOptions={
                                        (inputValue, callback) => {
                                            axios.get(
                                                Env.getApiUrl('api/' + Resources.AccountsQuick, {query: inputValue}),
                                                {
                                                    headers: {
                                                        'Authorization': 'Bearer ' + getJWT().access_token
                                                    }
                                                }
                                            )
                                                .then((response) => {
                                                    const result = processResponse(response)
                                                    if (result && result.status === 0) {
                                                        const list = result.data.list.map((it) => {
                                                            return {
                                                                label: it.AccountName,
                                                                value: it.AccountID
                                                            }
                                                        })
                                                        callback(list)
                                                    }
                                                })
                                        }
                                    }
                                />
                            </FieldContainer>
                        </div>

                    )}

                    {this.props?.item?.AmountTransferred !== 0 && !!this.props?.item?.AmountTransferred && (
                        <div
                            className="m-2 text-base font-bold"
                        >
                            <div>Transferred so far: {genericMoneyFormatter(this.props.item.AmountTransferred)}.</div>
                        </div>
                    )}
                </ModalDefault>
            </React.Fragment>
        )
    }
}
