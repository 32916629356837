import React, {useEffect, useState} from "react";
import CarriersImportDialogNumber from "./carriers-import-dialog-number";
import {useDispatch, useSelector} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import {getDialogResource, resetDialogResource} from "../../../../data/actions/dialogResource";
import CarriersImportDialogData from "./carrier-report-dialog";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";

export default function CarriersImportDialog({translate, onClose, onImportCarrierClick}) {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.dialogResource);

    const carrierData = resource?.data?.[0];

    /** Const
     ================================================================= */
    const isLoading = resource.isLoading;
    const message = resource?.data?.Message ?? null;
    const [hasCarrierData, setHasCarrierData] = useState(false);

    /** Data events
     ================================================================= */
    const fetchCarrier = (type, number) => {
        dispatch(getDialogResource({
            user: LocalStorage.get('user'),
            resource: Resources.MyCarriersIntegrationsPreview,
            query: {[type]: number},
            errorMessage: true
        }))
    }

    /** UI events
     ================================================================= */
    const handleCloseClick = () => {
        dispatch(resetDialogResource());
        setHasCarrierData(false);
        onClose();
    }

    useEffect(() => {
        setHasCarrierData(resource?.data?.[0]?.DotNumber || resource?.data?.[0]?.DocketNumber);
    }, [resource])

    return (
        <React.Fragment>
            <div className="relative">
                {!!message && (
                    <div className="p-1">
                        <InfoBar type="danger">
                            {message}
                        </InfoBar>
                    </div>
                )}

                {!hasCarrierData && (
                    <CarriersImportDialogNumber
                        translate={translate}
                        fetchCarrier={fetchCarrier}
                        isLoading={isLoading}
                        resource={resource}
                    />
                )}

                {/* Dialog Footer is inside this component*/}
                {hasCarrierData && (
                    <CarriersImportDialogData
                        data={carrierData}
                        dataSource={"MyCarrierImport"}
                        translate={translate}
                        setHasCarrierData={setHasCarrierData}
                        onImportCarrierClick={onImportCarrierClick}
                        onClose={onClose}
                    />
                )}
            </div>
        </React.Fragment>
    )
}
