import React, {useEffect, useRef, useState} from 'react'
import {
    BuildingOfficeIcon,
    DocumentIcon,
    InformationCircleIcon,
    PencilSquareIcon,
    PhoneIcon
} from '@heroicons/react/24/outline'
import {Field, FieldsManager} from '../../../../data/services/fields'
import {resourceIsDeleted} from '../../../util/util-helpers'
import {fieldsToHtml, fillFieldsFromData, includeFields} from '../../../util/util-fields'
import {checkPerm, classNames, getLookup, getProp, handleMaskedFocus} from '../../../util/util-helpers'
import LocalStorage from '../../../util/localStorage'
import {cloneDeep, scrollErrorIntoView} from '../../../util/util-vanilla'
import XMarkIcon from '@heroicons/react/20/solid/XMarkIcon'
import InfoParagraph from '../../../components/info-paragraph'
import Resources from '../../../../data/services/resources'
import {
    DEFAULT_DATABASE_DATETIME_FORMAT,
    DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS,
    feeTypes,
    FIELD_MASK_PHONE,
    UPDATE_PERM
} from '../../../../util/util-constants'
import LoaderSmall from "../../../components/loader/loader-small";
import DropZoneAlt from "../../../components/dropzone/drop-zone-alt";
import {download} from "../../../../data/actions/download";
import {useDispatch, useSelector} from "react-redux";
import FileViewer from "../../../components/file-viewer/components";
import moment from "moment";
import FileList from "../../../components/dropzone/file-list";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import Cog6ToothIcon from "@heroicons/react/24/outline/esm/Cog6ToothIcon";
import DialogTabs from "../../tabs-navigation/dialog-tabs";
import {HourglassIcon} from "../../../../data/themes/icons";
import MobileTabs from "../../tabs-navigation/mobile-tabs";
import {getCountryCodes} from "../../../util/countryCodes";
import CardSubTitle from "../../card/card-sub-title";
import Env from "../../../../util/env";
import ContactPhoto from "./contact-photo";
import Accordion from "../../accordion";
import ModalHeader from "../../modal/modal-header";
import ModalFooter from "../../modal/modal-footer";
import {getJWT} from "../../../util/util-auth";

const phoneTypes = getLookup('PhoneType')

// Move to util
const scrollToBottom = (el) => {
    el.scrollTop = el.scrollHeight
}

const scrollToTop = (el) => {
    el.scrollTop = 0
}

export default function ContactForm({
                                        data,
                                        onClose,
                                        clearErrorData,
                                        errorMessages = [],
                                        onSubmit,
                                        onMount,
                                        isLoading,
                                        forceSystemUser,
                                        hasSalesSection = false,
                                        hasDepartmentSection = false,
                                        isOrganizationHiddenTab = false,
                                        translate,
                                        onViewHistory,
                                        isEdit,
                                        isSystemUser
                                    }) {

    useEffect(() => {
        !!onMount && onMount()
    }, [])

    const [avatar, setAvatar] = useState(undefined)
    const [isStateDirty, setIsStateDirty] = useState(false)
    const [hasImagePath, setHasImagePath] = useState(!!data?.ImagePath);

    const maxFiles = 1;

    const dialogBodyScroll = useRef(null)
    let focusRef = useRef([])

    const getTabs = (item = null) => {
        return [
            {
                id: 1,
                name: 'Info',
                resource: 'Info',
                icon: InformationCircleIcon,
                visible: true,
                invalid: false,
                fieldsToValidate: ['FirstName', 'LastName', 'Email', 'PhoneNumber', 'Organization', 'OrganizationID'],
                current: true
            },
            {
                id: 2,
                name: 'Manage',
                resource: 'MoreContacts',
                icon: PhoneIcon,
                visible: true,
                invalid: false,
                fieldsToValidate: ['PhoneTypeID']
            },
            {
                id: 3,
                name: 'ContactSystemOptions',
                resource: 'ContactSystemOptions',
                icon: Cog6ToothIcon,
                visible: !!isSystemUser && checkPerm(Resources.UserPermissions, UPDATE_PERM),
                invalid: false,
                fieldsToValidate: ['Roles']
            },
            {
                id: 4,
                name: 'Notes',
                resource: 'Notes',
                icon: PencilSquareIcon,
                visible: !hasDepartmentSection,
                invalid: false,
                fieldsToValidate: []
            },
            {
                id: 5,
                name: 'Organization',
                resource: 'Organization',
                icon: BuildingOfficeIcon,
                visible: !isOrganizationHiddenTab,
                invalid: false,
                fieldsToValidate: []
            },
            {
                id: 5,
                name: 'Document',
                resource: 'ContactDocuments',
                icon: DocumentIcon,
                visible: true,
                invalid: false,
                fieldsToValidate: []
            },
            {
                id: 7,
                name: 'ApproveLimit',
                resource: 'ApproveLimit',
                icon: CheckIcon,
                visible: !!isSystemUser,
                invalid: false,
                fieldsToValidate: []
            },
            {
                id: 6,
                name: 'ContactsHistory',
                resource: 'ContactsHistory',
                icon: HourglassIcon,
                visible: isEdit,
                invalid: false,
                fieldsToValidate: []
            },
        ]
    }

    const getFields = (item = null) => {
        if (!!item && !!item?.Phones) {
            const primaryPhone = item.Phones.find(phone => phone.IsPrimary)
            if (!!primaryPhone) {
                item.AreaCode = primaryPhone.AreaCode
                item.PhoneNumber = primaryPhone.PhoneNumber
                item.PhoneExtension = primaryPhone.PhoneExtension
                item.PhoneTypeID = primaryPhone.PhoneTypeID
            }
        }

        let fieldTemplates = {
            PersonalTitleID: new Field('PersonalTitleID', '', [''], false, 'select', {addContainerClass: 'col-span-4'}),


            FirstName: new Field('FirstName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-4 col-start-1'}),
            MiddleName: new Field('MiddleName', '', [], false, 'text', {addContainerClass: 'col-span-4'}),
            LastName: new Field('LastName', '', ['empty'], false, 'text', {addContainerClass: 'col-span-4'}),
            Nickname: new Field('Nickname', '', [], false, 'text', {addContainerClass: 'col-span-4'}),
            Title: new Field('Title', '', [''], false, 'text', {
                addContainerClass: 'col-span-4',
                label: translate('job_title')
            }),

            DateOfBirth: new Field('DateOfBirth', '', [], false, 'date', {addContainerClass: 'col-span-4'}, {placement: "bottom-end"}),

            EmailEnableToggle: new Field('EmailEnableToggle', 0, [], false, 'hidden', {
                addContainerClass: 'col-span-12',
                note: translate("note.EmailEnableToggle")
            }),
            Email: new Field('Email', '', ['empty', 'email'], !!item?.Email, 'text', {addContainerClass: 'col-span-12'}),
            PhoneTypeID: new Field('PhoneTypeID', '', [''], false, 'select', {addContainerClass: 'col-span-6'}, {
                menuPlacement: 'top'
            }),
            AreaCode: new Field('AreaCode', LocalStorage.get('user')?.Office?.CountryCode ?? '', [], false, 'select', {addContainerClass: 'col-span-6'}, {menuPlacement: 'top'}),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {addContainerClass: 'col-start-1 col-span-6'}, {
                mask: FIELD_MASK_PHONE,
                showMask: true,
                onFocus: handleMaskedFocus
            }), // add isPrimary
            PhoneExtension: new Field('PhoneExtension', '', [''], false, 'text', {addContainerClass: 'col-span-6'}),

            PrivateEmail: new Field('PrivateEmail', '', ['email'], false, 'text', {hideLabel: true}, {placeholder: 'john@doe.com'}),

            Notes: new Field('Notes', '', [], false, 'textarea', {hideLabel: true}, {rows: 25}),

            MemberOfOrganization: new Field('MemberOfOrganization', 0, [], false, 'checkbox', {addContainerClass: 'col-span-full'}),
            Organization: new Field('Organization', '', !!item?.MemberOfOrganization ? ['empty'] : [''], false, 'hidden', {addContainerClass: 'col-span-full'}),
            OrganizationID: new Field('OrganizationID', '', !!item?.MemberOfOrganization ? ['empty'] : [''], false, 'hidden', {addContainerClass: 'col-span-full'}),
            DepartmentID: new Field('DepartmentID', '', [''], false, 'hidden', {addContainerClass: 'col-span-full'}),
        }

        fieldTemplates = fillFieldsFromData(fieldTemplates, item)

        if (!!item) {
            if (!item.IsSystemUser) {
                fieldTemplates.Email.disabled = false
            } else {
                fieldTemplates.EmailEnableToggle.type = 'checkbox'
            }

            fieldTemplates.MemberOfOrganization.disabled = true
            fieldTemplates.Organization.disabled = true
            fieldTemplates.DepartmentID.disabled = true
            fieldTemplates.OrganizationID.disabled = true
            if ("Carriers" in item) {
                fieldTemplates.MemberOfOrganization.value = true
                fieldTemplates.Organization.value = Resources.CarriersQuick
                fieldTemplates.OrganizationID.value = {
                    label: item.Carriers[0].Carrier,
                    value: item.Carriers[0].CarrierID,
                }
            }
            if ("Customers" in item) {
                fieldTemplates.MemberOfOrganization.value = true
                fieldTemplates.Organization.value = Resources.CustomersQuick
                fieldTemplates.OrganizationID.value = {
                    label: item.Customers[0].Customer,
                    value: item.Customers[0].CustomerID,
                }
            }
            if ("Vendors" in item) {
                fieldTemplates.MemberOfOrganization.value = true
                fieldTemplates.Organization.value = Resources.VendorsQuick
                fieldTemplates.OrganizationID.value = {
                    label: item.Vendors[0].Vendor,
                    value: item.Vendors[0].VendorID,
                }
            }
            if ("Carriers" in item || "Customers" in item || "Vendors" in item) {
                fieldTemplates.Organization.type = 'select'
                fieldTemplates.DepartmentID.type = 'select'
                fieldTemplates.OrganizationID.type = 'select-search'
            }
        }

        return fieldTemplates
    }

    const getFieldsAdvanced = (item = null) => {
        const fieldTemplates = {
            HighImportance: new Field('HighImportance', 0, [], false, 'switch'),
            IsSystemUser: new Field('IsSystemUser', forceSystemUser ? 1 : 0, [], !!forceSystemUser, (forceSystemUser && !data) ? 'hidden' : 'switch'),
            Roles: new Field('Roles', null, item?.IsSystemUser || forceSystemUser ? ['empty'] : [''], false, 'multi-select'),
            ValidationType: new Field('ValidationType', 1, [], false, item ? 'hidden' : 'select'),
            Groups: new Field('Groups', null, [''], false, 'select-search', {}, {multi: true, menuPlacement: 'top'}),
        }

        let filledFields = fillFieldsFromData(fieldTemplates, item)

        if (!!item) {
            filledFields.Groups.value = getProp(item, 'Groups', []).map(group => {
                return {
                    value: group.ContactGroupID,
                    label: group.ContactGroupName
                }
            })

            filledFields.Roles.value = getProp(item, 'Roles', []).map(role => {
                return {
                    value: role.role_id,
                    label: role.role_name
                }
            })

            if (!filledFields.Roles.value.length) {
                filledFields.Roles.value = undefined
            }
        }

        return filledFields

    }

    const getPhoneFields = (item = null) => {
        const index = focusRef.current.length

        const fieldTemplates = {
            PhoneTypeID: new Field('PhoneTypeID', 0, ['empty'], false, 'select', {}, {
                menuPlacement: 'top',
                innerRef: el => focusRef.current[index] = el
            }),
            AreaCode: new Field('AreaCode', LocalStorage.get('user')?.Office?.CountryCode ?? '', [''], false, 'select'),
            PhoneNumber: new Field('PhoneNumber', '', [''], false, 'mask', {}, {mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}),
            PhoneExtension: new Field('PhoneExtension', '', []),

            isNew: new Field('isNew', item?.isNew, [], true, 'meta'),
            isFlaggedForDeletion: new Field('isFlaggedForDeletion', false, [''], false, 'meta'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getCarrierSalesFields = (item = null) => {
        return ({
            FeeTypeID: new Field('FeeTypeID', '', ['empty'], false, 'select', {}, {
                values: Object.values(feeTypes).reduce((memo, it, i) => {
                    memo[i + 1] = translate(it)
                    return memo
                }, {}),
                menuPlacement: "top"
            }),
            Amount: new Field('Amount', '', ['float_up_to_12_not_require']),
        })
    }

    const getDepartmentFields = (item = null) => {
        return ({
            DepartmentID: new Field("DepartmentID", '', ['empty'], false, 'select', {addContainerClass: "col-span-full"}),
            Notes: new Field("Notes", '', [], false, 'textarea', {addContainerClass: "col-span-full"}),
        })
    }

    const getApproveLimitFields = (item = {}) => {
        let fieldTemplates = {
            CanApproveExpense: new Field("CanApproveExpense", '', [], false,'checkbox', {addContainerClass: "col-span-full"}),
            MaxExpenseValue: new Field("MaxExpenseValue", '', [], !item.CanApproveExpense, 'money', {addContainerClass: "col-span-full"}),
            CanIssueMoneyCode: new Field("CanIssueMoneyCode", '', [], false, 'checkbox', {addContainerClass: "col-span-full"}),
            MaxMoneyCodeValue: new Field("MaxMoneyCodeValue", '', [], !item.CanIssueMoneyCode, 'money', {addContainerClass: "col-span-full"}),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    const getPhonesFromData = () => {
        if (!!data?.Phones) {
            return data.Phones.filter(phone => !phone.IsPrimary).map(phone => getPhoneFields(phone))
        }
    }

    const [tabs, setTabs] = useState(getTabs())

    const [fields, setFields] = useState(getFields());
    const [fieldsAdvanced, setFieldsAdvanced] = useState(getFieldsAdvanced());
    const [carrierSalesFields, setCarrierSalesFields] = useState(getCarrierSalesFields());
    const [departmentFields, setDepartmentFields] = useState(getDepartmentFields());
    const [approveLimitFields, setApproveLimitFields] = useState(getApproveLimitFields());
    const [hasDocumentPath, setHasDocumentPath] = useState(!!data?.DocumentPath);

    useEffect(() => {
        if (!!data) {
            setTabs(getTabs(data))
            setFields(getFields(data))
            setFieldsAdvanced(getFieldsAdvanced(data))
            setApproveLimitFields(getApproveLimitFields(data))
            setPhoneFields(getPhonesFromData())
        }
    }, [data])

    useEffect(() => {
        setHasDocumentPath(data?.DocumentPath)
    }, [!!data?.DocumentPath])

    useEffect(() => {
        setHasImagePath(data?.ImagePath)
    }, [!!data?.ImagePath])
    const selects = {
        PersonalTitleID: getLookup('PersonalTitle'),
        Groups: {
            api: 'api/' + Resources.ContactGroupsQuick,
            query: {},
            searchMap: (item) => ({
                label: item.ContactGroupName,
                value: item.ContactGroupID
            })
        },
        Roles: getLookup('PermRoles'),
        ValidationType: {
            1: 'Email',
            2: 'SMS',
            3: 'Temporary password'
        }
    }

    const [phoneFields, setPhoneFields] = useState([])

    const handleInputChange = (name, value) => {
        let fieldsUpdate = cloneDeep(fields)

        if (name === 'EmailEnableToggle') {
            fieldsUpdate.Email.disabled = !value
            if (!!data && !value) {
                fieldsUpdate.Email.value = data.Email
            }
        }

        if ('Email' === name && fieldsUpdate.Email.errorMessage) {
            clearErrorData()
            fieldsUpdate.Email.errorMessage = ''
        }

        if (name === 'MemberOfOrganization') {
            fieldsUpdate.Organization.type = !!value ? 'select' : 'hidden'
            fieldsUpdate.DepartmentID.type = !!value ? 'select' : 'hidden'
            if (!value) {
                fieldsUpdate.OrganizationID.type = 'hidden'
            }
            if (!!value && fieldsUpdate.Organization.value) {
                fieldsUpdate.OrganizationID.type = 'select-search'
                fieldsUpdate.OrganizationID.metadata.label = fieldsUpdate.Organization.value
            }

            fieldsUpdate.Organization.validate = !!value ? ['empty'] : ['']
            fieldsUpdate.OrganizationID.validate = !!value ? ['empty'] : ['']
            fieldsUpdate.Organization.value = !!value ? fieldsUpdate.Organization.value : ''
            fieldsUpdate.OrganizationID.value = !!value ? fieldsUpdate.OrganizationID.value : ''
        }

        if (name === 'Organization') {
            fieldsUpdate.OrganizationID.value = ''
            fieldsUpdate.OrganizationID.type = !!value ? 'select-search' : 'hidden'
            fieldsUpdate.OrganizationID.metadata.label = value
            if (!fieldsUpdate.OrganizationID.props) {
                fieldsUpdate.OrganizationID.props = {}
            }
            fieldsUpdate.OrganizationID.props.key = value
        }

        fieldsUpdate[name].errorMessage = "";

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value)
        setFields(fieldsUpdate)
        setIsStateDirty(true)
    }

    const handleAdvancedInputChange = (name, value) => {
        let fieldsUpdate = cloneDeep(fieldsAdvanced)

        if ('IsSystemUser' === name) {
            fieldsUpdate.Roles.validate = !!value ? ['empty'] : ['']
            fieldsUpdate.Roles.errorMessage = null
        }

        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value)
        setFieldsAdvanced(fieldsUpdate)
        setIsStateDirty(true)
    }

    const handlePhoneFieldsChange = (index, name, value) => {
        let fieldsUpdate = cloneDeep(phoneFields)
        fieldsUpdate[index] = FieldsManager.updateField(fieldsUpdate[index], name, value)
        setPhoneFields(fieldsUpdate)
        setIsStateDirty(true)
    }

    const handleCarrierSalesInputChange = (name, value) => {
        let fieldsUpdate = cloneDeep(carrierSalesFields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);
        setCarrierSalesFields(fieldsUpdate);
        setIsStateDirty(true);
    }

    const handleDepartmentInputChange = (name, value) => {
        let fieldsUpdate = cloneDeep(departmentFields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);
        setDepartmentFields(fieldsUpdate);
        setIsStateDirty(true);
    }
    const handleApproveLimitInputChange = (name, value) => {
        let fieldsUpdate = cloneDeep(approveLimitFields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);

        if (name === 'CanApproveExpense') {
            fieldsUpdate.MaxExpenseValue.disabled = !value
        }

        if (name === 'CanApproveExpense' && !value) {
            fieldsUpdate.MaxExpenseValue.value = ''
        }

        if (name === 'CanIssueMoneyCode') {
            fieldsUpdate.MaxMoneyCodeValue.disabled = !value
        }

        if (name === 'CanIssueMoneyCode' && !value) {
            fieldsUpdate.MaxMoneyCodeValue.value = ''
        }

        setApproveLimitFields(fieldsUpdate);
        setIsStateDirty(true);
    }

    const handleAddPhoneClick = () => {
        let fieldsUpdate = cloneDeep(phoneFields)
        fieldsUpdate.push(getPhoneFields({isNew: true}))
        setPhoneFields(fieldsUpdate)

        if (focusRef.current.length) {
            setTimeout(() => {
                focusRef.current[focusRef.current.length - 1].focus()
                scrollToBottom(dialogBodyScroll.current)
            }, 200)
        }
    }

    const handleDeletePhoneClick = (index) => {
        let fieldsUpdate = cloneDeep(phoneFields)

        if (!!phoneFields[index].isNew.value) {
            fieldsUpdate.splice(index, 1)
        } else {
            fieldsUpdate[index] = FieldsManager.updateField(fieldsUpdate[index], 'isFlaggedForDeletion', true)
        }

        setPhoneFields(fieldsUpdate)
    }

    const handleUndoRemovePhoneClick = (index) => {
        let fieldsUpdate = cloneDeep(phoneFields)
        fieldsUpdate[index] = FieldsManager.updateField(fieldsUpdate[index], 'isFlaggedForDeletion', false)
        setPhoneFields(fieldsUpdate)
    }

    const handleTabChange = (name) => {
        validateTabs()

        if (name === "ContactsHistory") {
            onViewHistory()
        } else {
            setTabs(tabs.map((it) => {
                    it.current = it.name === name
                    it.invalid = it.invalid || !it.name === name // remove invalid classes on select
                    return it
                })
            )
        }
        scrollToTop(dialogBodyScroll.current)
    }

    const selectedTab = tabs.find(it => !!it.current)
    const invalidTab = tabs.find(it => it.invalid)

    const validateTabs = () => {
        let areAllTabsValid = true

        tabs.filter(tab => tab.fieldsToValidate.length).map(tab => {
            tab.invalid = false

            if (tab.name === 'Info') {
                let areDepartmentFieldsValid = true;
                let areCarrierSalesFieldsValid = true;

                let validFields = cloneDeep(fields);
                let validCarrierSalesFields = cloneDeep(carrierSalesFields);
                let validDepartmentFields = cloneDeep(departmentFields);
                validFields = FieldsManager.validateFields(validFields, tab.fieldsToValidate)

                if (hasSalesSection) {
                    validCarrierSalesFields = FieldsManager.validateFields(validCarrierSalesFields);
                    areCarrierSalesFieldsValid = FieldsManager.checkFieldsForErrors(validCarrierSalesFields)
                    if (!areCarrierSalesFieldsValid) {
                        scrollErrorIntoView(validCarrierSalesFields)
                    }
                }

                if (hasDepartmentSection) {
                    validDepartmentFields = FieldsManager.validateFields(validDepartmentFields);
                    areDepartmentFieldsValid = FieldsManager.checkFieldsForErrors(validDepartmentFields)
                    if (!areDepartmentFieldsValid) {
                        scrollErrorIntoView(validDepartmentFields)
                    }
                }

                tab.invalid = !FieldsManager.checkFieldsForErrors(validFields, tab.fieldsToValidate)
                    || !areCarrierSalesFieldsValid
                    || !areDepartmentFieldsValid
                setFields(validFields);
                setCarrierSalesFields(validCarrierSalesFields);
                setDepartmentFields(validDepartmentFields);
            }

            if (tab.name === 'Manage') {
                let phoneFieldsValidation = phoneFields.map(phone => {
                    phone = FieldsManager.validateFields(phone)
                    tab.invalid = !FieldsManager.checkFieldsForErrors(phone)

                    return phone
                })

                if (!!tab.invalid) {
                    areAllTabsValid = false
                }

                setPhoneFields(phoneFieldsValidation)
            }

            if (tab.name === 'ContactSystemOptions') {
                let validFields = cloneDeep(fieldsAdvanced)
                validFields = FieldsManager.validateFields(validFields, tab.fieldsToValidate)
                tab.invalid = !FieldsManager.checkFieldsForErrors(validFields, tab.fieldsToValidate)
                setFieldsAdvanced(validFields)
            }

            if (!!tab.invalid) {
                areAllTabsValid = false
            }

            return tab
        })

        return areAllTabsValid
    }

    const handleSaveClick = () => {
        if (validateTabs()) {
            let phones = cloneDeep(phoneFields)
            let primaryPhone = {
                AreaCode: fields.AreaCode,
                PhoneTypeID: fields.PhoneTypeID,
                PhoneNumber: fields.PhoneNumber,
                PhoneExtension: fields.PhoneExtension,
            }

            phones.unshift(primaryPhone)

            phones = phones.filter(phone => !phone?.isFlaggedForDeletion?.value).map((phone, index) => {
                phone = FieldsManager.getFieldKeyValues(phone)

                if (!!index) {
                    phone.IsPrimary = 0
                } else {
                    phone.IsPrimary = 1
                }

                return phone
            })

            let params = FieldsManager.getFieldKeyValues(Object.assign({}, fields, fieldsAdvanced))

            delete params.AreaCode
            delete params.PhoneTypeID
            delete params.PhoneNumber
            delete params.PhoneExtension

            params.IsSystemUser = !!fieldsAdvanced.IsSystemUser.value ? 1 : 0 // must override because getFieldKeyValues will not pass value when field is hidden
            params.Phones = phones
            params.id = data?.ContactID

            let approveLimitParams = FieldsManager.getFieldKeyValues(Object.assign({}, approveLimitFields));
            params = Object.assign({}, params, approveLimitParams);

            if (!hasDocumentPath) {
                params.DocumentPath = 1
            }

            if (!hasImagePath) {
                params.ImagePath = 1
            }

            if (hasSalesSection) {
                let carrierSalesParams = FieldsManager.getFieldKeyValues(Object.assign({}, carrierSalesFields));
                params = Object.assign({}, params, carrierSalesParams);
            }

            if (hasDepartmentSection) {
                let departmentParams = FieldsManager.getFieldKeyValues(Object.assign({}, departmentFields));
                params = Object.assign({}, params, departmentParams);
            }

            onSubmit(params, avatar, files)
        } else {
            let validFields = cloneDeep(fieldsAdvanced)
            validFields = FieldsManager.validateFields(validFields)

            let validFieldsInfo = cloneDeep(fields)
            validFieldsInfo = FieldsManager.validateFields(validFieldsInfo)

            let validFieldsInfoErrors =  FieldsManager.checkFieldsForErrors(validFieldsInfo,['FirstName','LastName','Email'])


            if (!!validFieldsInfoErrors && validFields.Roles.errorMessage) {
                handleTabChange("ContactSystemOptions");
            }
        }
    }

    const handleGetImage = (file) => {
        setAvatar(file)
        setIsStateDirty(true)

    }

    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    const [files, setFiles] = useState([]);
    const handleSelectDocuments = (addedFiles) => {
        setFiles(addedFiles);
        setIsStateDirty(true)
    }

    const handleRemoveFile = () => {
        setFiles([]);
    }

    useEffect(() => {
        if (!!errorMessages && !!errorMessages.length) {
            let fieldsUpdate = cloneDeep(fields)

            Object.values(errorMessages).forEach(error => {
                if (!!fieldsUpdate[error.field]) {
                    fieldsUpdate[error.field].errorMessage = [error.message]
                }
            })

            if (!!errorMessages.find(it => it.field === 'Email')) {
                let updateTabs = cloneDeep(tabs)

                updateTabs.map((it) => {
                    if (it.name === 'Info') {
                        it.invalid = true
                    }

                    return it
                })

                setTabs(updateTabs)
            }

            setFields(fieldsUpdate)
        }
    }, [errorMessages])

    const OrganizationMetadata = {
        Organization: {
            [Resources.CustomersQuick]: 'Customer',
            [Resources.CarriersQuick]: 'Carrier',
            [Resources.VendorsQuick]: 'Vendor'
        },
        OrganizationID: {
            api: 'api/' + fields.Organization.value,
            query: {offset: 0, limit: 50},
            searchMap: (item) => ({
                value: item.OrganizationID,
                label: item.LegalName
            })
        }
    }

    const handleDownloadDocumentsClick = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: Resources.ContactDocuments,
            query: Object.assign({}, {
                ContactID: data?.ContactID,
                name: data?.DocumentPath
            }),
            notificationMessage: 'Successfully downloaded document!'
        }))
    }

    useEffect(() => {
        if (resourceIsDeleted(resource)) {
            setFiles([]);
        }
    }, [resource])

    return (
        <React.Fragment>
            <ModalHeader
                title={!!data ? <span>Update <span
                    className="font-bold">{data.FirstName + ' ' + data.LastName}</span> {!!data.UserID ? 'system user' : 'contact'}</span> : !!isSystemUser ? 'Create system user' : 'Create contact'}
                onClose={onClose}
            />

            <div className="relative">
                {isLoading && (
                    <div
                        className="inset-0 absolute bg-popup flex items-center justify-center z-10">
                        <LoaderSmall/>
                    </div>
                )}

                <div ref={dialogBodyScroll} className="flex h-dialog-body min-h-[42rem] scroll-smooth">
                    <div
                        className="pr-3 border-tm-gray-200 border-r hidden lg:block w-52 sticky top-0">
                        <DialogTabs
                            tabs={tabs}
                            onTabChange={handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div className="w-full lg:w-[calc(100%-13rem)]">
                        <div className="lg:hidden mb-7 px-6 pt-2 pb-5 border-tm-gray-200 border-b text-tm-gray-900">
                            <p className="text-base">{translate('text.tabs')}</p>
                            <MobileTabs
                                className=""
                                tabs={tabs}
                                onTabChange={handleTabChange}
                                translate={translate}
                            />
                        </div>

                        <div className="px-6 pt-6 relative h-full">
                            {selectedTab.name === 'Info' && (
                                <React.Fragment>
                                    <CardSubTitle
                                        className="mb-6"
                                        subtitle={translate('text.basic_info')}
                                        strapline={translate('text.basic_info_strapline')}
                                    />

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className={"flex justify-end col-span-6 col-start-7 row-start-1"}>
                                            <div className={"relative"}>
                                                <ContactPhoto
                                                    imageID={hasImagePath ? data?.ContactID : null}
                                                    lastUpdated={data?.CreateUpdateDate}
                                                    onGetImage={(file) => handleGetImage(file)}
                                                    stateChangerImagePath={setHasImagePath}
                                                    stateChangerIsDirty={setIsStateDirty}
                                                    avatarSet={setAvatar}
                                                    hasImage={hasImagePath}
                                                    translate={translate}
                                                />
                                            </div>
                                        </div>
                                        {fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['FirstName', 'LastName', 'DateOfBirth', 'PersonalTitleID', 'Title', 'MiddleName', 'Nickname']))), translate, handleInputChange)}
                                    </div>

                                    <div className="mb-6 mt-10">
                                        <CardSubTitle
                                            className="mb-6"
                                            subtitle={translate('text.contact_info')}
                                            strapline={translate('text.contact_info_strapline')}
                                        />

                                        <div className="grid grid-cols-2 gap-4 mt-6">
                                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['EmailEnableToggle', 'Email', 'PhoneNumber', 'PhoneTypeID', 'PhoneExtension', 'AreaCode']))), translate, handleInputChange, {AreaCode: getCountryCodes()})}
                                        </div>
                                    </div>

                                    {hasSalesSection && (
                                        <div className="mb-6 mt-10">
                                            <CardSubTitle
                                                subtitle={translate('text.contact_sales_section_title')}
                                                // strapline={translate('text.contact_sales_section_subtitle')}
                                            />

                                            <div className="grid grid-cols-2 gap-4 mt-4">
                                                {fieldsToHtml(Object.values(carrierSalesFields), translate, handleCarrierSalesInputChange)}
                                            </div>
                                        </div>
                                    )}

                                    {hasDepartmentSection && (
                                        <div className="mb-6 mt-10">
                                            <CardSubTitle
                                                subtitle={translate('text.contact_department_section_title')}
                                            />

                                            <div className="grid grid-cols-2 gap-4 mt-4">
                                                {fieldsToHtml(Object.values(departmentFields), translate, handleDepartmentInputChange)}
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}

                            {selectedTab.name === 'Manage' && (
                                <React.Fragment>
                                    <div className="mb-6">
                                        <CardSubTitle
                                            subtitle={translate('text.home_email')}
                                            strapline={translate('text.home_email_strapline')}
                                        />

                                        <div className="grid grid-cols-1 gap-4">
                                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['PrivateEmail']))), translate, handleInputChange)}
                                        </div>
                                    </div>

                                    <div className="mb-6 mt-10">
                                        <CardSubTitle
                                            className="mb-6"
                                            subtitle={translate('text.more_phones')}
                                            strapline={translate('text.more_phones_strapline')}
                                        />

                                        {phoneFields.map((item, index) => {
                                            let isFlaggedForDeletion = item.isFlaggedForDeletion.value
                                            return (
                                                <div key={index} className="p-4 bg-tm-gray-100 rounded-md mb-6">
                                                    <div
                                                        className="pb-2 mb-4 border-b border-tm-gray-200 flex">
                                                        <h2
                                                            className={classNames(isFlaggedForDeletion ? 'line-through' : '', 'text-base font-medium text-tm-gray-900')}
                                                        >
                                                            {index + 1}. {!!phoneTypes && phoneTypes[phoneFields[index]?.PhoneTypeID?.value]}
                                                        </h2>

                                                        {!isFlaggedForDeletion && (
                                                            <button
                                                                onClick={() => handleDeletePhoneClick(index)}
                                                                className="ml-auto"
                                                            >
                                                                <XMarkIcon className="w-h-5 w-5"/>
                                                            </button>
                                                        )}
                                                    </div>

                                                    <div className="grid gap-4 grid-cols-2">
                                                        {fieldsToHtml(Object.values(Object.assign({}, phoneFields[index])), translate, (name, value) => handlePhoneFieldsChange(index, name, value), {AreaCode: getCountryCodes()})}
                                                    </div>

                                                    {isFlaggedForDeletion && (
                                                        <InfoParagraph type="warning" className="mt-4">
                                                            <div className="flex items-center">
                                                                <p>This phone will be removed after save action.</p>
                                                                <button
                                                                    onClick={() => handleUndoRemovePhoneClick(index)}
                                                                    className="btn btn-outline py-1 ml-auto border-yellow-500 hover:border-yellow-700 hover:text-yellow-700 text-yellow-600">{translate('btn.undo')}
                                                                </button>
                                                            </div>
                                                        </InfoParagraph>
                                                    )}
                                                </div>
                                            )
                                        })}
                                        <div className="text-right">
                                            <button
                                                onClick={handleAddPhoneClick}
                                                className="btn btn-outline-secondary"
                                            >
                                                {translate('btn.add_new_phone')}
                                            </button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {selectedTab.name === 'ContactSystemOptions' && (
                                <React.Fragment>
                                    <div className="mb-6">
                                        <CardSubTitle
                                            subtitle={translate('text.advanced_contact_settings')}
                                            strapline={translate('text.advanced_contact_settings_strapline')}
                                        />

                                        <div className="border-t border-tm-gray-200">
                                            <div
                                                className="relative flex items-center py-4 border-b border-tm-gray-200">
                                                <div className="min-w-0 flex-1 text-sm">
                                                    <label htmlFor="person-3"
                                                           className="font-medium text-tm-gray-900 select-none">
                                                        {translate('field.HighImportance')}
                                                    </label>

                                                    <p className="text-tm-gray-700">Flags contact as important.</p>
                                                </div>

                                                {fieldsToHtml(Object.values(Object.assign({}, includeFields(fieldsAdvanced, ['HighImportance']))), translate, handleAdvancedInputChange)}
                                            </div>

                                            <div
                                                className="relative flex items-center py-4 border-b border-tm-gray-200">
                                                <div className="min-w-0 flex-1 text-sm">
                                                    <label htmlFor="person-3"
                                                           className="font-medium text-tm-gray-900 select-none">
                                                        {translate('field.IsSystemUser')}
                                                    </label>

                                                    <p className="text-tm-gray-700">System user has access to TMS
                                                        application.</p>
                                                </div>

                                                {fieldsToHtml(Object.values(Object.assign({}, includeFields(fieldsAdvanced, ['IsSystemUser']))), translate, handleAdvancedInputChange)}
                                            </div>

                                            <Accordion expanded={fieldsAdvanced.IsSystemUser.value}>
                                                <div
                                                    className={'grid gap-4 bg-tm-gray-100 px-4 pt-4 pb-6 border-b border-tm-gray-200'}>
                                                    {fieldsToHtml(Object.values(Object.assign({}, includeFields(fieldsAdvanced, ['Groups', 'Roles']))), translate, handleAdvancedInputChange, selects)}
                                                </div>
                                            </Accordion>

                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {selectedTab.name === 'Notes' && (
                                <React.Fragment>
                                    <div className="mb-6">
                                        <CardSubTitle
                                            subtitle={translate('text.notes')}
                                            strapline={translate('text.notes_strapline')}
                                        />

                                        <div className="grid grid-cols-1 gap-4">
                                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['Notes']))), translate, handleInputChange)}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {selectedTab.name === 'Organization' && (
                                <React.Fragment>
                                    <div className="mb-6">
                                        <CardSubTitle
                                            subtitle={translate('text.Organization')}
                                            strapline={translate('text.organization_strapline')}
                                        />

                                        <div className="grid grid-cols-1 gap-4">
                                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['MemberOfOrganization', 'Organization', 'DepartmentID', 'OrganizationID']))), translate, handleInputChange, OrganizationMetadata)}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {selectedTab.name === 'ApproveLimit' && (
                                <React.Fragment>
                                    <div className="mb-6">
                                        <CardSubTitle
                                            subtitle={translate('text.ApproveLimit')}
                                            strapline={translate('text.approve_limit_strapline')}
                                        />

                                        <div className="grid grid-cols-1 gap-4">
                                            {fieldsToHtml(Object.values(Object.assign({}, includeFields(approveLimitFields, ['CanApproveExpense', 'MaxExpenseValue', 'CanIssueMoneyCode', 'MaxMoneyCodeValue']))), translate, handleApproveLimitInputChange)}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {selectedTab.name === 'Document' && (
                                <div className="h-full flex flex-col justify-between">
                                    <CardSubTitle
                                        className="mb-6"
                                        subtitle={translate('text.contact_document')}
                                        strapline={!data?.DocumentPath ? translate("text.contact_document_strapline") : undefined}
                                    />
                                    {/* Display documents that will be uploaded on save action */}
                                    <FileList
                                        addClass="mb-8"
                                        files={files}
                                        onFileDelete={handleRemoveFile}
                                        hideFileDownload={files.length}
                                        onFileDownload={handleDownloadDocumentsClick}
                                    />

                                    {(!!files.length && (
                                        <div>
                                            <FileViewer
                                                fileType={files[0]?.path ? files[0]?.path.split('.').pop() : ""}
                                                filePath={URL.createObjectURL(files[0])}
                                                onError={(e) => {
                                                    console.log(e)
                                                }}
                                            />
                                        </div>
                                    ))}

                                    {files.length === 0 && hasDocumentPath && (
                                        <div>
                                            {/* Display existing documents */}
                                            <FileList
                                                addClass="mb-8"
                                                filePath={data?.DocumentPath}
                                                onFileDelete={() => {
                                                    setHasDocumentPath(false);
                                                    setIsStateDirty(true);
                                                }}
                                                hideFileDownload={files.length}
                                                onFileDownload={handleDownloadDocumentsClick}
                                            />

                                            <FileViewer
                                                fileType={data?.DocumentPath ? data?.DocumentPath.split('.').pop() : ""}

                                                filePath={Env.getApiUrl("api/" + Resources.ContactDocuments, Object.assign({}, {
                                                    ContactID: data?.ContactID,
                                                    token: getJWT().access_token,
                                                    date: moment(data.CreateUpdateDate, DEFAULT_DATABASE_DATETIME_FORMAT).unix(),
                                                    type: 1
                                                }))}

                                                onError={(e) => {
                                                    console.log(e)
                                                }}
                                            />
                                        </div>
                                    )}

                                    <div className={'relative h-full'}>
                                        {maxFiles > files.length && !hasDocumentPath && (
                                            <DropZoneAlt
                                                className="h-full"
                                                maxFilesAccepted={maxFiles}
                                                accept={DEFAULT_DOCUMENTS_ACCEPTABLE_EXTENSIONS}
                                                translate={translate}
                                                onAcceptFiles={handleSelectDocuments}
                                                content={(isDragAccept, isDragReject) => {
                                                    return (
                                                        <React.Fragment>
                                                            <div className={classNames(
                                                                isDragAccept ? "border-2 border-dashed border-green-600 bg-green-600 bg-opacity-10" : undefined,
                                                                isDragReject ? " border-2 border-dashed border-red-600 bg-red-600 bg-opacity-10" : undefined,
                                                                !isDragAccept && !isDragReject ? "border-transparent" : undefined,
                                                                "pb-4 h-[calc(100%-3.25rem)] min-h-[8rem] relative rounded-card"
                                                            )}>
                                                                <React.Fragment>
                                                                    {!files.length && (
                                                                        <div
                                                                            className="rounded-card absolute inset-0 flex items-center justify-center border-2 border-dashed border-tm-gray-300">
                                                                            <div className="text-center">
                                                                                <p className="text-lg">click here or
                                                                                    drop
                                                                                    file to
                                                                                    upload</p>
                                                                                <p className="text-base text-tm-gray-500">(Max
                                                                                    1 file, 25
                                                                                    mb
                                                                                    per file, document and image files
                                                                                    only)</p>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                </React.Fragment>
                                                            </div>

                                                            <div
                                                                className="absolute bottom-0 -left-6 -right-6 px-6 cursor-pointer py-1 border-t border-primary border-dotted bg-primary-transparent">
                                                                Attach files by dragging and dropping them or <span
                                                                className="text-primary-tint font-semibold">click here</span> to
                                                                select them.
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ModalFooter
                buttonLabel={translate('btn.save')}
                buttonID="create-contact-btn"
                onButtonClick={handleSaveClick}
                buttonDisabled={!isStateDirty}
                closeButtonLabel={translate('btn.close')}
                closeButtonID="close-contact-btn"
                onClose={() => {
                    onClose()
                }}
            >
                {(!!invalidTab || !!errorMessages.length) && (
                    <InfoParagraph type="danger">

                        {errorMessages.map(error => (
                            <p key={error.field}>{translate(error.message)}</p>
                        ))}

                        {!!invalidTab && (
                            <p>{translate('text.required_fields')}</p>
                        )}
                    </InfoParagraph>
                )}
            </ModalFooter>
        </React.Fragment>
    )
}
