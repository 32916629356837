import React, {Component} from 'react'
import TrimbleMaps from "@trimblemaps/trimblemaps-js";

TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_API_KEY;

export default class TrimbleMap extends Component {
    constructor(props) {
        super(props);
        this.myMap = this.props.mapRef;
    }

    componentDidMount() {
        this.setupMap()
    }

    setupMap = () => {
        this.myMap.current = new TrimbleMaps.Map({
            container: "trimbleMap",
            center: new TrimbleMaps.LngLat(-96, 35),
            zoom: 3,
            style: TrimbleMaps.Common.Style.TRANSPORTATION,
            region: TrimbleMaps.Common.Region.NA,
            displayControlsDefault: true
        });

        this.myMap.current.addControl(new TrimbleMaps.NavigationControl());

        this.myMap.current.on('load', () => {
            this.props.stops.forEach((stop, index) => {
                const stopLngLat = new TrimbleMaps.LngLat(stop.Longitude, stop.Latitude)
                const {LocationName, AddressName, CityName, StateAbbreviation, IsStopCompleted} = stop
                const title = LocationName + (AddressName ? '\n' + AddressName : "") + '\n' + CityName + ', ' + StateAbbreviation;


                let stopColor = ''

                if (IsStopCompleted) {
                    stopColor = '#32CD32'
                } else {
                    stopColor = '#6495ED'
                }

                let content;
                content =
                    ` <svg class="w-8 h-8">
                            <circle cx="16" cy="16" r="16" fill=${stopColor} />
                            <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="20px" font-family="Arial" dy=".3em">${index + 1}</text>
                            </svg>`


                const svgContent = document.createElement('div');
                svgContent.title = title
                svgContent.innerHTML = content;
                new TrimbleMaps.Marker({
                    element: svgContent
                }).setLngLat([stopLngLat.lng, stopLngLat.lat]).addTo(this.myMap.current);
            })

            this.props?.tracks?.forEach((it, index) => {
                if (!!it?.Longitude && !!it?.Latitude) {
                    const trackLngLat = new TrimbleMaps.LngLat(it.Longitude, it.Latitude);
                    const {AddressName, CityName} = it
                    const title = (AddressName ? '\n' + AddressName : "") + '\n' + CityName;

                    let content;
                    if (index === 0) {
                        // Only if last/current location - truck icon
                        content = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#42a318" stroke-width="1" stroke="currentColor" class="5 h-5">' +
                            '<path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z"/>' +
                            '<path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z"/>' +
                            '<path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"/>' +
                            '</svg>'

                        const svgContent = document.createElement('div');
                        svgContent.title = title
                        svgContent.innerHTML = content;
                        new TrimbleMaps.Marker({
                            element: svgContent
                        }).setLngLat([trackLngLat.lng, trackLngLat.lat]).addTo(this.myMap.current);
                    }
                }
            })

            // Add tracks
            if (this.props?.tracks?.length > 1) {
                const tracksCoordinates = this.props.tracks.reduce((memo, it) => {
                    memo.push([it.Longitude, it.Latitude])

                    return memo;
                }, []);

                let geoJsonActualPathData = {
                    type: 'FeatureCollection',
                    features: [{
                        type: 'Feature',
                        properties: {
                            name: 'Actual Path'
                        },
                        geometry: {
                            type: 'LineString',
                            coordinates: tracksCoordinates
                        }
                    }]
                };

                this.myMap.current.addSource('actualPath', {
                    type: 'geojson',
                    data: geoJsonActualPathData
                });

                this.myMap.current.addLayer({
                    id: 'actualPathLayer',
                    type: 'line',
                    source: 'actualPath',
                    paint: {
                        'line-width': 4,
                    }
                });
            }

            //this.myMap.on('click', (e) => console.log(e)); // on map click example
        });
    }

    render() {
        return (
            <div
                id="trimbleMap"
                className={this.props.className ?? "border border-tm-gray-300 rounded-lg overflow-hidden mt-6"}
            />
        )
    }
}
