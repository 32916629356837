import React, {Component} from 'react'
import {connect} from 'react-redux'
import DashboardView from './dashboardView'
import MapView from './mapView'
import Resources from '../../data/services/resources'
import {READ_PERM} from '../../util/util-constants'
import {checkPerm, getProp} from '../../common/util/util-helpers'
import CalendarTab from "./calendar-tab";
import {Cog8ToothIcon} from "@heroicons/react/20/solid";
import Layout from "../../common/components/layout";
import Page from "../../common/components/layout/layout-components/page";
import NavResponsive from "../../common/components/nav-responsive";
import MobileTabs from "../../common/components/tabs-navigation/mobile-tabs";
import {getDecodedJWTJson} from "../../common/util/util-auth";
import LocalStorage from "../../common/util/localStorage";
import {LINE_OF_BUSINESS_PRIMARY_CARRIER} from "../../common/util/util-consts";

class HomeView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabs: [
                {
                    name: 'dashboard',
                    resource: 'dashboard',
                    visible: checkPerm(Resources.Routes, READ_PERM) ||
                        checkPerm(Resources.NotesPersonal, READ_PERM) ||
                        checkPerm(Resources.MineTasks, READ_PERM),
                    current: true
                }, {
                    name: 'fleet_map',
                    resource: 'fleet_map',
                    visible: checkPerm(Resources.Vehicles, READ_PERM) && getProp(LocalStorage.get('user'), 'Company.LineOfBusiness', []).includes(LINE_OF_BUSINESS_PRIMARY_CARRIER),
                    current: false
                },
                {
                    name: 'calendar',
                    resource: 'calendar',
                    visible: checkPerm(Resources.Calendar, READ_PERM),
                    current: false
                }],
            isDashboardSettingsModalVisible: false,
            dashboardKeyUpdate: 0,
            isMobileView: false
        }

        this.user = getDecodedJWTJson();
    }

    handleTabChange = (tabResource) => {
        this.setState({
            tabs: this.state.tabs.map((it) => {
                it.current = it.resource === tabResource
                return it
            }),
        })
    }

    handleSettingsToggleClick = () => {
        this.setState({
            isDashboardSettingsModalVisible: !this.state.isDashboardSettingsModalVisible
        })
    }

    updateDashboardTab = () => {
        this.setState({
            dashboardKeyUpdate: this.state.dashboardKeyUpdate + 1
        })
    }

    handleBreakpointChange = (breakpoint) => {
        if (breakpoint.index <= 1 && !this.state.isMobileView) {
            this.setState({
                isMobileView: true
            });
        } else if (breakpoint.index > 1 && this.state.isMobileView) {
            this.setState({
                isMobileView: false
            });
        }
    }

    render() {
        const {translate, dispatch, resource} = this.props
        const user = this.user;

        const userName = user.FirstName + ' ' + user.LastName

        const currentTab = this.state.tabs.find(tab => tab.current)

        return (
            <Layout
                customPageTitle="Dashboard"
                onBreakpoinChange={this.handleBreakpointChange}
                {...this.props}
            >
                <Page>
                    <div className="flex gap-4 items-center justify-between">
                        {currentTab.name === 'dashboard' ?
                            <h1 className="mr-5 text-3xl">Welcome {userName}</h1>
                            :
                            currentTab.name === 'fleet_map' ?
                                <h1 className="mr-5 text-3xl">{translate('page.heading.map')}</h1>
                                : <h1 className="mr-5 text-3xl">{translate('page.heading.calendar')}</h1>
                        }

                        {currentTab.name === 'dashboard' && (
                            <button
                                className="btn btn-primary"
                                onClick={this.handleSettingsToggleClick}
                            >
                                <span className="hidden md:inline">
                                    {translate('btn.dashboard_settings')}
                                </span>

                                <span className="inline md:hidden">
                                    <Cog8ToothIcon className="w-5 h-5" />
                                </span>
                            </button>
                        )}
                    </div>

                    <div className="mb-5">
                        <NavResponsive
                            addClass="hidden sm:block"
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />

                        <MobileTabs
                            tabs={this.state.tabs}
                            onTabChange={this.handleTabChange}
                            translate={translate}
                        />
                    </div>

                    <div>
                        {currentTab.name === 'dashboard' && (
                            <DashboardView
                                key={this.state.dashboardKeyUpdate}
                                onSettingsToggleClick={this.handleSettingsToggleClick}
                                isDashboardSettingsModalVisible={this.state.isDashboardSettingsModalVisible}
                                updateDashboardTab={this.updateDashboardTab}
                                isMobileView={this.state.isMobileView}
                                {...this.props}
                            />
                        )}

                        {currentTab.name === 'fleet_map' && (
                            <MapView {...this.props} />
                        )}

                        {currentTab.name === 'calendar' && (
                            <CalendarTab
                                translate={translate}
                                dispatch={dispatch}
                                isLoading={getProp(resource, "isLoading", false)}
                                data={getProp(resource.data, "dashboard/calendar", [])}
                            />
                        )}
                    </div>
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(HomeView)
