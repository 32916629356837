export function getDialogInfoResource(data) {
    return {
        type: 'GET_DIALOG_INFO_RESOURCE',
        data: data
    };
}

export function doneGetDialogInfoResource(data) {
    return {
        type: 'DONE_GET_DIALOG_INFO_RESOURCE',
        data: data
    };
}

export function errorGetDialogInfoResource(data) {
    return {
        type: 'ERROR_GET_DIALOG_INFO_RESOURCE',
        data: data
    };
}

export function clearDialogInfoResource() {
    return {
        type: 'CLEAR_DIALOG_INFO_RESOURCE',
    };
}

export function deleteDialogInfoResource(data) {
    return {
        type: 'DELETE_DIALOG_INFO_RESOURCE',
        data: data
    };
}

export function doneDeleteDialogInfoResource(data) {
    return {
        type: 'DONE_DELETE_DIALOG_INFO_RESOURCE',
        data: data
    };
}

export function errorDeleteDialogInfoResource(data) {
    return {
        type: 'ERROR_DELETE_DIALOG_INFO_RESOURCE',
        data: data
    };
}
