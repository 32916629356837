import React, {useRef, useState} from 'react'
import { classNames } from "../../../../common/util/util-helpers";
import Resources from "../../../../data/services/resources";
import TrimbleMap from "../../../../common/components/map/TrimbleMap";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import Tippy from "@tippyjs/react";
import ArrowsPointingOutIcon from "@heroicons/react/24/outline/ArrowsPointingOutIcon";
import { toFrontDateTimeFromUTC } from '../../../../common/util/util-dates'
import Subtitle from "../../../../common/components/layout/layout-components/page/subtitle";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import {Loader, LoaderLarge} from "../../../../common/components/loader";

export default function LoadInfoMap({
                                        innerRef,
                                        isSectionExpanded,
                                        toggleExpandSection,
                                        stops,
                                        onTabChange,
                                        needsUpdate,
                                        loadDispatchData,
                                        isLoading,
                                        translate
                                    }) {

    const loadMapRef = useRef();
    const LastDetectedAddress = loadDispatchData.LastDetectedAddress;
    const LastDetectedAddressEtaTime = toFrontDateTimeFromUTC(loadDispatchData.LastDetectedAddressEtaTime);
    const LastDetectedEta = toFrontDateTimeFromUTC(loadDispatchData.LastDetectedEta);
    const hasETAData = !!LastDetectedAddress && !!LastDetectedAddressEtaTime && LastDetectedEta;

    const [weatherAlertLegend, setWeatherAlertLegend] = useState(false)
    let sortedStops = stops.sort((a, b) => a.StopOrder - b.StopOrder);

    return (
        <div ref={innerRef} className={classNames(
            "grid grid-cols-12 px-6 border-t border-tm-gray-300 relative py-3"
        )}>
            <div className="col-span-full py-3">
                <div className="flex items-center">
                    <button
                        className="btn btn-icon w-8 h-8 -ml-2 md mr-2"
                        onClick={() => toggleExpandSection("LoadInfoMap")}
                    >
                        <ChevronRightIcon
                            className={
                                classNames(
                                    "w-5 h-5",
                                    isSectionExpanded ? "rotate-90" : undefined
                                )
                            }
                        />
                    </button>

                    <Subtitle
                        subtitle={translate("text.load_map")}
                    />

                    {!!needsUpdate && (
                        <Tippy
                            content={
                                <div>
                                    <p>
                                        {translate("text.stops_updated")}
                                    </p>
                                    <p>
                                        {translate("text.miles_calculation_needs_update")}
                                    </p>
                                </div>
                            }
                        >
                            <button className="ml-2 focus:ring ring-offset-2 rounded-full">
                                <ExclamationTriangleIcon
                                    className="w-5 h-5 text-yellow-600 group-focus:ring-2 ring-offset-2 rounded-full"
                                />
                            </button>
                        </Tippy>
                    )}

                    <div className="ml-auto flex items-center">
                        <button
                            className="btn-icon"
                            onClick={() => onTabChange(Resources.LoadTracks)}
                        >
                            <ArrowsPointingOutIcon className="w-5 h-5 "/>
                        </button>
                    </div>
                </div>

                {hasETAData && isSectionExpanded && (
                    <InfoBar>
                        Last detected address {LastDetectedAddress} at {LastDetectedAddressEtaTime}.
                        Estimated arrival at {LastDetectedEta}.
                    </InfoBar>
                )}

                {isSectionExpanded && (
                    <div
                        className="relative w-full h-96 mt-2 rounded-xl border border-tm-gray-300 overflow-hidden"
                    >
                        {!isLoading && (
                            <TrimbleMap
                                className={"absolute inset-0"}
                                stops={sortedStops}
                                mapRef={loadMapRef}
                                weatherAlertLegend={weatherAlertLegend}
                            />
                        )}
                    </div>
                )}
            </div>

            {isLoading && (
                <React.Fragment>
                    {isSectionExpanded && (
                        <LoaderLarge stripesBg={true}/>
                    )}

                    {!isSectionExpanded && (
                        <Loader stripesBg={true}/>
                    )}
                </React.Fragment>
            )}
        </div>
    )
}
