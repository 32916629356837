import React, {useState} from 'react'
import {useDispatch} from "react-redux";
import LocalStorage from "../../../util/localStorage";
import Tippy from "@tippyjs/react";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {getJWT} from "../../../util/util-auth";
import Resources from "../../../../data/services/resources";
import {isTodayDate} from "../../../util/util-dates";
import {LoaderSmall} from "../../loader";
import Subtitle from "../../layout/layout-components/page/subtitle";
import CommentForm from "../../forms/CommentForm";
import OrganizationInfoDialog from "../../modal/modal-global/organization-info-dialog";
import ContactInfoDialog from "../../modal/contact-info-dialog";
import Env from "../../../../util/env";

const CommentsDialogTab = ({
                               commentsResourceName,
                               parentResourceName,
                               translate,
                               primaryKey,
                               selectedItem,
                               createDispatchAction,
                               deleteDispatchAction,
                               onDeleteComment,
                               Comments,
                               isLoading
                           }) => {

    /** Constants
     ================================================================= */
    const dispatch = useDispatch()

    const [confirmDelete, setConfirmDelete] = useState(-1)
    const [organizationID, setOrganizationID] = useState(null)
    const [isOrganizationDialogOpen, setIsOrganizationDialogOpen] = useState(false)
    const [contactID, setContactID] = useState(null)
    const [isContactDialogOpen, setIsContactDialogOpen] = useState(false)

    const orgID = selectedItem.OrganizationID
    const contID = selectedItem.ContactID

    /** Helpers
     ================================================================= */
    const getQuery = () => {
        return {
            [primaryKey]: selectedItem[primaryKey]
        }
    }

    const handleDeleteCommentClick = (index) => {
        setConfirmDelete(index)
    }

    const cancelConfirmDelete = () => {
        setConfirmDelete(-1)
    }

    const handleToggleOrganizationInfoDialog = (id) => {
        setOrganizationID(id)
        setIsOrganizationDialogOpen(true)
    }

    const handleToggleContactInfoDialog = (id) => {
        setContactID(id)
        setIsContactDialogOpen(true)
    }

    /** Data events
     ================================================================= */
    const handleAddCommentClick = (comment) => {
        const params = {
            ...getQuery(),
            ...(primaryKey === 'ExpenseID' && {ExpenseCommentNote: comment}),
            ...(primaryKey === 'InvoiceID' && {InvoiceCommentNote: comment})
        }

        dispatch(createDispatchAction({
            user: LocalStorage.get('user'),
            query: getQuery(),
            params,
            resource: commentsResourceName,
            piggyResource: parentResourceName,
            errorMessage: true,
            successMessage: translate('text.comment_added'),
        }))
    }

    const handleConfirmDeleteCommentClick = (id) => {
        if (typeof(onDeleteComment) === 'function') onDeleteComment(id)

        else {
            const query = {
                ...getQuery(),
                ...(primaryKey === 'ExpenseID' && {ExpenseCommentID: id}),
                ...(primaryKey === 'InvoiceID' && {InvoiceCommentID: id})
            }

            setConfirmDelete(-1)
            dispatch(deleteDispatchAction({
                user: LocalStorage.get('user'),
                query,
                resource: commentsResourceName,
                piggyResource: parentResourceName,
                piggyQuery: getQuery(),
                errorMessage: true, successMessage: translate('text.comment_deleted'),
            }))
        }

    }

    /** Render
     ================================================================= */
    const getCommentsHtml = (comments) => {
        return comments.map((comment, i) => {
            const isMe = comment.UpdatedByContactID === LocalStorage.get('user')?.Contact?.ContactID

            return (
                <div key={i} className="block hover:bg-tm-gray-50">
                    <div className="p-4">
                        <div className="flex justify-between items-start">
                            <div className="flex items-start">
                                <img className="h-12 w-12 rounded-full mr-5"
                                     src={comment.ImagePath ? Env.getApiUrl('api/' + Resources.ContactImage, {
                                         token: getJWT().access_token,
                                         ContactID: comment.UpdatedByContactID
                                     }) : '/images/avatar.png'} alt={comment.ImagePath}/>
                                <div>
                                    <p
                                        className="text-sm font-medium text-indigo-600 truncate">{comment.FirstName} {comment.LastName}</p>
                                    <p
                                        className=" flex items-center text-sm text-tm-gray-500">{isTodayDate(comment.CreateUpdateDate, translate)}</p>
                                </div>
                            </div>

                            <div>
                                {!!isMe && (
                                    <React.Fragment>
                                        {confirmDelete !== i && (
                                            <Tippy
                                                content={translate('btn.delete')}
                                            >
                                                <button
                                                    className="bg-tm-gray-100 table-action mx-1 bg-inverse p-2 rounded-full text-tm-gray-700 hover:bg-tm-gray-200 focus:outline-none focus:ring-2 ring-offset-inverse ring-link focus:ring-offset-2"
                                                    onClick={() => {
                                                        handleDeleteCommentClick(i)
                                                    }}
                                                >
                                                    <TrashIcon className="h-4 w-4"/>
                                                </button>
                                            </Tippy>
                                        )}

                                        {confirmDelete === i && (
                                            <span className="py-1 px-2">
                                                {translate('text.are_you_sure')}
                                                <button type="button"
                                                        onClick={() => handleConfirmDeleteCommentClick(comment.InvoiceCommentID || comment.ExpenseCommentID)}
                                                        className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-primary">
                                                    {translate('btn.yes')}
                                                </button>
                                                <button type="button"
                                                        onClick={() => cancelConfirmDelete()}
                                                        className="ml-2 inline-flex items-center px-2.5 py-1.5 border border-tm-gray-300 shadow-sm text-xs font-medium rounded text-tm-gray-700 bg-white hover:bg-tm-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary focus:ring-offset-inverse">
                                                    {translate('btn.cancel')}
                                                </button>
                                            </span>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>

                        <div className={'pl-12 pr-4 ml-5'}>
                            {comment.InvoiceCommentNote || comment.ExpenseCommentNote}
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className={"px-4 py-5"}>
            {isLoading ? (
                <div className="p-5 text-center">
                    <LoaderSmall/>
                </div>
            ) : (
                <div>
                    <div className={"flex justify-between"}>
                        <Subtitle
                            className="mb-4"
                            subtitle={translate('tab.comments')}
                        />

                        <button
                            className={"mb-4"}
                            onClick={() => orgID ? handleToggleOrganizationInfoDialog(orgID) : handleToggleContactInfoDialog(contID)}>
                            <InformationCircleIcon className={"w-5 h-5 text-blue-500"}/>
                        </button>
                    </div>

                    {(Comments.length === 0) && (
                        <div
                            className={'mb-3 mt-1 text-tm-gray-700'}>{translate('text.no_comments')}</div>)}


                    {(Comments.length > 0) && getCommentsHtml(Comments)}

                    <CommentForm
                        style={{width: '100%'}}
                        maxLength={8000}
                        rows={3}
                        required
                        fieldName={translate('field.Comment') + ' *'}
                        translate={translate}
                        onAddComment={(_, value) => handleAddCommentClick(value)}
                    />
                </div>
            )}

            {(isOrganizationDialogOpen &&
                <OrganizationInfoDialog
                    show={isOrganizationDialogOpen}
                    translate={translate}
                    organizationID={organizationID}
                    handleClose={() => setIsOrganizationDialogOpen(false)}
                />
            )}

            {(isContactDialogOpen &&
                <ContactInfoDialog
                    show={isContactDialogOpen}
                    translate={translate}
                    contactID={contactID}
                    handleClose={() => setIsContactDialogOpen(false)}
                />
            )}
        </div>
    )
}

export default CommentsDialogTab;