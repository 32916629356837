import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {classNames} from "../../../../common/util/util-helpers";
import {LoaderLarge} from "../../../../common/components/loader";
import InfoBar from "../../../../common/components/info-paragraph/info-bar";
import FieldsToHtml from "../../../../common/components/fields/fields-to-html";

export default function CarriersImportDialogNumber({translate, fetchCarrier, resource, isLoading}) {
    const [fields, setFields] = useState(getInitialFields(translate));
    const [hasMCError, setHasMCError] = useState(false);
    const [hasDOTError, setHasDOTError] = useState(false);

    function getInitialFields() {
        return {
            NumberType: new Field("NumberType", 1, [], false, 'button-group', {
                data: {1: translate("btn.DOTNumber"), 2: translate("btn.MCNumber")},
                addClass: 'w-full',
                labelType: 'float',
                hideLabel: true
            }),
            Number: new Field("Number", "", ['empty'], false, "text", {
                labelType: 'float',
                hideLabel: true
            }, {placeholder: "1234567"}),
        }
    }


    const handleInputChange = (name, value) => {
        const fieldsUpdate = fields;
        if ("NumberType" === name) {
            fieldsUpdate.Number.props.placeholder = value === 1 ? "1234567" : "MC-987654";
        }

        setHasMCError(false);
        setHasDOTError(false);

        setFields(FieldsManager.updateField(fieldsUpdate, name, value));
    }

    useEffect(() => {
        if (fields.NumberType.value !== 1 && resource.error) {
            setHasMCError(true);
        }

        if (fields.NumberType.value === 1 && resource.error) {
            setHasDOTError(true);
        }
    }, [resource]);

    useEffect(() => {
        if (isLoading) {
            setHasMCError(false);
            setHasDOTError(false);
        }
    }, [isLoading])

    return (
        <div className="relative">
            {hasMCError && (
                <div className="p-1">
                    <InfoBar type="danger" addClass="justify-center">
                        {translate("text.invalid_mc")} 
                    </InfoBar>
                </div>
            )}

            {hasDOTError && (
                <div className="p-1">
                    <InfoBar type="danger" addClass="justify-center">
                       {translate("text.invalid_dot")} 
                    </InfoBar>
                </div>
            )}

            <form
                className={
                    classNames(
                        "w-56 text-center mx-auto pt-16 pb-32",
                        isLoading ? "invisible" : undefined
                    )
                }
                onSubmit={(e) => {
                    e.preventDefault();
                    !!fields.Number.value && fetchCarrier(
                        fields.NumberType.value === 1 ? "USDOTNumber" : "MC",
                        fields.Number.value
                    )
                }}
            >
                <p className="text-lg">I have a</p>

                <div className="space-y-5 mt-3 mb-5">
                    <FieldsToHtml
                        fieldsState={fields}
                        onInputChange={handleInputChange}
                        translate={translate}
                    />
                </div>

                <button
                    disabled={!fields.Number.value}
                    className="btn btn-primary w-full justify-center"
                >
                    Get carrier
                </button>

            </form>

            {isLoading && (
                <LoaderLarge />
            )}
        </div>
    )
}

