import React, {useEffect, useState} from "react";
import {getLookup} from "../../../common/util/util-helpers";
import {getResource, updateResource} from "../../../data/actions/resource";
import LocalStorage from "../../../common/util/localStorage";
import {Field, FieldsManager} from "../../../data/services/fields";
import {useDispatch, useSelector} from "react-redux";
import PageFooter from "../../../common/components/layout/layout-components/page/page-footer";
import {LoaderLarge} from "../../../common/components/loader";
import InfoParagraph from "../../../common/components/info-paragraph";
import CardSubTitle from "../../../common/components/card/card-sub-title";
import Card from "../../../common/components/card";
import TabTitle from "../../../common/components/layout/layout-components/tab-title";
import {fieldsToHtml, fillFieldsFromData, includeFields} from "../../../common/util/util-fields";

const BillingTab = ({translate, resourcePath}) => {
    /** Store
     ================================================================= */
    const dispatch = useDispatch();
    const resource = useSelector((state) => state.resource);
    /** Constants
     ================================================================= */
    const selects = {
        FactoringStateID: getLookup('State')
    }

    /** Fields/Data definitions
     ================================================================= */
    const getFields = (item = null) => {
        let fieldTemplates = {
            MinimumMargin: new Field('MinimumMargin', '', ['float'], false, 'float'),
            CreditLimit: new Field('CreditLimit', '', ['float'], false, 'float'),
            AllowMarginOverride: new Field('AllowMarginOverride', '', [''], false, 'checkbox', {
                addContainerClass: "col-start-1",
                labelType: "float"
            }),
            AllowCreditLimitOverride: new Field('AllowCreditLimitOverride', '', [''], false, 'checkbox', {labelType: "float"}),
            FactoringLegalName: new Field('FactoringLegalName', '', [''], false, 'text'),
            FactoringAddressName: new Field('FactoringAddressName', '', [''], false, 'text'),
            FactoringCityName: new Field('FactoringCityName', '', [''], false, 'text'),
            FactoringStateID: new Field('FactoringStateID', '', [''], false, 'select'),
            FactoringPostalCode: new Field('FactoringPostalCode', '', [''], false, 'text'),

            // Factoring Payment data
            AccountName: new Field('AccountName', '', [''], false, 'text'),
            AccountNumber: new Field('AccountNumber', '', [''], false, 'text'),
            RoutingNumber: new Field('RoutingNumber', '', [''], false, 'text'),
            BankName: new Field('BankName', '', [''], false, 'text'),
        }

        return fillFieldsFromData(fieldTemplates, item)
    }

    /** Helpers
     ================================================================= */
    const getResourceName = () => {
        return resourcePath
    }

    /** State
     ================================================================= */
    const [fields, setFields] = useState(getFields())
    const [canSubmit, setCanSubmit] = useState(false)

    /** Data events
     ================================================================= */
    const onSubmit = () => {
        const validatedFields = FieldsManager.validateFields(fields)
        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            dispatch(updateResource({
                user: LocalStorage.get('user'),
                params: FieldsManager.getFieldKeyValues(validatedFields),
                resource: getResourceName(),
                piggyResource: getResourceName(),
                errorMessage: true, successMessage: 'Resource updated successfully!'
            }))
            setCanSubmit(false)
        } else {
            setFields(validatedFields)
        }
    }

    const handleInputChange = (name, value) => {
        setFields(FieldsManager.updateField(fields, name, value))
        setCanSubmit(true)
    }

    const handleFormCancel = () => {
        setFields(getFields(resource.data))
        setCanSubmit(false)
    }
    /** UI events
     ================================================================= */
    const fetchData = () => {
        dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: getResourceName()
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [])


    useEffect(() => {
        if (resource?.data && !resource?.isLoading) {
            setFields(getFields(resource.data))
        }
    }, [resource])

    /** Render
     ================================================================= */
    const fieldsGeneralHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['MinimumMargin', 'CreditLimit', 'AllowMarginOverride', 'AllowCreditLimitOverride']))), translate, handleInputChange, selects);
    const fieldsFactoringHtml = fieldsToHtml(Object.values(Object.assign({}, includeFields(fields, ['FactoringLegalName', 'FactoringAddressName', 'FactoringCityName', 'FactoringStateID', 'FactoringPostalCode', 'AccountName', 'AccountNumber', 'RoutingNumber', 'BankName']))), translate, handleInputChange, selects);

    return (
        <div>
            <div className="max-w-3xl mx-auto space-y-4">
                <TabTitle
                    text={translate('page.heading.Billing')}
                />

                <Card addClass={'relative'}>
                    <CardSubTitle
                        text={translate('text.General')}
                    />
                    <InfoParagraph
                        type="info"
                    >
                        {translate("text.customerInfoPricingDefaults")}
                    </InfoParagraph>

                    <div className="grid grid-cols-2 gap-5">
                        {fieldsGeneralHtml}
                    </div>

                    {resource.isLoading && (
                        <LoaderLarge stripesBg={true}/>
                    )}
                </Card>

                <Card addClass={'relative'}>
                    <CardSubTitle
                        text={translate('text.Factoring')}
                    />
                    <InfoParagraph
                        type="info">{translate("text.factoringSettingsInfo")} </InfoParagraph>
                    <div className="grid grid-cols-2 gap-5">
                        {fieldsFactoringHtml}
                    </div>

                    {resource.isLoading && (
                        <LoaderLarge stripesBg={true}/>
                    )}
                </Card>

            </div>

            <PageFooter
                translate={translate}
                canSubmit={canSubmit}
                actionCancel={handleFormCancel}
                actionSubmit={onSubmit}
            />
        </div>
    )
}

export default BillingTab
